import React, { useEffect, useState, useMemo, useContext } from 'react';
import { Spin, Col, Row, Button } from 'antd';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

// import mixpanel from 'mixpanel-browser';

import PublicHeader from 'Components/PublicHeader';
import ShowOn from 'Util/ShowOn';
import CustomizedButton from "Components/CustomizedButton";
import { get, API_ROOT } from 'Util/API';
import { GlobalContext } from 'Store/store';

import useOnUpdateOrder from './useOnUpdateOrder';
import useOnUpdateInvoiceStatus from './useOnUpdateInvoiceStatus';
import Orders from './Orders';

const BulkOrderAfterPayment = (props) => {
  const { match, location, history } = props;
  const orderIds = location.state?.orderIds;
  const paymentMethodId = location.state?.paymentMethodId;
  const [state] = useContext(GlobalContext);
  const { companyDetails, settings } = state.global;
  const { message, isListen: isMessageListenerOn } = state.sse;
  const [showContent, setShowContent] = useState(false);
  const [currentOrders, setCurrentOrders] = useState(null);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const currentOrderIds = useMemo(() => currentOrders?.map((item) => item.id), [currentOrders]);
  const updatedOrder = useOnUpdateOrder(currentOrderIds, message);
  const activeOrders = useMemo(() => currentOrders?.filter((item) => item.consignmentNo), [currentOrders]);
  const activeOrderIds = useMemo(() => activeOrders?.map((item) => item.id), [activeOrders]);

  const currentInvoiceId = location.state?.invoiceId || match.params.invoiceId;
  const invoiceStatusRes = useOnUpdateInvoiceStatus(currentInvoiceId, message);

  useEffect(() => {
    if (updatedOrder) {
      const updatedOrders = currentOrders.map((item) => {
        if (item.id === updatedOrder.id) {
          return updatedOrder
        } else {
          return item;
        }
      })
      setCurrentOrders(updatedOrders);
    }
  }, [updatedOrder])

  useEffect(() => {
    if (currentInvoiceId && !!invoiceStatusRes && invoiceStatusRes.event === "invoice.status") {
      const getOrderIds = async () => {
        const fetch = await get('order?invoiceId=' + currentInvoiceId);
        if (fetch.status === 200) {
          const fbPixelId = settings["consumer_portal.dashboard"]?.data?.scripts_fb_pixelid;
          const googleAnalyticsPixelId = settings["consumer_portal.dashboard"]?.data?.scripts_google_analyticid;

          if (invoiceStatusRes?.status === 1 && fetch.data.data[0]) {
            const amount = fetch.data.data[0].price.amount;
            const currency = fetch.data.data[0].price.currency;

            if(googleAnalyticsPixelId)
            {
                ReactGA.event({
                  category: 'Conversion',
                  action: 'Order',
                  label: 'Bulk Order',
                  value: Number(amount)
                });
            }

            // if (fbPixelId) {
            //   ReactPixel.track(
            //     'Order',
            //     {
            //       value: amount,
            //       currency,
            //       // invoiceId: currentInvoiceId
            //     }
            //   )
            // }

          }
          setIsPaymentSuccessful(invoiceStatusRes?.status === 1)
          setCurrentOrders(fetch.data.data);
          setShowContent(true);
        }
      }
      getOrderIds()
    }
  }, [invoiceStatusRes]);

  useEffect(() => {
    if (orderIds && isMessageListenerOn) {
      const getOrderIds = async () => {
        const fetch = await get('order/' + orderIds.join());
        if (fetch.status === 200) {
          let { data } = fetch.data;
          if (typeof data.length !== 'number') {
            data = [data];
          }
          setIsPaymentSuccessful(paymentMethodId > 0 ? false : true);
          setCurrentOrders(data);
          setShowContent(true);
        }
      }
      getOrderIds()
    }
  }, [orderIds, isMessageListenerOn])

  return (
    <PublicHeader style={{ width: '100%', maxWidth: 1280 }}>
      <ShowOn on={showContent} elseShow={<Spin tip={'Getting your payment status, please wait...'} />}>
        <h2 style={{ fontSize: 30, marginBottom: 50 }}>{isPaymentSuccessful ? 'Thanks for your order.' : 'Sorry. Something wrong with your order(s)/transaction.'}</h2>
        <Row gutter={[20, 20]} style={{ width: '100%', marginBottom: 20 }} type="flex" justify="end">
          <Col xs={24} sm={24} md={12} lg={6} xl={4}>
            <CustomizedButton
              style={{ width: '100%' }}
              text="Back to bulk orders"
              onClick={() => {
                history.push(`${process.env.PUBLIC_URL}/orders/bulk-orders`)
              }}
            />
          </Col>
          <Col xs={24} sm={24} md={12} lg={6} xl={4}>
            <Button
              type="primary"
              style={{ border: 'none', width: '100%' }}
              target="_blank"
              href={activeOrderIds && `${API_ROOT}order/${activeOrderIds.join()}/label?companyId=${companyDetails.id}`}
              disabled={!activeOrderIds || activeOrderIds?.length === 0}
            >
              Print all labels
            </Button>
          </Col>
        </Row>
        <Row gutter={[20, 20]} style={{ width: '100%' }}>
          <Col>
            <Orders dataSource={currentOrders} {...props} />
          </Col>
        </Row>
      </ShowOn>
    </PublicHeader>
  )
}

export default BulkOrderAfterPayment;
