import React from "react";
import moment from "moment";

const ordersTableColumns = (props) => {

  return [
    {
      title: props.transl('content.transactionTable.dateAndTime'),
      dataIndex: "timestamp",
      key: "timestamp",
      width: 80,
      render: text => {
          return <div>
          {moment(text).format("D MMM YYYY")}<br/>
          {moment(text).format("h:mm a")}
          </div>;
      }
    },
    {
      title: props.transl('content.transactionTable.description'),
      key: "desc",
      dataIndex: "desc",
      width: 150
    },
    {
      title: props.transl('content.transactionTable.category'),
      key: "category",
      width: 100,
      dataIndex: "category",
      render: text => {
        return <div>{text ? text : "0"}</div>;
      }
    },
    {
      title: props.transl('content.transactionTable.amount'),
      key: "amount",
      dataIndex: "amount",
      width: 100,
      render: (amount, record) => {
        return (
          <div>{amount ? record.currency + " " + record.amount : "N/A"}</div>
        );
      }
    },
    {
      title: props.transl('content.transactionTable.tax'),
      key: "tax",
      dataIndex: "tax",
      width: 50,
      render: text => {
        return <div>{text ? text : "N/A"}</div>;
      }
    },
    {
      title: props.transl('content.transactionTable.status'),
      dataIndex: "status",
      key: "status",
      width: 100,
      render: text => {
        if (text === 0) {
          return <div>Pending</div>;
        }
        if (text === 1) {
          return <div>Success</div>;
        }
        if (text === 9) {
          return <div>Failed</div>;
        }
        return <div>N/A</div>
      }
    },
    // {
    //   title: TEXT_ACTIONS,
    //   key: "actions",
    //   fixed: "right",
    //   width: 220,
    //   render: (text, record) => {
    //     return (
    //       <div style={{ display: "flex", alignItems: "center" }}>
    //         <Button style={{ margin: '0 5px' }} target="_blank">
    //           View
    //         </Button>
    //         <Button style={{ margin: '0 5px' }} target="_blank">
    //           Retry
    //         </Button>
    //       </div>
    //     );
    //   }
    // }
  ]
};

export default ordersTableColumns;
