import React, { useEffect, useState, useContext } from "react";
import { message } from 'antd';

import { get } from "Util/API";
import ReferEarnContent from "./ReferEarnContent";
import PageWrapper from 'Components/PageWrapper';
import withStore from 'Util/withStore';
import { GlobalContext } from 'Store/store';

import { mixPanelTrack } from "Util/CustomFunctions";

const ReferAndEarn = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [transactionData, setTransactionData] = useState({
    data: [],
    rowTotal: 0
  });

  const [state] = useContext(GlobalContext);
  const customerId = state.global.customerDetails.id;

  useEffect(() => {
    getTransactions(1, 1000);
  }, []);

  const getTransactions = async (page, limit) => {
    const transactionResponse = await get(`referrals/${customerId}/lists?limit=${limit}&page=${page}`);

    if (transactionResponse.status === 200) {
      setTransactionData({
        data: transactionResponse.data.data,
        rowTotal: transactionResponse.data.rowTotal
      })
      setIsLoading(false);
    } else {
      setIsLoading(false);
      message.error(transactionResponse);
    }
  };

  const handlePageSize = pagination => {
    getTransactions(pagination.current, pagination.pageSize);
  };

  useEffect(() => {
    mixPanelTrack('ViewRefer');
  }, []);

  return (
    <PageWrapper loading={isLoading} currentPath={props.location.pathname}>
      <ReferEarnContent
        transactionData={transactionData.data}
        transactionRowTotal={transactionData.rowTotal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handlePageSize={handlePageSize}
        getContacts={getTransactions}
      />
    </PageWrapper>
  )
}

export default withStore(ReferAndEarn);
