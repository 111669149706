import React, { Component, useEffect, useState, useContext } from "react";
import { message } from 'antd';

import PageWrapper from 'Components/PageWrapper';
import withStore from 'Util/withStore';
import useLocalStorage from 'Util/useLocalStorage';
import { get } from 'Util/API';
import { GlobalContext } from "Store/store";

import HomeContent from "./HomeContent";
import { getItemTypes } from 'Fetch';

import { mixPanelTrack } from "Util/CustomFunctions";

const axios = require('axios');

const FetchWrapper = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const [fetchItems, setFetchItems] = useState(null);

  const { settings, companyDetails } = state.global;
  const customerId = parseInt(useLocalStorage.getItem("customerId"));

  async function getCustomerDetails() {
        const getCustomerDetails = await get(customerId ? `/customer/${customerId}?companyId=${companyDetails.id}&retrieve=branding` : "/customer&retrieve=branding");
        if (getCustomerDetails.status === 200) {
          const customerDetails = getCustomerDetails.data.data;

          //TODO! Set state global
          state.global.customerDetails = customerDetails;
        }
  }

  async function getOrdersByStatus(orderStatus) {
        if(orderStatus)
        {
            let apiurl = 'order?status='+orderStatus+'&limit=1';
            if(orderStatus == 100 || orderStatus == '100') apiurl = 'order?statusCode=100&limit=1';

            const orderResponse = await get(apiurl);
            if (orderResponse.status === 200)
            {
                useLocalStorage.setItem("countOrders"+orderStatus, orderResponse.data.rowTotal);
            }else {
                useLocalStorage.setItem("countOrders"+orderStatus, 0);
            }
        }else {
            useLocalStorage.setItem("countOrders"+orderStatus, 0);
        }
  }

  async function allFetchMyAlerts(url) {
        if(url.length > 5)
        {
          const response = await axios.get(url)
          useLocalStorage.setItem("myAlerts", response.data);
        }else {
            useLocalStorage.setItem("myAlerts", []);
        }
  }

  async function allFetchMyBanners(url) {
        if(url.length > 5)
        {
            const response = await axios.get(url)
            useLocalStorage.setItem("myBanners", response.data);
        }else {
            useLocalStorage.setItem("myBanners", []);
        }
  }

  async function allFetch() {
    const companyId = companyDetails.id;
    const getItemTypesRes = await getItemTypes(companyId);

    return {
      getItemTypesRes: await getItemTypesRes,
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const fetchAll = await allFetch();
      const { getItemTypesRes } = fetchAll;
      if (getItemTypesRes.status === 200) {
        const data = getItemTypesRes.data.data;
        const parcelIndex = data.findIndex((item) => item.name === 'PARCEL');
        if (parcelIndex !== -1) {
          const parcelData = data[parcelIndex];
          data.splice(parcelIndex, 1);
          data.splice(0, 0, parcelData);
        }
        setFetchItems({
          itemTypes: data
        })
      } else message.error('Request time out. Please try again.')

      if(settings['consumer_portal.dashboard'] )
      {
          const consumer_portal_dashboard = settings['consumer_portal.dashboard'];
          if(consumer_portal_dashboard.data.feed_url.length > 5 && consumer_portal_dashboard.data.news_promo_feeds == true)
          {
              await allFetchMyBanners(consumer_portal_dashboard.data.feed_url);
          }else {
              useLocalStorage.setItem("myBanners", []);
          }
      }

      if(settings['consumer_app.banner_settings'] )
      {
          const consumer_app_banner_settings = settings['consumer_app.banner_settings'];

          const bannersCount = consumer_app_banner_settings.data.length;

          const bannerIndex = Math.floor(Math.random() * (bannersCount));

          if(consumer_app_banner_settings.data.length > (bannersCount-1))
          {
              useLocalStorage.setItem("myPromoImage", consumer_app_banner_settings.data[bannerIndex].imageURL);
              useLocalStorage.setItem("myPromoLink", consumer_app_banner_settings.data[bannerIndex].linkURL);
              useLocalStorage.setItem("myPromoTitle", consumer_app_banner_settings.data[bannerIndex].title);
              useLocalStorage.setItem("myPromoDescription", consumer_app_banner_settings.data[bannerIndex].description);
          }else {
              useLocalStorage.setItem("myPromoImage", null);
              useLocalStorage.setItem("myPromoLink", null);
              useLocalStorage.setItem("myPromoTitle", null);
              useLocalStorage.setItem("myPromoDescription", null);
          }
      }

      if(settings['consumer_portal.customer_mobileapp'] )
      {
          const customer_mobileapp = settings['consumer_portal.customer_mobileapp'];
          if(customer_mobileapp.data.feed_url.length > 5 && customer_mobileapp.data.news_promo_feeds == true)
          {
              await allFetchMyAlerts(customer_mobileapp.data.feed_url);
          }else {
              useLocalStorage.setItem("myAlerts", []);
          }
      }

      await getCustomerDetails();

      await getOrdersByStatus('DRAFT');
      await getOrdersByStatus('100');
      await getOrdersByStatus('PENDING');
      await getOrdersByStatus('CURRENT');
    }
    fetch()
  }, [])

  useEffect(() => {
    mixPanelTrack('ViewHome');
  }, []);

  return fetchItems
    ? (
      <GlobalContext.Provider value={[{ ...state, global: { ...state.global, itemTypes: fetchItems.itemTypes } }, dispatch]}>
        <Home wrapper={{ ...fetchItems }} {...props} />
      </GlobalContext.Provider>
    )
    : (
      <PageWrapper loading={!fetchItems} loadingMsg="loading...">
        <div style={{ width: '100%', height: '100%' }} />
      </PageWrapper>
    )
}

class Home extends Component {
  state = {
    isLoading: false
  };

  render() {
    const { isLoading } = this.state;

    return (
      <PageWrapper loading={isLoading || this.props.state.global.isLoading} currentPath={this.props.location}>
        <HomeContent
          {...this.props}
          // serviceGroups={this.props.state.global.serviceGroups}
          instantQuote={this.instantQuote}
          isLoading={isLoading}
          setIsLoading={(val) => this.setState({ isLoading: val })}
        />
      </PageWrapper>
    );
  }
}

export default withStore(FetchWrapper);
