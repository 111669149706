import React from "react";
import { Form, Input } from "antd";

const PasswordInput = ({
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  name,
  placeholder,
  type,
  prefix,
  label,
  max,
  onChange,
  addonAfter,
  addonBefore,
  onBlur,
  size,
  disabled,
  className,
  touched
}) => (
  <Form.Item
    hasFeedback={addonAfter || addonBefore ? null : !!errors[name]}
    validateStatus={disabled ? null : errors[name] && "error"}
    help={disabled ? null : errors[name]}
    label={label}
    className={className}
  >
    <Input.Password
      disabled={disabled}
      prefix={prefix}
      addonAfter={addonAfter}
      addonBefore={addonBefore}
      placeholder={placeholder}
      value={values[name]}
      onChange={
        onChange
          ? onChange
          : event => {
              if (event.target.value.length === max + 1) {
                return;
              }
              setFieldValue(name, event.target.value);
              setFieldTouched(name, true, false);
            }
      }
      type={type}
      size={size}
    />
  </Form.Item>
);

export default PasswordInput;
