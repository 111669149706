import React, { useContext, useState, useEffect } from 'react';
import { Input, Timeline, Tooltip, message } from 'antd';
import { Link } from "react-router-dom";
import moment from 'moment'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import PublicHeader from 'Components/PublicHeader';
import CustomizedButton from 'Components/CustomizedButton';
import { GlobalContext } from 'Store/store';
import { IMAGE_URL_ROOT } from 'Util/API';
import { post, get } from 'Util/API';
import CustomizedModal from "Components/CustomizedModal";
import useWindowSize from 'Util/useWindowSize';

import { displayEstimatedDeliveryDate } from 'Util/CustomFunctions';

const timelineColor = statusCode => {
  switch (statusCode) {
    case 100:
      return "#445A64";
    case 110:
      return "#445A64";
    case 200:
      return "#445A64";
    case 475:
      return "#E91E63";
    case 500:
      return "#ff8000";
    case 650:
      return "#E91E63";
    case 651:
      return "#E91E63";
    case 655:
      return "#E91E63";
    case 700:
      return "#00C853";
    case 900:
      return "#ff0000";
    case 1000:
      return "#00C853";
    default:
      return "#ff8000";
  }
};

const modalStateInitialVal = {
  isModalVisible: false,
  footer: true,
  bodyStyle: null,
  maskStyle: null,
  closable: true,
  modal: {
    type: null,
    title: null,
    onOk: null,
    onOkData: null,
    okText: 'Confirm',
    onCancel: null,
    text: null,
    maxWidth: 400,
    dialogContent: null,
    dialogContentProps: {}
  }
}

const SingleTracking = (props) => {
  const [state] = useContext(GlobalContext);
  const { companyDetails, customerDetails } = state.global;
  const { history } = props;
  const urlParams = new URLSearchParams(props.location.search);
  const trackingNo = urlParams.get("trackingNo");
  const [consignmentNo, setConsignmentNo] = useState(trackingNo || '');
  const [trackingData, setTrackingData] = useState({ histories: [], description: null });
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState(modalStateInitialVal);
  const { dialogContent: DialogContent } = modalState.modal;
  const size = useWindowSize();
  const [promoBanner, setPromoBanner] = useState({ img: null, title:null, description: null, url: null });

  useEffect(() => {
    document.title = 'Track & trace - ' + companyDetails.name
  }, [])

  useEffect(() => {
    getTrackingDetails();
  }, [trackingNo])

  const getSettings = async () => {
      const bannerSettings = await get(`company/${companyDetails.id}/guest-settings/?name=consumer_app.banner_settings`);
      if (bannerSettings.status === 200) {
          const consumer_app_banner_settings = bannerSettings.data.data.length >0 ? bannerSettings.data.data[0]:null;
          if(consumer_app_banner_settings.data.length > 0)
          {
              setPromoBanner({img: consumer_app_banner_settings.data[0].imageURL, title: consumer_app_banner_settings.data[0].title, description: consumer_app_banner_settings.data[0].description, url: consumer_app_banner_settings.data[0].linkURL})
          }
      }
  }

  const getTrackingDetails = async () => {
    setIsLoading(true);
    setTrackingData({ histories: [], description: null });

    getSettings();

    if (trackingNo?.length > 3)
    {
        let params = {
          companyId: state.global.companyDetails.id,
          consignmentNo: trackingNo,
          resultType: "latestFirst"
        };
        const fetch = await post("order/track", params);
        if (fetch.status === 200) {
          setTrackingData(fetch.data.data);
          !fetch.data.data && message.error('No tracking data available')
        } else message.error(fetch);
    }

    setIsLoading(false)
  }

  return (
    <PublicHeader loading={isLoading} showLogo={false}>
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', maxWidth: 500, marginBottom: 30 }}>
        <Link to={`${process.env.PUBLIC_URL}/`}>
          <img
            style={{ height: 64,  marginBottom: 10 }}
            src={
              companyDetails.logob ?
                IMAGE_URL_ROOT + companyDetails.logob :
                'https://cdn.delyva.app/assets/delyvax-logo-b.svg'
              }
            alt="company logo"
          />
        </Link>
        <p className="heading3">Tracking</p>
        <div style={{ display: 'flex', flexDirection: size[0] > 450 ? 'row' : 'column', margin: -5, marginBottom: 10, width: '100%', alignItems: 'center', justifyContent: 'center' }}>
          <Input
            style={{ minWidth: 'calc(70% - 10px)', margin: 5 }}
            placeholder="Tracking no"
            value={consignmentNo}
            onChange={(val) => setConsignmentNo(val.target.value)}
          />
          <CustomizedButton
            style={{ margin: 5, minWidth: '30%' }}
            className="button-success button-fullWidth"
            text="Track"
            onClick={() => {
              if (consignmentNo.length > 3) {
                if (trackingNo === consignmentNo) {
                  getTrackingDetails()
                } else history.push(`${process.env.PUBLIC_URL}/strack?trackingNo=${consignmentNo}`)
              } else message.error('Tracking number length must be more than 3.')
            }}
            isLoading={isLoading}
          />
        </div>
        {
          trackingData.histories?.length > 0 &&
          <div style={{ backgroundColor: 'white', width: '100%', borderRadius: 4 }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', backgroundColor: timelineColor(trackingData.statusCode), padding: '10px 15px', color: 'white', borderRadius: '4px 4px 0 0' }}>
              <span>{trackingData.statusText}</span>
              <div style={{ display: 'flex' }}>
                <CopyToClipboard text={window.location.href}>
                  <button
                    className="button__transparent"
                    style={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }}
                    onClick={() => message.success('Tracking URL copied!')}
                  >
                    <i class="far fa-copy" style={{ fontSize: 16 }} />
                  </button>
                </CopyToClipboard>
                <button
                  className="button__transparent"
                  style={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }}
                  onClick={async () => {
                    const { default: dialogContent } = await import(`Pages/Tracking/SingleTracking/SocialSharing.js`);
                    setModalState({
                      ...modalState,
                      isModalVisible: true,
                      footer: false,
                      bodyStyle: { backgroundColor: 'transparent' },
                      maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.76)' },
                      modal: {
                        maxWidth: 600,
                        dialogContent
                      }
                    })
                  }}
                >
                  <i class="far fa-share-square" style={{ fontSize: 16 }} />
                </button>
                {/**
                  customerDetails?.id &&
                  <button
                    className="button__transparent"
                    style={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }}
                    onClick={() => {
                      const Message = () => (
                        <div style={{ margin: '20px 0', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                          <i class="fas fa-exclamation-circle" style={{ fontSize: 70, color: '#40A9FF' }} />
                          <h2 style={{ fontWeight: 500, margin: 20 }}>Notify me!</h2>
                          <span style={{ textAlign: 'center' }}>
                            Subscription to {companyDetails.name} Notify Me! is successfull. You will be notified about your order status changes.
                          </span>
                        </div>
                      )
                      setModalState({
                        ...modalState,
                        isModalVisible: true,
                        footer: false,
                        modal: {
                          maxWidth: 500,
                          dialogContent: Message
                        }
                      })
                    }}
                  >
                    <i class="far fa-bell" style={{ fontSize: 16 }} />
                  </button>
                **/}
              </div>
            </div>

            <div style={{ backgroundColor: '#e0e4ea', padding: '10px 15px', fontSize: 13, fontWeight: 'bold' }}>
            {trackingData.consignmentNo}
            <span class="pull-right">
            { trackingData.extTrackUrl ?
              <Link to={{ pathname: trackingData.extTrackUrl }} target="_blank" >Courier Tracking</Link>
            : ''}
            </span>
            </div>

            {(trackingData.etaDuration && trackingData.etaDuration > 0) ?
            <div style={{ backgroundColor: '#efefef', padding: '10px 15px', fontSize: 13}}>
            <Tooltip title="The estimate is based on the service performance for the past 30 days. Subject to service provider's working days and volume.">
            <b>Estimated Delivery Date (?)</b>
            <span class="pull-right">
            {displayEstimatedDeliveryDate(trackingData)}
            </span>
            </Tooltip>
            </div>
            :''}

            { (trackingData.personnel && trackingData.personnel.name && trackingData.personnel.name != 'N/A' && trackingData.statusCode >= 200 && trackingData.statusCode < 700) ?
            <span>
            <div style={{ backgroundColor: '#eeeeee', padding: '10px 15px', fontSize: 13, fontWeight: 'bold' }}>
              <span><i class="fa fa-user" style={{ fontSize: 16 }} /> &nbsp; {trackingData.personnel.name} &nbsp; {trackingData.personnel.vehicleRegNo}</span>
              { trackingData.personnel.phone ?
            <span class="pull-right">
                <a href={"tel:" + trackingData.personnel.phone} >
                <button
                  className="button__transparent"
                  style={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }}
                >
                  <i class="fa fa-phone" style={{ fontSize: 16 }} />
                </button>
                </a>
                <a href={"sms:" + trackingData.personnel.phone} >
                <button
                  className="button__transparent"
                  style={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }}
                >
                  <i class="fa fa-comment" style={{ fontSize: 16 }} />
                </button>
                </a>
                <a href={"https://api.whatsapp.com/send/?text=Hello!&phone=" + trackingData.personnel.phone} target="_blank">
                <button
                  className="button__transparent"
                  style={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }}
                >
                  <i class="fa fa-whatsapp" style={{ fontSize: 16 }} />
                </button>
                </a>
            </span>
            : ''}
            </div>

            { (trackingData.personnel.name != 'N/A' && trackingData?.coord?.lon != null && trackingData?.coord?.lat != null
                && (trackingData.statusCode >= 400 && trackingData.statusCode < 500)
                    || (trackingData.statusCode >= 600 && trackingData.statusCode < 700)
              ) ?
                <div class="container">
                  <iframe src={window.location.origin+ '/track/rmap?trackingNo=' + trackingData.consignmentNo} width="100%" height="280px" frameborder="0" allowfullscreen></iframe>
                </div>
              : ''
            }
            </span>
            : ''}

            <div style={{ padding: '40px 30px' }}>
              <Timeline mode={"mode"}>
                {
                  trackingData.histories.map((item) => (
                    <Timeline.Item color={timelineColor(item.statusCode)} key={item.id}>
                      <div style={{ fontWeight: 'bold' }}>{item.statusText}</div>
                      <div>{item.description}</div>
                      <div>{item.location}</div>
                      <div style={{ fontSize: 13 }}>{moment(item.createdAt).format("D MMM YYYY, h:mm a")}</div>
                    </Timeline.Item>
                  ))
                }
              </Timeline>
            </div>
            <hr/>
            { promoBanner.img ?
            <div style={{ padding: '20px 20px' }}>
              <a href={promoBanner.url} target="_blank" title={promoBanner.title}>
              { promoBanner.img.startsWith('http') ?
                <img src={promoBanner.img} width="100%" alt={promoBanner.description} title={promoBanner.description} />
                :
                <img src={IMAGE_URL_ROOT + promoBanner.img} width="100%" alt={promoBanner.description} title={promoBanner.description} />
               }
              </a>
            </div>
            :''}
          </div>
        }
      </div>
      <CustomizedModal
        type={modalState.modal.type}
        title={modalState.modal.title}
        onOk={modalState.modal.onOk}
        onOkData={modalState.modal.onOkData}
        maxWidth={modalState.modal.maxWidth}
        onCancel={() => {
          modalState.closable && setModalState({ ...modalStateInitialVal })
          if (modalState.modal.onCancel) {
            modalState.modal.onCancel()
          }
        }}
        text={modalState.modal.text}
        okText={modalState.modal.okText}
        okButtonProps={{ type: "danger" }}
        visible={modalState.isModalVisible}
        footer={modalState.footer}
        bodyStyle={modalState.bodyStyle}
        maskStyle={modalState.maskStyle}
        closable={modalState.closable}
      >
        <DialogContent
          {...modalState.modal.dialogContentProps}
        />
      </CustomizedModal>

    </PublicHeader>
  )
}

export default SingleTracking;
