import React, { Component } from "react";
import { Alert, Form } from "antd";
import {
  TEXT_UNDO_CHANGES,
  TEXT_VERIFICATION_CODE_SENT,
  TEXT_RESEND_PHONE_NUMBER,
  TEXT_VERIFICATION_CODE,
  TEXT_CONFIRM
} from "Util/Text";
import {
  MESSAGE_PHONE_NUMBER_VERIFICATION_CODE,
  MESSAGE_MAX_FIVE_NUMBERS,
  MESSAGE_VERIFICATION_CODE_REQUIRED
} from "Util/ErrorMessage";
import CustomizedButton from "Components/CustomizedButton";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "Components/TextInput";

const formik = {
  initialValues: {
    verificationCode: ""
  },
  validationSchema: Yup.object().shape({
    verificationCode: Yup.string()
      .min(5, MESSAGE_MAX_FIVE_NUMBERS)
      .required(MESSAGE_VERIFICATION_CODE_REQUIRED)
  })
};

class PendingPhoneNumberVerification extends Component {
  submitVerificationCode = values => {
    // console.log(values);
  };

  render() {
    return (
      <div>
        <Alert
          message={TEXT_VERIFICATION_CODE_SENT}
          description={
            <span>
              {MESSAGE_PHONE_NUMBER_VERIFICATION_CODE}
              <b>{this.props.currentPhoneNumber}</b>
            </span>
          }
          type="warning"
          showIcon
          className="manage-auth-alert"
        />
        <Formik {...formik} onSubmit={this.submitVerificationCode}>
          {form => (
            <Form onSubmit={form.handleSubmit} className="manage-auth-form">
              <TextInput
                {...form}
                name="verificationCode"
                label={TEXT_VERIFICATION_CODE}
                type="number"
                max={5}
              />
              <Form.Item className="manage-auth-form-item ">
                <CustomizedButton
                  text={TEXT_CONFIRM}
                  type="primary"
                  htmlType="submit"
                />
              </Form.Item>
            </Form>
          )}
        </Formik>
        <div className="pending-phone-verification-buttons">
          <CustomizedButton
            text={TEXT_RESEND_PHONE_NUMBER}
            className="left-button"
          />
          <CustomizedButton text={TEXT_UNDO_CHANGES} />
        </div>
      </div>
    );
  }
}

export default PendingPhoneNumberVerification;
