import React, { useEffect, useState } from "react";
import { message } from "antd";

import { get } from "Util/API";

import ApiTableHeader from "./ApiTableHeader";

const AddressBook = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [transactionData, setTransactionData] = useState(null);

  useEffect(() => {
    !transactionData && getAllUserTokens();
  }, [transactionData]);

  const getAllUserTokens = async (page, limit) => {
    const response = await get('/auth/personalAccessToken');
    if (response.status === 200) {
      setTransactionData(response.data.data)
      setIsLoading(false);
    } else {
      setIsLoading(false);
      message.error(response);
    }
  };

  return (
    <ApiTableHeader
      transactionData={transactionData}
      setDataSource={setTransactionData}
      isLoading={isLoading}
      setIsLoading={setIsLoading}
      getContacts={getAllUserTokens}
    />
  );
};

export default AddressBook;
