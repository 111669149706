import React, { useState, useContext } from "react";
import { Route, Link } from 'react-router-dom';
import { Layout, Row, Col, Divider, Menu, Affix } from "antd";
import { useTranslation } from "react-i18next";

import PageWrapper from "Components/PageWrapper";
import { GlobalContext } from "Store/store";

import AccountInformation from "./SettingsContent/AccountInformation";
import ProfileInformation from "./SettingsContent/ProfileInformation";
import ChangePassword from "./SettingsContent/ChangePassword";
import Integrations from "./SettingsContent/api-integrations/ApiIntegration";
import Notifications from "./SettingsContent/Notifications";
import CustomBranding from "./SettingsContent/CustomBranding";
import BankInformation from "./SettingsContent/BankInformation";
import Services from "./SettingsContent/Services";
// import TwoFactorAuthentication from "./SettingsContent/TwoFactorAuthentication";
import ChangeEmailAddress from "./SettingsContent/ChangeEmailAddress/ChangeEmailAddress";
import ChangePhoneNumber from "./SettingsContent/ChangePhoneNumber/ChangePhoneNumber";
import WebhookPage from './SettingsContent/webhook/WebhookPage.jsx';

const { Content } = Layout;

const Settings = (props) => {
  const [_, dispatch] = useContext(GlobalContext);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation('settingsPage');

  const settingsRoute = [
    { path: '/', label: t('content.mainMenu.account'), component: AccountInformation, otherProps: { exact: true } },
    { path: '/account-information', label: t('content.mainMenu.account'), icon: "far fa-address-book menu-icon", component: AccountInformation },
    { path: '/profile-information', label: t('content.mainMenu.profile'), icon: "fas fa-briefcase menu-icon", component: ProfileInformation },
    { path: '/bank-information', label: t('content.mainMenu.billing'), icon: "fas fa-file-invoice-dollar menu-icon", component: BankInformation },
    { path: '/services', label: t('content.mainMenu.services'), icon: 'fas fa-tools menu-icon', component: Services },
    { path: '/notifications', label: t('content.mainMenu.notifications'), icon: "far fa-bell menu-icon", component: Notifications },
    { path: '/custom-branding', label: t('content.mainMenu.customBranding'), icon: "fas fa-signature menu-icon", component: CustomBranding },
    { path: '/integrations', label: t('content.mainMenu.apiIntegration'), icon: "fas fa-cogs menu-icon", component: Integrations },
    // { path: '/two-factor-authentication', label: 'Two Factor Authentication', icon: "fas fa-shield-alt menu-icon", component: TwoFactorAuthentication },
    { path: '/webhook', label: 'Webhook', icon: "fas fa-lock menu-icon", component: WebhookPage },
    { path: '/change-email-address', label: t('content.mainMenu.changeEmailAddress'), icon: "far fa-envelope menu-icon", component: ChangeEmailAddress },
    { path: '/change-phone-number', label: t('content.mainMenu.changePhoneNo'), icon: "fas fa-mobile-alt menu-icon", component: ChangePhoneNumber },
    { path: '/change-password', label: t('content.mainMenu.changePassword'), icon: "fas fa-lock menu-icon", component: ChangePassword },
  ];
  const settingMenus = [...settingsRoute];
  settingMenus.shift();

  return (
    <PageWrapper loading={isLoading} currentPath={props.location} isCollapseSidebar={true}>
      <Layout>
        <Content
          className="ui-right-content form"
          style={{
            minHeight: "unset",
            marginBottom: 0,
            flex: "initial"
          }}
        >
          <Row style={{ marginTop: 20 }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <p className="heading1" style={{ margin: 0, float: "left" }}>
                {t('content.pageTitle')}
              </p>
            </Col>
          </Row>
        </Content>
        <Divider style={{ marginTop: 0 }} />
        <Content className="ui-right-content form" style={{ paddingTop: 0 }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={7} xl={7}>
              <Affix offsetTop={70} target={() => window.innerWidth > 989 && window}>
                <Menu
                  defaultOpenKeys={["sub1"]}
                  mode="inline"
                  defaultSelectedKeys={window.location.pathname}
                >
                  {settingMenus.map(menu => (
                    <Menu.Item style={{ height: 'auto' }} key={`${process.env.PUBLIC_URL}/settings` + menu.path}>
                      <Link to={`${process.env.PUBLIC_URL}/settings` + menu.path}>
                        <i className={menu.icon} />
                        <span style={{ whiteSpace: 'normal' }}>{menu.label}</span>
                      </Link>
                    </Menu.Item>
                  ))}
                  <Menu.Item
                    onClick={() => {
                      dispatch({ type: "LOGOUT" })
                    }}
                    key="logout"
                    className="color-logout"
                  >
                    <i className="fas fa-sign-out-alt menu-icon" />
                    {t('content.logout')}
                  </Menu.Item>
                </Menu>
              </Affix>
            </Col>
            <Col xs={24} sm={24} md={24} lg={17} xl={17}>
              {
                settingsRoute.map((route) => {
                  const { path, component: Component, otherProps } = route;
                  return (
                    <Route
                      path={`${process.env.PUBLIC_URL}/settings` + path}
                      render={() => <Component setIsLoading={setIsLoading} isLoading={isLoading} />}
                      {...otherProps}
                    />
                  )
                })
              }
            </Col>
          </Row>
        </Content>
      </Layout>
    </PageWrapper>
  )
}

export default Settings;
