import React from "react";
import { Form, Input } from "antd";
import { useField } from 'formik';

const TextInput = ({
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  name,
  placeholder,
  type,
  prefix,
  label,
  max,
  onChange,
  addonAfter,
  addonBefore,
  onBlur,
  size,
  disabled,
  viewOnly,
  className,
  style,
  touched,
  step,
  value,
  handleChange
}) => {
  const [field] = useField({ name });

  return (
    <Form.Item
      hasFeedback={addonAfter || addonBefore ? null : !!errors[name]}
      validateStatus={disabled ? null : errors[name] && "error"}
      help={disabled ? null : errors[name]}
      label={label}
      className={className}
      style={style}
    >
      <Input
        {...field}
        disabled={disabled}
        style={{ color: viewOnly && '#000000a6', border: viewOnly && 'none' }}
        prefix={prefix}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={() => {
          setFieldValue(name, value)
        }}
        type={type}
        size={size}
        step={step}
      />
    </Form.Item>
  )
};

export default TextInput;
