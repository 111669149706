import React, { Component } from "react";

import PaymentMethodList from "./PaymentMethodList";

class PaymentMethodStep extends Component {
  backtolist = () => {
    this.setState({
      isPaymentMethodSelected: false
    });
  };

  render() {
    return (
      <PaymentMethodList
        selectPaymentMethod={this.props.selectPaymentMethod}
        paymentMethod={this.props.paymentMethod}
        selectedTopupAmount={this.props.selectedTopupAmount}
      />
    );
  }
}

export default PaymentMethodStep;
