import React, { Component, useEffect } from "react";
import { Form, Card, Row, Col, message } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import {
  TEXT_CHANGE
} from "Util/Text";
import {
  MESSAGE_MINIMUM_PASSWORD,
  MESSAGE_PASSWORD_REQUIRED,
  MESSAGE_PASSWORD_MUST_NOT_MATCH
} from "Util/ErrorMessage";
import CustomizedButton from "Components/CustomizedButton";
import PasswordInput from "Components/PasswordInput";
import { update } from "Util/API";

import { mixPanelTrack } from "Util/CustomFunctions";

const formik = {
  initialValues: {
    currentPassword: "",
    newPassword: ""
  },
  validationSchema: Yup.object().shape({
    currentPassword: Yup.string("")
      .min(1, MESSAGE_MINIMUM_PASSWORD)
      .required(MESSAGE_PASSWORD_REQUIRED),
    newPassword: Yup.string("")
      .notOneOf([Yup.ref("currentPassword")], MESSAGE_PASSWORD_MUST_NOT_MATCH)
      .required(MESSAGE_PASSWORD_REQUIRED)
  })
};

class ChangePassword extends Component {
  state = {
    isLoading: false
  };

  submitNewPassword = async values => {
    this.setState({
      isLoading: true
    });

    let passwordData = {
      oldPassword: values.currentPassword,
      password: values.newPassword
    };

    const passwordResponse = await update("user", passwordData);
    if (passwordResponse.status === 200) {
      message.success('Password updated.')
    } else message.error(passwordResponse)

    this.setState({
      isLoading: false
    });
  };

  render() {
    const { isLoading } = this.state;

    return (
      <Row>
        <Col className="personal-container">
          <Card className="manage-auth-container">
            <div
              className="whiteBColor"
              style={{ padding: "10px 20px", minHeight: "auto" }}
            >
              <p className="heading3" style={{ margin: 0, marginBottom: 10 }}>
                {this.props.transl('content.changePassword.title')}
              </p>
              <Formik {...formik} onSubmit={this.submitNewPassword}>
                {form => (
                  <Form
                    layout="vertical"
                    onSubmit={form.handleSubmit}
                    className="manage-auth-form"
                  >
                    <PasswordInput
                      {...form}
                      name="currentPassword"
                      label={this.props.transl('content.changePassword.currentPassword')}
                      type="password"
                    />
                    <PasswordInput
                      {...form}
                      name="newPassword"
                      label={this.props.transl('content.changePassword.newPassword')}
                      type="password"
                    />
                    <Form.Item
                      className="manage-auth-form-item"
                      style={{ marginBottom: 0 }}
                    >
                      <CustomizedButton
                        text={TEXT_CHANGE}
                        type="primary"
                        htmlType="submit"
                        isLoading={isLoading}
                      />
                    </Form.Item>
                  </Form>
                )}
              </Formik>
            </div>
          </Card>
        </Col>
      </Row>
    );
  }
}

const Renderer = () => {
  const { t } = useTranslation('settingsPage');

  useEffect(() => {
    mixPanelTrack('ViewChangePassword');
  }, []);

  return (
    <ChangePassword transl={t} />
  )
}

export default Renderer;
