import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";

import { Row, Form, Input } from "antd";
import { LabelledCheckbox } from 'Components/optimized-input'

const AddOn = (props) => {
  const { t } = useTranslation('multiPointOrderPage');
  const { form } = props;
  const { setFieldValue } = form;
  const { serviceDetails, addon } = form.values;

  const onChangeAddon = (item, checked) => {
    if (!checked) {
      const addonCopy = addon;
      delete addonCopy[item.id];
      setFieldValue('addon', { ...addonCopy });
    } else {
      setFieldValue('addon', { ...addon, [item.id]: { ...addon[item.id] } })
    }
  }

  return (
    <Row>
        {
          serviceDetails?.service.addon?.map((item) => (
            <Fragment>
              <LabelledCheckbox
                id={item.id}
                text={item.name}
                checked={!!addon[item.id]}
                description={item.description}
                onChange={e => onChangeAddon(item, e.target.checked)}
              />
              {
                !!addon[item.id] && item.required?.map((requiredItem) => (
                  <Input
                    placeholder={requiredItem.name}
                    onChange={e => setFieldValue('addon', { ...addon, [item.id]: { ...addon[item.id], [requiredItem.key]: e.target.value } })}
                  />
                ))
              }
            </Fragment>
          ))
        }
    </Row>
  )
}

export default AddOn;
