import React, { useState, useContext } from "react";
import { Tag, Button, message, Row, Col, Menu, Dropdown, Popconfirm, Tooltip } from "antd";
import { Link, Redirect } from "react-router-dom";
import moment from "moment";

import { API_ROOT } from 'Util/API';
import { capitalizeFirstLetter, getServiceCompany } from "Util/CustomFunctions";
import { post, IMAGE_URL_ROOT } from 'Util/API';
import { GlobalContext } from "Store/store";

import { orderStatusColor2 } from 'Util/orderStatusColor';
import { onOpenCancelOrderModal } from 'Pages/Order/ordersHelper';
import ShowOn from 'Util/ShowOn';
import generateAddressByKeys from 'Util/generateAddressByKeys';
import LimitText from 'Components/LimitText';

//import { QuestionCircleOutlined } from '@ant-design/icons';

const RecipientColumn = ({ data, consignmentNo, nanoId }) => {
  const [store] = useContext(GlobalContext);
  const { companyDetails } = store.global;
  const { primaryColor } = companyDetails.personalization;
  const dropoffWaypoint = data.filter((item) => item.type === 'DROPOFF');
  const [seeMore, setSeeMore] = useState(false);
  const dataSource = seeMore ? dropoffWaypoint : [dropoffWaypoint[0]];

  const onRedirectToWhatsapp = (name, phone) => {
    const wsText = `Hi ${name}, track your order: https://${companyDetails.code}.delyva.app/customer/etrack/${nanoId}`;
    const wsLink = `https://api.whatsapp.com/send?phone=${phone}&text=${wsText}`;
    window.open(wsLink)
  }

  return (
    dataSource ?
    <div>
      {
        dataSource.map((item) => {
          return (
            item ?
            <div style={{ marginBottom: 10 }} key={item.id}>
              <Tooltip title={generateAddressByKeys(['name','phone','unitNo', 'address1', 'address2', 'postcode', 'city', 'state', 'country'], item.contact)}>
              <b>{item.contact.name + ' '}</b>
              </Tooltip>
              <br/>
              <small>
              <a href={"tel:"+item.contact.phone}>+{item.contact.phone}</a>
              <i
                onClick={() => onRedirectToWhatsapp(item.contact.name, item.contact.phone)}
                style={{ marginLeft: 5, color: 'blue', cursor: 'pointer' }}
                className="fab fa-whatsapp"
              />
              <br/>
              {item.contact.city} {item.contact.country}
              </small>
            </div>
            : ''
          )
        })
      }
      {
        dropoffWaypoint.length > 1 &&
        <div style={{ cursor: 'pointer', color: primaryColor }} onClick={() => setSeeMore(!seeMore)}>{seeMore ? 'See less' : 'See more'}</div>
      }
    </div>
    : '-'
  )
}

const ordersTableColumns = (props) => {
  const { state: { global }, selectedRowsIds, setIsLoading, resetState, ordersTableData, history, transl } = props;
  const { modalState, setModalState, customerDetails, serviceCompanies } = global;

  const retrySubmit = async (id, serviceCode) => {
    setIsLoading(true);
    const dataToSubmit = {
      customerId: customerDetails.id,
      process: true,
      orderIds: [id],
      serviceCode
    }
    const createOrder = await post('order/process', dataToSubmit);
    if (createOrder.status === 200) {
      resetState({ ...ordersTableData, data: null, setToPage: ordersTableData.page })
    } else message.error(createOrder)
  }

  const cloneSubmit = async (id, serviceCode) => {
    setIsLoading(true);
    const dataToSubmit = {
      // scheduledAt,
      serviceCode,
      process: true
    }

    const cloneOrder = await post('order/'+id+'/clone', dataToSubmit);
    if (cloneOrder.status === 200) {
        resetState({ ...ordersTableData, data: null, setToPage: ordersTableData.page })
        const res = cloneOrder.data.data;
        history.push({ pathname: `${process.env.PUBLIC_URL}/order/`+res.orderId+`` });
        window.location.reload(false);
    } else message.error(cloneOrder)
  }

  return [
    {
      title: transl('content.orderTableColumn.orderNo'),
      dataIndex: "consignmentNo",
      key: "consignmentNo",
      width: 120,
      render: (consignmentNo, data) => {
        return (
          <span>
          <Link to={{
              pathname: `${process.env.PUBLIC_URL}/order/${data.id}`,
              state: { from: `${process.env.PUBLIC_URL}/orders` }
            }}
          >
            <b>{consignmentNo || "N/A"}</b>
          </Link>
          <br/>
          <small>{moment(data.createdAt).fromNow()}</small>
          <br/>
          <Link target="_blank"
            to={{
              pathname: `${process.env.PUBLIC_URL}/etrack/${data.nanoId}`,
              state: { from: `${process.env.PUBLIC_URL}/orders` }
            }}
          >
          <small>{data.nanoId}</small>
          </Link><br/>
          {data.referenceNo}
          </span>
        );
      }
    },
    {
      title: transl('content.orderTableColumn.status'),
      dataIndex: "statusCode",
      key: "status",
      width: 100,
      render: (text, record) => {
        return (
          <div>
            <Tag color={orderStatusColor2(text).color}>
            <span>{capitalizeFirstLetter(orderStatusColor2(text).text)}</span>
            </Tag>
          </div>
        );
      }
    },
    {
      title: transl('content.orderTableColumn.scheduleDate'),
      width: 100,
      dataIndex: "waypoint",
      key: "scheduledAt",
      render: (data) => {
        const selectedWaypoint = data.filter((item) => item.type === 'PICKUP');
        if (selectedWaypoint.length > 0) {
          const { scheduledAt } = selectedWaypoint[0];
          return (
            scheduledAt ?
            <div>
              <span>{moment(scheduledAt).fromNow()}</span>
              <br/>
              <small>
              {moment(scheduledAt).format("Do MMM YYYY")}<br/>
              {moment(scheduledAt).format("h:mm a")}
              </small>
            </div> :
            'N/A'
          )
        } else return null;
      }
    },
    {
      title: transl('content.orderTableColumn.recipient'),
      dataIndex: "waypoint",
      key: "recipient",
      width: 150,
      render: (data, record) => <RecipientColumn data={data} consignmentNo={record.consignmentNo} nanoId={record.nanoId} />
    },
    {
      title: transl('content.orderTableColumn.sender'),
      dataIndex: "waypoint",
      key: "sender",
      width: 100,
      render: (data) => {
        const selectedWaypoint = data.filter((item) => item.type === 'PICKUP');
        if (selectedWaypoint.length > 0) {
          const { contact } = selectedWaypoint[0];
          const addressStr = generateAddressByKeys(['name','phone,','unitNo', 'address1', 'address2', 'postcode', 'city', 'state', 'country'], contact);
          return (
            <div>
            <Tooltip title={addressStr}>
            <b>{contact.name}</b><br/>
            <small>
            <a href={"tel:"+contact.phone}>+{contact.phone}</a><br/>
            {contact.city} {contact.country}
            </small>
            </Tooltip>
            </div>
          )
        } else return null;
      }
    },
    {
      title: transl('content.orderTableColumn.service'),
      dataIndex: "serviceCode",
      key: "serviceCode",
      width: 100,
      render: (val, record) => {
        const theServiceCompany = getServiceCompany(record.service?.serviceCompanyId, serviceCompanies);
        return (
        <div>
          {theServiceCompany?.logo ?
            <span>
            <img src={IMAGE_URL_ROOT + '/' + theServiceCompany?.logo} height="16" style={{marginBottom:10}} />
            </span>
            :''
          }
          <br/>
          <small>{record.service?.name}</small>
        </div>
      )}
    },
    {
      title: transl('content.orderTableColumn.weight'),
      dataIndex: "weight",
      key: "weight",
      width: 80,
      render: (weight, record) => (
        <div>
          <h3 style={{padding:0,margin:0}}>{weight.value?weight.value.toFixed(2):0.00}</h3>
          {weight.unit}
        </div>
      )
    },
    {
      title: transl('content.orderTableColumn.price'),
      dataIndex: "price",
      key: "price",
      width: 80,
      render: val => (
        <div>
          {val.currency}<br/>
          <h2 >{val.amount ? val.amount.toFixed(2):0.00}</h2>
        </div>
      )
    },
    {
      title: 'Note',
      dataIndex: "note",
      key: "note",
      width: 100,
      render: (note, record) =>
      (
          // record.note ? <LimitText string={record.note} maxChar={80} /> : '-'
          <div>
          <Tooltip title={<span dangerouslySetInnerHTML={{ __html: record.note ? record.note: '-' }} />}>
          {record.note ? <span dangerouslySetInnerHTML={{ __html: record.note.substring(0, 80) }} /> : '-'}
          </Tooltip>
          </div>
      )
    },
    {
      title: 'Personnel',
      dataIndex: "personnel",
      key: "personnel",
      width: 120,
      render: val => (
        <ShowOn on={val} elseShow="N/A">
          <div>
            {val?
            <span>
            <b>{val.name}</b><br/>
            {val.vehicleRegNo ? val.vehicleRegNo+'</br>':''}
            <small>
            <a href={"tel:"+val.phone}>{val.phone}</a>
            </small>
            </span>
            :''}
          </div>
        </ShowOn>
      )
    },
    {
      title: transl('content.orderTableColumn.action'),
      key: "actions",
      fixed: "right",
      width: 180,
      render: (text, record) => {

        const allowPlaceMultipointOrder = record.source === 'web-customer-multipoint' || record.source === 'android-mover' || record.source === 'ios-mover' || record.waypoint.length > 2;

          const menu = (
            <Menu>
              <Menu.Item>
                  <Link
                    to={{
                      pathname: `${process.env.PUBLIC_URL}/order/${record.id}`,
                      state: { from: `${process.env.PUBLIC_URL}/orders` }
                    }}
                  >
                  View order
                  </Link>
              </Menu.Item>
              <Menu.Item>
                <a target="_blank" href={`${API_ROOT}order/${record.id}/label?companyId=${global.companyDetails.id}`} >
                  Print label
                </a>
              </Menu.Item>
              <Menu.Item>
                <a target="_blank" href={`${API_ROOT}order/${record.id}/packlist?companyId=${global.companyDetails.id}`} >
                  Print Packing list
                </a>
              </Menu.Item>
              <Menu.Item>
                <a target="_blank" href={`${API_ROOT}order/${record.id}/inv_invoice?companyId=${global.companyDetails.id}`} >
                  Print Commercial Invoice
                </a>
              </Menu.Item>
              <Menu.Item>
                <a target="_blank" href={`${API_ROOT}order/${record.id}/invoice_packlist?companyId=${global.companyDetails.id}`} >
                  Com. Invoice + Packing list
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href={`${process.env.PUBLIC_URL}/strack?trackingNo=${record.consignmentNo}`} target="_blank" rel='noopener noreferrer'>
                  Track order
                </a>
              </Menu.Item>
              <Menu.Item>
                <a href={`${process.env.PUBLIC_URL}/etrack/${record.nanoId}`} target="_blank" rel='noopener noreferrer'>
                  Secure track order
                </a>
              </Menu.Item>
              <Menu.Item>
                <a onClick={() => {
                    if (allowPlaceMultipointOrder) {
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/orders/multipoint`,
                        state: {
                          orderDetails: record
                        }
                      })
                    }else {
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/orders/new-order`,
                        state: {
                          orderDetails: record
                        }
                      })
                    }
                  }} >
                  Place order again
                </a>
              </Menu.Item>
              { /** record.serviceCode ?
              <Menu.Item>
                <a onClick={() => cloneSubmit(record.id, record.serviceCode)}>
                  Clone order
                </a>
              </Menu.Item>
              : '' **/}
              <Menu.Item>
                <a onClick={() => {
                    if (allowPlaceMultipointOrder) {
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/orders/multipoint`,
                        state: {
                          orderDetails: record,
                          returnOrder: true
                        }
                      })
                    }else {
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/orders/new-order`,
                        state: {
                          orderDetails: record,
                          returnOrder: true
                        }
                      })
                    }
                  }} >
                  Create return order
                </a>
              </Menu.Item>
              { (record.serviceCode && record.statusCode < 100 ) ?
              <Menu.Item>
                <a onClick={() => retrySubmit(record.id, record.serviceCode)} >
                  Retry order
                </a>
              </Menu.Item>
              : ''}
              <Menu.Item>
                <a onClick={() => onOpenCancelOrderModal({
                    setIsLoading,
                    setModalState,
                    selectedRowsIds: [record.id],
                    resetState,
                    ordersTableData,
                    modalState,
                    history,
                    transl
                })} >
                  Cancel order
                </a>
              </Menu.Item>
            </Menu>
        );

        return (
          <Row gutter={3} type="flex">
            <Col>
             {
               (record.statusCode < 100 && record.serviceCode != null ) ?
               <Button type="primary">
                 <a onClick={() => retrySubmit(record.id, record.serviceCode)} >
                   Retry order
                 </a>
               </Button>
               : record.statusCode == 100 ?
              <Button type="primary">
                <a target="_blank" href={`${API_ROOT}order/${record.id}/label?companyId=${global.companyDetails.id}`} >
                  Print label
                </a>
              </Button>
              :
              <Button type="primary">
                <a href={`${process.env.PUBLIC_URL}/strack?trackingNo=${record.consignmentNo}`} target="_blank" rel='noopener noreferrer'>
                  Track
                </a>
              </Button>
              }
            </Col>
            <Col>
              <Dropdown overlay={menu} placement="bottomRight" arrow>
                <Button>
                <i className="fas fa-angle-down" />
                </Button>
              </Dropdown>
            </Col>
          </Row>
        );
      }
    }
  ]
};

export default ordersTableColumns;
