import React from "react";
import { Tag } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";

// import { API_ROOT } from 'Util/API';
import { capitalizeFirstLetter } from "Util/CustomFunctions";
import { post } from 'Util/API';
// import { GlobalContext } from "Store/store";

import { codStatusColor } from 'Util/orderStatusColor';
// import { onOpenCancelOrderModal } from 'Pages/Order/ordersHelper';
// import ShowOn from 'Util/ShowOn';
// import generateAddressByKeys from 'Util/generateAddressByKeys';
// import LimitText from 'Components/LimitText';

//import { QuestionCircleOutlined } from '@ant-design/icons';

const ordersTableColumns = (props) => {
  const { state: { global }, transl } = props;
  // const { customerDetails } = global;

  return [
    {
      title: transl('content.orderTableColumn.orderNo'),
      dataIndex: "consignmentNo",
      key: "consignmentNo",
      width: 100,
      render: (consignmentNo, data) => {
        return (
          <span>
          <Link to={{
              pathname: `${process.env.PUBLIC_URL}/order/${data.orderId}`,
              state: { from: `${process.env.PUBLIC_URL}/orders` }
            }}
          >
            <b>{consignmentNo || "N/A"}</b><br/>            
            <small>{data.createdAt ? moment(data.createdAt).fromNow():'-'}</small>
          </Link>
          </span>
        );
      }
    },
    {
      title: transl('content.orderTableColumn.status'),
      dataIndex: "codStatus",
      key: "codStatus",
      width: 100,
      render: (text, record) => {
        return (
          <div>
            <Tag color={codStatusColor(text).color}>
            <span>{capitalizeFirstLetter(codStatusColor(text).text)}</span>
            </Tag>
          </div>
        );
      }
    },
    {
      title: transl('content.orderTableColumn.cod'),
      dataIndex: "cod",
      key: "cod",
      width: 100,
      render: (cod, record) => (
        <div>
          {cod.currency? cod.currency: record.price.currency}
          <h2 style={{padding:0,margin:0}}>{cod.amount?cod.amount:0.00}</h2>
        </div>
      )
    },    
    {
      title: transl('content.orderTableColumn.date'),
      // dataIndex: "customerBankDetails",
      key: "date",
      width: 100,
      render: (record) => (
        <div>
          <p>
            <b>{transl('content.orderTableColumn.deliveredDate')}</b><br/>
            {record.deliveredAt ? moment(record.deliveredAt).format("Do MMM YYYY"):'-'}<br/>
            <b>{transl('content.orderTableColumn.dueDate')}</b><br/>
            {record.codDueAt ? moment(record.codDueAt).fromNow():'-'}
          </p>
        </div>
      )
    },
    {
      title: transl('content.orderTableColumn.bank'),
      dataIndex: "customerBankDetails",
      key: "customerBankDetails",
      width: 100,
      render: (bank, record) => (
        <div>
          <p>
            {bank.accountNumber ? bank.accountNumber:'-'}<br/>
            {bank.accountName ? bank.accountName:'-'}<br/>
            {bank.bankName ? bank.bankName:'-'}
          </p>
        </div>
      )
    },
    {
      title: transl('content.orderTableColumn.service'),
      dataIndex: "serviceCode",
      key: "serviceCode",
      width: 100,
      render: (val, record) => (
        <div>
          {val? val:'-'}
        </div>
      )
    },
  ]
};

export default ordersTableColumns;
