import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import moment from "moment";
import { Form, Tabs, Row, Col, message } from "antd";

import {
  TEXT_IN_TRANSIT,
  TEXT_TRACK,
  TEXT_PICKUP,
  TEXT_ALERT,
  TEXT_DELIVERED,
  TEXT_ORIGIN,
  TEXT_DESTINATION,
  TEXT_LAST_UPDATE,
  TEXT_PENDING,
  TEXT_CONSIGNMENT_NO_UPPERCASE,
  TEXT_ALL
} from "Util/Text";
import CustomizedTextarea from "Components/CustomizedTextarea";
import CustomizedButton from "Components/CustomizedButton";
import { post } from "Util/API";
import withStore from "Util/withStore";

import PublicTrackingTabpane from "./PublicTrackingTabpane";

const TabPane = Tabs.TabPane;

const trackingColumn = [
  {
    title: TEXT_CONSIGNMENT_NO_UPPERCASE,
    dataIndex: "consignmentNo",
    key: "consignmentNo",
    width: 250
  },
  {
    title: "Status",
    dataIndex: "histories[0].statusText",
    key: "statusText",
    width: 250
  },
  {
    title: TEXT_ORIGIN,
    dataIndex: "origin",
    key: "origin",
    width: 200
  },
  {
    title: TEXT_DESTINATION,
    dataIndex: "destination",
    key: "origin",
    width: 200
  },
  {
    title: TEXT_LAST_UPDATE,
    dataIndex: "date",
    key: "date",
    width: 200,
    render: (text, record) => (
      <div>{moment(text).format("D MMM YYYY, h:mm a")}</div>
    )
  }
];

const PublicTrackingList2 = props => {
  const [isRateModalVisible, setIsRateModalVisible] = useState(false);
  const [trackingData, setTrackingData] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const [pageNotFound, isPageNotFound] = useState();
  const [trackingError, setTrackingError] = useState([]);
  const dataSource = [];

  useEffect(() => {
    let mount = true;
    setIsLoading(true);
    const getTrackingNumber = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const trackingNo = urlParams.get("trackingNo");

      let trackingNoArr = trackingNo ? trackingNo.split(",") : [];
      let trackingData = {
        companyId: props.state.global.companyDetails.id,
        consignmentNo: trackingNoArr,
        resultType: "latestFirst"
      };

      if (trackingNoArr.length > 0) {
        const trackingResponse = await post("order/track", trackingData);
        if (trackingResponse.status === 200) {
          props.form.setFieldValue("trackingNo", trackingNo);
          mount && setTrackingData(trackingResponse.data);
          if (trackingResponse.data.length === 0) {
            message.error('No data available.');
          } else if (trackingResponse.data.length !== trackingNoArr.length) {
            const successTracking = trackingResponse.data.map((item) => item.consignmentNo);
            const noTrackingData = trackingNoArr.filter(x => !successTracking.includes(x));
            setTrackingError(noTrackingData);
          } else setTrackingError([])
        } else {
          message.error(trackingResponse);
          setTrackingData([]);
        }
      } else setTrackingData([])

      mount && setIsLoading(false);
    };
    getTrackingNumber();
    return () => {
      mount = false;
    };
  }, [window.location.search]);

  const openRateModal = () => {
    setIsRateModalVisible(true);
  };

  const onSubmit = () => {
    let trackingNoArr = [];
    const { values } = props.form;

    if (values.trackingNo.indexOf(",") >= 0) {
      trackingNoArr = values.trackingNo.split(/[ ,]+/);
    } else if (values.trackingNo.indexOf(" ") >= 0) {
      trackingNoArr = values.trackingNo.split(" ").map(item => {
        return item.trim();
      });
    } else {
      trackingNoArr = values.trackingNo.split("\n").map(item => {
        return item.trim();
      });
    }
    props.history.push(
      `${process.env.PUBLIC_URL}/track?trackingNo=${trackingNoArr.join()}`
    );
  };

  if (pageNotFound) {
    return (
      <Redirect
        to={{
          pathname: `${process.env.PUBLIC_URL}/page-not-found`
        }}
      />
    );
  }

  let deliveredData = [];
  let alertData = [];
  let intransitData = [];
  let pickupData = [];
  let pendingData = [];
  let allData = [];

  if (trackingData) {
    allData = trackingData;
    deliveredData = trackingData.filter(
      tracking => tracking.statusCode === 700 || tracking.statusCode === 1000
    );
    alertData = trackingData.filter(
      tracking =>
        tracking.statusCode === 475 ||
        tracking.statusCode === 650 ||
        tracking.statusCode === 655 ||
        tracking.statusCode === 900
    );
    intransitData = trackingData.filter(
      tracking =>
        tracking.statusCode >= 200 &&
        tracking.statusCode <= 1000 &&
        tracking.statusCode !== 500 &&
        tracking.statusCode !== 100 &&
        tracking.statusCode !== 200 &&
        tracking.statusCode !== 700 &&
        tracking.statusCode !== 1000 &&
        tracking.statusCode !== 475 &&
        tracking.statusCode !== 650 &&
        tracking.statusCode !== 655 &&
        tracking.statusCode !== 900
    );
    pickupData = trackingData.filter(tracking => tracking.statusCode === 500);
  }

  if (dataSource.length > 0) {
    allData = dataSource;
    pendingData = dataSource.filter(
      tracking => tracking.statusCode === 100 || tracking.statusCode === 200
    );
    deliveredData = dataSource.filter(
      tracking => tracking.statusCode === 700 || tracking.statusCode === 1000
    );
    alertData = dataSource.filter(
      tracking =>
        tracking.statusCode === 475 ||
        tracking.statusCode === 650 ||
        tracking.statusCode === 655 ||
        tracking.statusCode === 900
    );
    intransitData = dataSource.filter(
      tracking =>
        tracking.statusCode >= 200 &&
        tracking.statusCode <= 1000 &&
        tracking.statusCode !== 500 &&
        tracking.statusCode !== 100 &&
        tracking.statusCode !== 200 &&
        tracking.statusCode !== 700 &&
        tracking.statusCode !== 1000 &&
        tracking.statusCode !== 475 &&
        tracking.statusCode !== 650 &&
        tracking.statusCode !== 655 &&
        tracking.statusCode !== 900
    );
    pickupData = dataSource.filter(tracking => tracking.statusCode === 500);
  }

  return (
    trackingData && (
      <Row style={{ width: '100%', maxWidth: trackingData && trackingData.length > 0 ? 'unset' : 900 }} gutter={12} type="flex">
        <Col xs={24} sm={24} md={24} lg={24} xl={{ span: trackingData && trackingData.length > 0 ? 8 : 24, order: 2 }}>
          <Form className="login-form">
            <CustomizedTextarea
              {...props.form}
              name="trackingNo"
              placeholder="ER01234567890MY"
              autoSize={{ maxRows: 6, minRows: 6 }}
              style={{ marginBottom: 10 }}
            />
            <div style={{ color: 'red', marginBottom: 10 }}>
              {trackingError.length > 0 && 'No tracking data available for '}
              {trackingError.map((item, index) => <span>{item}{(index < trackingError.length - 1) && ', '}</span>)}
            </div>
            <CustomizedButton
              onClick={onSubmit}
              type="success"
              isBlockType={true}
              className="button-success login-form-button marginBottom-40"
              text={TEXT_TRACK}
            />
          </Form>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={{ span: 16, order: 1 }}>
        {trackingData && trackingData.length > 0 && (
          <Tabs
            defaultActiveKey="1"
            style={{ background: "white" }}
            animated={false}
          >
            <TabPane tab={TEXT_ALL} key="1">
              <PublicTrackingTabpane
                trackingData={allData}
                trackingColumn={trackingColumn}
                openRateModal={openRateModal}
                isLoading={isLoading}
              />
            </TabPane>
            <TabPane tab={TEXT_PENDING} key="2">
              <PublicTrackingTabpane
                trackingData={pendingData}
                trackingColumn={trackingColumn}
                openRateModal={openRateModal}
                isLoading={isLoading}
              />
            </TabPane>
            <TabPane tab={TEXT_IN_TRANSIT} key="3">
              <PublicTrackingTabpane
                trackingData={intransitData}
                trackingColumn={trackingColumn}
                openRateModal={openRateModal}
                isLoading={isLoading}
              />
            </TabPane>
            <TabPane tab={TEXT_ALERT} key="4">
              <PublicTrackingTabpane
                trackingData={alertData}
                trackingColumn={trackingColumn}
                openRateModal={openRateModal}
                isLoading={isLoading}
              />
            </TabPane>
            <TabPane tab={TEXT_PICKUP} key="5">
              <PublicTrackingTabpane
                trackingData={pickupData}
                trackingColumn={trackingColumn}
                openRateModal={openRateModal}
                isLoading={isLoading}
              />
            </TabPane>
            <TabPane tab={TEXT_DELIVERED} key="6">
              <PublicTrackingTabpane
                trackingData={deliveredData}
                trackingColumn={trackingColumn}
                openRateModal={openRateModal}
                isLoading={isLoading}
              />
            </TabPane>
          </Tabs>
        )}
        </Col>
      </Row>
    )
  );
};

export default withStore(PublicTrackingList2);
