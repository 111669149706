import React, { useContext, useCallback, useState, useEffect } from "react";
import moment from 'moment';
import { useField } from 'formik';


import { Row, Col } from "antd";
import { LabelledDatePicker, LabelledTimePicker, LabelledCheckbox } from 'Components/optimized-input';
import { GlobalContext } from "Store/store";
import CustomizedButton from "Components/CustomizedButton";
import { useTranslation } from "react-i18next";

const dateFormat = "DD-MM-YYYY";

const ReadyDateAndTime = (props) => {
  const [state] = useContext(GlobalContext);
  const { form } = state.newOrder;
  const { errors, values, setFieldValue } = form;
  const { t } = useTranslation('orderPage');
  const [disableHourList, setDisableHourList] = useState([]);

  function disabledDate(current) {
    let customDate = moment(new Date()).format(dateFormat);
    return current && current < moment(customDate, dateFormat);
  }

  useEffect(() => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const pickupDate = moment(values.pickupDate).format("YYYY-MM-DD");
    if (today === pickupDate) {
      const toDisable = new Date(moment(values.pickupTime));
      const newDisableHourList = [];
      let i = 0;
      for (i; i < toDisable.getHours(); i++) {
        newDisableHourList.push(i);
      }
      setDisableHourList(newDisableHourList);
    } else {
      setDisableHourList([])
    }
  }, [values.pickupDate])

  useField('pickupDate')

  return (
    <Row>
      <p className="heading3">{t('content.newOrder.readyDateTime')}</p>
      <div style={{ backgroundColor: "white", padding: "30px", width: "100%" }}>
        <LabelledCheckbox
          text="Now"
          checked={values.pickupNow}
          onChange={e => { form.setFieldValue('pickupNow', e.target.checked) }}
        />
        <Row gutter={[20, 20]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <LabelledDatePicker
              disabled={values.pickupNow}
              disabledDate={disabledDate}
              id="pickupDate"
              dateFormat={dateFormat}
              label={t('content.newOrder.ready.date')}
              error={errors.pickupDate}
              defaultValue={values.pickupDate}
              onChange={
                useCallback((date, dateString) => {
                  const formattedDate = moment(date).format();
                  setFieldValue('pickupDate', formattedDate);
                }, [])
              }
              required
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <LabelledTimePicker
              disabled={values.pickupNow}
              disabledHours={() => disableHourList}
              format="h:mm a"
              error={errors.pickupTime}
              defaultValue={values.pickupTime}
              minuteStep={1}
              onChange={
                useCallback((time, timeString) => {
                const formattedDate = moment(time).format();
                form.setFieldValue(
                  'pickupTime',
                  formattedDate
                )}, [])
              }
              label={t('content.newOrder.ready.time')}
              required
            />
          </Col>
        </Row>
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <CustomizedButton
            text={t('content.newOrder.next')}
            onClick={props.onClickNext}
          />
        </div>
      </div>
    </Row>
  );
}

export default ReadyDateAndTime;
