import React from "react";
import { Form } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import FormItem from './FormItem';

const PhoneNumberInput = ({
  setFieldValue,
  values,
  errors,
  name,
  placeholder,
  label,
  onChange,
  disabled,
  viewOnly,
  className,
  enableSearchField,
  dropdownStyle,
  searchPlaceholder,
  marginTop,
  touched,
  country,
  style
}) => (
  <FormItem
    validateStatus={disabled ? null : errors[name] && "error"}
    help={disabled ? null : errors[name]}
    label={label}
    className={className}
    error={errors[name]}
  >
    <ReactPhoneInput
      searchPlaceholder={searchPlaceholder}
      country={country || 'my'}
      enableSearchField={enableSearchField}
      inputStyle={
        errors[name]
          ? { border: "1px solid red", width: "100%", height: "32px", ...style }
          : { width: "100%", backgroundColor: (viewOnly || disabled) && '#F5F5F5', border: viewOnly && 'none', height: "32px", ...style }
      }
      dropdownStyle={dropdownStyle}
      buttonStyle={
        errors[name]
          ? { border: "1px solid red", height: "32px", marginTop: marginTop }
          : { height: "32px", marginTop: marginTop, border: viewOnly && 'none' }
      }
      disableDropdown={disabled}
      disabled={disabled}
      value={values[name] || undefined}
      placeholder={placeholder}
      onChange={
        onChange
          ? onChange
          : event => {
              setFieldValue(name, event);
            }
      }
    />
  </FormItem>
);

export default PhoneNumberInput;
