import React, { useContext, useState, useEffect } from "react";
import { Layout, List, Alert, Button, message, Avatar, Statistic, Card, Row, Col, Modal  } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TextLoop from 'react-text-loop';
import useLocalStorage from 'Util/useLocalStorage';

import mixpanel from 'mixpanel-browser';
import TagManager from 'react-gtm-module';

import "App.css";
import { homeList } from "Util/ArrayObjects";
import { getInstantQuote } from "Util/API";
import { GlobalContext } from 'Store/store';

import InstantQuote from "./InstantQuote";
import ReactHtmlParser from 'react-html-parser';

const { Content } = Layout;

const initialValues = {
  origin: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    countryName: "",
    type:  0
  },
  destination: {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zipcode: "",
    country: "",
    countryName: "",
    type: 0
  },
  itemType: null,
  weight: 0.1
}

const HomeContent = (props) => {
  const [state] = useContext(GlobalContext);
  const { userDetails, multipointServices, customerDetails, companyDetails, itemTypes, settings } = state.global;
  const [quotation, setQuotation] = useState({ services: [] });
  const [promo, showPromo] = useState(false);

  const googleAdsPixelId = settings["consumer_portal.dashboard"]?.data?.scripts_google_adwordsid;

  const t = {};
  t.common = useTranslation('homePage').t;
  t.pageWrapper = useTranslation('pageWrapper').t;
  initialValues.itemType = itemTypes[0];

  const activeHomeList = multipointServices.length > 0 ? homeList : homeList.filter((item) => (item.key !== 'multipoint' && item.key !== 'sameday'))

  const myBanners = useLocalStorage.getItem("myBanners")
  const myBannersItems = [];
  if(myBanners)
  {
      for (const [index, value] of myBanners.entries()) {
        myBannersItems.push({
            title: ReactHtmlParser(value.title.rendered),
            link: value.link ? value.link: '#',
            description: value.excerpt? ReactHtmlParser(value.excerpt.rendered.substring(0, 255)+"..."): '',
            img_url: value.jetpack_featured_media_url ? value.jetpack_featured_media_url : ''
        });
      }
  }

  const myAlerts = useLocalStorage.getItem("myAlerts")
  const myAlertItems = [];
  if(myAlerts)
  {
      for (const [index, value] of myAlerts.entries()) {
        myAlertItems.push(<div><strong>{ReactHtmlParser(value.title.rendered)} <a href={value.link} target="_blank">More info</a></strong></div>)
      }
  }

  const myPromoImage = useLocalStorage.getItem("myPromoImage");
  const myPromoLink = useLocalStorage.getItem("myPromoLink");
  const myPromoTitle = useLocalStorage.getItem("myPromoTitle");
  const myPromoDescription = useLocalStorage.getItem("myPromoDescription");

  const draftOrders = useLocalStorage.getItem("countOrdersDRAFT")? useLocalStorage.getItem("countOrdersDRAFT"):0;
  const newOrders = useLocalStorage.getItem("countOrders100")? useLocalStorage.getItem("countOrders100"):0;
  const pendingOrders = useLocalStorage.getItem("countOrdersPENDING")? useLocalStorage.getItem("countOrdersPENDING"):0;
  const currentOrders = useLocalStorage.getItem("countOrdersCURRENT")? useLocalStorage.getItem("countOrdersCURRENT"):0;

  const onClickDraftCard = (key, path) => {
    props.history.push({
      pathname: `${process.env.PUBLIC_URL}/orders/bulk-orders`,
      state: {
        selectedTab: key
      }
    })
  }

  const onClickStatisticCard = (key, path) => {
    props.history.push({
      pathname: `${process.env.PUBLIC_URL}/orders`,
      state: {
        selectedTab: key
      }
    })
  }

  const onSubmit = async (values) => {
    const { destination, origin, weight, itemType, codValue, insuranceValue } = values;
    setQuotation({ destination, origin, services: null });
    if ((origin.address1 || origin.zipcode || origin.country || origin.city) &&
    (destination.address1 || destination.zipcode || destination.country || destination.city)) {
      const fieldChecker = (value) => value && value.length > 0 ? value : "";
      let data = {
        companyId: companyDetails.id,
        customerId: customerDetails.id,
        origin: {
          address1: fieldChecker(origin.address1),
          address2: fieldChecker(origin.address2),
          city: fieldChecker(origin.city),
          state: fieldChecker(origin.state),
          postcode: fieldChecker(origin.zipcode),
          country: fieldChecker(origin.country)
        },
        destination: {
          address1: fieldChecker(destination.address1),
          address2: fieldChecker(destination.address2),
          city: fieldChecker(destination.city),
          state: fieldChecker(destination.state),
          postcode: fieldChecker(destination.zipcode),
          country: fieldChecker(destination.country)
        },
        itemType: itemType?.name,
        weight: {
          unit: "kg",
          value: weight
        },
      	serviceAddon: [{
      		id: -1, // COD
          qty: 1,
      		value: codValue ? codValue : 0,
      	}, {
          id: -3, //Insurance
      		qty: 1,
      		value: insuranceValue ? insuranceValue : 0,
      	}]
      };

      if (origin.lat && origin.lon) {
        data.origin.coord = {
          lat: Number(origin.lat),
          lon: Number(origin.lon)
        }
      }

      if (destination.lat && destination.lon) {
        data.destination.coord = {
          lat: Number(destination.lat),
          lon: Number(destination.lon)
        }
      }

      const response = await getInstantQuote(customerDetails?.id, data);
      if (response.status === 200) {
        const { services, waypoints } = response.data.data;

        ///remove cod and insurance if entered
        let aservices = [];
        let count = 0;
        for(let i=0; i < services.length; i++)
        {
            let isCod = false;
            let isInsured = false;

            let serviceAddons = services[i].service.addon;
            for(let x=0; x<serviceAddons.length; x++)
            {
                if(serviceAddons[x].id == -1)
                {
                    isCod = true;
                }
                if(serviceAddons[x].id == -3)
                {
                    isInsured = true;
                }
            }

            if(codValue > 0 && insuranceValue > 0)
            {
                if(isCod && isInsured)
                {
                    aservices[count] = services[i];
                    count++;
                }
            }else if(codValue > 0)
            {
                if(isCod)
                {
                    aservices[count] = services[i];
                    count++;
                }
            }else if(insuranceValue > 0)
            {
                if(isInsured)
                {
                    aservices[count] = services[i];
                    count++;
                }
            }else {
                aservices[count] = services[i];
                count++;
            }
        }
        //

        setQuotation({ services:aservices, origin: waypoints?.[0], destination: waypoints?.[1] });

        if (services.length === 0) {
          const geocodeError = response.data.errors?.[0]?.error?.code === 'GEOCODER_ERR';
          if (geocodeError) {
            const errorMessage = response.data.errors?.[0]?.error?.message;
            message.error(errorMessage ? 'Address not found' : ('Address not found: ' + errorMessage))
          } else {
            message.error('No available service.')
          }
        }

        //mixpanel
        let mpptoken = useLocalStorage.getItem("mpToken");
        if(mpptoken)
        {
            mixpanel.init(mpptoken);
            mixpanel.track('Quote - Web');
        }
        //mixpanel

      } else {
        message.error(response);
        setQuotation({ services: [] });
      }
    } else setQuotation({ destination, origin, services: [] });
  }

  useEffect(()=>{
    if(myPromoImage) showPromo(true);

    //reminder low balance
    if(customerDetails.planName && customerDetails.billing && customerDetails.billing.paymentTerm == 'debit' && customerDetails.walletBalance < 20)
    {
        message.warn('Low credit balance! '+companyDetails.currency+' '+customerDetails.walletBalance);
    }

    if(googleAdsPixelId)
    {
        const tagManagerArgs = {
          gtmId: googleAdsPixelId,
          dataLayer:  {
            'event': 'dashboard_view',
          }
        }
        TagManager.initialize(tagManagerArgs)
    }

  }, [])


  return (
    <Layout>

    {myAlertItems.length > 0 ?
    <Content style={{ margin: 'auto', padding: 20, width: '100%', maxWidth:'100%' }}>
      <Row>
        <Col span={24}>
            <Alert
                banner
                type="info"
                message={
                  <TextLoop mask={true} noWrap={false} >
                    {myAlertItems}
                  </TextLoop>
                }
              />
            </Col>
        </Row>
    </Content>
    : ''}

      <Content style={{ margin: 'auto', padding: 20, width: '100%', maxWidth: 1280 }}>
        <p className="heading1">{t.common('content.welcome', { username: userDetails.name })}</p>

        {newOrders >=0 || pendingOrders >= 0 || currentOrders >= 0 || customerDetails.walletBalance != 0 ?
        <div className="first-row-statistic-card">
            <p className="heading3">{t.common('content.stats.creditBalance')} {companyDetails.currency} {customerDetails.walletBalance}</p>
            <Row gutter={8,8}>
              <Col span={6}>
                <Card onClick={() => onClickDraftCard('DRAFT')}>
                  <Statistic
                    title={t.common('content.stats.drafts')}
                    value={draftOrders}
                    precision={0}
                    valueStyle={{ color: 'black' }}
                    // suffix={t.common('content.stats.orders')}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card onClick={() => onClickStatisticCard('NEW')}>
                  <Statistic
                    title={t.common('content.stats.new')}
                    value={newOrders}
                    precision={0}
                    valueStyle={{ color: '#cf1322' }}
                    // suffix={t.common('content.stats.orders')}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card onClick={() => onClickStatisticCard('PENDING')}>
                  <Statistic
                    title={t.common('content.stats.pending')}
                    value={pendingOrders}
                    precision={0}
                    valueStyle={{ color: 'orange' }}
                    // suffix={t.common('content.stats.orders')}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card onClick={() => onClickStatisticCard('CURRENT')}>
                  <Statistic
                    title={t.common('content.stats.current')}
                    value={currentOrders}
                    precision={0}
                    valueStyle={{ color: 'green' }}
                    // suffix={t.common('content.stats.orders')}
                  />
                </Card>
              </Col>
              {/*<Col span={4}>
                <Card onClick={() => props.history.push(`${process.env.PUBLIC_URL}/payment`)}>
                  <Statistic
                    title={t.common('content.stats.creditBalance')}
                    value={customerDetails.walletBalance}
                    precision={2}
                    valueStyle={{ color: 'blue' }}
                    // prefix={companyDetails.currency}
                  />
                </Card>
              </Col>*/}
            </Row>
          </div>
        : '' }

        {/*<div className="first-row-statistic-card">
          <p className="heading3">Your stats</p>
          <Row>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <div className="initial-statistic-card" onClick={() => onClickStatisticCard('1')}>
                <p className="heading4 darkBlueColor">Pending</p>
                <p className="heading3">{pendingOrders}</p>
              </div>
            </Col>
            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <div className="initial-statistic-card" onClick={() => onClickStatisticCard('2')}>
                <p className="heading4 darkBlueColor">Current</p>
                <p className="heading3">{currentOrders}</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <div className="initial-statistic-card" onClick={() => onClickStatisticCard('3')}>
                <p className="heading4 darkBlueColor">Completed</p>
                <p className="heading3">{onDelivery}</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={6} lg={6} xl={6}>
              <div className="initial-statistic-card" onClick={() => props.history.push(`${process.env.PUBLIC_URL}/payment`)}>
                <p className="heading4 darkBlueColor">Credit Balance</p>
                <p className="heading3">{companyDetails.currency} {customerDetails.walletBalance}</p>
              </div>
            </Col>
          </Row>
        </div>*/}

        <InstantQuote
          setQuotation={setQuotation}
          history={props.history}
          quotation={quotation}
          initialValues={initialValues}
          onSubmit={onSubmit}
          setIsLoading={props.setIsLoading}
        />
        <div
          style={{
            paddingTop: 30,
            marginBottom: 30
          }}
        >
          <p className="heading3"> {t.common('content.newOrder.title')} </p>
          <List
            style={{ padding: 0 }}
            itemLayout="horizontal"
            bordered
            // footer={homeList.map(list => (
            //   <Link key={list.key} to={list.path}>
            //     <div
            //       style={{
            //         background: "white",
            //         display: "flex",
            //         padding: "12px 24px",
            //         justifyContent: "space-between",
            //         alignItems: "center",
            //         width: "100%"
            //       }}
            //     >
            //       <div style={{ padding: "12px 0", color: "black" }}>
            //         {list.title}
            //       </div>
            //       <div>
            //         <i className="fa fa-chevron-right" />
            //       </div>
            //     </div>
            //   </Link>
            // ))}
            loading={false}
            dataSource={activeHomeList}
            renderItem={item => (
              <Link to={item.path}>
                <List.Item style={{ display: "flex", background: "#fff" }}>
                  <List.Item.Meta
                    title={
                      <p
                        style={{
                          paddingTop: 10
                        }}
                      >
                        {t.pageWrapper(`sidebarOrderMenus.${item.labelKey}`)}
                      </p>
                    }
                  />
                  <div>
                    <i className="fa fa-chevron-right" />
                  </div>
                </List.Item>
              </Link>
            )}
          />
        </div>

        {myBannersItems.length > 0 ?
        <div>
        <p className="heading3"> {t.common('content.feeds.title')} </p>
        <List itemLayout="horizontal"
            dataSource={myBannersItems}
            renderItem={item => (
              <List.Item >
                <List.Item.Meta
                  avatar={<Avatar src={item.img_url} shape="square" size="large" />}
                  title={<a href={item.link} target="_blank">{item.title}</a>}
                  description={item.description}
                />
              </List.Item>
            )}
          />
          </div>
          : ''}

          <Modal
            centered
            visible={promo}
            onOk={() => showPromo(false)}
            onCancel={() => showPromo(false)}
            width={720}
            footer={[]}
          >
          <Row>
            <Col xs={24} md={24} lg={24}>
            <a href={myPromoLink} target="_blank">
            <img src={"https://cdn.delyva.app"+myPromoImage} width="100%" height="100%" />
            </a>
            <br/>&nbsp;<br/>
            <p><b>{myPromoTitle}</b></p>
            <small>{myPromoDescription}</small>
          </Col>
          </Row>
          </Modal>

      </Content>
    </Layout>
  )
}

export default HomeContent;
