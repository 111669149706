import { message } from 'antd';
import { useEffect } from 'react';

export const useShowMessage = (isLoading, locationState, history) => {
  useEffect(() => {
    if (!isLoading && locationState?.showMsg) {
      const { type, time, message: msg } = locationState.showMsg;
      message[type](msg, time);
      history.replace({
        ...history.location,
        state: null
      })
    }
  }, [locationState, isLoading])

  return null
}

export const useOnAllRowsSelectedChange = (props) => {
  const { allRows, page, orderData, setSelectedRowsByPage, setTotalSelectedRows, rowTotal } = props;
   useEffect(() => {
     if (allRows.unselectedRowsId.length === 0) {
       if (allRows.selected) {
         setSelectedRowsByPage({ [page]: orderData });
         setTotalSelectedRows(rowTotal)
       } else {
         setSelectedRowsByPage({ [page]: [] });
         setTotalSelectedRows(0)
       }
     }
  }, [allRows.selected, allRows.checked])
  return null;
}

export const useOnSelectedRowsByPageChange = (selectedRowsByPage, onSetCurrentPageSelectedRowKeys) => {
  useEffect(() => {
    onSetCurrentPageSelectedRowKeys();
  }, [selectedRowsByPage])
  return null;
}

export const useOnOrderDataChange = (props) => {
  const { setSelectedRowsByPage, selectedRowsByPage, page, allRows, orderData } = props;
  useEffect(() => {
    setSelectedRowsByPage({
      ...selectedRowsByPage,
      [page]: selectedRowsByPage[page] || (allRows.selected ? orderData : [])
    });
  }, [orderData])
  return null;
}

export const useGetSelectedRowsIds = async (props) => {
  const { selectedRowsByPage, manualChecked, unselectedIds, setSelectedRowsIds, allRows } = props;
  useEffect(() => {
    let selectedRows = [];
    let selectedRowsIds;
    if (manualChecked) {
      selectedRowsIds = [].filter((i) => !unselectedIds.includes(i));
    } else {
      Object.keys(selectedRowsByPage).forEach((item, i) => {
        selectedRows = selectedRows.concat(selectedRowsByPage[item])
      })
      selectedRowsIds = selectedRows.map((item) => item.id)
    }
    setSelectedRowsIds(selectedRowsIds)
  }, [selectedRowsByPage, allRows.unselectedRowsId])
  return null;
}

export const useOnFilterChange = (props) => {
  const { dateFrom, dateTo, dateRangeId, reinitializeTable } = props;
  useEffect(() => {
    reinitializeTable()
  }, [dateFrom, dateTo, dateRangeId])
}
