import moment from "moment";
import { post, update, API_ROOT } from "Util/API";
import { message } from 'antd';

const submitOrder = async data => {
  const {
    state: { global },
    publicUrl,
    history,
    orderId,
    closeLoading,
    resetState,
    setLoadingMsg,
    process,
    addAnother,
    itemTypeOption,
    senderId,
    senderName,
    senderEmail,
    senderMobile,
    senderCompany,
    senderUnitNo,
    senderAddress1,
    senderAddress2,
    senderCity,
    senderState,
    senderPostcode,
    senderCountry,
    outlets,
    selectedOutletIndex,
    receiverId,
    receiverName,
    receiverEmail,
    receiverMobile,
    receiverCompany,
    receiverUnitNo,
    receiverAddress1,
    receiverAddress2,
    receiverCity,
    receiverState,
    receiverPostcode,
    receiverCountry,
    selectedItemTypeIndex,
    selectedServiceIndex,
    services,
    itemDetails,
    weightValue,
    widthValue,
    lengthValue,
    heightValue,
    itemValue,
    hsCode,
    quantity,
    pickupDate,
    pickupTime,
    pickupNow,
    promoCode,
    paymentMethodId,
    addon,
    note,
    referenceNo,
    dropAtOutlet
  } = data;

  const formattedPickupDate = new Date(pickupDate);
  const formattedPickupTime = new Date(pickupTime);
  const pickupYear = formattedPickupDate.getFullYear();
  const pickupMonth = formattedPickupDate.getMonth();
  const pickupDay = formattedPickupDate.getDate();
  const pickupHour = formattedPickupTime.getHours();
  const pickupMinute = formattedPickupTime.getMinutes();
  const pickupSchedule = new Date(
    pickupYear,
    pickupMonth,
    pickupDay,
    pickupHour,
    pickupMinute
  );
  const formattedPickupSchedule = moment(pickupSchedule).format();

  const serviceAddon = [];
  Object.keys(addon).forEach((key) => {
    const addonCopy = { qty: 1, ...addon[key] };
    addonCopy.id = Number(key);
    serviceAddon.push(addonCopy)
  })

  const dataToSubmit = {
    customerId: global.customerDetails.id,
    process,
    promoCode,
    serviceAddon,
    source: 'web-customer-single'
  };

  if (process) {
    dataToSubmit.paymentMethodId = paymentMethodId
  }

  if (services.length > 0 && selectedServiceIndex > -1 ) {
    dataToSubmit.serviceCode = services[Number(selectedServiceIndex)].service.code
  }

  if (note) {
    dataToSubmit.note = note
  }

  if(referenceNo) {
    dataToSubmit.referenceNo = referenceNo
  }

  let waypoints = [];
  if (orderId) {
    waypoints.push({
      id: senderId,
      type: 'PICKUP',
      scheduledAt: pickupNow ? 'now' : formattedPickupSchedule,
      inventory: [
        {
          name: itemDetails || "",
          type: itemTypeOption[selectedItemTypeIndex].name,
          weight: {
            value: weightValue,
            unit: "kg"
          },
          dimension: {
            unit: "cm",
            width: widthValue ? widthValue : 0,
            length: lengthValue ? lengthValue : 0,
            height: heightValue ? heightValue : 0
          },
          quantity: quantity,
          price: {
            amount: itemValue,
            currency: global.companyDetails.currency
          },
          hsCode: hsCode
        }
      ],
      contact: {
        name: senderName,
        phone: senderMobile,
        mobile: senderMobile,
        unitNo: (senderCompany?.length > 0 ? senderCompany + ', ' : '') + senderUnitNo || "",
        address1: senderAddress1 || '',
        address2: senderAddress2 || ' ',
        city: senderCity,
        state: senderState,
        postcode: senderPostcode,
        country: senderCountry
      }
    })
  }else if (dropAtOutlet && selectedOutletIndex) {
    waypoints.push({
      type: 'PICKUP',
      scheduledAt: pickupNow ? 'now' : formattedPickupSchedule,
      placeId: outlets[Number(selectedOutletIndex)].id,
      inventory: [
        {
          name: itemDetails || "",
          type: itemTypeOption[selectedItemTypeIndex].name,
          weight: {
            value: weightValue,
            unit: "kg"
          },
          dimension: {
            unit: "cm",
            width: widthValue ? widthValue : 0,
            length: lengthValue ? lengthValue : 0,
            height: heightValue ? heightValue : 0
          },
          quantity: quantity,
          price: {
            amount: itemValue,
            currency: global.companyDetails.currency
          },
          hsCode: hsCode
        }
      ],
      contact: {
        name: senderName,
        phone: senderMobile,
        mobile: senderMobile,
        unitNo: (senderCompany?.length > 0 ? senderCompany + ', ' : '') + senderUnitNo || "",
        address1: senderAddress1 || '',
        address2: senderAddress2 || ' ',
        city: senderCity,
        state: senderState,
        postcode: senderPostcode,
        country: senderCountry
      }
    })
  } else {
    waypoints.push({
      type: 'PICKUP',
      scheduledAt: pickupNow ? 'now' : formattedPickupSchedule,
      inventory: [
        {
          name: itemDetails || "",
          type: itemTypeOption[selectedItemTypeIndex].name,
          weight: {
            value: weightValue,
            unit: "kg"
          },
          dimension: {
            unit: "cm",
            width: widthValue ? widthValue : 0,
            length: lengthValue ? lengthValue : 0,
            height: heightValue ? heightValue : 0
          },
          quantity: quantity,
          price: {
            amount: itemValue,
            currency: global.companyDetails.currency
          },
          hsCode: hsCode
        }
      ],
      contact: {
        name: senderName,
        phone: senderMobile,
        mobile: senderMobile,
        unitNo: (senderCompany?.length > 0 ? senderCompany + ', ' : '') + senderUnitNo || "",
        address1: senderAddress1 || '',
        address2: senderAddress2 || ' ',
        city: senderCity,
        state: senderState,
        postcode: senderPostcode,
        country: senderCountry
      }
    })
  }

  if (senderEmail?.length > 5) {
    waypoints[waypoints.length - 1].contact.email = senderEmail
  }

  if (orderId) {
      waypoints.push({
        id: receiverId,
        type: 'DROPOFF',
        scheduledAt: pickupNow ? 'now' : moment(pickupSchedule).add(1, 'hours').format(),
        inventory: [
          {
            name: itemDetails || "",
            type: itemTypeOption[selectedItemTypeIndex].name,
            weight: {
              value: weightValue,
              unit: "kg"
            },
            dimension: {
              unit: "cm",
              width: widthValue ? widthValue : 0,
              length: lengthValue ? lengthValue : 0,
              height: heightValue ? heightValue : 0
            },
            quantity: quantity,
            price: {
              amount: itemValue,
              currency: global.companyDetails.currency
            },
            hsCode: hsCode
          }
        ],
        contact: {
          name: receiverName,
          phone: receiverMobile,
          mobile: receiverMobile,
          unitNo: (receiverCompany?.length > 0 ? receiverCompany + ', ' : '') + receiverUnitNo || "",
          address1: receiverAddress1 || '',
          address2: receiverAddress2 || '',
          city: receiverCity,
          state: receiverState,
          postcode: receiverPostcode,
          country: receiverCountry
        }
      })
  }else {
      waypoints.push({
        type: 'DROPOFF',
        scheduledAt: pickupNow ? 'now' : moment(pickupSchedule).add(1, 'hours').format(),
        inventory: [
          {
            name: itemDetails || "",
            type: itemTypeOption[selectedItemTypeIndex].name,
            weight: {
              value: weightValue,
              unit: "kg"
            },
            dimension: {
              unit: "cm",
              width: widthValue ? widthValue : 0,
              length: lengthValue ? lengthValue : 0,
              height: heightValue ? heightValue : 0
            },
            quantity: quantity,
            price: {
              amount: itemValue,
              currency: global.companyDetails.currency
            },
            hsCode: hsCode
          }
        ],
        contact: {
          name: receiverName,
          phone: receiverMobile,
          mobile: receiverMobile,
          unitNo: (receiverCompany?.length > 0 ? receiverCompany + ', ' : '') + receiverUnitNo || "",
          address1: receiverAddress1 || '',
          address2: receiverAddress2 || ' ',
          city: receiverCity,
          state: receiverState,
          postcode: receiverPostcode,
          country: receiverCountry
        }
      })
  }

  if (receiverEmail?.length > 5) {
    waypoints[waypoints.length - 1].contact.email = receiverEmail
  }

  dataToSubmit.waypoint = waypoints

  if(orderId)
  {
      //edit order
      const updateOrder = await update('order/'+orderId, dataToSubmit);
      if (updateOrder.status === 200) {
          if (addAnother) {
              message.success('Order successfully saved as draft.');
              resetState()
          } else if (process) {
              //submit process order
              const dataToProcess = {
                customerId: global.customerDetails.id,
                process: true,
                orderIds: [orderId]
              }

              const processOrder = await post('order/process', dataToProcess);
              if (processOrder.status === 200) {
                const { statusCode, invoiceId, orderId, id } = processOrder.data.data;
                if (statusCode === 10) {
                  setLoadingMsg('Do not close this page. Redirecting to payment gateway. Please wait..')
                  const doneUrl = window.location.origin + `${publicUrl}/orders/new-order/${orderId}`;
                  const paymentUrl = API_ROOT + `wallet/invoice/${invoiceId}/payment/?doneUrl=${doneUrl}&companyId=${global.companyDetails.id}`;
                  window.open(paymentUrl, '_self');
                } else {
                  history.replace(`${publicUrl}/orders/new-order`, {
                    orderId: orderId || id,
                    statusCode
                  })
                }
              }
          } else {
              history.push({
                pathname: `${publicUrl}/orders/bulk-orders`,
                state: {
                  showMsg: {
                    type: 'success',
                    time: 5,
                    message: 'Order successfully saved as draft. It will appear in your order list shortly.'
                  }
                }
              })
          }
      }else {
        message.error(updateOrder, 5);
        closeLoading();
      }
  }else {
      //submit new order
      const createOrder = await post("/order", dataToSubmit);
      if (createOrder.status === 200) {
        if (addAnother) {
          message.success('Order successfully saved as draft.');
          resetState()
        } else if (process) {
          const { statusCode, invoiceId, orderId, id } = createOrder.data.data;
          if (statusCode === 10) {
            setLoadingMsg('Do not close this page. Redirecting to payment gateway. Please wait..')
            const doneUrl = window.location.origin + `${publicUrl}/orders/new-order/${orderId}`;
            const paymentUrl = API_ROOT + `wallet/invoice/${invoiceId}/payment/?doneUrl=${doneUrl}&companyId=${global.companyDetails.id}`;
            window.open(paymentUrl, '_self');
          } else {
            history.replace(`${publicUrl}/orders/new-order`, {
              orderId: orderId || id,
              statusCode
            })
          }
        } else {
          history.push({
            pathname: `${publicUrl}/orders/bulk-orders`,
            state: {
              showMsg: {
                type: 'success',
                time: 5,
                message: 'Order successfully saved as draft. It will appear in your order list shortly.'
              }
            }
          })
        }
      } else {
        message.error(createOrder, 5);
        closeLoading();
      };
  }

  return;
}

export default submitOrder;
