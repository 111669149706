import axios from "axios";
import { MESSAGE_404 } from "./ErrorMessage";
import { TEXT_SERVER_DOWN } from "Util/Text";
import useLocalStorage from 'Util/useLocalStorage';

export let API_ROOT;
let image_url_root;

if (
  localStorage.getItem('dxStagingApi') === 'Y' ||
  window.location.hostname.includes("staging-my.delyva.app") ||
  window.location.hostname.includes("localhost")
) {
  API_ROOT = "https://staging-api.delyva.app/v1.0/";
  image_url_root = "https://md-test.sgp1.digitaloceanspaces.com";
} else {
  API_ROOT = "https://api.delyva.app/v1.0/";
  image_url_root = "https://cdn.delyva.app";
}

export const IMAGE_URL_ROOT = image_url_root;

function getErrorMessage(e) {
  if (e?.response?.data?.error && e?.response.data.error.message) {
    return e?.response?.data.error.message;
  }
  return TEXT_SERVER_DOWN;
}

export const getCompanyInfo = async () => {
  try {
    const response = await axios.get(
      process.env.NODE_ENV === 'development' ?
      API_ROOT + "company/info?url=https://my.staging.delyva.app/admin" :
      // API_ROOT + "company/info?url=https://my.delyva.app/customer" :
      // API_ROOT + "company/info?url=https://demo.delyva.app/customer" :
      API_ROOT + "company/info"
    );
    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      const companyId = useLocalStorage.getItem("companyId");
      if (companyId) {
        return await getCompanyInfoById(companyId)
      }
      return getErrorMessage(e);
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

const getCompanyInfoById = async (id) => {
  try {
    const response = await axios.get(API_ROOT + "company/" + id, {
      headers: {
        Authorization: `Bearer ` + useLocalStorage.getItem("accessToken")
      }
    });
    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 401) {
      const renewTokenRes = await renewToken();
      if (renewTokenRes?.status === 200) {
        return await getCompanyInfoById(id);
      }
      return renewTokenRes;
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
}

export const get = async endpoint => {
  try {
    const response = await axios.get(API_ROOT + endpoint, {
      headers: {
        Authorization: `Bearer ` + useLocalStorage.getItem("accessToken")
      }
    });

    if (!response.data.data) {
      console.error(`Missing 'data' key, ${endpoint}`);
      // return TEXT_SERVER_DOWN
    }

    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 401) {
      const renewTokenRes = await renewToken();
      if (renewTokenRes?.status === 200) {
        return await get(endpoint);
      }
      return renewTokenRes;
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export const post = async (endpoint, data) => {
  try {
    const response = await axios.post(API_ROOT + endpoint, data || null, {
      headers: {
        Authorization: `Bearer ` + useLocalStorage.getItem("accessToken"),
        "Content-Type": "application/json"
      }
    });

    if (!response.data.data) {
      console.error(`Missing 'data' key, ${endpoint}`);
      // return TEXT_SERVER_DOWN
    }

    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 401) {
      const renewTokenRes = await renewToken();
      if (renewTokenRes?.status === 200) {
        return await post(endpoint, data);
      }
      return renewTokenRes;
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export const update = async (endpoint, data) => {
  try {
    const response = await axios.patch(API_ROOT + endpoint, data, {
      headers: {
        Authorization: `Bearer ` + useLocalStorage.getItem("accessToken")
      }
    });
    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 401) {
      const renewTokenRes = await renewToken();
      if (renewTokenRes?.status === 200) {
        return await update(endpoint, data);
      }
      return renewTokenRes;
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export const remove = async (endpoint, data) => {
  try {
    let payload = {
      headers: {
        Authorization: `Bearer ` + useLocalStorage.getItem("accessToken"),
      }
    };
    if (data) {
      if (Object.keys(data)) {
        payload = Object.assign(payload, { data });
      }
    }
    const response = await axios.delete(`${API_ROOT}${endpoint}`, payload);
    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 401) {
      const renewTokenRes = await renewToken();
      if (renewTokenRes?.status === 200) {
        return await remove(endpoint);
      }
      return renewTokenRes;
    }
    if (e.response?.status === 403) {
      return e.response;
    }
    if (e.response?.status === 404) {
      return e.response;
    } else return getErrorMessage(e);
  }
};

export const register = async data => {
  try {
    const response = await axios.post(API_ROOT + "user/register", data);
    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else getErrorMessage(e);
  }
};

export const forgotPassword = async data => {
  try {
    const response = await axios.post(API_ROOT + "user/forgotPassword", data);
    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export const resetPassword = async data => {
  try {
    const response = await axios.post(API_ROOT + "user/resetPassword", data);

    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export const login = async (endpoint, data) => {
  try {
    const response = await axios.post(API_ROOT + endpoint, data);
    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      const { response: { data } } = e;
      if (data) {
        return data;
      }
      return TEXT_SERVER_DOWN;
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export const queryAddress = async query => {
  try {
    const response = await axios.get(
      "https://api.matdespatch.my/api/address?" + query
    );
    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export const getInstantQuote = async (isLoggedIn, data) => {
  try {
    const response = await axios.post(
      API_ROOT + "service/instantQuote/",
      data,
      {
        headers: {
          "Content-Type": "application/json"
        }
      }
    );

    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export const cancelOrder = async ids => {
  try {
    const response = await axios.post(
      API_ROOT + "order/" + ids.join() + "/cancel",
      null,
      {
        headers: {
          Authorization: `Bearer ` + useLocalStorage.getItem("accessToken"),
          "Content-Type": "application/json"
        }
      }
    );
    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 401) {
      const renewTokenRes = await renewToken();
      if (renewTokenRes?.status === 200) {
        return await cancelOrder(ids);
      }
      return renewTokenRes;
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export const getPaymentUrl = async endpoint => {
  await axios.get(API_ROOT + endpoint, {
    headers: {
      Authorization: `Bearer ` + useLocalStorage.getItem("accessToken")
    }
  });
};

export const openApiUrl = async url => {
    window.open(API_ROOT+url, "_blank");
};

export const renewToken = async () => {
  try {
    const fetchRes = await axios.post(API_ROOT + 'auth/refreshToken',
    {
      refreshToken: useLocalStorage.getItem("refreshToken")
    },
    {
      headers: {
        Authorization: `Bearer ` + useLocalStorage.getItem("accessToken")
      }
    });

    useLocalStorage.setItem("accessToken", fetchRes.data.data.accessToken);
    return fetchRes;
  } catch (e) {
    // useLocalStorage.clear();
    return e.response;
  }
}
