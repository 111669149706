import React, { useEffect } from "react";
import { Layout, Card, Row, Col, Typography } from 'antd';
import { Link } from 'react-router-dom';

import PageWrapper from 'Components/PageWrapper';

import styles from './analytic.module.css';

import { mixPanelTrack } from "Util/CustomFunctions";

const { Content, Header } = Layout;
const { Title } = Typography;

const AnalyticsPage = (props) => {

  useEffect(() => {
    mixPanelTrack('ViewAnalytics');
  }, []);

  return (
    <PageWrapper loading={false} currentPath={props.location} isCollapseSidebar={true}>
    <Layout className="ui-content">
      <Header style={{ backgroundColor: "#f0f2f5", paddingRight: 5, paddingLeft: 5 }}><Title level={2} className="ui-page-title">Analytics</Title></Header>
      <Content>
          <Card>
            <Row gutter={16}>
              <Col md={4} lg={4}>
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/analytics/orders`,
                    state: { id: 13 }
                  }}
                >
                  <Card>
                    <i className="fas fa-chart-line" style={{ margin: 15, fontSize: 35 }} />
                    <div style={{ fontSize: 17, fontWeight: 'bold' }}>Orders</div>
                  </Card>
                </Link>
              </Col>
              <Col md={4} lg={4}>
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/analytics/operations`,
                    state: { id: 34 }
                  }}
                >
                  <Card>
                    <i className="fas fa-chart-line" style={{ margin: 15, fontSize: 35 }} />
                    <div style={{ fontSize: 17, fontWeight: 'bold' }}>Operations</div>
                  </Card>
                </Link>
              </Col>
              <Col md={4} lg={4}>
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/analytics/cod`,
                    state: { id: 44 }
                  }}
                >
                  <Card>
                    <i className="fas fa-chart-line" style={{ margin: 15, fontSize: 35 }} />
                    <div style={{ fontSize: 17, fontWeight: 'bold' }}>COD</div>
                  </Card>
                </Link>
              </Col>
              <Col md={4} lg={4}>
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/analytics/ratings`,
                    state: { id: 43 }
                  }}
                >
                  <Card>
                    <i className="fas fa-chart-line" style={{ margin: 15, fontSize: 35 }} />
                    <div style={{ fontSize: 17, fontWeight: 'bold' }}>Ratings</div>
                  </Card>
                </Link>
              </Col>
              <Col md={6} lg={6}>
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/analytics/performance`,
                    state: { id: 49 }
                  }}
                >
                  <Card>
                    <i className="fas fa-chart-line" style={{ margin: 15, fontSize: 35 }} />
                    <div style={{ fontSize: 17, fontWeight: 'bold' }}>Service Performance</div>
                  </Card>
                </Link>
              </Col>
            </Row>
          </Card>
        </Content>
      </Layout>
    </PageWrapper>
  )
}

export default AnalyticsPage;
