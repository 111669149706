import { useEffect, useState, useRef } from 'react';

import { get } from 'Util/API';

const useOrderUpdateListener = (orderId, message) => {
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [blockSseUpdateUi, setBlockSseUpdateUi] = useState(false);
  let __timeout = useRef();

  const getOrder = async () => {
    const fetch = await get(`order/${orderId}`);
    if (fetch.status === 200) {
      const data = fetch.data.data;
      setUpdatedOrder(data)
    } else message.error(fetch);
  }

  useEffect(() => {
    const isRelatedEvent = message &&
      (
        message.event === 'order.status' ||
        message.event === 'order.failed' ||
        message.event === 'order.updated' ||
        message.event === 'order.created'
      );
    const isRelatedOrder = message && orderId === message.orderId;
    if (orderId && isRelatedEvent && isRelatedOrder && !blockSseUpdateUi) {
      message.statusCode === 200 && setBlockSseUpdateUi(true);
      clearTimeout(__timeout.current);
      getOrder();
    }
  }, [message])

  useEffect(() => {
    if (orderId) {
      __timeout.current = setTimeout(() => {
        getOrder();
      }, 3000);
    }
  }, [orderId])

  return updatedOrder;
}

export default useOrderUpdateListener;
