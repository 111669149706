import React, { useMemo, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { LabelledDropdown, LabelledInputTextarea } from "Components/optimized-input";

const CopypasteForm = (props) => {
  const { form, inputArray, inputValue, type, setInputValue, countryDropdownChildren } = props;
  const { handleChange, errors, values } = form;
  const { t } = useTranslation('orderPage');

  const formPlaceholder = useMemo(() => inputArray.reduce((acc, item, index) => {
    return acc.concat(item.placeholder + (`\n`).repeat(item.line))
  }, ``), [inputArray]);

  const onGetTextareaErrors = useMemo(() => {
    if (Object.keys(errors).length > 0) {
      const activeKey = inputArray.map((item) => item.fullAccessor);
      const activeKeyErrors = [];
      Object.keys(errors).forEach((key, i) => {
        if (activeKey.includes(key)) {
          activeKeyErrors.push(errors[key])
        }
      });
      return activeKeyErrors.join('. ')
    }
  }, [errors, inputArray])

  return (
    <div>
      <div style={{ display: 'flex', marginTop: 20 }}>
        {
          window.innerWidth > 576 &&
          <div style={{ minWidth: 150, padding: '4px 11px' }}>
            {
              inputArray.map((item) => (
                <div key={item.accessor} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  {
                    item.required && <span style={{ color: 'red', marginRight: 4 }}>*</span>
                  }
                  <div dangerouslySetInnerHTML={{ __html: `${item.label}${(`<br />`).repeat(item.line)}` }} />
                </div>
              ))
            }
          </div>
        }
        <LabelledInputTextarea
          id={type + 'Name'}
          error={onGetTextareaErrors}
          style={useMemo(() => ({ whiteSpace: inputValue && 'nowrap', overflowY: 'hidden', width: '100%' }), [inputValue])}
          value={inputValue}
          onChange={useCallback((val) => {
            setInputValue(val.target.value)
            props.onSetNormalFormValue(true, val.target.value)
          }, [])}
          placeholder={formPlaceholder}
          autoSize={useMemo(() => ({ minRows: 11 }), [])}
        />
      </div>
      <LabelledDropdown
        label={t('newOrderForm.copyPaste.country')}
        error={errors[type + 'Country']}
        value={values[type + 'Country']}
        onChange={useCallback(handleChange(type + 'Country'), [])}
        optionFilterProp="children"
        children={countryDropdownChildren}
        required
      />
    </div>
  )
}

export default CopypasteForm;
