import React from 'react';
import { Spin } from 'antd';

import PublicHeader from 'Components/PublicHeader';
import ShowOn from 'Util/ShowOn';

const AfterPayment = (props) => {
  const { showContent, isPaymentSuccessful, failedMessage, failedContent: FailedContent } = props;

  return (
    <PublicHeader style={{ width: '100%', maxWidth: 1280 }}>
      <ShowOn on={showContent} elseShow={<Spin tip={'Getting your payment status, please wait...'} />}>
        <h2 style={{ fontSize: 30, marginBottom: 50 }}>{isPaymentSuccessful ? 'Thanks for your order.' : failedMessage || 'Failed to process'}</h2>
        <FailedContent />
        {props.children}
      </ShowOn>
    </PublicHeader>
  )
}

export default AfterPayment;
