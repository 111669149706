import React, { useState, useEffect, Fragment } from "react";
import { Tabs, Layout } from "antd";
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  useShowMessage,
  useOnAllRowsSelectedChange,
  useOnSelectedRowsByPageChange,
  useOnOrderDataChange,
  useOnFilterChange
} from './tablePageHelper';
import usePrevious from 'Util/Hooks/usePrevious';
import ShowOn from 'Components/ShowOn';

const TabPane = Tabs.TabPane;
const { Content } = Layout;

const TablePage = (props) => {
  const {
    isLoading,
    setIsLoading,
    defaultActiveTabValue,
    tabs,
    generateColumn,
    tableRenderer: TableRenderer,
    rowsActionRenderer: RowsActionRenderer,
    filtersRenderer: FiltersRenderer,
    headerRenderer: Header,
    onFetchDataSource,
    filterKeys,
    initialState,
    onChangeTabKey,
    tabBarStyle,
    rootStyle
  } = props;

  const location = useLocation();
  const history = useHistory();
  const { state: locationState } = location;

  const [allSelectedRows, setAllSelectedRows] = useState([]);
  const [selectedRowsByPage, setSelectedRowsByPage] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [totalSelectedRows, setTotalSelectedRows] =  useState(0);
  const [selectAll, setSelectAll] =  useState({
    checked: false,
    selected: false,
    manualChecked: false,
    unselectedRowsId: []
  });
  const [filterAndTableState, setFilterAndTableState] = useState({
    data: [],
    limit: 10,
    page: 1,
    pageTotal: 0,
    rowTotal: 0,
    orderBy: 'desc',
    sortBy: null,
    [onChangeTabKey]: defaultActiveTabValue,
    ...initialState
  });
  const { limit, page, orderBy, sortBy, rowTotal, data: rowsDataSource } = filterAndTableState;

  useEffect(() => {
    let allSelectedOrders = [];
    Object.values(selectedRowsByPage).forEach((item) => {
      allSelectedOrders = allSelectedOrders.concat(item)
    });
    setAllSelectedRows(allSelectedOrders);
  }, [selectedRowsByPage])

  const getTableRows = async (__data) => {
    const dataCopy = { ...filterAndTableState };
    dataCopy.page = __data?.page || dataCopy.page;
    const fetchDataSource = await onFetchDataSource(dataCopy);
    const { data, ...rest } = fetchDataSource;
    const ordersWithKey = data.map((item, i) => ({
      key: dataCopy.page > 1 ? dataCopy.page - 1 + i.toString() : i.toString(),
      ...item
    }));
    setFilterAndTableState({
      ...filterAndTableState,
      page: dataCopy.page,
      data: ordersWithKey,
      ...rest
    })
  }

  const keyToListen = [limit, orderBy, page, sortBy];
  filterKeys.forEach((item) => {
    keyToListen.push(filterAndTableState[item]);
  });
  const prevPage = usePrevious(page);

  useEffect(() => {
    getTableRows({ page: prevPage !== page ? page : 1 })
    if (prevPage === page) {
      resetState()
    }
  }, keyToListen)

  useEffect(() => {
    // TOFIX: This cause multiple fetch, use for cancel multiple order
    if (!rowsDataSource) {
      getTableRows({ ...keyToListen, page: prevPage !== page ? page : 1 })
    }
  }, [rowsDataSource])

  const onSetCurrentPageSelectedRowKeys = () => {
    let newSelectedRowKeys = [];
    selectedRowsByPage[page] && selectedRowsByPage[page].forEach((item) => {
      newSelectedRowKeys.push(item.key)
    })
    setSelectedRowKeys(newSelectedRowKeys);
  }

  const reinitializeTable = () => {
    // setIsLoading(false);
    setSelectedRowsByPage({})
    setTotalSelectedRows(0);
    setSelectedRowKeys([]);
    setSelectAll({
      checked: false,
      selected: false,
      manualChecked: false,
      unselectedRowsId: []
    });
  }

  const resetState = (props) => {
    reinitializeTable();
    setFilterAndTableState({
      ...filterAndTableState,
      ...props,
      page: props?.setToPage || 1
    })
  }

  useShowMessage(isLoading, locationState, history);

  useOnAllRowsSelectedChange({
    allRows: selectAll,
    selectedRowsByPage,
    page,
    orderData: rowsDataSource,
    setSelectedRowsByPage,
    setTotalSelectedRows,
    rowTotal
  })

  useOnSelectedRowsByPageChange(
    selectedRowsByPage,
    onSetCurrentPageSelectedRowKeys
  )

  useOnOrderDataChange({
    setSelectedRowsByPage,
    selectedRowsByPage,
    page,
    allRows: selectAll,
    orderData: rowsDataSource
  })

  useOnFilterChange({
    ...filterAndTableState,
    reinitializeTable
  })

  const handleSelectAll = () => {
    const isChecked = !selectAll.checked
    setSelectAll({
      unselectedRowsId: [],
      manualChecked: !selectAll.manualChecked,
      selected: isChecked,
      checked: isChecked
    });
  };

  const prevSelectedRows = usePrevious(selectedRowsByPage[page]) || [];
  const rowSelection = {
    selectedRowKeys,
    // trigger on single & multi row selection
    onChange: (key, selected) => {
      setSelectedRowsByPage({
        ...selectedRowsByPage,
        [page]: selected
      });
      const getTotalSelectedRows = totalSelectedRows - prevSelectedRows.length + selected.length;
      const currentPageAllRowIds = rowsDataSource.map((item) => item.id);
      const currentPageSelectedRowIds = selected.map((item) => item.id);
      const currentPageUnselectedRowIds = selectedRowsByPage[page]?.filter((item) => !currentPageSelectedRowIds.includes(item.id)).map((item) => item.id) || [];
      const allUnselectedRowIds = selectAll.unselectedRowsId.filter((item) => !currentPageAllRowIds.includes(item)).concat(currentPageUnselectedRowIds);
      setTotalSelectedRows(getTotalSelectedRows);
      setSelectAll({
        ...selectAll,
        checked: rowTotal === getTotalSelectedRows,
        unselectedRowsId: allUnselectedRowIds

      })
    }
  };

  const handleSetFilterAndTableState = (val) => {
    let validItem = {};
    Object.keys(val).forEach((item) => {
      if (val[item] !== undefined) {
        validItem[item] = val[item]
      }
    })
    const newData = {...filterAndTableState, ...validItem};
    setFilterAndTableState(newData)
  }

  const columns = generateColumn({
    // state,
    history,
    setIsLoading,
    resetState,
    setFilterAndTableState,
    filterAndTableState
  });

  if (tabs) {
    return (
      <div style={rootStyle}>
        <Header filterAndTableState={filterAndTableState} />
        <div>
          <div
            // className="whiteBColor"
            // style={{ height: "100%", border: "1px solid #cfdae6" }}
          >
            <Tabs
              tabBarStyle={{ ...tabBarStyle, backgroundColor: 'white' }}
              defaultActiveKey={defaultActiveTabValue}
              animated={false}
              onChange={(val) => resetState({ [onChangeTabKey]: val })}
            >
              {
                tabs.map((item) => {
                  const { renderer: ItemRenderer } = item;
                  return (
                    <TabPane tab={item.name} key={item.key}>
                      <ShowOn on={filterAndTableState[onChangeTabKey] === item.key}>
                        {
                          item.renderer ?
                          <ItemRenderer /> :
                          <div>
                            <FiltersRenderer
                              filterAndTableState={filterAndTableState}
                              handleSetFilterAndTableState={handleSetFilterAndTableState}
                            />
                            <RowsActionRenderer
                              allSelectedRows={allSelectedRows}
                              selectAllChecked={selectAll.checked}
                              selectAllManualChecked={selectAll.manualChecked}
                              selectAllUnselectedRowsId={selectAll.unselectedRowsId}
                              handleSelectAll={handleSelectAll}
                              filterAndTableState={filterAndTableState}
                              setIsLoading={setIsLoading}
                              resetState={resetState}
                              history={history}
                              totalSelectedRows={totalSelectedRows}
                            />
                            <TableRenderer
                              filterAndTableState={filterAndTableState}
                              handleSetFilterAndTableState={handleSetFilterAndTableState}
                              selectedRowsByPage={selectedRowsByPage}
                              rowSelection={rowSelection}
                              columns={columns}
                            />
                          </div>
                        }
                      </ShowOn>
                    </TabPane>
                  )
                })
              }
            </Tabs>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div style={rootStyle}>
      <Header filterAndTableState={filterAndTableState} />
      <div
        // className="whiteBColor"
        // style={{ height: "100%", border: "1px solid #cfdae6" }}
      >
        <FiltersRenderer
          filterAndTableState={filterAndTableState}
          handleSetFilterAndTableState={handleSetFilterAndTableState}
        />
        <RowsActionRenderer
          allSelectedRows={allSelectedRows}
          selectAllChecked={selectAll.checked}
          selectAllManualChecked={selectAll.manualChecked}
          selectAllUnselectedRowsId={selectAll.unselectedRowsId}
          handleSelectAll={handleSelectAll}
          filterAndTableState={filterAndTableState}
          setIsLoading={setIsLoading}
          resetState={resetState}
          history={history}
          totalSelectedRows={totalSelectedRows}
        />
        <TableRenderer
          filterAndTableState={filterAndTableState}
          handleSetFilterAndTableState={handleSetFilterAndTableState}
          selectedRowsByPage={selectedRowsByPage}
          rowSelection={rowSelection}
          columns={columns}
        />
      </div>
    </div>
  )
}

TablePage.propTypes = {
  tableRenderer: PropTypes.func,
  rowsActionRenderer: PropTypes.func,
  filtersRenderer: PropTypes.func,
  headerRenderer: PropTypes.func,
}

TablePage.defaultProps = {
  tableRenderer: () => <Fragment />,
  rowsActionRenderer: () => <Fragment />,
  filtersRenderer: () => <Fragment />,
  headerRenderer: () => <Fragment />,
}

export default TablePage;
