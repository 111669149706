import React from "react";
import { Form, Input } from "antd";
import { useField } from 'formik';

const { TextArea } = Input;

const CustomizedTextarea = ({
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  name,
  placeholder,
  prefix,
  label,
  max,
  onChange,
  onBlur,
  disabled,
  className,
  autoSize,
  style,
  value
}) => {
  const [field] = useField({ name });
  return  (
    <Form.Item
      validateStatus={disabled ? null : errors[name] && "error"}
      help={disabled ? null : errors[name]}
      label={label}
      className={className}
      style={style}
    >
      <TextArea
        {...field}
        disabled={disabled}
        prefix={prefix}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={() => {
          setFieldValue(name, value)
        }}
        autoSize={autoSize}
      />
    </Form.Item>
  )
};

export default CustomizedTextarea;
