import React from "react";
import { Form, Select } from "antd";

const CustomizedDropdown = ({
  defaultValue,
  errors,
  name,
  label,
  handleFocus,
  handleBlur,
  className,
  placeholder,
  optionFilterProp,
  children,
  setFieldValue,
  values,
  mode,
  onChange,
  disabled,
  style,
  wrapperStyle
}) => (
  <Form.Item
    label={label}
    className={className}
    validateStatus={errors[name] && "error"}
    help={errors[name]}
    style={wrapperStyle}
  >
    <Select
      // defaultValue={defaultValue}
      onBlur={() => {}}
      style={style}
      value={values[name] || defaultValue}
      mode={mode}
      showSearch
      placeholder={placeholder}
      optionFilterProp={optionFilterProp}
      onChange={onChange ? onChange : value => setFieldValue(name, value)}
      onFocus={handleFocus}
      //onBlur={handleBlur}
      filterOption={(input, option) =>
        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      }
      disabled={disabled}
    >
      {children}
    </Select>
  </Form.Item>
);

export default CustomizedDropdown;
