import React from "react";
import { Form, Input } from "antd";

const { TextArea } = Input;

const LabelledInputTextArea = React.memo(({
  error,
  placeholder,
  type,
  prefix,
  label,
  addonAfter,
  addonBefore,
  size,
  disabled,
  viewOnly,
  className,
  style,
  step,
  value,
  onChange,
  id,
  name,
  max,
  autoSize,
  required
}) => {
  // console.log('LabelledInputTextArea');
  return (
    <Form.Item
      validateStatus={disabled ? null : error && "error"}
      help={disabled ? null : error}
      label={label}
      className={className}
      style={{ width: '100%' }}
      noStyle
      required={required}
    >
      <TextArea
        id={id}
        disabled={disabled}
        style={{ color: viewOnly && '#000000a6', border: viewOnly && 'none', ...style }}
        prefix={prefix}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        size={size}
        step={step}
        autoSize={autoSize}
      />
    </Form.Item>
  )
})

export default LabelledInputTextArea;
