import React, { useContext, useEffect, useState } from "react";
import {
  Table,
  Button,
  Dropdown,
  Menu,
  Radio,
  Checkbox,
  Input,
  Row,
  Col,
  Select
} from "antd";
import moment from 'moment';
import { useTranslation } from "react-i18next";
import { message } from "antd";

import { get } from 'Util/API';
import EmptyData from "Components/EmptyData";
import { API_ROOT } from 'Util/API';
import withStore from 'Util/withStore';
import useWindowSize from 'Util/useWindowSize';
import DateFilter from 'Pages/Order/DateFilter';
import { GlobalContext } from 'Store/store';
import { onOpenCancelOrderModal } from 'Pages/Order/ordersHelper';

const { Option } = Select;

const OrdersTableContent = (props) => {
  const {t} = useTranslation('orderPage');
  const size = useWindowSize();
  // const tableWidth = size[0] - 310;
  const { state: { global }, ordersTableData, setIsLoading, resetState, history } = props;
  const { data: dataSource, rowTotal } = ordersTableData;
  const [state] = useContext(GlobalContext);
  const { serviceCompanies, modalState, setModalState } = state.global;
  let orderIds = [...props.orderIds];

  const getTrackingUrl = (rows) => {
    let allSelectedOrders = [];
    const consignmentNumbers = [];
    Object.values(rows).forEach((item) => {
      allSelectedOrders = allSelectedOrders.concat(item)
    });
    allSelectedOrders.forEach((item) => {
      consignmentNumbers.push(item.consignmentNo)
    })
    return `${process.env.PUBLIC_URL}/track?trackingNo=${consignmentNumbers.join(',')}`
  }

  const handleTableChange = (pagination, filters, sorter) => {
    props.setOrdersTableData({
      page: pagination.current,
      limit: pagination.pageSize,
      sortBy: sorter.field,
      orderBy: sorter.order
    })
  }

  const getAllOrders = async () => {
    setIsLoading(true);
    const dataCopy = { ...ordersTableData };
    dataCopy.dateFrom = dataCopy.dateFrom?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.dateTo = dataCopy.dateTo?.format('YYYY-MM-DD').toLocaleString();
    delete dataCopy.limit;
    delete dataCopy.data;
    delete dataCopy.pageTotal;
    delete dataCopy.rowTotal;
    delete dataCopy.dateRangeId;
    const params = [];
    Object.keys(dataCopy).forEach((key, i) => {
      dataCopy[key] && params.push((i > 0 ? '&' : '') + key + '=' + dataCopy[key]);
    });
    const orderResponse = await get(`order?limit=${rowTotal}&` + params.join(""));
    setIsLoading(false);
    if (orderResponse.status === 200) {
      return orderResponse.data.data;
    } else {
      message.error(orderResponse);
      return [];
    };
  }

  return (
    <div>
      <div style={{ marginBottom: 16, paddingLeft: 20, paddingRight: 20 }}>
        <Input.Search
          style={{ marginBottom: 10 }}
          enterButton={t('content.tableContent.search')}
          placeholder={t('content.tableContent.orderNo')}
          allowClear
          onSearch={(val) => props.setOrdersTableData({ keyword: val })}
        />
        <Row gutter={[10, 10]} style={{ display: 'flex', flexDirection: size[0] > 472 ? 'row' : 'column', alignItems: size[0] > 472 ? 'center' : 'unset', marginBottom: 10 }}>
          <Col>
            <div>{t('content.tableContent.filters')}</div>
          </Col>
          <Col>
            <DateFilter
              currentId={ordersTableData.dateRangeId}
              handleModalRangeDate={(dateFrom, dateTo, id) => {
                props.setOrdersTableData({
                  dateFrom: dateFrom && moment(dateFrom),
                  dateTo: dateTo && moment(dateTo),
                  dateRangeId: id
                })
              }}
              handleCustomDateRange={props.onOpenDateFilter}
            />
          </Col>
        </Row>
        {/* <Radio.Group defaultValue="a">
          <Radio.Button>
            {props.totalSelectedRows} {t('content.tableContent.selected')}
          </Radio.Button>
          <Radio.Button value="b">
            <Checkbox
              checked={props.checked}
              className="table-checkbox"
              onChange={() => props.selectAll()}
            >
              {t('content.tableContent.selectAll')} {rowTotal + " "}
              {t('content.tableContent.orders')}
            </Checkbox>
          </Radio.Button>
          <Radio.Button value="c">
            <Dropdown
              overlay={
                <Menu style={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    style={{ border: 'none' }}
                    target="_blank"
                    disabled={!(orderIds.length > 0)}
                    onClick={async () => {
                      if (Number(props.totalSelectedRows) === Number(rowTotal)) {
                        const rows = await getAllOrders();
                        orderIds = rows.map((item) => item.id);
                      }
                      window.open(`${API_ROOT}order/${orderIds.join()}/label?companyId=${global.companyDetails.id}`);
                    }}
                  >
                    {t('content.tableContent.printLabel')}
                  </Button>
                  <Button
                    style={{ border: 'none' }}
                    target="_blank"
                    disabled={!(orderIds.length > 0)}
                    onClick={async () => {
                      if (Number(props.totalSelectedRows) === Number(rowTotal)) {
                        const rows = await getAllOrders();
                        orderIds = rows.map((item) => item.id);
                      }
                      window.open(`${API_ROOT}order/${orderIds.join()}/packlist?companyId=${global.companyDetails.id}`);
                    }}
                  >
                    Print Packing Lists
                  </Button>
                  <Button
                    style={{ border: 'none' }}
                    target="_blank"
                    disabled={!(orderIds.length > 0)}
                    onClick={async () => {
                      if (Number(props.totalSelectedRows) === Number(rowTotal)) {
                        const rows = await getAllOrders();
                        orderIds = rows.map((item) => item.id);
                      }
                      window.open(`${API_ROOT}order/${orderIds.join()}/inv_invoice?companyId=${global.companyDetails.id}`);
                    }}
                  >
                    Print Com. Invoice
                  </Button>
                  <Button
                    style={{ border: 'none' }}
                    target="_blank"
                    disabled={!(orderIds.length > 0)}
                    onClick={async () => {
                      if (Number(props.totalSelectedRows) === Number(rowTotal)) {
                        const rows = await getAllOrders();
                        orderIds = rows.map((item) => item.id);
                      }
                      window.open(`${API_ROOT}order/${orderIds.join()}/invoice_packlist?companyId=${global.companyDetails.id}`);
                    }}
                  >
                    Com. Invoice + Packing List
                  </Button>
                  <Button
                    style={{ border: 'none' }}
                    disabled={!(orderIds.length > 0)}
                    onClick={async () => {
                      let rowsByKey = { ...props.selectedRowsByPage };
                      if (Number(props.totalSelectedRows) === Number(rowTotal)) {
                        const rows = await getAllOrders();
                        rowsByKey = { rows }
                      }
                      const trackingUrl = getTrackingUrl(rowsByKey);
                      window.open(trackingUrl);
                    }}
                  >
                    {t('content.tableContent.track')}
                  </Button>
                  <Button
                    style={{ border: 'none' }}
                    disabled={!(orderIds.length > 0)}
                    onClick={async () => {

                      if (Number(props.totalSelectedRows) === Number(rowTotal)) {
                        const rows = await getAllOrders();
                        orderIds = rows.map((item) => item.id);
                      }

                      onOpenCancelOrderModal({
                        setIsLoading,
                        setModalState,
                        selectedRowsIds: orderIds,
                        resetState,
                        ordersTableData,
                        modalState,
                        history,
                        transl: t
                      })
                    }}
                  >
                    {t('content.tableContent.cancel')}
                  </Button>
                </Menu>
              }
              trigger={["click"]}
              className="action-dropdown"
            >
              <span>
              {t('content.tableContent.action')} <i className="fas fa-chevron-down" />
              </span>
            </Dropdown>
          </Radio.Button>
        </Radio.Group> */}
      </div>
      <Table
        rowSelection={props.rowSelection}
        dataSource={dataSource}
        columns={props.columns}
        total={props.pageTotal}
        pagination={{
          current: ordersTableData.page,
          total: props.ordersTableData.rowTotal,
          defaultPageSize: 10,
          showSizeChanger: false,
          pageSizeOptions: ["10", "20", "30", "40", "50"],
          showTotal: (total, range) => `${range[1]} of ${total} ${t('content.tableContent.items')}`
        }}
        onChange={handleTableChange}
        loading={false}
        scroll={{ x: 1200 }}
        style={{ maxHeight: "60%" }}
        locale={{
          emptyText: (
            <EmptyData
              text={t('content.header.newOrder')}
              path={`${process.env.PUBLIC_URL}/orders/new-order`}
            />
          )
        }}
      />
    </div>
  )
}

export default withStore(OrdersTableContent);
