import React, { useContext } from 'react';
import { Spin, Button } from 'antd';
import styled from 'styled-components'
import { useTranslation } from "react-i18next";

import ShowOn from 'Util/ShowOn';
import { onOpenCancelOrderModal } from 'Pages/Order/ordersHelper';
import { GlobalContext } from "Store/store";
import useAfterPayment from './useAfterPayment';

const AfterPaymentDialogContent = (props) => {
  const { currentOrder, showContent, customText, onNextOrder, onCancelOrder } = useAfterPayment(props);
  const [store] = useContext(GlobalContext);
  const { modalState, setModalState } = store.global;
  const { t } = useTranslation('orderPage');

  return (
    <RootContainer>
      <ShowOn on={!showContent}>
        <Spin spinning={!showContent} tip="Please wait. Getting your order status...">
          <div style={{ minWidth: 200, minHeight: 100 }} />
        </Spin>
      </ShowOn>
      <ShowOn on={showContent}>
        <div style={{ display: 'flex', alignItems: 'baseline', marginBottom: 20 }}>
          <div style={{ marginRight: 20, textAlign: 'center' }}>Order status: {currentOrder?.status + ' '} ({currentOrder?.statusCode})</div>
          <Spin spinning={currentOrder?.statusCode !== 200 && currentOrder?.statusCode !== 900} />
        </div>
        <h2 style={{ fontSize: 25, textAlign: 'center' }}>{customText}</h2>
        <ShowOn on={currentOrder?.failedReason}>
          <div style={{ marginBottom: 20, color: 'red' }}>
            {currentOrder?.failedReason}
          </div>
        </ShowOn>
      </ShowOn>
      <Button
        style={{ border: 'none', width: '100%', marginTop: 20 }}
        onClick={() => onOpenCancelOrderModal({
          id: currentOrder.id,
          modalState,
          setModalState,
          onConfirmCancelOrder: onCancelOrder,
          transl: t
        })}
      >
        Cancel
      </Button>
      <Button
        type="primary"
        style={{ border: 'none', width: '100%' }}
        onClick={onNextOrder}
      >
        Next order
      </Button>
    </RootContainer>
  )
}

const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export default AfterPaymentDialogContent;
