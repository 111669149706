import React, { useContext, useState, useEffect } from "react";
import { Card, Form, Row, Col, Upload, Modal, message } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import CustomizedButton from "Components/CustomizedButton";
import { IMAGE_URL_ROOT } from "Util/API";

import {
  TEXT_SEARCH_COUNTRY,
  TEXT_PROFILE_PHOTO_PREVIEW,
  TEXT_UPLOAD_OR_DROP_FILE,
} from "Util/Text";
import TextInput from "Components/TextInput";
import CustomizedTextarea from "Components/CustomizedTextarea";
import CustomizedSwitch from "Components/CustomizedSwitch";

import PhoneNumberInput from "Components/PhoneNumberInput";
import { STATE_LIST } from "Util/State";
import { GlobalContext } from "Store/store";
import { post, update } from "Util/API";

import previewTrack from 'images/screens/custom-brand-trackpage.jpeg';
import previewEmail from 'images/screens/custom-brand-email.png';
import previewSms from 'images/screens/custom-brand-sms.png';

import { mixPanelTrack } from "Util/CustomFunctions";

const uploadLogoButton = (
  <div>
    <i className="fas fa-plus" />
    <div className="ant-upload-text">{TEXT_UPLOAD_OR_DROP_FILE}</div>
  </div>
);

const uploadPromoImgButton = (
  <div>
    <i className="fas fa-plus" />
    <div className="ant-upload-text">{TEXT_UPLOAD_OR_DROP_FILE}</div>
  </div>
);

const uploadPopupImgButton = (
  <div>
    <i className="fas fa-plus" />
    <div className="ant-upload-text">{TEXT_UPLOAD_OR_DROP_FILE}</div>
  </div>
);

const CustomBranding = (props) => {
  const { isLoading, setIsLoading } = props;
  const [state, dispatch] = useContext(GlobalContext);
  const { userDetails, customerDetails } = state.global;
  // const [newProfilePic, setNewProfilePic] = useState(false);
  const [newLogo, setNewLogo] = useState(false);
  const [newPromoImg, setNewPromoImg] = useState(false);
  const [newPopupImg, setNewPopupImg] = useState(false);
  const [colorBg, setColorBg] = useState(false);
  const [colorPrimary, setColorPrimary] = useState(false);

  const { t } = useTranslation('settingsPage');

  const onSubmit = async (values) => {
    !isLoading && setIsLoading(true);

    const brandingData = { ...values };

    brandingData.notifyEmail100_content_a = brandingData.notifyEmail100_content ? brandingData.notifyEmail100_content.split("\n") : [];
    brandingData.notifyEmail200_content_a = brandingData.notifyEmail200_content ? brandingData.notifyEmail200_content.split("\n") : [];
    // brandingData.notifyEmail400_content_a = brandingData.notifyEmail400_content ? brandingData.notifyEmail400_content.split("\n") : [];
    brandingData.notifyEmail500_content_a = brandingData.notifyEmail500_content ? brandingData.notifyEmail500_content.split("\n") : [];
    brandingData.notifyEmail600_content_a = brandingData.notifyEmail600_content ? brandingData.notifyEmail600_content.split("\n") : [];
    brandingData.notifyEmail700_content_a = brandingData.notifyEmail700_content ? brandingData.notifyEmail700_content.split("\n") : [];
    brandingData.notifyEmail900_content_a = brandingData.notifyEmail900_content ? brandingData.notifyEmail900_content.split("\n") : [];

    let branding = {
        customerId: customerDetails.id,
        // logo: undefined
        name: brandingData.name ? brandingData.name: '',
        description: brandingData.description ? brandingData.description: '',

        colorPrimary: brandingData.colorPrimary ? brandingData.colorPrimary: '',
        colorBg: brandingData.colorBg ? brandingData.colorBg: '',

        pixelFb: brandingData.pixelFb ? brandingData.pixelFb: '',
        pixelGoogleAds: brandingData.pixelGoogleAds ? brandingData.pixelGoogleAds: '',
        pixelGoogleAnalytics: brandingData.pixelGoogleAnalytics ? brandingData.pixelGoogleAnalytics: '',
        pixelMixpanel: brandingData.pixelMixpanel ? brandingData.pixelMixpanel: '',
        pixelTiktok: brandingData.pixelTiktok ? brandingData.pixelTiktok: '',

        // promoBannerImg: brandingData.promoBannerImg ? brandingData.promoBannerImg: '',
        promoBannerDesc: brandingData.promoBannerDesc ? brandingData.promoBannerDesc: '',
        promoBannerUrl: brandingData.promoBannerUrl ? brandingData.promoBannerUrl: '',
        promoNoticeCTA: brandingData.promoNoticeCTA ? brandingData.promoNoticeCTA: '',
        promoNoticeDescription: brandingData.promoNoticeDescription ? brandingData.promoNoticeDescription: '',
        promoNoticeTitle: brandingData.promoNoticeTitle ? brandingData.promoNoticeTitle: '',
        promoNoticeUrl: brandingData.promoNoticeUrl ? brandingData.promoNoticeUrl: '',

        // promoPopupImg: brandingData.promoPopupImg ? brandingData.promoPopupImg: '',
        promoPopupDesc: brandingData.promoPopupDesc ? brandingData.promoPopupDesc: '',
        promoPopupUrl: brandingData.promoPopupUrl ? brandingData.promoPopupUrl: '',

        supportEmail: brandingData.supportEmail ? brandingData.supportEmail: '',
        supportPhone: brandingData.supportPhone ? brandingData.supportPhone: '',
        supportUrl: brandingData.supportUrl ? brandingData.supportUrl: '',

        url: brandingData.url ? brandingData.url: '',
        notifySms: brandingData.notifySms ? brandingData.notifySms: '',

        notifyEmail100: {title: brandingData.notifyEmail100_title ? brandingData.notifyEmail100_title:'', content: brandingData.notifyEmail100_content_a},
        notifyEmail200: {title: brandingData.notifyEmail200_title ? brandingData.notifyEmail200_title:'', content: brandingData.notifyEmail200_content_a},
        // notifyEmail400: {title: brandingData.notifyEmail400_title ? brandingData.notifyEmail400_title:'', content: brandingData.notifyEmail400_content_a},
        notifyEmail500: {title: brandingData.notifyEmail500_title ? brandingData.notifyEmail500_title:'', content: brandingData.notifyEmail500_content_a},
        notifyEmail600: {title: brandingData.notifyEmail600_title ? brandingData.notifyEmail600_title:'', content: brandingData.notifyEmail600_content_a},
        notifyEmail700: {title: brandingData.notifyEmail700_title ? brandingData.notifyEmail700_title:'', content: brandingData.notifyEmail700_content_a},
        notifyEmail900: {title: brandingData.notifyEmail900_title ? brandingData.notifyEmail900_title:'', content: brandingData.notifyEmail900_content_a},

    }

    if (newLogo) {
      const formData = new FormData();
      formData.append("logo", brandingData.logo);
      const logoResponse = await update(
        "customer/" + customerDetails.id + "/branding",
        formData
      );

      setIsLoading(false);
      logoResponse.status !== 200 && message.error(logoResponse);

      console.log('logoResponse',logoResponse);

      customerDetails.branding.logo = logoResponse.data.data.logo;
    }

    if (newPromoImg) {
      const formData = new FormData();
      formData.append("promoBannerImg", brandingData.promoBannerImg);
      const promoBannerImgResponse = await update(
        "customer/" + customerDetails.id + "/branding",
        formData
      );

      setIsLoading(false);
      promoBannerImgResponse.status !== 200 && message.error(promoBannerImgResponse);

      customerDetails.branding.promoBannerImg = promoBannerImgResponse.data.data.promoBannerImg;
    }

    if (newPopupImg) {
      const formData = new FormData();
      formData.append("promoPopupImg", brandingData.promoPopupImg);
      const promoPopupImgResponse = await update(
        "customer/" + customerDetails.id + "/branding",
        formData
      );

      setIsLoading(false);
      promoPopupImgResponse.status !== 200 && message.error(promoPopupImgResponse);

      customerDetails.branding.promoPopupImg = promoPopupImgResponse.data.data.promoPopupImg;
    }
    //
    const updateCustomerDetails = await update("customer/"+customerDetails.id, {customBranding:brandingData.customBranding} );
    if (updateCustomerDetails.status === 200) {
      dispatch({
        type: "UPDATE_CUSTOM_BRANDING",
        payload: {
          customerDetails,
        }
      })

      customerDetails.customBranding = updateCustomerDetails.data.data.customBranding;

    } else message.error(updateCustomerDetails);

    //
    const updateCustomerBrandingDetails = await update("customer/"+customerDetails.id+"/branding", branding );
    if (updateCustomerBrandingDetails.status === 200) {
      dispatch({
        type: "UPDATE_CUSTOM_BRANDING",
        payload: {
          updateCustomerBrandingDetails,
        }
      })

      customerDetails.branding = updateCustomerBrandingDetails.data.data;

      message.success(t('content.customBranding.update'))
      setIsLoading(false);
    } else message.error(updateCustomerBrandingDetails);

    isLoading && setIsLoading(false);
  }

  useEffect(() => {
    mixPanelTrack('ViewSettingsCustomBranding');
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        customBranding: customerDetails.customBranding,

        name: userDetails.name,

        logo: customerDetails.branding ? customerDetails.branding.logo : '',
        colorPrimary: customerDetails.branding ? customerDetails.branding.colorPrimary : '',
        colorBg: customerDetails.branding ? customerDetails.branding.colorBg : '',

        name: customerDetails.branding ? customerDetails.branding.name : '',
        description: customerDetails.branding ? customerDetails.branding.description : '',
        url: customerDetails.branding ? customerDetails.branding.url : '',

        supportUrl: customerDetails.branding ? customerDetails.branding.supportUrl : '',
        supportEmail: customerDetails.branding ? customerDetails.branding.supportEmail : '',
        supportPhone: customerDetails.branding ? customerDetails.branding.supportPhone : '',

        promoBannerImg: customerDetails.branding ? customerDetails.branding.promoBannerImg : '',
        promoBannerDesc: customerDetails.branding ? customerDetails.branding.promoBannerDesc : '',
        promoBannerUrl: customerDetails.branding ? customerDetails.branding.promoBannerUrl : '',

        promoPopupImg: customerDetails.branding ? customerDetails.branding.promoPopupImg : '',
        promoPopupDesc: customerDetails.branding ? customerDetails.branding.promoPopupDesc : '',
        promoPopupUrl: customerDetails.branding ? customerDetails.branding.promoPopupUrl : '',

        promoNoticeTitle: customerDetails.branding ? customerDetails.branding.promoNoticeTitle : '',
        promoNoticeDescription: customerDetails.branding ? customerDetails.branding.promoNoticeDescription : '',
        promoNoticeCTA: customerDetails.branding ? customerDetails.branding.promoNoticeCTA : '',
        promoNoticeUrl: customerDetails.branding ? customerDetails.branding.promoNoticeUrl : '',

        pixelGoogleAnalytics: customerDetails.branding ? customerDetails.branding.pixelGoogleAnalytics : '',
        pixelGoogleAds: customerDetails.branding ? customerDetails.branding.pixelGoogleAds : '',
        pixelFb: customerDetails.branding ? customerDetails.branding.pixelFb : '',
        pixelMixpanel: customerDetails.branding ? customerDetails.branding.pixelMixpanel : '',
        pixelTiktok: customerDetails.branding ? customerDetails.branding.pixelTiktok : '',

        notifyEmail100_title: (customerDetails.branding && customerDetails.branding.notifyEmail100 && customerDetails.branding.notifyEmail100.title) ? customerDetails.branding.notifyEmail100.title : '{{tracking_no}} - {{tracking_status}}',
        notifyEmail100_content: (customerDetails.branding && customerDetails.branding.notifyEmail100 && customerDetails.branding.notifyEmail100.content) ? customerDetails.branding.notifyEmail100.content.join('\n') : 'New order {{tracking_no}} has been created.',

        notifyEmail200_title: (customerDetails.branding && customerDetails.branding.notifyEmail200 && customerDetails.branding.notifyEmail200.title) ? customerDetails.branding.notifyEmail200.title : '{{tracking_no}} - {{tracking_status}}',
        notifyEmail200_content: (customerDetails.branding && customerDetails.branding.notifyEmail200 && customerDetails.branding.notifyEmail200.content) ? customerDetails.branding.notifyEmail200.content.join('\n') : 'Your order {{tracking_no}} is now {{tracking_status}}. \n If you have any questions regarding the order, please contact {{personnel_name}} ({{personnel_phone}})',

        // notifyEmail400_title: customerDetails.branding.notifyEmail400 ? customerDetails.branding.notifyEmail400.title : '{{tracking_no}} - {{tracking_status}}',
        // notifyEmail400_content: (customerDetails.branding.notifyEmail400 && customerDetails.branding.notifyEmail400.content) ? customerDetails.branding.notifyEmail400.content.join('\n') : 'Hi {{receiver_name}}! \n Your order {{tracking_no}} is now {{tracking_status}}.',

        notifyEmail500_title: (customerDetails.branding && customerDetails.branding.notifyEmail500 && customerDetails.branding.notifyEmail500.title) ? customerDetails.branding.notifyEmail500.title : '{{tracking_no}} - {{tracking_status}}',
        notifyEmail500_content: (customerDetails.branding && customerDetails.branding.notifyEmail500 && customerDetails.branding.notifyEmail500.content) ? customerDetails.branding.notifyEmail500.content.join('\n') : 'Your order {{tracking_no}} is now {{tracking_status}}.',

        notifyEmail600_title: (customerDetails.branding && customerDetails.branding.notifyEmail600 && customerDetails.branding.notifyEmail600.title) ? customerDetails.branding.notifyEmail600.title : '{{tracking_no}} - {{tracking_status}}',
        notifyEmail600_content: (customerDetails.branding && customerDetails.branding.notifyEmail600 && customerDetails.branding.notifyEmail600.content) ? customerDetails.branding.notifyEmail600.content.join('\n') : 'Your order {{tracking_no}} is now {{tracking_status}}.',

        notifyEmail700_title: (customerDetails.branding && customerDetails.branding.notifyEmail700 && customerDetails.branding.notifyEmail700.title) ? customerDetails.branding.notifyEmail700.title : '{{tracking_no}} - {{tracking_status}}',
        notifyEmail700_content: (customerDetails.branding && customerDetails.branding.notifyEmail700 && customerDetails.branding.notifyEmail700.content) ? customerDetails.branding.notifyEmail700.content.join('\n') : 'Your order {{tracking_no}} is now {{tracking_status}}.',

        notifyEmail900_title: (customerDetails.branding && customerDetails.branding.notifyEmail900 && customerDetails.branding.notifyEmail900.title) ? customerDetails.branding.notifyEmail900.title : '{{tracking_no}} - {{tracking_status}}',
        notifyEmail900_content: (customerDetails.branding && customerDetails.branding.notifyEmail900 && customerDetails.branding.notifyEmail900.content) ? customerDetails.branding.notifyEmail900.content.join('\n') : 'Your order {{tracking_no}} is now {{tracking_status}}.',

        notifySms: customerDetails.branding ? customerDetails.branding.notifySms : 'Hi {{receiver_name}}! Track your delivery for {{courier_name}} {{tracking_no}} at {{tracking_link}} - {{company_name}}',

      }}
      onSubmit={onSubmit}
    >
      {form => (
        <BrandInformation
          form={form}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          // setNewProfilePic={setNewProfilePic}
          setNewLogo={setNewLogo}
          setNewPromoImg={setNewPromoImg}
          setNewPopupImg={setNewPopupImg}
        />
      )}
    </Formik>
  )
}

const BrandInformation = (props) => {
  const { t } = useTranslation('settingsPage');
  const [state] = useContext(GlobalContext);
  const { userDetails, customerDetails } = state.global;
  const { form, setNewLogo, setNewPromoImg, setNewPopupImg } = props;

  //
  const [visibleLogo, setVisibleLogo] = useState(false);
  const [previewLogo, setPreviewLogo] = useState('');
  const [listLogo, setListLogo] = useState([
    {
      uid: "-1",
      status: "done",
      url:
        IMAGE_URL_ROOT + '/' +
        customerDetails.branding.logo
    }
  ]);
  const handlePreviewLogo = file => {
    setPreviewLogo(file.url || file.thumbUrl);
    setVisibleLogo(true);
  };
  const handleChangeLogo = ({ fileList }) => {
    setListLogo(fileList);
    setNewLogo(true);
  };
  //
  const [visiblePromoImg, setVisiblePromoImg] = useState(false);
  const [previewPromoImg, setPreviewPromoImg] = useState('');
  const [listPromoImg, setListPromoImg] = useState([
    {
      uid: "-1",
      status: "done",
      url:
        IMAGE_URL_ROOT + '/' +
        customerDetails.branding.promoBannerImg
    }
  ]);
  const handlePreviewPromoImg = file => {
    setPreviewPromoImg(file.url || file.thumbUrl);
    setVisiblePromoImg(true);
  };
  const handleChangePromoImg = ({ fileList }) => {
    setListPromoImg(fileList);
    setNewPromoImg(true);
  };
  //
  const [visiblePopupImg, setVisiblePopupImg] = useState(false);
  const [previewPopupImg, setPreviewPopupImg] = useState('');
  const [listPopupImg, setListPopupImg] = useState([
    {
      uid: "-1",
      status: "done",
      url:
        IMAGE_URL_ROOT + '/' +
        customerDetails.branding.promoPopupImg
    }
  ]);
  const handlePreviewPopupImg = file => {
    setPreviewPopupImg(file.url || file.thumbUrl);
    setVisiblePopupImg(true);
  };
  const handleChangePopupImg = ({ fileList }) => {
    setListPopupImg(fileList);
    setNewPopupImg(true);
  };
  //

  return (
    <Form layout="vertical" onSubmit={form.handleSubmit}>
      <Card>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p
            className="heading2"
            style={{ margin: 0, marginBottom: 10 }}
          >
           {t('content.customBranding.title')}
          </p>

          <Row gutter={16}>
            <Col xs={24} md={24} lg={24}>
              <CustomizedSwitch
                {...form}
                name="customBranding"
                label={t('content.customBranding.title')+" ($"+customerDetails.customBrandingRate+"/order)"}
                style={{ width: "100%" }}
                checked={customerDetails.customBranding}
                onChange={checked => {
                  if (checked) {
                    form.setFieldValue("customBranding", true)
                    customerDetails.customBranding = true;
                  }else {
                    form.setFieldValue("customBranding", false)
                    customerDetails.customBranding = false;
                  }
                }}
              />
            </Col>
          </Row>

          <p
            className="heading3"
            style={{ margin: 0, marginBottom: 10 }}
          >
           {t('content.customBranding.brandInfoSection')}
          </p>
          <div>
            <p
              style={{
                width: "100%",
                color: "rgba(0, 0, 0, 0.85)",
                margin: 0
              }}
            >
              {t('content.customBranding.brandInfoLogo')}
            </p>
            <p style={{ width: "100%", color: "rgba(0, 0, 0, 0.5)" }}>
            Best dimension 600px (width) x 120px (height)
            </p>
            <Form.Item
              validateStatus={
                form.touched["logo"] &&
                form.errors["logo"] &&
                "error"
              }
              help={
                form.touched["logo"]
                  ? form.errors["logo"]
                  : ""
              }
            >
              <Upload
                beforeUpload={file => {
                  form.setFieldValue("logo", file);
                  // TODO: Change to false to upload the file
                  return false;
                }}
                onPreview={handlePreviewLogo}
                onChange={handleChangeLogo}
                fileList={listLogo}
                listType="picture-card"
                name="logo"
                accept=".png"
              >
                {listLogo.length >= 1 ? null : uploadLogoButton}
              </Upload>
              <Modal
                visible={visibleLogo}
                footer={null}
                centered
                className="modal-img-view"
              >
              <Row>
                <Col span={24}>
                <img
                  alt={TEXT_PROFILE_PHOTO_PREVIEW}
                  className="profile-picture-preview"
                  src={previewLogo}
                />
                </Col>
                </Row>
              </Modal>
            </Form.Item>
          </div>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                name="name"
                label={t('content.customBranding.brandInfoName')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                name="description"
                label={t('content.customBranding.brandInfoDescription')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                name="url"
                label={t('content.customBranding.brandInfoUrl')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                className="pickerBox"
                label={t('content.customBranding.brandInfoPrimaryColor')}
                name="colorPrimary"
                type="text"
                onChange={e => {
                  if (e) {
                    form.setFieldValue("colorPrimary", e.target.value)
                    customerDetails.branding.colorPrimary = e.target.value;
                    // this.setState({
                    //   colorPrimary: e.target.value
                    // })
                  }
                }}
                addonBefore={
                  <div style={{ background: customerDetails.branding.colorPrimary, display: "block", height: 29, width: 40, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }} />
                }
              />
            </Col>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                className="pickerBox"
                label={t('content.customBranding.brandInfoBackgroundColor')}
                name="colorBg"
                type="text"
                onChange={e => {
                  if (e) {
                    form.setFieldValue("colorBg", e.target.value)
                    customerDetails.branding.colorBg = e.target.value;
                    // this.setState({
                    //   colorBg: e.target.value
                    // })
                  }
                }}
                addonBefore={
                  <div style={{ background: customerDetails.branding.colorBg, display: "block", height: 29, width: 40, borderTopLeftRadius: 4, borderBottomLeftRadius: 4 }} />
                }
              />
            </Col>
            </Row>
            <Row gutter={16}>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                name="supportEmail"
                label={t('content.customBranding.brandInfoSupportEmail')}
                type="supportEmail"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <PhoneNumberInput
                {...form}
                name="supportPhone"
                viewOnly
                enableSearchField={true}
                searchPlaceholder={TEXT_SEARCH_COUNTRY}
                label={t('content.customBranding.brandInfoSupportPhone')}
                onChange={value => {
                  form.setFieldValue("supportPhone", value);
                }}
              />
            </Col>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                name="supportUrl"
                label={t('content.customBranding.brandInfoSupportUrl')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
        </div>
      </Card>
      <Card style={{ marginTop: 20 }}>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p
            className="heading3"
            style={{ margin: 0, marginBottom: 10 }}
          >
           {t('content.customBranding.brandTrackingSection')}
          </p>
          <Row>
            <Col xs={24} md={24} lg={24}>
            <img src={previewTrack} width="100%" />
            </Col>
          </Row>
          <div>
            <p style={{ marginTop: '50px' }} >
             <strong>{t('content.customBranding.brandTrackingNoticeSection')}</strong>
            </p>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="promoNoticeTitle"
                  label={t('content.customBranding.brandTrackingNoticeTitle')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="promoNoticeDescription"
                  label={t('content.customBranding.brandTrackingNoticeDescription')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="promoNoticeCTA"
                  label={t('content.customBranding.brandTrackingNoticeCta')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="promoNoticeUrl"
                  label={t('content.customBranding.brandTrackingNoticeUrl')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <p style={{ margin: 0, marginBottom: 10 }} >
             <strong>{t('content.customBranding.brandTrackingPromoBannerTitle')}</strong>
            </p>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24}>
                <p
                  style={{
                    width: "100%",
                    color: "rgba(0, 0, 0, 0.85)",
                    margin: 0
                  }}
                >
                  {t('content.customBranding.brandTrackingPromoBannerImage')}
                </p>
                <p style={{ width: "100%", color: "rgba(0, 0, 0, 0.5)" }}>
                Best dimension 512px (width) x 512px (height)
                </p>
                <Form.Item
                  validateStatus={
                    form.touched["promoBannerImg"] &&
                    form.errors["promoBannerImg"] &&
                    "error"
                  }
                  help={
                    form.touched["promoBannerImg"]
                      ? form.errors["promoBannerImg"]
                      : ""
                  }
                >
                  <Upload
                    beforeUpload={file => {
                      form.setFieldValue("promoBannerImg", file);
                      // TODO: Change to false to upload the file
                      return false;
                    }}
                    onPreview={handlePreviewPromoImg}
                    onChange={handleChangePromoImg}
                    fileList={listPromoImg}
                    listType="picture-card"
                    name="promoBannerImg"
                    accept=".png, .jpg, .jpeg"
                  >
                    {listPromoImg.length >= 1 ? null : uploadPromoImgButton}
                  </Upload>
                  <Modal
                    visible={visiblePromoImg}
                    footer={null}
                    centered
                    className="modal-img-view"
                  >
                    <img
                      alt={TEXT_PROFILE_PHOTO_PREVIEW}
                      className="profile-picture-preview"
                      src={previewPromoImg}
                    />
                  </Modal>
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="promoBannerDesc"
                  label={t('content.customBranding.brandTrackingPromoBannerDescription')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="promoBannerUrl"
                  label={t('content.customBranding.brandTrackingPromoBannerUrl')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <p style={{ margin: 0, marginBottom: 10 }} >
             <strong>{t('content.customBranding.brandTrackingPopupBannerTitle')}</strong>
            </p>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24}>
                <p
                  style={{
                    width: "100%",
                    color: "rgba(0, 0, 0, 0.85)",
                    margin: 0
                  }}
                >
                  {t('content.customBranding.brandTrackingPopupBannerImage')}
                </p>
                <p style={{ width: "100%", color: "rgba(0, 0, 0, 0.5)" }}>
                Best dimension 512px (width) x 512px (height)
                </p>
                <Form.Item
                  validateStatus={
                    form.touched["promoPopupImg"] &&
                    form.errors["promoPopupImg"] &&
                    "error"
                  }
                  help={
                    form.touched["promoPopupImg"]
                      ? form.errors["promoPopupImg"]
                      : ""
                  }
                >
                  <Upload
                    beforeUpload={file => {
                      form.setFieldValue("promoPopupImg", file);
                      // TODO: Change to false to upload the file
                      return false;
                    }}
                    onPreview={handlePreviewPopupImg}
                    onChange={handleChangePopupImg}
                    fileList={listPopupImg}
                    listType="picture-card"
                    name="promoPopupImg"
                    accept=".png, .jpg, .jpeg"
                  >
                    {listPopupImg.length >= 1 ? null : uploadPopupImgButton}
                  </Upload>
                  <Modal
                    visible={visiblePopupImg}
                    footer={null}
                    centered
                    className="modal-img-view"
                  >
                    <img
                      alt={TEXT_PROFILE_PHOTO_PREVIEW}
                      className="profile-picture-preview"
                      src={previewPopupImg}
                    />
                  </Modal>
                </Form.Item>
              </Col>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="promoPopupDesc"
                  label={t('content.customBranding.brandTrackingPopupBannerDescription')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="promoPopupUrl"
                  label={t('content.customBranding.brandTrackingPopupBannerUrl')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
            </Row>
          </div>
          <div>
            <p style={{ margin: 0, marginBottom: 10 }} >
             <strong>{t('content.customBranding.brandTrackingPixelsTitle')}</strong>
            </p>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="pixelGoogleAnalytics"
                  label={t('content.customBranding.brandTrackingPixelsGoogleAnalytics')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="pixelGoogleAds"
                  label={t('content.customBranding.brandTrackingPixelsGoogleAds')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="pixelFb"
                  label={t('content.customBranding.brandTrackingPixelsFbAds')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
              {/**<Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="pixelMixpanel"
                  label={t('content.customBranding.brandTrackingPixelsMixpanel')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>**/}
              {/**<Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="pixelTiktok"
                  label={t('content.customBranding.brandTrackingPixelsTiktok')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>**/}
            </Row>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: 20 }}>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p
            className="heading3"
            style={{ margin: 0, marginBottom: 10 }}
          >
           {t('content.customBranding.brandNotificationEmailSection')}
          </p>
          <Row>
            <Col xs={24} md={24} lg={24}>
            <img src={previewEmail} width="100%" />
            </Col>
          </Row>
          <div>
          <Row gutter={16}>
          <Col xs={24} md={24} lg={24}>
          <p style={{ width: "100%", color: "rgba(0, 0, 0, 1)" , marginTop:'50px'}}>
          <b>{t('content.customBranding.brandNotificationEmailMessageNewOrder')}</b>
          </p>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <TextInput
              {...form}
              name="notifyEmail100_title"
              label={t('content.customBranding.brandNotificationSubject')}
              type="text"
              style={{ width: "100%" }}
            />
            <CustomizedTextarea
              {...form}
              name="notifyEmail100_content"
              label={t('content.customBranding.brandNotificationContent')}
              rows={6}  style={{ width: "100%" }}
            />
          </Col>
          <Col xs={24} md={24} lg={24}>
          <p style={{ width: "100%", color: "rgba(0, 0, 0, 1)" }}>
          <b>{t('content.customBranding.brandNotificationEmailMessageAcceptance')}</b>
          </p>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <TextInput
              {...form}
              name="notifyEmail200_title"
              label={t('content.customBranding.brandNotificationSubject')}
              type="text"
              style={{ width: "100%" }}
            />
            <CustomizedTextarea
              {...form}
              name="notifyEmail200_content"
              label={t('content.customBranding.brandNotificationContent')}
              rows={6}  style={{ width: "100%" }}
            />
          </Col>
          {/**
          <Col xs={24} md={24} lg={24}>
          <p style={{ width: "100%", color: "rgba(0, 0, 0, 1)" }}>
          <b>{t('content.customBranding.brandNotificationEmailMessageStartPickup')}</b>
          </p>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <TextInput
              {...form}
              name="notifyEmail400_title"
              label={t('content.customBranding.brandNotificationSubject')}
              type="text"
              style={{ width: "100%" }}
            />
            <CustomizedTextarea
              {...form}
              name="notifyEmail400_content"
              label={t('content.customBranding.brandNotificationContent')}
              rows={6}  style={{ width: "100%" }}
            />
          </Col>
          **/}

          <Col xs={24} md={24} lg={24}>
          <p style={{ width: "100%", color: "rgba(0, 0, 0, 1)" }}>
          <b>{t('content.customBranding.brandNotificationEmailMessageCompletePickup')}</b>
          </p>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <TextInput
              {...form}
              name="notifyEmail500_title"
              label={t('content.customBranding.brandNotificationSubject')}
              type="text"
              style={{ width: "100%" }}
            />
            <CustomizedTextarea
              {...form}
              name="notifyEmail500_content"
              label={t('content.customBranding.brandNotificationContent')}
              rows={6}  style={{ width: "100%" }}
            />
          </Col>

          <Col xs={24} md={24} lg={24}>
          <p style={{ width: "100%", color: "rgba(0, 0, 0, 1)" }}>
          <b>{t('content.customBranding.brandNotificationEmailMessageStartDelivery')}</b>
          </p>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <TextInput
              {...form}
              name="notifyEmail600_title"
              label={t('content.customBranding.brandNotificationSubject')}
              type="text"
              style={{ width: "100%" }}
            />
            <CustomizedTextarea
              {...form}
              name="notifyEmail600_content"
              label={t('content.customBranding.brandNotificationContent')}
              rows={6}  style={{ width: "100%" }}
            />
          </Col>

          <Col xs={24} md={24} lg={24}>
          <p style={{ width: "100%", color: "rgba(0, 0, 0, 1)" }}>
          <b>{t('content.customBranding.brandNotificationEmailMessageDelivered')}</b>
          </p>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <TextInput
              {...form}
              name="notifyEmail700_title"
              label={t('content.customBranding.brandNotificationSubject')}
              type="text"
              style={{ width: "100%" }}
            />
            <CustomizedTextarea
              {...form}
              name="notifyEmail700_content"
              label={t('content.customBranding.brandNotificationContent')}
              rows={6}  style={{ width: "100%" }}
            />
          </Col>

          <Col xs={24} md={24} lg={24}>
          <p style={{ width: "100%", color: "rgba(0, 0, 0, 1)" }}>
          <b>{t('content.customBranding.brandNotificationEmailMessageCancelled')}</b>
          </p>
          </Col>
          <Col xs={24} md={24} lg={24}>
            <TextInput
              {...form}
              name="notifyEmail900_title"
              label={t('content.customBranding.brandNotificationSubject')}
              type="text"
              style={{ width: "100%" }}
            />
            <CustomizedTextarea
              {...form}
              name="notifyEmail900_content"
              label={t('content.customBranding.brandNotificationContent')}
              rows={6}  style={{ width: "100%" }}
            />
          </Col>
          </Row>
          <p style={{ width: "100%", color: "rgba(0, 0, 0, 0.50)" }}>
          You can use these variables: <br/>
          {"{{"}sender_name{"}}"} {"{{"}receiver_name{"}}"} {"{{"}courier_name{"}}"}
          {"{{"}tracking_no{"}}"} {"{{"}tracking_link{"}}"} {"{{"}tracking_status{"}}"}
          {"{{"}personnel_name{"}}"} {"{{"}personnel_phone{"}}"}
          </p>
          </div>
        </div>
      </Card>
      <Card style={{ marginTop: 20 }}>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p
            className="heading3"
            style={{ margin: 0, marginBottom: 10 }}
          >
           {t('content.customBranding.brandNotificationSMSSection')}
          </p>
          <Row>
            <Col xs={24} md={24} lg={24}>
            <img src={previewSms} height="128px" />
            </Col>
          </Row>
          <div>
          <Row gutter={16}>
          <Col xs={24} md={24} lg={24}>
            <CustomizedTextarea
              {...form}
              name="notifySms"
              placeholder="(Max 140 characters)"
              label={t('content.customBranding.brandNotificationEmailMessage')}
              rows={3}  maxLength={140} style={{ width: "100%", marginTop:'50px' }}
            />
          </Col>
          </Row>
          <p style={{ width: "100%", color: "rgba(0, 0, 0, 0.50)" }}>
          You can use these variables: <br/>
          {"{{"}sender_name{"}}"} {"{{"}receiver_name{"}}"} {"{{"}courier_name{"}}"}
          {"{{"}tracking_no{"}}"} {"{{"}tracking_link{"}}"} {"{{"}tracking_status{"}}"}
          {"{{"}personnel_name{"}}"} {"{{"}personnel_phone{"}}"}
          </p>
          </div>
        </div>
      </Card>
      <div style={{ float: "right", marginTop: 20, marginBottom: 20 }}>
        <CustomizedButton
          text={t('content.save')}
          type="primary"
          htmlType="submit"
        />
      </div>
    </Form>
  )
}

export default CustomBranding;
