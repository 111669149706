import { useContext, useEffect } from "react";
import { message } from "antd";

import { GlobalContext } from "Store/store";
import { get } from "Util/API";
import useLocalStorage from 'Util/useLocalStorage';

import mixpanel from 'mixpanel-browser';

const useGetUserInformation = () => {
  const [state, dispatch] = useContext(GlobalContext);
  const { companyDetails, userDetails, lang } = state.global;
  const customerId = parseInt(useLocalStorage.getItem("customerId"));

  const getUserInformation = async () => {
    const getCustomerDetails = await get(customerId ? `customer/${customerId}?companyId=${companyDetails.id}&retrieve=branding` : "customer?retrieve=branding");
    if (getCustomerDetails.status === 200) {
        useLocalStorage.setItem("customerId", getCustomerDetails.data.data.id);

        let customerData = getCustomerDetails.data.data;

        //mixpanel
        let mpptoken = useLocalStorage.getItem("mpToken");
        if(mpptoken)
        {
            mixpanel.init(mpptoken);

            mixpanel.identify(customerData.id);
            mixpanel.track('Update Profile - Web');
            mixpanel.people.set({
                "companyId": customerData.companyId,
                'customerId': customerData.id,
                '$name': customerData.name,
                '$email': customerData.email,
                'name': customerData.name,
                'email': customerData.email,
                'mobile': customerData.phone,
                'identityNo': customerData.identityNo,
                'type': customerData.type,
                'dob': customerData.dob,
                'industry': customerData.industry,
                'websiteUrl': customerData.websiteUrl,
                'country': customerData.country,
                'postcode': customerData.postcode,
                'state': customerData.state,
                'city': customerData.city,
                'defaultLabelType': customerData.defaultLabelType,
                'discount': customerData.discount,
                'discountExp': customerData.discountExp,
                'commission': customerData.commission,
                'paymentTerm': customerData.billing ? customerData.billing.paymentTerm : 'debit',
                'maxCredit': customerData.billing ? customerData.billing.maxCredit : 0,
                'accountNumber': customerData.accountNumber,
                'planName': customerData.planName,
                'planExpiryDate': customerData.planExpiryDate,
                'walletBalance': customerData.walletBalance,
                'unbilledAmount': customerData.unbilledAmount,
                'geoAccuracy': customerData.geoAccuracy,
                'geoAccuracyLock': customerData.geoAccuracyLock,
                'userId': customerData.userId,
                'created_at': customerData.created_at,
                'updated_at': customerData.updated_at
            });
        }
        //mixpanel

        dispatch({
          type: "AUTH_SUCCESS",
          payload: {
            ...state.global,
            lang: useLocalStorage.getItem("lang") || lang,
            customerDetails: getCustomerDetails.data.data,
            customerId: getCustomerDetails.data.data.id,
            authFinish: true,
          }
        })
    } else message.error(getCustomerDetails)
  }

  useEffect(() => {
    companyDetails?.id &&
    userDetails?.id &&
    getUserInformation();
  }, [companyDetails, userDetails])

  return null
};

export default useGetUserInformation;
