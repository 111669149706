import React, { Component } from "react";
import { Alert, Form } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  TEXT_UNDO_CHANGES,
  TEXT_RESEND_EMAIL,
  TEXT_UPDATE_PENDING_VERIFICATION,
  TEXT_VERIFICATION_CODE,
  TEXT_SEND
} from "Util/Text";
import {
  MESSAGE_PENDING_EMAIL_VERIFICATION_FIRST,
  MESSAGE_PENDING_EMAIL_VERIFICATION_SECOND,
  MESSAGE_VERIFICATION_CODE_REQUIRED
} from "Util/ErrorMessage";
import TextInput from "Components/TextInput";
import CustomizedButton from "Components/CustomizedButton";

const formik = {
  initialValues: {
    verificationCode: ""
  },
  validationSchema: Yup.object().shape({
    verificationCode: Yup.string().required(MESSAGE_VERIFICATION_CODE_REQUIRED)
  })
};

class PendingEmailVerification extends Component {
  render() {
    return (
      <div style={{ marginTop: 20 }}>
        <Alert
          message={TEXT_UPDATE_PENDING_VERIFICATION}
          description={
            <span>
              {MESSAGE_PENDING_EMAIL_VERIFICATION_FIRST}
              <b>{this.props.currentEmail}</b>
              {MESSAGE_PENDING_EMAIL_VERIFICATION_SECOND}
            </span>
          }
          type="warning"
          showIcon
          className="manage-auth-alert "
        />
        <div className="pending-verification-buttons" style={{ marginTop: 20 }}>
          <Formik {...formik} onSubmit={this.props.submitVerificationCode}>
            {form => (
              <Form onSubmit={form.handleSubmit} className="manage-auth-form">
                <TextInput
                  {...form}
                  name="verificationCode"
                  label={TEXT_VERIFICATION_CODE}
                />
                <Form.Item className="manage-auth-form-item ">
                  <CustomizedButton
                    text={TEXT_SEND}
                    type="primary"
                    htmlType="submit"
                  />
                </Form.Item>
              </Form>
            )}
          </Formik>
          <CustomizedButton
            text={TEXT_RESEND_EMAIL}
            className="left-button"
            style={{ marginRight: 10 }}
            onClick={this.props.submitVerificationCode}
          />
          <CustomizedButton text={TEXT_UNDO_CHANGES} />
        </div>
      </div>
    );
  }
}

export default PendingEmailVerification;
