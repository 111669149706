import React from "react";
import { Table } from "antd";

import useWindowSize from 'Util/Hooks/useWindowSize';

const PersonnelDetailsTable = (props) => {
  const size = useWindowSize();
  const tableWidth = size[0] - 310;
  const { filterAndTableState, rowSelection, handleSetFilterAndTableState, columns, pageTotal } = props;
  const { data: dataSource } = filterAndTableState;

  const handleTableChange = (pagination, filters, sorter) => {
    handleSetFilterAndTableState({
      page: pagination.current,
      limit: pagination.pageSize,
      sortBy: sorter.field,
      // orderBy: sorter.order === "descend" ? 'desc' : 'asc',
      orderBy: 'desc'
    })
  }

  return (
    <Table
      // rowSelection={rowSelection}
      dataSource={dataSource}
      columns={columns}
      total={pageTotal}
      pagination={{
        current: filterAndTableState.page,
        total: filterAndTableState.rowTotal,
        defaultPageSize: 10,
        // showSizeChanger: true,
        // pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total, range) => `${range[1]} of ${total} item`
      }}
      onChange={handleTableChange}
      loading={false}
      scroll={{ x: tableWidth < 1610 && tableWidth }}
      style={{ maxHeight: "60%", backgroundColor: 'white' }}
    />
  )
}

export default PersonnelDetailsTable;
