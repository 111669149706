import React, { useState, useEffect, useContext } from "react";
import { Layout, message } from "antd";
import { useTranslation } from "react-i18next";

import PageWrapper from 'Components/PageWrapper';

import { get } from 'Util/API';
import ServerSidePaginationTable from 'Components/table/ServerSidePaginationTable';
import OrdersHeader from "./OrdersHeader";
import OrdersTableContent from "./OrdersTableContent";
import ordersTableColumn from './ordersTableColumns';

import { mixPanelTrack } from "Util/CustomFunctions";

import { GlobalContext } from "Store/store";

const { Content } = Layout;

const initialVal = {
  tableRowsData: {
    data: [],
    limit: 10,
    page: 1,
    pageTotal: 0,
    rowTotal: 0,
    orderBy: 'asc',
    sortBy: 'createdAt',
    status: 'PENDING',
    keyword: null,
    dateFrom: null,
    dateTo: null,
    dateRangeId: 0,
    serviceCompanyId: null
  }
}

const OrdersPage = (props) => {
  const { t } = useTranslation('orderPage');
  const [isLoading, setIsLoading] = useState(false);
  const [allOrdersIds, setAllOrderIds] = useState([]);
  const [tableRowsData, setTableRowsData] = useState(initialVal.tableRowsData);
  const { page, limit, orderBy, sortBy, status, keyword, dateFrom, dateTo, dateRangeId, serviceCompanyId, rowTotal, data: rowsData } = tableRowsData;
  
  const [state] = useContext(GlobalContext);
  const { customerDetails } = state.global;

  useEffect(() => {
    getTableRows()
  }, [limit, page, orderBy, sortBy, keyword, status])

  useEffect(() => {
    (dateFrom || dateTo || dateRangeId || serviceCompanyId) && getTableRows({ page: 1 })
  }, [dateFrom, dateTo, dateRangeId, serviceCompanyId])

  useEffect(() => {
    !rowsData && getTableRows()
  }, [rowsData]);

  const getTableRows = async (data) => {
    setIsLoading(true);

    const dataCopy = { ...tableRowsData };
    dataCopy.status = data?.status || dataCopy.status;
    dataCopy.page = data?.page || dataCopy.page;
    dataCopy.createdDateFrom = dataCopy.dateFrom?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.createdDateTo = dataCopy.dateTo?.format('YYYY-MM-DD').toLocaleString();

    delete dataCopy.data;
    delete dataCopy.pageTotal;
    delete dataCopy.rowTotal;
    delete dataCopy.dateRangeId;
    delete dataCopy.dateFrom;
    delete dataCopy.dateTo;

     var re = /^[A-Za-z]+$/;
     if(dataCopy.keyword && re.test(dataCopy.keyword))
        dataCopy.keyword = "n="+dataCopy.keyword;

    if(dataCopy.status == 'PENDING')
    {
        dataCopy.codStatus = '0';
        dataCopy.status = '';
    }else if(dataCopy.status == 'COLLECTED')
    {
        dataCopy.codStatus = 1;
        dataCopy.status = '';
    }else if(dataCopy.status == 'PROCESSING')
    {
        dataCopy.codStatus = 2;
        dataCopy.status = '';
    }else if(dataCopy.status == 'SETTLED')
    {
        dataCopy.codStatus = 3;
        dataCopy.status = '';
    }else if(dataCopy.status == 'OVERDUE')
    {
        dataCopy.pastDue = true;
        dataCopy.codStatus = '';
        dataCopy.status = '';
    }else {
        dataCopy.codStatus = '0';
        dataCopy.status = '';
    }

    if(dataCopy.keyword)
    {
        dataCopy.consignmentNo = dataCopy.keyword;
        dataCopy.keyword = '';
    }

    const params = [];
    Object.keys(dataCopy).forEach((key, i) => {
      dataCopy[key] && params.push((i > 0 ? '&' : '') + key + '=' + dataCopy[key]);
    });    

    const orderResponse = await get(`order/codSettlement?`+ params.join(""));
    if (orderResponse.status === 200) {
      const { data, ...rest } = orderResponse.data
      const ordersWithKey = data.map((item, i) => ({
        key: dataCopy.page > 1 ? dataCopy.page - 1 + i.toString() : i.toString(),
        ...item
      }));
      setTableRowsData({
        ...tableRowsData,
        page: dataCopy.page,
        data: ordersWithKey,
        ...rest
      })
    } else message.error(orderResponse);
    setIsLoading(false);
  }

  const getAllRows = async (props) => {
    setIsLoading(true);

    const getAll = await get(`order/codSettlement?codStatus=${status}&limit=${rowTotal}&orderBy=${orderBy}&sortBy=${sortBy}`);
    // const getAll = await get(`order/codSettlement`);
    if (getAll.status === 200) {
      const ordersIds = getAll.data.data.map((item) => item.id);
      setAllOrderIds(ordersIds);
    } else message.error(getAll);
    setIsLoading(false);
  }

  useEffect(() => {
    mixPanelTrack('ViewOrdersList');
  }, []);

  return (
    <PageWrapper loading={isLoading} currentPath={props.location.pathname} isCollapseSidebar={true}>
      <Layout>
        <Content className="ui-right-content form">
          <OrdersHeader ordersTableData={tableRowsData} />
          <div style={{ paddingTop: 30 }}>
            <div
              className="whiteBColor"
              style={{
                height: "100%",
                border: "1px solid #cfdae6",
                position: "relative"
              }}
            >
              <ServerSidePaginationTable
                data={tableRowsData}
                setData={setTableRowsData}
                getAllRowsIds={getAllRows}
                allRowsIds={allOrdersIds}
                setAllRowsIds={setAllOrderIds}
                location={props.location}
                history={props.history}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                generateColumn={(props) => ordersTableColumn({ ...props, transl: t })}
                itemRenderer={OrdersTableContent}
                defaultActiveKey='PENDING'
                tab={[
                  { name: 'Pending', key: 'PENDING' },
                  { name: 'Collected', key: 'COLLECTED' },
                  { name: 'Processing', key: 'PROCESSING' },
                  { name: 'Settled', key: 'SETTLED' },
                  { name: 'Overdue', key: 'OVERDUE' }
                ]}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </PageWrapper>
  )
}

export default OrdersPage;
