import React from "react";

import Store from 'Store/store';

import Router from "./Router";
import './App.css';

const App = () => {

  return (
    <Store>
      <Router />
    </Store>
  )
}

export default App;
