import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Button, Menu, Dropdown, Row, Col, Icon, message } from "antd";
import { useTranslation } from "react-i18next";

// import "App.css";
import { submenus } from "Util/ArrayObjects";
import { API_ROOT } from 'Util/API';
import useLocalStorage from 'Util/useLocalStorage';
import { GlobalContext } from "Store/store";

const OrdersHeader = (props) => {
  const { ordersTableData } = props;
  const [exportLink, setExportLink] = useState(null);
  const { dateRangeId, dateFrom, dateTo } = ordersTableData;
  const [state] = useContext(GlobalContext);
  const { multipointServices } = state.global;

  const allowToExport = dateRangeId !== 0 || (dateFrom && dateTo);
  const t = {};
  t.common = useTranslation('orderPage').t;
  t.pageWrapper = useTranslation('pageWrapper').t;

  const activeSubmenus = multipointServices.length > 0 ? submenus : submenus.filter((item) => (item.key !== 'multipoint' && item.key !== 'sameday') )

  const generateMenuItem = (t) => (
    <Menu>
      {activeSubmenus.map(submenu => (
        <Menu.Item key={submenu.key}>
          <Link to={submenu.path}>
            <i className={submenu.icon} />
            {t('sidebarOrderMenus.' + submenu.key)}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  useEffect(() => {
    getExportLink()
  }, [ordersTableData])

  const getExportLink = async () => {
    const dataCopy = { ...ordersTableData };
    dataCopy.dateFrom = dataCopy.dateFrom?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.dateTo = dataCopy.dateTo?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.limit = dataCopy.rowTotal;
    dataCopy.jwt = useLocalStorage.getItem("accessToken");
    delete dataCopy.data;
    delete dataCopy.pageTotal;
    delete dataCopy.rowTotal;
    delete dataCopy.dateRangeId;
    const params = [];
    Object.keys(dataCopy).forEach((key, i) => {
      dataCopy[key] && params.push((i > 0 ? '&' : '') + key + '=' + dataCopy[key]);
    });
    setExportLink(`${API_ROOT}order/export?` + params.join(""))
  }

  return (
    <Row>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <p className="heading1" style={{ margin: 0, float: "left" }}>
        {t.pageWrapper('sidebarMenus.cod')}
        </p>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        className="navigation-table"
      >
        {/* {
          allowToExport ?
          <Button
            className="button-bg-transparent"
            style={{ padding: 0, marginRight: 15 }}
            target="_blank"
            href={exportLink}
          >
            Export <i className="fa fa-upload" style={{ marginLeft: 5 }} />
          </Button> :
          <Button
            className="button-bg-transparent"
            style={{ padding: 0, marginRight: 15 }}
            onClick={() => message.error('Please select date range.')}
          >
            {t.common('content.header.export')} <i className="fa fa-upload" style={{ marginLeft: 5 }} />
          </Button>
        }         */}
      </Col>
    </Row>
  )
}

export default OrdersHeader;
