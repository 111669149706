import * as Yup from "yup";

const formik = {
  validationSchema: Yup.object().shape({
    itemTypeOption: Yup.array().of(Yup.object().shape({})),
    senderName: Yup.string()
      .nullable()
      .required("Name is required field"),
    senderEmail: Yup.string()
      .nullable(),
    senderMobile: Yup.string()
      .nullable()
      .required("Phone number is required field"),
    senderCompany: Yup.string()
      .nullable(),
    senderAddress1: Yup.string()
      .nullable()
      .when("dropAtOutlet", {
        is: false,
        then: Yup.string()
          .nullable()
          .required("Address is required field")
      }),
    senderUnitNo: Yup.string()
      .nullable(),
    senderCity: Yup.string()
      .nullable()
      .when("dropAtOutlet", {
        is: false,
        then: Yup.string()
          .nullable()
          .required("City is required field")
      }),
    senderState: Yup.string()
      .nullable()
      .when("dropAtOutlet", {
        is: false,
        then: Yup.string()
          .nullable()
          .required("State is required field")
      }),
    senderPostcode: Yup.string()
      .nullable()
      .when("dropAtOutlet", {
        is: false,
        then: Yup.string()
          .nullable()
          .required("Postcode is required field")
      }),
    senderCountry: Yup.string()
      .nullable()
      .when("dropAtOutlet", {
        is: false,
        then: Yup.string()
          .nullable()
          .required("Country is required field")
      }),
    dropAtOutlet: Yup.boolean().required(),
    selectedOutletIndex: Yup.string()
      .nullable()
      .when("dropAtOutlet", {
        is: true,
        then: Yup.string()
          .nullable()
          .required("Choose outlet is required field")
      }),
    receiverName: Yup.string()
      .nullable()
      .required("Name is required field"),
    receiverEmail: Yup.string().nullable(),
    receiverMobile: Yup.string()
      .nullable()
      .required("Phone number is required field"),
    receiverCompany: Yup.string().nullable(),
    receiverAddress1: Yup.string()
      .nullable()
      .required("Address is required field"),
    receiverUnitNo: Yup.string()
      .nullable(),
    receiverCity: Yup.string()
      .nullable()
      .required("City is required field"),
    receiverState: Yup.string()
      .nullable()
      .required("State is required field"),
    receiverPostcode: Yup.string()
      .nullable()
      .required("Postcode is required field"),
    receiverCountry: Yup.string()
      .nullable()
      .required("Country is required field"),
    selectedItemTypeIndex: Yup.number()
      .integer()
      .nullable()
      .required("Item type is required field"),
    itemDetails: Yup.string().nullable().required("Item description is required field"),
    itemValue: Yup.number().min(0.1).required("Item value is required field"),
    note: Yup.string().nullable(),
    weightValue: Yup.number().min(0.1).required("Weight is required field"),
    quantity: Yup.number()
      .min(0)
      .integer()
      .required("Quantity is required"),
    pickupDate: Yup.string().nullable(),
    selectedServiceIndex: Yup.string().nullable().required("Service is required"),
    paymentMethodId: Yup.number().nullable().required('Payment method is required')
  })
};

export default formik;
