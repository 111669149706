import React, { useState, useContext, useEffect } from "react";
import { Card, Layout, Transfer, message } from "antd";
import { useTranslation } from "react-i18next";

import { update, get } from "Util/API";
import CustomizedButton from "Components/CustomizedButton";
import { GlobalContext } from "Store/store";
import './services.css';

import { mixPanelTrack } from "Util/CustomFunctions";

const ServicesSetting = (props) => {
  const { t } = useTranslation('settingsPage');
  const [state, dispatch] = useContext(GlobalContext);
  const { customerDetails, availableServiceCompany, companyDetails, userDetails, allCustomerAccounts } = state.global;
  const [selectedServicesId, setSelectedServicesId] = useState(customerDetails.selectedServices || []);
  const servicesData = availableServiceCompany.map((item, i) => ({
    key: item.id,
    title: item.name,
    companyCode: item.companyCode
  }));

  const handleChange = (nextTargetKeys, direction, moveKeys) => {
    setSelectedServicesId(nextTargetKeys);
  };

  const handleSearch = (dir, value) => {
    if (dir === 'left') {
      //TODO: filter services === selectedServicesId and match value
    }
  };

  const onSubmit = async () => {
    props.setIsLoading(true);
    const updateCustomerDetails = await update("customer", { selectedServices: selectedServicesId, id: customerDetails.id, companyId: companyDetails.id });
    if (updateCustomerDetails.status === 200) {
      // TODO: Remove this after api response correct bankInformation after updateCustomerDetails
      const getCustomerDetails = await get(`/customer/${customerDetails.id}?companyId=${companyDetails.id}`);
      if (getCustomerDetails.status === 200) {
        props.setIsLoading(false);
        dispatch({
          type: "UPDATE_PROFILE",
          payload: {
            userDetails,
            allCustomerAccounts,
            customerDetails: getCustomerDetails.data.data,
          }
        })
        message.success(t('content.services.updated'))
      } else message.error(getCustomerDetails);
    } else message.error(updateCustomerDetails);

    props.setIsLoading(false);
  }

  useEffect(() => {
    mixPanelTrack('ViewSettingsServices');
  }, []);

  return (
    <Layout>
      <Card>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p className="heading3" style={{ margin: 0 }}>
            {t('content.services.title')}
          </p>
          <div className="transferWrapper">
            <Transfer
              dataSource={servicesData}
              titles={['', t('content.select')]}
              showSearch
              listStyle={{
                height: (servicesData.length * 60),
                minHeight: 240,
                maxHeight: 500,
                width: '100%'
              }}
              // showSearch
              onSearch={handleSearch}
              targetKeys={selectedServicesId}
              onChange={handleChange}
              render={item => `${item.title} (${item.companyCode})`}
            />
          </div>
        </div>
      </Card>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 20, marginBottom: 20 }}>
        <CustomizedButton
          text={t('content.cancel')}
          style={{ marginRight: 10 }}
          className="button__transparent"
        />
        <CustomizedButton
          text={t('content.save')}
          type="primary"
          onClick={onSubmit}
        />
      </div>
    </Layout>
  )
}

export default ServicesSetting;
