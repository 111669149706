import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Table, Button, Card, Col, Row, Form, Icon } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";

import CustomizedButton from "Components/CustomizedButton";
import InputNumber from "Components/InputNumber";
import { GlobalContext } from 'Store/store';

// import DelyvaCoreFeaturesDialogContent from './DelyvaCoreFeatures';
// import MarketingFeaturesDialogContent from './MarketingFeatures';
// import generateColumns from './tableColumns';
// import RowHead from './RowHead';

import styles from './myTopupStep.module.css';

// const BooleanRowContent = ({ bool }) => (
//   bool
//   ? <Icon style={{ color: 'green' }} type="check" />
//   : "-"
// )

const MyTopupAmountStep = (props) => {
  const [state] = useContext(GlobalContext);
  const { topupPlan } = props;
  const { companyDetails, footerWidth } = state.global;
  const { t } = useTranslation('topupPage');

  const topupPlanPrices = topupPlan.prices?.map((item) => parseFloat(item.value));
  const sortedTopupPlanPrices = topupPlanPrices ? topupPlanPrices.sort(function(a, b){return a - b}) : [10];
  const topupPlanObj = topupPlan.prices.reduce((acc, item, index) => {
    acc[parseFloat(item.value)] = { ...item };
    return acc;
  }, {});
  const sortedTopupPlan = sortedTopupPlanPrices.map((item) => topupPlanObj[item]);

  const ColumnHead = ({ popular, title, priceRange, defaultPrice }) => (
    <div style={{ textAlign: 'center' }}>
      {popular && <div style={{ fontWeight: 'bold', color: 'red' }}>Popular</div>}
      {/*<div style={{ fontSize: 18, fontWeight: 'bold' }}>{title}</div>
      <div>{priceRange}</div>*/}
      <div dangerouslySetInnerHTML={{ __html: title }} />
      <div dangerouslySetInnerHTML={{ __html: priceRange }} />
      <br />
      <Button onClick={() => props.submitAmount({ amount: defaultPrice })}>Select</Button>
    </div>
  )


  // const CourierRates = (props) => (
  //   <div style={{ textAlign: 'center' }}>
  //     <div><b>CJ Century</b></div>
  //     <div>RM{props.cjCentury[0]} {props.cjCentury[1]}kg (Parcel)</div>
  //     <div><b>Poslaju(drop)</b></div>
  //     <div>RM{props.poslaju[0]} {props.poslaju[1]}kg</div>
  //     <div><b>Poslaju(pickup)</b></div>
  //     <div style={{ marginBottom: 10 }}>RM{props.poslajuPickup[0]} {props.poslajuPickup[1]}kg</div>
  //     <a
  //       href={"https://app.delyva.com/customer/services/print?plan_code=" + props.code}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //     >
  //       View rates
  //     </a>
  //   </div>
  // )

  const rowToShow = topupPlan.feature.filter((item) => !item.hide);
  const plansObj = sortedTopupPlan.reduce((acc, item) => {
    acc[item.id] = item;
    return acc;
  }, {});
  const rowToShowObj = rowToShow.reduce((acc, item) => {
    acc[item.key] = item;
    return acc;
  }, {});
  const combineRowData = Object.keys(rowToShowObj).map((key) => ({
    rowHead: rowToShowObj[key],
    ...plansObj
  }))

  const columns = [
    {
      title: (
        <div style={{ fontWeight: 'bold' }}>
          <div><span style={{ fontSize: 20 }}>BASIC</span> Plans</div>
          <div>Top-up amount</div>
          <br />
          <div>Choose your topup amount</div>
        </div>
      ),
      dataIndex: 'rowHead',
      render: val => {
        return val.name
      }
    }
  ]

  sortedTopupPlan.forEach((item) => {
    columns.push({
      title: <ColumnHead title={item.name} priceRange={item.description} defaultPrice={item.value} />,
      dataIndex: item.id,
      render: (val, record) => {
        const featuresObj = val.feature.reduce((acc, item) => {
          acc[item.key] = item;
          return acc;
        }, {});
        return <div dangerouslySetInnerHTML={{ __html: featuresObj[record.rowHead.key]?.valueTxt || '-' }} />
      }
    })
  })

  const validationSchema = Yup.object().shape({
    amount: Yup.number().nullable().min(
      sortedTopupPlanPrices[0],
      `Minimum amount ${companyDetails.currency} ${sortedTopupPlanPrices[0]} is required`
    ).required(`Minimum amount ${companyDetails.currency} ${sortedTopupPlanPrices[0]} is required`),
  });

  return (
    <div style={{ padding: "0 30px 30px", maxWidth: footerWidth }}>
      <div>
        <Formik
          enableReinitialize
          validationSchema={validationSchema}
          initialValues={{ amount: 20 }}
          onSubmit={props.submitAmount}
        >
          {form => (
            <Form onSubmit={form.handleSubmit}>
              <Card hoverable={true}>
                <Row gutter={[20, 20]}>
                  <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                    <span className="heading1" style={{ margin: 0, padding: 0, lineHeight: 'normal' }}>
                      {t('content.topupForm.enterAmount')}:
                    </span>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                    <div style={{ maxWidth: '80%', margin: 'auto' }}>
                      <InputNumber
                        {...form}
                        inputStyle={{ width: '100%' }}
                        step={10.00}
                        name="amount"
                        type="number"
                        placeholder={"Min amount " + companyDetails.currency + " " + sortedTopupPlanPrices[0]}
                        style={{ marginBottom: 0, width: "100%" }}
                      />
                    </div>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                    <CustomizedButton
                      htmlType="submit"
                      text={t('content.topupForm.topupNow')}
                      className="button-success is-fullwidth-r"
                      style={{ width: '100%' }}
                    />
                  </Col>
                </Row>
              </Card>
            </Form>
          )}
        </Formik>
        <div style={{ backgroundColor: 'white', marginTop: 20 }}>
          <Table
            className={styles.topupTable}
            columns={columns}
            // rowClassName={(record, index) => index === 0 ? 'my-topup-table-header' : null}
            dataSource={combineRowData}
            scroll={{ x: 900 }}
            bordered
            pagination={false}
            // showHeader={false}
          />
        </div>
        <div style={{ backgroundColor: 'white', marginTop: 20, padding: 20 }}>
          <div style={{ paddingBottom: 10, borderBottom: '1px solid lightgrey', fontWeight: 'bold', fontSize: 20, marginBottom: 20 }}>Notes</div>
          <div>
            1. Your plans will be renewed according to your latest top-up plan. For example, if you are on B2000 plan and you choose to top up with a B200 plan, your new rates follow B200 plan.
          </div>
          <div>
            2. Your account credits are NOT refundable.
          </div>
          <div>
            3. We have the right to amend any of the above plans, promotions, and terms & conditions from time to time without prior notice.
          </div>
          <div>
            4. You can also opt for a monthly Subscription Plan to lock the rates without having to top-up more. Enjoy the lowest rates with only RM 20 minimum top-Up. Just top-up credits as you go.
          </div>
          <div>
            {"5. By continuing you accept our" + " "}
            <a
              href="https://delyva.com/my/terms/"
              target="_blank"
              rel="noopener noreferrer"
            >
               Terms of Use.
            </a>
          </div>
          <div>
            6. For more information, please contact us via our LiveChat or e-mail.
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyTopupAmountStep;

// const data = [
//     {
//       rowHead: <RowHead title="Special courier rates" content="Poslaju, CJ Century, DHL Ecommerce Special Rates. Valid for shipment within West Malaysia (Semenanjung)" />,
//       BD10: <CourierRates cjCentury={[6.90, 2]} poslaju={[6.50, 2]} poslajuPickup={[6.90, 2]} code="BD10" />,
//       BD50: <CourierRates cjCentury={[6.90, 2]} poslaju={[6.50, 2]} poslajuPickup={[6.90, 2]} code="BD50" />,
//       BD100: <CourierRates cjCentury={[6.90, 2]} poslaju={[6.50, 2]} poslajuPickup={[6.90, 2]} code="BD100" />,
//       BD1000: <CourierRates cjCentury={[6.90, 2]} poslaju={[6.50, 2]} poslajuPickup={[6.90, 2]} code="BD1000" />,
//       BD10000: <CourierRates cjCentury={[6.90, 2]} poslaju={[6.50, 2]} poslajuPickup={[6.90, 2]} code="BD10000" />,
//     },
//     {
//       rowHead: <RowHead title="Free credits" content="Get free additional credits! FREE credits = FREE shipping!" outOfStock />,
//       BD10: "-",
//       BD50: "-",
//       BD100: "-",
//       BD1000: "RM10",
//       BD10000: "RM100"
//     },
//     {
//       rowHead: <RowHead title="Free courier bags" content="For free courier bags delivery arrangements, please contact us via LiveChat" outOfStock />,
//       BD10: "-",
//       BD50: "-",
//       BD100: "-",
//       BD1000: "100 pcs",
//       BD10000: "1000 pcs"
//     },
//     {
//       rowHead: <RowHead title="SMS Notification" content="SMS notification to your customer once the package have been collected by one of our partners." />,
//       BD10: "RM0.20",
//       BD50: "RM0.15",
//       BD100: "RM0.10",
//       BD1000: "RM0.07",
//       BD10000: "RM0.05"
//     },
//     {
//       rowHead: <RowHead title="E-mail notification" content="Unlimited pick-up & delivery status notification to you and your customer via email." />,
//       BD10: "FREE",
//       BD50: "FREE",
//       BD100: "FREE",
//       BD1000: "FREE",
//       BD10000: "FREE",
//     },
//     {
//       rowHead: <RowHead title="Push notification" content="Unlimited pick-up & delivery status notification to your customer via push notification to your desktop or mobile browser." />,
//       BD10: "FREE",
//       BD50: "FREE",
//       BD100: "FREE",
//       BD1000: "FREE",
//       BD10000: "FREE",
//     },
//     {
//       rowHead: <RowHead title="Delyva Core features" dialogContent={DelyvaCoreFeaturesDialogContent} />,
//       BD10: <BooleanRowContent bool />,
//       BD50: <BooleanRowContent bool />,
//       BD100: <BooleanRowContent bool />,
//       BD1000: <BooleanRowContent bool />,
//       BD10000: <BooleanRowContent bool />,
//     },
//     {
//       rowHead: <RowHead title="Marketing features" dialogContent={MarketingFeaturesDialogContent}  />,
//       BD10: <BooleanRowContent />,
//       BD50: <BooleanRowContent bool />,
//       BD100: <BooleanRowContent bool />,
//       BD1000: <BooleanRowContent bool />,
//       BD10000: <BooleanRowContent bool />,
//     },
//     {
//       rowHead: <RowHead title="Validity" />,
//       BD10: "365 days",
//       BD50: "365 days",
//       BD100: "365 days",
//       BD1000: "365 days",
//       BD10000: "365 days",
//     },
//     {
//       rowHead: "",
//       BD10: <Button type="primary" onClick={() => props.submitAmount({ amount: 20 })}>Select</Button>,
//       BD50: <Button type="primary" onClick={() => props.submitAmount({ amount: 50 })}>Select</Button>,
//       BD100: <Button type="primary" onClick={() => props.submitAmount({ amount: 100 })}>Select</Button>,
//       BD1000: <Button type="primary" onClick={() => props.submitAmount({ amount: 1000 })}>Select</Button>,
//       BD10000: <Button type="primary" onClick={() => props.submitAmount({ amount: 10000 })}>Select</Button>,
//     }
//   ];
