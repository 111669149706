import React from 'react';

const Error = ({ error }) => (
  error ? <div style={{ color: 'red' }}>{error}</div> : null
);

const Required = ({ required }) => (
  required ? <span style={{ color: 'red' }}>*</span> : null
);

const FormItem = (props) => {
  const { error, label, children, required } = props;

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginBottom: 10 }}><Required required={required}/> {label}:</div>
      {children}
      <Error error={error} />
    </div>
  )
}

export default FormItem;
