import React, { useCallback, useContext, useState, useEffect } from "react";
import { message, Row, Col, Alert } from "antd";
import { useTranslation } from "react-i18next";

import { get, post } from 'Util/API';
import TablePage from 'Components/table-page/TablePage';
import { GlobalContext } from "Store/store";
import PageWrapper from 'Components/PageWrapper';

import tableColumns from './ordersTableColumns';
import Table from './BulkOrdersTable';
import BulkOrdersHeader from './OrdersHeader';
// import Overview from "./Tabs/Overview/Overview";
import RowsAction from './BulkOrdersRowsAction';
import Filters from './BulkOrderFilters';

import { useHistory } from "react-router-dom";

import { getItemTypes, getCustomerDetails } from 'Fetch';

import useLocalStorage from 'Util/useLocalStorage';

import { mixPanelTrack } from "Util/CustomFunctions";

const BulkOrders = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [store] = useContext(GlobalContext);
  const { t } = useTranslation('orderPage');
  const { modalState, setModalState, resetModalState, companyDetails } = store.global;
  const [itemTypes, setItemTypes] = useState(null);

  async function allFetch() {
    const companyId = store.global.companyDetails.id;
    const customerId = store.global.customerDetails.id;

    const getItemTypesRes = await getItemTypes(companyDetails.id);
    const getCustomerDetailsRes = await getCustomerDetails(companyId, customerId);

    return {
      getItemTypesRes: await getItemTypesRes,
      getCustomerDetailsRes: await getCustomerDetailsRes
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const fetchAll = await allFetch();
      const { getItemTypesRes, getCustomerDetailsRes } = fetchAll;
      const itemTypesDataSource = getItemTypesRes.status === 200 ? getItemTypesRes.data?.data : [];

      setItemTypes(itemTypesDataSource);

      PageWrapper.collapsedSidebar = true;

      useLocalStorage.setItem("customerDetails", getCustomerDetailsRes.status === 200? getCustomerDetailsRes.data.data: store.global.customerDetails);

    }
    fetch()
  }, [])

  const history = useHistory();

  const getOrders = async (tableDataKey) => {
    setIsLoading(true);
    tableDataKey.dateFrom = tableDataKey.dateFrom?.format('YYYY-MM-DD').toLocaleString();
    tableDataKey.dateTo = tableDataKey.dateTo?.format('YYYY-MM-DD').toLocaleString();
    delete tableDataKey.data;
    delete tableDataKey.pageTotal;
    delete tableDataKey.rowTotal;
    delete tableDataKey.dateRangeId;
    delete tableDataKey.tabKey;    

    if(tableDataKey.status == 'FAILED')
    {
        tableDataKey.status = '';
        tableDataKey.statusCode = 99;
    }

    var re = /^[A-Za-z]+$/;

    if(tableDataKey.keyword && re.test(tableDataKey.keyword))
        tableDataKey.keyword = "n="+tableDataKey.keyword;

    const params = [];
    Object.keys(tableDataKey).forEach((key, i) => {
      tableDataKey[key] && params.push((i > 0 ? '&' : '') + key + '=' + tableDataKey[key]);
    });
    const orderResponse = await get('order?retrieve=quotes&' + params.join(""));
    if (orderResponse.status === 200) {
      setIsLoading(false)
      return orderResponse.data
    } else message.error(orderResponse);
    setIsLoading(false)
  }

  useEffect(() => {
    mixPanelTrack('ViewDrafts');
  }, []);

  return (
    <PageWrapper loading={isLoading} currentPath={props.location.pathname} isCollapseSidebar={true}>
      <TablePage
        rootStyle={{
          margin: 20,
        }}
        tabBarStyle={{ margin: 0 }}
        initialState={{
          dateFrom: null,
          dateTo: null,
          dateRangeId: 0,
          status: 'DRAFT',
          keyword: null,
          serviceCompanyId: null,
          keyword: null,
          statusCode: null,
          limit: 50
        }}
        filterKeys={['dateFrom', 'dateTo', 'dateRangeId', 'keyword', 'serviceCompanyId','status','statusCode']}
        onFetchDataSource={getOrders}
        isLoading={props.isLoading}
        setIsLoading={(val) => setIsLoading(val)}
        generateColumn={(myProps) => {
          return tableColumns({
            store,
            transl: t,
            modalState,
            setModalState,
            setIsLoading,
            history,
            itemTypes,
            ...myProps
          })
        }}
        headerRenderer={
          (myProps) => (
            <BulkOrdersHeader {...myProps} />
          )
        }
        tableRenderer={Table}
        rowsActionRenderer={(myProps) => (
          <RowsAction
            {...myProps}
          />
        )}
        filtersRenderer={useCallback((myProps) => <Filters {...myProps} />, [])}
        onChangeTabKey="status"
        defaultActiveTabValue='DRAFT'
        tabs={[
          { name: 'Drafts', key: 'DRAFT' },
          { name: 'Failed', key: 'FAILED' },
        ]}
      />

      <div style={{ margin:'20px'}} >
      <Row xs={24} sm={24} md={24} lg={24} xl={24} gutter={20}>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
      <Alert message={t('content.newOrder.item.itemWeightNotice')} type="warning" />
      </Col>
      </Row>
      <br/>
      </div>

    </PageWrapper>
  )
}

export default BulkOrders;
