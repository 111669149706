const useLocalStorage = {
  getItem: function(key) {
    const currentData = JSON.parse(localStorage.getItem('customer')) || {};
    return currentData[key];
  },
  setItem: function(key, val) {
    const currentData = JSON.parse(localStorage.getItem('customer')) || {};
    currentData[key] = val;
    localStorage.setItem('customer', JSON.stringify(currentData))
  },
  clear: function() {
    localStorage.setItem('customer', null)
  }
}

export default useLocalStorage;
