import React from "react";
import { Table } from "antd";

import EmptyData from "Components/EmptyData";
import useWindowSize from 'Util/Hooks/useWindowSize';

const PersonnelDetailsTable = (props) => {
  const size = useWindowSize();
  const tableWidth = size[0] - 310;
  const { filterAndTableState, rowSelection, handleSetFilterAndTableState, columns, pageTotal } = props;
  const { data: dataSource } = filterAndTableState;

  const handleTableChange = (pagination, filters, sorter) => {
    handleSetFilterAndTableState({
      page: pagination.current,
      limit: pagination.pageSize,
      sortBy: sorter.field,
      // orderBy: sorter.order === "descend" ? 'desc' : 'asc',
      orderBy: 'desc'
    })
  }

  return (
    <Table
      rowSelection={rowSelection}
      dataSource={dataSource}
      columns={columns}
      total={pageTotal}
      pagination={{
        current: filterAndTableState.page,
        total: filterAndTableState.rowTotal,
        defaultPageSize: 50,
        showSizeChanger: true,
        pageSizeOptions: ["10", "20", "30", "40", "50","100","200","500","1000"],
        showTotal: (total, range) => `${range[1]} of ${total} item`
      }}
      onChange={handleTableChange}
      loading={false}
      scroll={{ x: tableWidth < 1610 && tableWidth }}
      style={{ maxHeight: "60%", backgroundColor: 'white' }}
      locale={{
        emptyText: (
          <EmptyData
            text="New order"
            path={`${process.env.PUBLIC_URL}/orders/new-order`}
          />
        )
      }}
    />
  )
}

export default PersonnelDetailsTable;
