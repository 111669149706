import React, { Component } from "react";
import { Layout, Typography, Button, Tag, Tabs, Table, Modal, message } from "antd";
import {
  TEXT_ID,
  TEXT_NAME,
  TEXT_DESCRIPTION,
  TEXT_IN_PLAN,
  TEXT_STATUS,
  TEXT_ACTIONS,
  TEXT_APPS,
  TEXT_DATE,
  // TEXT_ADD_APPS,
  TEXT_SUBSCRIBED,
  TEXT_CONNECT_COURIER_ACCOUNT
} from "../../Util/Text";
import { Link, Redirect } from "react-router-dom";
import AppsListTable from "./AppsListTable";
import AppsInstalledTable from "./AppsInstalledTable";
import { remove } from "../../Util/API";
import moment from "moment"

const { confirm } = Modal;

const { Title, Text } = Typography;

const { TabPane } = Tabs;

class AppsContent extends Component {
  state = {
    installedAppsData: this.props.installedAppsData,
    availableAppsData: this.props.availableAppsData,
    installedAppsColumns: [
      {
        title: TEXT_ID,
        dataIndex: "id",
        key: "id",
        render: (text, record) => (
            <div style={{ marginLeft: 0 }}>
            <p>{record.id}</p>
            </div>
        )
      },
      {
        title: TEXT_NAME,
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
            <div style={{ display: "flex", minWidth: 320 }}>
                <img
                  src={"https://delyvax.com/plugins/images/"+record.path+".png"}
                  alt={record.path}
                  style={{
                    borderRadius: 10,
                    height: 48
                  }}
                />
                <div style={{ marginLeft: 20 }}>
                <Title level={4} className="ui-page-title">
                  {text}
                </Title>
                <small>App ID: {record.appId}. Path: {record.path}</small>
              </div>
            </div>
        )
      },
      {
        title: TEXT_DATE,
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (date, record) => (
          <span
            style={{ textAlign: "left", width: "100%", display: "block" }}
          >
            {date === null || date === undefined ? (
              <Text type="secondary">-</Text>
            ) : (
                moment(date).format("LLLL").toLocaleString()
              )}
          </span>
        )
      },
      {
        title: TEXT_ACTIONS,
        key: "action",
        render: (text, record) => (
          <span className="ui-table-btn-act-container">
              { (record.path != 'aws-ses' && record.path != 'geocoder-google' && record.path != 'geocoder-here'
                  && record.name != 'Email (DelyvaX-Email)' && record.name != 'SMS (DelyvaX-SMS)' && record.name != 'Geocoder (DelyvaX-Geocoder)'
                ) ?
                <div>
                <Link
                  to={{
                    pathname: `${process.env.PUBLIC_URL}/apps/edit/`+record.id,
                    state: { record }
                  }}
                >
                  <Button alt="Edit Configuration" title="Edit Configuration">
                    <i className="fa fa-cog" />
                  </Button>
              </Link>
              <Button onClick={() => this.uninstallRecord(record)} alt="Uninstall" title="Uninstall">
                <i className="far fa-trash-alt" />
              </Button>
              </div>
              :''
              }
          </span>
        )
      }
    ],
    availableAppsColumns: [
      {
        title: TEXT_NAME,
        dataIndex: "name",
        key: "name",
        render: (text, record) => (
          <div style={{ display: "flex", minWidth: 320 }}>
            <img
              src={"https://delyvax.com/plugins/images/"+record.path+".png"}
              alt={record.path}
              style={{
                borderRadius: 10,
                height: 48
              }}
            />
            <div style={{ marginLeft: 20 }}>
              <Title level={4} className="ui-page-title">
                {text}
              </Title>
              <small>App ID: {record.id}. Path: {record.path}</small>
            </div>
          </div>
        )
      },
      {
        title: TEXT_DESCRIPTION,
        dataIndex: "description",
        key: "description"
      },
      {
        title: TEXT_ACTIONS,
        key: "action",
        render: (text, record) => (
          <span className="ui-table-btn-act-container">
            <Link
              to={{
                pathname: `${process.env.PUBLIC_URL}/apps/install`,
                state: { app:record }
              }}
            >
              <Button alt="Install" title="Install">
                <i className="fa fa-download" />
              </Button>
            </Link>
          </span>
        )
      }
    ],
    deletedApp: {},
    isDeleteAppModalVisible: false
  };

  deleteApp = () => {
    this.setState({

    });
  };

  uninstallRecord = async app => {
    confirm({
      title: 'Do you want to uninstall this app?',
      content: app.id+" "+app.name,
      onOk: async () => {
          const uninstallRes = await remove("plugin/uninstall/" + app.id);

          if (uninstallRes.status === 200 || uninstallRes.status === 204) {
            message.success("App "+app.id+" "+app.name+" uninstalled.");

            window.location.reload();
          } else message.error("Unknown error");
      },
      onCancel() {

      },
    });
  };

  handleCancel = () => {
    this.setState({
      // isDeleteAppModalVisible: false
    });
  };
  render() {

    return (
      <Layout className="ui-content-container">
        <Layout className="ui-content">
          <div>
            <div className="list-title-content">
              <Title level={2} className="heading1" style={{ paddingLeft: 20 }}>
                {TEXT_APPS}: {TEXT_CONNECT_COURIER_ACCOUNT}
              </Title>
              <p style={{ paddingLeft: 20 }}>
                You need to subscribe to Standard / Advanced / Enterprise plans to use this feature.
              </p>
            </div>

            <div className="ui-card">

            <Tabs defaultActiveKey="installed">
              <TabPane tab="Installed" key="installed" style={{ marginLeft: 20 }}>
                <AppsInstalledTable
                  appsColumns={this.state.installedAppsColumns}
                  appsData={this.props.installedAppsData}
                  total={this.props.installedRowTotal}
                  deletedApp={this.state.deletedApp}
                  isDeleteAppModalVisible={this.state.isDeleteAppModalVisible}
                  deleteApp={this.deleteApp}
                  handleCancel={this.handleCancel}
                  getInstalled={this.props.getInstalled}
                />
              </TabPane>
              <TabPane tab="Available" key="available" style={{ marginLeft: 0 }}>
                <AppsListTable
                  appsColumns={this.state.availableAppsColumns}
                  appsData={this.props.availableAppsData}
                  total={this.props.availableRowTotal}
                  deletedApp={this.state.deletedApp}
                  isDeleteAppModalVisible={this.state.isDeleteAppModalVisible}
                  deleteApp={this.deleteApp}
                  handleCancel={this.handleCancel}
                  getAvailable={this.props.getAvailable}
                />
              </TabPane>
            </Tabs>
            </div>

          </div>
        </Layout>
      </Layout>
    );
  }
}

export default AppsContent;
