import React, { useEffect, useState } from "react";
import { message } from "antd";

import { get } from "Util/API";
import PageWrapper from "Components/PageWrapper";

import AddressBookTable from "./address-book-table/AddressBookTable";

import { mixPanelTrack } from "Util/CustomFunctions";

const AddressBook = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [transactionData, setTransactionData] = useState({
    data: [],
    rowTotal: 0
  });

  useEffect(() => {
    getContacts("", 1, 10);
  }, []);

  const getContacts = async (query, page, limit) => {
    if(!page) page = 1;
    if(!limit) limit = 10;

    let apiUrl = `contacts?page=${page}&limit=${limit}`;

    if(query?.keyword) apiUrl = apiUrl+"&name="+query.keyword;
    if(query?.tag && query?.tag != 'Any') apiUrl = apiUrl+"&tags="+query.tag;

    const transactionResponse = await get(apiUrl);

    if (transactionResponse.status === 200) {
      setTransactionData({
        data: transactionResponse.data.data,
        rowTotal: transactionResponse.data.rowTotal
      })
      setIsLoading(false);
    } else {
      setIsLoading(false);
      message.error(transactionResponse);
    }
  };

  const handlePageSize = pagination => {
    getContacts("", pagination.current, pagination.pageSize);
  };

  useEffect(() => {
    mixPanelTrack('ViewAddressBookList');
  }, []);

  return (
    <PageWrapper loading={false} currentPath={props.location.pathname} isCollapseSidebar={true}>
      <AddressBookTable
        transactionData={transactionData.data}
        transactionRowTotal={transactionData.rowTotal}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        handlePageSize={handlePageSize}
        getContacts={getContacts}
      />
    </PageWrapper>
  );
};

export default AddressBook;
