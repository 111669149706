import React from "react";
import { Form } from "antd";

const TextInput = ({
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  name,
  placeholder,
  type,
  prefix,
  label,
  max,
  onChange,
  addonAfter,
  addonBefore,
  onBlur,
  size,
  disabled,
  className,
  style,
  touched,
  step,
  children
}) => (
  <Form.Item
    hasFeedback={addonAfter || addonBefore ? null : !!errors[name]}
    validateStatus={disabled ? null : touched[name] && errors[name] && "error"}
    help={disabled ? null : touched[name] ? errors[name] : ""}
    label={label}
    className={className}
    style={style}
  >
    {children}
  </Form.Item>
);

export default TextInput;
