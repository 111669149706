import React, { Component } from "react";
import { Table, Timeline } from "antd";
import moment from "moment";

class PublicTrackingTabpane extends Component {
  timelineColor = statusCode => {
    switch (statusCode) {
      case 100:
        return "#445A64";
      case 110:
        return "#445A64";
      case 200:
        return "#445A64";
      case 475:
        return "#E91E63";
      case 500:
        return "#ff8000";
      case 650:
        return "#E91E63";
      case 651:
        return "#E91E63";
      case 655:
        return "#E91E63";
      case 700:
        return "#00C853";
      case 900:
        return "#ff0000";
      case 1000:
        return "#00C853";
      default:
        return "#ff8000";
    }
  };

  render() {
    const tableWidth = 60 / 100 * window.innerWidth;
    return (
      <Table
        dataSource={this.props.trackingData}
        columns={this.props.trackingColumn}
        pagination={false}
        loading={this.props.isLoading}
        scroll={{ x: this.props.trackingData.length > 0 && tableWidth < 1610 && tableWidth }}
        style={{ maxHeight: "60%" }}
        expandedRowRender={record => (
          <div
            style={{
              width: "100%"
            }}
          >
            <div
              style={{
                width: "100%"
              }}
            >
              <Timeline>
                {record.histories.map((history, index) => {
                  return (
                    <Timeline.Item
                      key={index}
                      color={this.timelineColor(history.statusCode)}
                    >
                      <div style={{ fontWeight: 'bold' }}>{history.statusText}</div>
                      <div>{history.description}</div>
                      <div>{history.location}</div>
                      <div style={{ fontSize: 13 }}>{moment(history.createdAt).format("D MMM YYYY, h:mm a")}</div>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </div>
            {/* <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexGrow: 1
              }}
            >
              <CustomizedButton
                style={{ margin: "0 10px" }}
                text={
                  <span>
                    <i className="fas fa-share-alt" /> {TEXT_SHARE}
                  </span>
                }
              />
              <CustomizedButton
                style={{ margin: "0 10px" }}
                className="bg-warning"
                text={
                  <span>
                    <i className="fas fa-star" /> {TEXT_RATE}
                  </span>
                }
                onClick={this.props.openRateModal}
              />
            </div> */}
          </div>
        )}
      />
    );
  }
}

export default PublicTrackingTabpane;
