import React from "react";
import {
  Row,
  Col,
} from "antd";
import moment from 'moment';

import withStore from 'Util/withStore';
import useWindowSize from 'Util/useWindowSize';
import DateFilter from 'Pages/Order/DateFilter';

const OrdersTableContent = (props) => {
  const size = useWindowSize();
  const { filterAndTableState: ordersTableData, handleSetFilterAndTableState } = props;

  return (
    <div style={{ backgroundColor: 'white', padding: 20 }}>
      <Row gutter={[10, 10]} style={{ display: 'flex', flexDirection: size[0] > 472 ? 'row' : 'column', alignItems: size[0] > 472 ? 'center' : 'unset' }}>
        <Col>
          <DateFilter
            currentId={ordersTableData.dateRangeId}
            handleModalRangeDate={(dateFrom, dateTo, id) => {
              handleSetFilterAndTableState({
                dateFrom: dateFrom && moment(dateFrom),
                dateTo: dateTo && moment(dateTo),
                dateRangeId: id
              })
            }}
            handleCustomDateRange={props.onOpenDateFilter}
          />
        </Col>
      </Row>
    </div>
  )
}

export default withStore(OrdersTableContent);
