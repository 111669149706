import React from "react";
import { Form, Input } from "antd";
import FormItem from './FormItem';

const TextInput = ({
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  name,
  placeholder,
  type,
  prefix,
  label,
  max,
  onChange,
  addonAfter,
  addonBefore,
  onBlur,
  size,
  disabled,
  viewOnly,
  className,
  style,
  inputStyle,
  touched,
  step,
  value,
  suffix,
  required
}) => (
  <FormItem
    hasFeedback={addonAfter || addonBefore ? null : !!errors[name]}
    validateStatus={disabled ? null : errors[name] && "error"}
    help={disabled ? null : errors[name]}
    label={label}
    className={className}
    style={style}
    error={errors[name]}
    required={required}
  >
    <Input
      disabled={disabled}
      style={{ ...inputStyle, color: viewOnly && '#000000a6', border: viewOnly && 'none' }}
      prefix={prefix}
      addonAfter={addonAfter}
      addonBefore={addonBefore}
      placeholder={placeholder}
      value={value || values[name]}
      onChange={
        onChange
          ? onChange
          : event => {
              if (event.target.value.length === max + 1) {
                return;
              }
              setFieldValue(name, event.target.value);
            }
      }
      onBlur={onBlur ? onBlur : () => setFieldTouched(name)}
      type={type}
      size={size}
      step={step}
      suffix={suffix}
    />
  </FormItem>
);

export default TextInput;
