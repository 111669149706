import React, { useEffect } from "react";
import { Modal } from "antd";
import { useTranslation } from "react-i18next";

import CustomizedButton from 'Components/CustomizedButton'

const CustomizedModal = ({
  title,
  visible,
  onOk,
  onOkData,
  onCancel,
  text,
  children,
  footer,
  closable,
  okButtonProps,
  okText,
  cancelButtonProps,
  cancelText,
  width,
  maxWidth=600,
  bodyStyle,
  style,
  others,
  type,
  maskStyle
}) => {
  const { t } = useTranslation('components');

  useEffect(() => {
    let mount = true;
    if (visible && mount) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "auto";
      mount = false;
    }
  }, [])

  return (
    <Modal
      maskStyle={maskStyle}
      destroyOnClose={true}
      title={title}
      afterClose={() => {
        document.body.style.overflow = "auto";
      }}
      onCancel={onCancel}
      visible={visible}
      footer={(footer || onOk) ?
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          {onCancel && <CustomizedButton onClick={onCancel} {...cancelButtonProps} text={cancelText || t('customizedModal.cancel')} />}
          {onOk && <CustomizedButton onClick={() => onOk(onOkData)} {...okButtonProps} text={okText || t('customizedModal.confirm')} />}
        </div> :
        null
      }
      closable={closable}
      bodyStyle={{  backgroundColor: type === 'success' && '#F6FFED', ...bodyStyle, }}
      style={{ maxWidth, ...style }}
      okText={okText}
      centered
      cancelText={cancelText}
      width={width || "90vw"}
    >
      {text ? text : children}
    </Modal>
  )
};

export default CustomizedModal;
