import React, { useContext, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Layout, Form, Card, Spin, message } from "antd";
import { Link } from "react-router-dom";

import { GlobalContext } from 'Store/store';
import TextInput from "Components/TextInput";
import CustomizedButton from "Components/CustomizedButton";
import { IMAGE_URL_ROOT, post } from 'Util/API';

const ForgotPassword = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { companyDetails } = state.global;
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async (data) => {
    setIsLoading(true)
    const fetch = await post("user/deleteAccount", { ...data })
    if (fetch.status === 200) {
      message.success('Delete account successful.')
      dispatch({ type: "LOGOUT" })

    } else message.error(fetch);
    setIsLoading(false)
  }

  return (
    <Spin spinning={isLoading} size="large">
      <Layout style={{ minHeight: "100vh" }}>
        <div className="center">
          <div className="logo-company">
            <img
              style={{ height: 64, margin: '0 20px' }}
              src={
                companyDetails.logob ?
                  IMAGE_URL_ROOT + companyDetails.logob :
                  'https://cdn.delyva.app/assets/delyvax-logo-b.svg'
                }
              alt="company logo"
            />
          </div>
          <p
            className="heading2"
            style={{ textAlign: "center", fontWeight: 400 }}
          >
            Delete your account?
          </p>
          <Card>
            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                password: null
              }}
              validationSchema={Yup.object().shape({
                password: Yup.string().nullable().required("Password is required")
              })}
              onSubmit={onSubmit}
            >
              {form => (
                <Form onSubmit={form.handleSubmit} className="login-form">
                  <div style={{ marginBottom: 20 }}>Enter your password to confirm account deletion.</div>
                  <TextInput
                    {...form}
                    name="password"
                    placeholder="Password"
                    type="password"
                    className="marginBottom-10"
                  />
                  <CustomizedButton
                    type="primary"
                    htmlType="submit"
                    isBlockType={true}
                    text="Delete my account now"
                  />
                </Form>
              )}
            </Formik>
          </Card>
        </div>
      </Layout>
    </Spin>
  )
}

export default ForgotPassword;
