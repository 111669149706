import React, { useContext, useState, useEffect } from "react";
import { Card, Form, Row, Col, Upload, Modal, Select, message } from "antd";
import { Formik } from "formik";
import { Link } from 'react-router-dom';
import { useTranslation } from "react-i18next";

import CustomizedButton from "Components/CustomizedButton";
import { IMAGE_URL_ROOT } from "Util/API";

import {
  TEXT_SEARCH_COUNTRY,
  TEXT_PROFILE_PHOTO_PREVIEW,
  TEXT_UPLOAD_OR_DROP_FILE,
} from "Util/Text";
import TextInput from "Components/TextInput";
import PhoneNumberInput from "Components/PhoneNumberInput";
import { STATE_LIST } from "Util/State";
import { GlobalContext } from "Store/store";
import { post, update } from "Util/API";

import { mixPanelTrack } from "Util/CustomFunctions";

const uploadButton = (
  <div>
    <i className="fas fa-plus" />
    <div className="ant-upload-text">{TEXT_UPLOAD_OR_DROP_FILE}</div>
  </div>
);

const Option = Select.Option;
const stateOption = [];
const state = STATE_LIST;

for (let i = 0; i < state.length; i++) {
  stateOption.push(
    <Option key={i} value={state[i]}>
      {state[i]}
    </Option>
  );
}

const ContactForm = (props) => {
  const { isLoading, setIsLoading } = props;
  const [state, dispatch] = useContext(GlobalContext);
  const { userDetails, customerDetails, allCustomerAccounts } = state.global;
  const [newProfilePic, setNewProfilePic] = useState(false);
  const { t } = useTranslation('settingsPage');

  const onSubmit = async (values) => {
    !isLoading && setIsLoading(true);
    const accountData = { ...values };

    const { profilePic, name } = accountData;
    if (newProfilePic) {
      const formData = new FormData();
      formData.append("profilePic", profilePic);
      const profilePicResponse = await post(
        "user/" + userDetails.id + "/photo",
        formData
      );
      profilePicResponse.status !== 200 && message.error(profilePicResponse);
    }

    const updateUserDetails = await update("user", { name });
    if (updateUserDetails.status === 200) {
      dispatch({
        type: "UPDATE_PROFILE",
        payload: {
          userDetails: updateUserDetails.data.data,
          allCustomerAccounts,
          customerDetails,
        }
      })
      message.success(t('content.accountInfo.update'))
    } else message.error(updateUserDetails);

    isLoading && setIsLoading(false);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        profilePic: userDetails.profilePic,
        name: userDetails.name,
        email: userDetails.email,
        mobile: userDetails.mobile
      }}
      onSubmit={onSubmit}
    >
      {form => (
        <AccountInformation
          form={form}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          setNewProfilePic={setNewProfilePic}
        />
      )}
    </Formik>
  )
}

const AccountInformation = (props) => {
  const { t } = useTranslation('settingsPage');
  const [state] = useContext(GlobalContext);
  const { userDetails } = state.global;
  const { form, setNewProfilePic } = props;
  const [visibleProfilePic, setVisibleProfilePic] = useState(false);
  const [previewProfilePic, setPreviewProfilePic] = useState('');
  const [listProfilePic, setListProfilePic] = useState([
    {
      uid: "-1",
      status: "done",
      url:
        IMAGE_URL_ROOT + '/' +
        userDetails.profilePic
    }
  ]);

  const handlePreviewProfilePic = file => {
    setPreviewProfilePic(file.url || file.thumbUrl);
    setVisibleProfilePic(true);
  };

  const handleChangeProfilePic = ({ fileList }) => {
    setListProfilePic(fileList);
    setNewProfilePic(true);
  };

  useEffect(() => {
    mixPanelTrack('ViewSettingsAccount');
  }, []);

  return (
    <Form layout="vertical" onSubmit={form.handleSubmit}>
      <Card>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p
            className="heading3"
            style={{ margin: 0, marginBottom: 10 }}
          >
           {t('content.accountInfo.title')}
          </p>
          <div>
            <p
              style={{
                width: "100%",
                color: "rgba(0, 0, 0, 0.85)",
                margin: 0
              }}
            >
              {t('content.accountInfo.profilePhoto')}
            </p>
            <p style={{ width: "100%", color: "rgba(0, 0, 0, 0.5)" }}>
            {t('content.accountInfo.profilePhoto1')}
            </p>
            <Form.Item
              validateStatus={
                form.touched["profilePic"] &&
                form.errors["profilePic"] &&
                "error"
              }
              help={
                form.touched["profilePic"]
                  ? form.errors["profilePic"]
                  : ""
              }
            >
              <Upload
                beforeUpload={file => {
                  form.setFieldValue("profilePic", file);
                  // TODO: Change to false to upload the file
                  return false;
                }}
                onPreview={handlePreviewProfilePic}
                onChange={handleChangeProfilePic}
                fileList={listProfilePic}
                listType="picture-card"
                name="profilePic"
                accept=".png, .jpg, .jpeg"
              >
                {listProfilePic.length >= 1 ? null : uploadButton}
              </Upload>
              <Modal
                visible={visibleProfilePic}
                footer={null}
                // onCancel={this.handleCancelProfilePic}
                centered
                className="modal-img-view"
              >
                <img
                  alt={TEXT_PROFILE_PHOTO_PREVIEW}
                  className="profile-picture-preview"
                  src={previewProfilePic}
                />
              </Modal>
            </Form.Item>
          </div>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                name="name"
                label={t('content.accountInfo.name')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                disabled
                viewOnly
                name="email"
                label={t('content.accountInfo.email')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <PhoneNumberInput
                {...form}
                name="mobile"
                disabled
                viewOnly
                enableSearchField={true}
                searchPlaceholder={TEXT_SEARCH_COUNTRY}
                label={t('content.accountInfo.mobile')}
                onChange={value => {
                  form.setFieldValue("mobile", value);
                }}
              />
            </Col>
            </Row>
            <Row>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <Link to={`${process.env.PUBLIC_URL}/delete-account`} >
            {t('content.deleteAccount')}
            </Link>
            </Col>
            </Row>
        </div>
      </Card>
      <div style={{ float: "right", marginTop: 20, marginBottom: 20 }}>
        <CustomizedButton
          text={t('content.save')}
          type="primary"
          htmlType="submit"
        />
      </div>
    </Form>
  )
}

export default ContactForm;
