import React, { useEffect, useState, useContext } from 'react';
import { Col, Row, Button } from 'antd';

import CustomizedButton from "Components/CustomizedButton";
import { API_ROOT } from 'Util/API';
import { GlobalContext } from 'Store/store';
import AfterPayment from 'Components/AfterPayment.jsx';
import ShowOn from 'Util/ShowOn';

import useOnUpdateOrder from './useOnUpdateOrder';

const MultipointOrderAfterPayment = (props) => {
  const { match, location, history } = props;
  const [state] = useContext(GlobalContext);
  const orderId = location.state?.orderId || match.params.orderId;
  const { companyDetails } = state.global;
  const { message } = state.sse;
  const [showContent, setShowContent] = useState(false);
  const [currentOrder, setCurrentOrder] = useState(null);
  const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);
  const [failedMessage, setFailedMessage] = useState(null);

  const updatedOrder = useOnUpdateOrder(orderId, message);
  useEffect(() => {
    if (updatedOrder) {
      setCurrentOrder(updatedOrder);
      setShowContent(true);
      setIsPaymentSuccessful(updatedOrder?.statusCode !== 0 && updatedOrder?.statusCode !== 99 && updatedOrder?.statusCode !== 10);
      if (updatedOrder?.statusCode === 10 ) {
        setFailedMessage('Pending payment. View your order to monitor the status.')
      }
    }
  }, [updatedOrder])

  return (
    <AfterPayment
      showContent={showContent}
      isPaymentSuccessful={isPaymentSuccessful}
      failedMessage={failedMessage}
      failedContent={
        () => (
          <ShowOn on={!isPaymentSuccessful}>
            <div style={{ marginBottom: 20, color: currentOrder.statusCode === 10 ? 'black' : 'red' }}>
              <div>STATUS: {currentOrder.status}</div>
              <div>STATUS_CODE: {currentOrder.statusCode}</div>
              <div>FAILED_REASON: {currentOrder.failedReason}</div>
            </div>
          </ShowOn>
        )
      }
    >
      <Row gutter={[20, 20]} style={{ width: '100%', marginBottom: 20 }} type="flex" justify="end">
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CustomizedButton
            style={{ width: '100%' }}
            text="Back to multipoint order"
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/orders/multipoint`)
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <CustomizedButton
            style={{ width: '100%' }}
            text="View order details"
            onClick={() => {
              history.push(`${process.env.PUBLIC_URL}/order/${currentOrder.id}`)
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8}>
          <Button
            type="primary"
            style={{ border: 'none', width: '100%' }}
            target="_blank"
            href={isPaymentSuccessful && currentOrder?.id && `${API_ROOT}order/${currentOrder?.id}/label?companyId=${companyDetails.id}`}
            disabled={!isPaymentSuccessful}
          >
            Print label
          </Button>
        </Col>
      </Row>
    </AfterPayment>
  )
}

export default MultipointOrderAfterPayment;
