import React, { Component, useContext } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import TextInput from "Components/TextInput";
import PasswordInput from "Components/PasswordInput";
import CustomizedTextarea from "Components/CustomizedTextarea";
import { Form, Row, Col, Layout, Divider, message } from "antd";
import {
  MESSAGE_API_ID_REQUIRED,
  MESSAGE_NAME_REQUIRED,
  MESSAGE_CONFIG_REQUIRED
} from "Util/ErrorMessage";
import {
  TEXT_DISCARD,
  TEXT_SETTINGS,
  TEXT_INSTRUCTION,
  TEXT_SAVE,
  TEXT_APP_ID,
  TEXT_APP_CONFIG,
  TEXT_NAME,
  TEXT_WEBHOOK,
  TEXT_URL,
  TEXT_ID
} from "Util/Text";
import CustomizedButton from "Components/CustomizedButton";
import { Redirect, Link } from "react-router-dom";
import { appPathToConfig } from 'Util/CustomFunctions';
import useLocalStorage from 'Util/useLocalStorage';

import { post, update } from "Util/API";

const formik = {};

let theApp = {
    id:null,
    name:null,
    appId:null,
    path:null,
    customerId:null,
    config:null
};


class Settings extends Component {
  state = {
      isComplete: false
  };

  submitSettings = async values => {

    const config = {
        sandbox: values.config_sandbox,
        market: values.config_market,
        apiKey: values.config_apiKey,
        secretKey: values.config_secretKey
    }

    //edit
    if (values.id) {

      let data = {
        name: values.name,
        customerId: values.customerId,
        config: config,
      };

      const updateRes = await update("plugin/installed/" + values.id, data);

      if (updateRes.status === 200) {
        message.success("App configuration saved.");
        this.setState({
          isComplete: true
        });
        return;
      } else message.error(updateRes);

    //install
    } else {

      let data = {
        name: values.name+' - '+values.customerId,
        appId: values.appId,
        customerId: values.customerId,
        config: config
      };

      const installRes = await post("plugin/install", data);
      if (installRes.status === 200) {
        message.success("App installed");
        this.setState({
          isComplete: true
        });
        return;
      } else message.error(installRes);
    }
  };

  render() {

    if (this.state.isComplete === true) {
      return (
        <Redirect
          to={{
            pathname: `${process.env.PUBLIC_URL}/apps/list`
          }}
        />
      );
    }

    const record = this.props.record;
    const app = this.props.app;

    const companyId = useLocalStorage.getItem("companyId");
    const customerId = useLocalStorage.getItem("customerId");

    if(record)
    {
        theApp.id = record.id;
        theApp.name = record.name;
        theApp.appId = record.appId;
        theApp.path = record.path;
        theApp.companyId = record.companyId;
        theApp.customerId = record.customerId;
        // theApp.config = JSON.stringify(record.config);
        theApp.config_sandbox = record.config.sandbox;
        theApp.config_market = record.config.market;
        theApp.config_apiKey = record.config.apiKey;
        theApp.config_secretKey = record.config.secretKey;
    }else {
        theApp.id = null;
        theApp.name = app.name;
        theApp.appId = app.id;
        theApp.path = app.path;
        theApp.companyId = companyId;
        theApp.customerId = customerId;
        // theApp.config = appPathToConfig(app.path);
        theApp.config_market = "MY";
        theApp.config_apiKey = "";
        theApp.config_secretKey = "";
    }

    return (
      <Layout>
        <Formik
          initialValues={{
            id: theApp.id,
            name: theApp.name,
            appId: theApp.appId,
            // config: theApp.config,
            customerId: theApp.customerId,
            config_sandbox: theApp.config_sandbox,
            config_market: theApp.config_market,
            config_apiKey: theApp.config_apiKey,
            config_secretKey: theApp.config_secretKey
          }}
          validationSchema={
            Yup.object().shape({
              name: Yup.string().required(MESSAGE_NAME_REQUIRED),
              appId: Yup.string().required(MESSAGE_API_ID_REQUIRED),
              // config: Yup.string().required(MESSAGE_CONFIG_REQUIRED)
            })
          }
          {...formik}
          onSubmit={this.submitSettings}
        >
          {form => (
            <Form layout="vertical" onSubmit={form.handleSubmit}>
              <Row>
                <Col md={24} xl={19} style={{ width: "100%" }}>
                  <Col span={12}>
                    <div className="ui-card">
                      <h2 className="new-driver-title">{TEXT_SETTINGS}</h2>

                        <img
                          src={"https://delyvax.com/plugins/images/"+theApp.path+".png"}
                          alt={theApp.path}
                          style={{
                            borderRadius: 10,
                            height: 108
                          }}
                        />

                        <p>{TEXT_ID}:
                        { theApp.id?
                        <span>{theApp.id}</span>
                        : "-"}
                        </p>

                        <TextInput
                          {...form}
                          id="appId"
                          name="appId"
                          label={TEXT_APP_ID}
                          type="text"
                          disabled
                        />

                        <TextInput
                          {...form}
                          id="name"
                          name="name"
                          label={TEXT_NAME}
                          type="text"
                        />

                        <TextInput
                          {...form}
                          id="config_market"
                          name="config_market"
                          label={"Market"}
                          type="text"
                        />
                        <TextInput
                          {...form}
                          id="config_apiKey"
                          name="config_apiKey"
                          label={"API Key"}
                          type="text"
                        />
                        <PasswordInput
                          {...form}
                          id="config_secretKey"
                          name="config_secretKey"
                          label={"Secret Key"}
                        />

                        <p>{TEXT_WEBHOOK} {TEXT_URL}:</p>
                        <p>
                        { (theApp.path == 'kiple') ? "https://api.delyva.app/v1.0/wallet/callback/kiple"
                         : (theApp.path == 'toyyibpay') ? "https://api.delyva.app/v1.0/wallet/callback/toyyibpay"
                         : (theApp.path == 'billplz') ? "https://api.delyva.app/v1.0/wallet/callback/billplz"
                         : (theApp.path == 'molpay') ? "https://api.delyva.app/v1.0/wallet/callback/molpay"
                         : (theApp.path == 'stripe') ? "https://api.delyva.app/v1.0/wallet/callback/stripe"
                         : theApp.id?
                        "https://api.delyva.app/v1.0/tracking-callback/"+theApp.path+"?pid="+theApp.id+"&cid="+theApp.companyId
                        : "-"}
                        </p>
                    </div>
                  </Col>
                  <Col span={11} offset={1}>

                    <div
                      style={{
                        background: "blue",
                        padding: 20,
                        color: "white",
                        height: "100%"
                      }}
                    >
                      <div><strong>{TEXT_INSTRUCTION}</strong></div>
                      <div>
                        <p>Bring your own courier account(s):</p>
                        1. Get the API parameters from the courier's portal or account manager.<br/>
                        2. Enter the API parameters and Save.<br/>
                        3. Please ensure you have subscribed to the right plan and contact our support team to activate it.<br/>
                        4. Copy and paste the webhook URL into the courier's portal (will be displayed you have saved the app).
                      </div>
                    </div>
                  </Col>
                </Col>
                <Divider />
                <Col className="button-container" span={24}>
                  <Link to={`${process.env.PUBLIC_URL}/apps/list`}>
                    <CustomizedButton
                      text={TEXT_DISCARD}
                      className="left-button"
                    />
                  </Link>
                  <CustomizedButton
                    text={TEXT_SAVE}
                    type="primary"
                    htmlType="submit"
                  />
                </Col>
              </Row>
            </Form>
          )}
        </Formik>
      </Layout>
    );
  }
}

export default Settings;
