import React, { useState, useCallback, useEffect, useContext } from "react";

import { Table, Button, Tabs } from "antd";
import {
  TEXT_STATUS,
  TEXT_ACTIONS,
  TEXT_EMAIL,
  TEXT_DATE,
} from "Util/Text";
import ShowOn from 'Util/ShowOn'
import moment from "moment";
import { GlobalContext } from "Store/store";

const TabPane = Tabs.TabPane;

const columns = (props) => [
  {
    title: 'Name',
    dataIndex: "name",
    key: "name",
    width: 200
  },
  {
    title: 'Current Plan',
    dataIndex: "planName",
    key: "planName",
    width: 100,
    render: text => (
      <>
        {!text || text === '' ? 'N/A' : text}
      </>
    )
  },
  // {
  //   title: TEXT_ACTION_NEEDED,
  //   dataIndex: "ActionNeeded",
  //   key: "ActionNeeded"
  // },
  {
    title: 'Signup At',
    key: "createdAt",
    dataIndex: "createdAt",
    width: 150,
    render: text => {
      return <div>{moment(text).format("D MMM YYYY, h:mm a")}</div>;
    }
  }
]

const AddressBookTableConfig = props => {
  const [activeTab, setActiveTab] = useState(-1);
  const [dataSource, setDataSource] = useState(props.transactionData);
  const [state] = useContext(GlobalContext);
  const { companyDetails } = state.global;

  useEffect(() => {
    if (activeTab === -1) {
      setDataSource(props.transactionData)
    } else {
      setDataSource(props.transactionData.filter((item) => item.status === activeTab))
    }
  }, [activeTab, props.transactionData])

  const TableContent = useCallback(() => (
    <Table
      dataSource={dataSource}
      columns={columns({ onSendInvites: props.onSendInvites, currency: companyDetails.currency })}
      rowKey={record => record.id}
      pagination={{
        defaultPageSize: 10,
        showSizeChanger: false,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total, range) =>
          `${range[1]} of ${total} referral(s)`,
        total: dataSource.length
      }}
      scroll={{ x: window.innerWidth < 1100 && 900 }}
      // style={{ maxHeight: "60%" }}
    />
  ), [dataSource, props.isLoading])

  return (
    <div>
    <TableContent />
     {/* <Tabs defaultActiveKey={activeTab} animated={false} onChange={(val) => setActiveTab(Number(val))}>
        <TabPane tab="All" key={-1}>
          <ShowOn on={true}>
            <TableContent />
          </ShowOn>
        </TabPane>
        <TabPane tab="Invited" key={0}>
          <ShowOn on={activeTab === 0}>
            <TableContent />
          </ShowOn>
        </TabPane>
        <TabPane tab="Active" key={1}>
          <ShowOn on={activeTab === 1}>
            <TableContent />
          </ShowOn>
        </TabPane>
      </Tabs>*/}
    </div>
  );
};

export default AddressBookTableConfig;
