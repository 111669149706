import React, { useContext, useEffect } from "react";
import { Form, message, Layout, Card, Divider } from "antd";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";

import CheckBox from "Components/Checkbox";
import TextInput from "Components/TextInput";
import { GlobalContext } from "Store/store";
import CustomizedButton from "Components/CustomizedButton";
import { update, get } from "Util/API";

import { mixPanelTrack } from "Util/CustomFunctions";

const NotificationsSetting = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { customerDetails, companyDetails, userDetails, allCustomerAccounts } = state.global;
  const { notifications } = customerDetails;
  const { t } = useTranslation('settingsPage');

  const initialValues = {
    newsletter: notifications?.newsletter,
    smsNotifications: notifications?.smsNotifications,
    pushNotifications: notifications?.pushNotifications,
    emailNotifications: notifications?.emailNotifications,
    notifyMeOrderStatus: notifications?.notifyMeOrderStatus,
    notifyRecipientOrderStatus: notifications?.notifyRecipientOrderStatus,
    instabookNotifySender: notifications?.instabookNotifySender,
    instabookNotifyReceiver: notifications?.instabookNotifyReceiver,
    whatsappMeOrderStatus: notifications?.whatsappMeOrderStatus,
    whatsappRecipientOrderStatus: notifications?.whatsappRecipientOrderStatus,
    newInvoice: notifications?.newInvoice,
    paidInvoice: notifications?.paidInvoice,
    minBalanceNotify: customerDetails.minBalanceNotify,
    minBalanceVal: customerDetails.minBalanceVal
  }

  const onSubmit = async (val) => {

    const minBalanceNotify = val.minBalanceNotify;
    const minBalanceVal = val.minBalanceVal;

    delete val.minBalanceNotify;
    delete val.minBalanceVal;

    const updateCustomerDetails = await update("customer", {
        id: customerDetails.id,
        companyId: companyDetails.id,
        notifications: val,
        minBalanceNotify: minBalanceNotify,
        minBalanceVal: minBalanceVal
    });

    if (updateCustomerDetails.status === 200) {
      const getCustomerDetails = await get(`/customer/${customerDetails.id}?companyId=${companyDetails.id}`);
      if (getCustomerDetails.status === 200) {
        const newCustomersAccount = allCustomerAccounts.slice(0);
        const currentCustomerDetailsIndex = newCustomersAccount.findIndex((item) => item.id === updateCustomerDetails.data.data.id);
        newCustomersAccount[currentCustomerDetailsIndex] = updateCustomerDetails.data.data;
        props.setIsLoading(false);
        dispatch({
          type: "UPDATE_PROFILE",
          payload: {
            userDetails,
            allCustomerAccounts: newCustomersAccount,
            customerDetails: getCustomerDetails.data.data,
          }
        })
        message.success(t('content.notification.update'))
      } else message.error(getCustomerDetails);

    } else message.error(updateCustomerDetails);
  }

  useEffect(() => {
    mixPanelTrack('ViewSettingsNotifications');
  }, []);

  return (
    <Layout>
      <Formik initialValues={initialValues} onSubmit={onSubmit}>
        {form => (
          <Form onSubmit={form.handleSubmit}>
            <Card>
              <div
                className="whiteBColor"
                style={{ padding: "10px 20px", minHeight: "auto" }}
              >
                <p className="heading3" style={{ margin: 0 }}>
                  {t('content.notification.title')}
                </p>
              </div>
              <div
                className="whiteBColor"
                style={{ padding: "10px 20px", minHeight: "auto" }}
              >
              <CheckBox
                {...form}
                name="smsNotifications"
                text={t('content.notification.smsNotification')+" ($"+(customerDetails.smsRate)+"/"+t('content.notification.sms')+")*"}
                style={{ marginBottom: 0 }}
                checked={form.values.smsNotifications}
              />
              <CheckBox
                {...form}
                name="emailNotifications"
                text={t('content.notification.emailNotification')+" ($"+(customerDetails.emailRate)+"/"+t('content.notification.email')+")*"}
                style={{ marginBottom: 0 }}
                checked={form.values.emailNotifications}
              />
              <CheckBox
                {...form}
                name="pushNotifications"
                text={t('content.notification.pushNotification')+" ($"+(customerDetails.pushRate)+"/"+t('content.notification.notification')+")*"}
                style={{ marginBottom: 0 }}
                checked={form.values.pushNotifications}
              />
              
              { (companyDetails.code.length < 5 || companyDetails.code.includes("staging") ) ?
              <span>
                <p style={{ marginTop: 20 }}>
                  <strong>WhatsApp {t('content.notification.title')}</strong>
                </p>
                <CheckBox
                  {...form}
                  name="whatsappMeOrderStatus"
                  text={t('content.notification.notifyOrderStatus')+" ($"+(customerDetails.waRate ? customerDetails.waRate:0)+"/"+t('content.notification.notification')+")*"}
                  style={{ marginBottom: 0 }}
                  checked={form.values.whatsappMeOrderStatus}
                />
                <CheckBox
                  {...form}
                  name="whatsappRecipientOrderStatus"
                  text={t('content.notification.notifyReceiverOrderStatus')+" ($"+(customerDetails.waRate ? customerDetails.waRate:0)+"/"+t('content.notification.notification')+")*"}
                  style={{ marginBottom: 0 }}
                  checked={form.values.whatsappRecipientOrderStatus}
                />
              </span>
              :""}

              <br/>

              <p>*{t('content.notification.notice')}</p>

              
              <Divider />
              <CheckBox
                {...form}
                name="newsletter"
                text={t('content.notification.receiveNewsletter')}
                style={{ marginBottom: 0 }}
                checked={form.values.newsletter}
              />
              <CheckBox
                {...form}
                name="notifyMeOrderStatus"
                text={t('content.notification.notifyOrderStatus')}
                style={{ marginBottom: 0 }}
                checked={form.values.notifyMeOrderStatus}
              />
              <CheckBox
                {...form}
                name="notifyRecipientOrderStatus"
                text={t('content.notification.notifyReceiverOrderStatus')}
                style={{ marginBottom: 0 }}
                checked={form.values.notifyRecipientOrderStatus}
              />
              <CheckBox
                {...form}
                name="instabookNotifySender"
                text={t('content.notification.instabookNotifySender')}
                style={{ marginBottom: 0 }}
                checked={form.values.instabookNotifySender}
              />
              <CheckBox
                {...form}
                name="instabookNotifyReceiver"
                text="Notify receiver for Instabook order"
                style={{ marginBottom: 0 }}
                checked={form.values.instabookNotifyReceiver}
              />

              <CheckBox
                {...form}
                name="newInvoice"
                text="Notify me for new unpaid invoice"
                style={{ marginBottom: 0 }}
                checked={form.values.newInvoice}
              />
              <CheckBox
                {...form}
                name="paidInvoice"
                text="Notify me for paid invoices"
                style={{ marginBottom: 0 }}
                checked={form.values.paidInvoice}
              />

              </div>
              
              <Divider />

              <div
                className="whiteBColor"
                style={{ padding: "10px 20px", minHeight: "auto" }}
              >
                <p className="heading3" style={{ margin: 0 }}>
                  {t('content.notification.minBalanceTitle')}
                </p>
                <p style={{ margin: 0 }}>
                  {t('content.notification.minBalanceSubtitle')}
                  <br/> &nbsp; <br/>
                </p>
                <CheckBox
                  {...form}
                  name="minBalanceNotify"
                  text={t('content.notification.minBalanceNotify')}
                  style={{ marginBottom: 0 }}
                  checked={form.values.minBalanceNotify}
                />
                <TextInput
                  {...form}
                  name="minBalanceVal"
                  label={t('content.notification.minBalanceVal')}
                  type="number"
                  min="0"
                  max="100000"
                  style={{ width: "200px" }}
                />
                </div>
            </Card>

            <div style={{ float: "right", marginTop: 20, marginBottom: 20 }}>
              <CustomizedButton
                text={t('content.save')}
                type="primary"
                htmlType="submit"
              />
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  )
}

export default NotificationsSetting;
