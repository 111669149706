import React, { useEffect, useState, useContext, Fragment } from "react";
import { Formik } from "formik";
import { Table, Tag, Tooltip } from "antd";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

import {
  MESSAGE_WEIGHT_REQUIRED,
  MESSAGE_NAME_REQUIRED
} from "Util/ErrorMessage";
import CustomizedButton from "Components/CustomizedButton";
import { GlobalContext } from 'Store/store';
import { IMAGE_URL_ROOT } from 'Util/API';

import QuotationForm from './QuotationForm';

import { displayDurationValue, displayScoreValue } from "Util/CustomFunctions";

const validationSchema = Yup.object().shape({
  origin: Yup.object().shape({}).required(MESSAGE_NAME_REQUIRED),
  destination: Yup.object().shape({}).required(MESSAGE_NAME_REQUIRED),
  weight: Yup.number().min(0.1, 'Min weight is 0.1kg').required(MESSAGE_WEIGHT_REQUIRED),
  itemType: null,
  codValue: 0,
  insuranceValue: 0
})

const LimitText = ({ string, ...props }) => {
  const [maxChar, setMaxChar] = useState(props.maxChar);

  if (string?.length > maxChar) {
    const originalStringArray = string.split('');
    const newStringArray = [];
    for (let i = 0; i < maxChar; i += 1) {
      newStringArray.push(originalStringArray[i]);
    }
    newStringArray.push('...');
    return (
      <div>
        <span dangerouslySetInnerHTML={{ __html: newStringArray.join('') }} />
        <span onClick={() => setMaxChar(string.length)} style={{ color: 'blue', cursor: 'pointer' }}>(more)</span>
      </div>
    )
  } else {
    return <span dangerouslySetInnerHTML={{ __html: string }} />
  }
}

const InstantQuote = ({ setQuotation, history, quotation, initialValues, onSubmit, setIsLoading }) => {
  const [state] = useContext(GlobalContext);
  const { companyDetails } = state.global;
  const { origin, destination, services } = quotation;
  const [cityList, setCityList] = useState([]);
  const { t } = useTranslation('homePage');

  useEffect(() => {
    setIsLoading(!services);
  }, [services])

  const quotationComponentItems = (form) => [
    {
      dataIndex: "service",
      key: "service",
      render: (data, _, index) => {
        return (
          <span>
          <img
            style={{ height: 48, margin: '0 10px' }}
            src={
              data.serviceCompany?.logo ?
                IMAGE_URL_ROOT + '/' + data.serviceCompany.logo :
                companyDetails.logob ?
                  IMAGE_URL_ROOT + companyDetails.logob :
                  'https://cdn.delyva.app/assets/delyvax-logo-b.svg'
              }
            alt="company logo"
          />
          </span>
        )
      }
    },
    {
      dataIndex: "service",
      key: "service",
      render: (data, _, index) => {
        return (
          <span>
            <h4>{data.name}</h4>
            <small><LimitText string={data.custDescription} maxChar={50} /></small>
          </span>
        )
      }
    },
    {
      dataIndex: "duration",
      key: "estimate",
      render: (data, _, index) => {
        const duration = (data > 0) ? data : '';

        return (
          <span>
            { (companyDetails.code.length <= 5) ?
            <div>
            { duration ?
            <span>
            <Tooltip title="The estimate is based on the service performance for the past 30 days">
            <small>Estimate (?)</small><br/>
            <Tag color="blue" >
            {displayDurationValue(duration)}
            </Tag>
            </Tooltip>
            </span>
            :''}
            </div>
            :''}
          </span>
        )
      }
    },
    {/*{
      dataIndex: "score",
      key: "estimate",
      render: (data, _, index) => {
        const score = (data > 0) ? data : '';

        return (
          <span>
            { companyDetails.code.length < 500 ?
            <div>
            {score ?
            <span>
            <Tooltip title="The score is based on recent and overall service performance">
            <small>REPUTATION</small><br/>
            <Tag color="geekblue" >
            {displayScoreValue(score)}
            </Tag>
            </Tooltip>
            </span>
            :''}
            </div>
            :''}
          </span>
        )
      }
    },*/},
    {
      dataIndex: "price",
      key: "price",
      render: (data) => {
        return (
          <span>
            <b><i>{data.currency} {data.amount}</i></b>
          </span>
        )
      }
    },
    {
      key: "action",
      render: (data, _, i) => {
        return (
          <CustomizedButton
            text={t('content.instantQuote.book')}
            type="primary"
            onClick={() => history.push({
              pathname: `${process.env.PUBLIC_URL}/orders/new-order`,
              state: {
                orderDetail: data,
                origin,
                destination,
                services,
                selectedServiceIndex: i,
                weight: Number(form.values.weight),
                codValue: Number(form.values.codValue),
                insuranceValue: Number(form.values.insuranceValue)
              }
            })}
          />
        )
      }
    }
  ]

  return (
    <div style={{ paddingTop: 30, width: '100%' }}>
      <p className="heading3">{t('content.instantQuote.title')}</p>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
          {form => {
            return (
              <Fragment>
                <div className="whiteBColor ui-form-iq" style={{ width: '100%' }}>
                  <QuotationForm
                    form={form}
                    cityList={cityList}
                    initialValues={initialValues}
                    setQuotation={setQuotation}
                    defaultCitySuggestion={{
                      origin: [{ ...initialValues.origin.city }],
                      destination: [{ ...initialValues.destination.city }]
                    }}
                    defaultAddressSuggestion={{
                      origin: [{ ...initialValues.origin }],
                      destination: [{ ...initialValues.destination }]
                    }}
                  />
                  </div>
                  {
                    services?.length > 0 &&
                    <div style={{ background: "white" }}>
                      <Table
                        showHeader={false}
                        columns={quotationComponentItems(form)}
                        pagination={{ pageSize: 50 }}
                        style={{ maxHeight: "60%", margin: "30px 0" }}
                        dataSource={services}
                      />
                    </div>
                  }
              </Fragment>
            )
          }}
        </Formik>

    </div>
  )
}

export default InstantQuote;
