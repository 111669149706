import { useEffect, useState, useRef } from 'react';
import { message } from 'antd';

import { get } from 'Util/API';

const useGetOrderStatusFallback = (currentOrder, msg) => {
  const [orderStatusRes, setOrderStatusRes] = useState(msg);
  let __timeout = useRef();
  const isCurrentOrderCreated = orderStatusRes?.orderId === currentOrder.orderId && orderStatusRes?.event === 'order.created';

  useEffect(() => {
    setOrderStatusRes(msg)
  }, [msg])

  useEffect(() => {
    if (currentOrder.orderId) {
      __timeout.current = setTimeout(() => {
        const getOrderStatus = async () => {
          const fetch = await get(`/order/${currentOrder.orderId}`);
          if (fetch.status === 200) {
            const data = fetch.data.data;
            data.orderId = data.id;
            setOrderStatusRes(data)
          } else message.error(fetch);
        }
        getOrderStatus();
      }, 3000);
    }
  }, [currentOrder.orderId])

  useEffect(() => {
    if (isCurrentOrderCreated) {
      clearTimeout(__timeout.current);
    }
  }, [orderStatusRes])

  return orderStatusRes;
}

export default useGetOrderStatusFallback;
