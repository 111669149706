import React, { useState } from 'react';
import { Input, Spin, message } from "antd";
import { CopyToClipboard } from 'react-copy-to-clipboard';

import useWindowSize from 'Util/useWindowSize';
import { post } from 'Util/API';
import './styles.css';

import CustomizedButton from "Components/CustomizedButton";
import { useTranslation } from "react-i18next";

const AddNewApi = (props) => {
  const { resetModalState, setDataSource } = props;
  const [keyName, setKeyName] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const size = useWindowSize();
  const { t } = useTranslation('settingsPage');

  const onCreate = async () => {
    setIsLoading(true);
    const createToken = await post('/auth/personalAccessToken', { name: keyName });
    if (createToken.status === 200) {
      setTokenData(createToken.data.data);
    } else message.error(createToken);
    setIsLoading(false);
  }

  return (
    <div>
      <Spin tip="Loading..." spinning={isLoading}>
        {
          tokenData ?
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <i class="fas fa-key" style={{ fontSize: 30, margin: '20px 0' }} />
            <h3>{t('content.integration.createAPI')}</h3>
            <div>{t('content.integration.createMessage')}</div>
            <div style={{ color: 'red', textAlign: 'center' }}>{t('content.integration.createWarn')}</div>
            <div style={{ display: size[0] > 488 ? 'flex' : 'block', alignItems: 'center', backgroundColor: '#F5F5F5', padding: 10, width: '100%', justifyContent: 'space-between', margin: 20 }}>
              <span>{tokenData.token}</span>
              <CopyToClipboard text={tokenData.token}>
                <button style={{ borderWidth: '1px 0', borderRadius: 0 }} className="copyButton" onClick={() => setCopySuccess(true)}>
                  {copySuccess ? t('content.integration.copied') : t('content.integration.copy')}
                </button>
              </CopyToClipboard>
            </div>
            <CustomizedButton
              style={{ margin: '20px 0' }}
              type="primary"
              isDisabled={!copySuccess}
              text={t('content.integration.done')}
              onClick={() => {
                setDataSource(null);
                resetModalState();
              }}
            />
          </div> :
          <div>
            <Input
              style={{ marginBottom: 20 }}
              type="text"
              placeholder={t('content.integration.apiKeyName')}
              value={keyName}
              onChange={val => setKeyName(val.target.value)}
            />
            <div style={{ display: 'flex', margin: -5, justifyContent: 'flex-end' }}>
              <CustomizedButton
                text={t('content.cancel')}
                onClick={() => { resetModalState(); }}
                style={{ margin: 5 }}
              />
              <CustomizedButton
                type="primary"
                text={t('content.integration.createView')}
                onClick={onCreate}
                style={{ margin: 5 }}
              />
            </div>
          </div>
        }
      </Spin>
    </div>
  )
}

export default AddNewApi;
