import React, { Fragment, useState } from "react";
import { Button, message, Row, Col, Dropdown, Menu, Divider, Popconfirm, Select, Input, InputNumber, Tooltip, Tag } from "antd";

import { LabelledCheckbox } from 'Components/optimized-input'
import { Link, Redirect } from "react-router-dom";
import moment from "moment";

import { capitalizeFirstLetter, getServiceCompany, roundCurrencyValue } from "Util/CustomFunctions";
import { post, update, get, IMAGE_URL_ROOT } from 'Util/API';
import LimitText from 'Components/LimitText';
import generateAddressByKeys from 'Util/generateAddressByKeys';

import { orderStatusColor2 } from 'Util/orderStatusColor';
import { sourceIcon } from 'Util/sourceIcon';

import { onOpenCancelOrderModal, onOpenConfirmOrderModal } from 'Pages/Order/ordersHelper';

import useLocalStorage from 'Util/useLocalStorage';

const Option = Select.Option;

const ordersTableColumns = (props) => {
  const { store, selectedRowsIds, resetState, transl, setIsLoading, history, itemTypes, ordersTableData, filterAndTableState } = props;
  const { modalState, setModalState, customerDetails, serviceCompanies } = store.global;

  const updateOrder = async (id, dataToSubmit) => {
      const updateOrderRes = await update('order/'+id, dataToSubmit);
      if (updateOrderRes.status === 200) {
          const orderRec = updateOrderRes.data.data;

          for(let i=0; i<filterAndTableState.data.length; i++)
          {
              if(orderRec.id == filterAndTableState.data[i].id)
              {
                  filterAndTableState.data[i].price = orderRec.price;
              }
          }

          resetState({ ...ordersTableData, data: filterAndTableState.data, setToPage: filterAndTableState.page })
      } else message.error(updateOrderRes);
  };

  const confirmSubmit = async (id, serviceCode) => {
      setIsLoading(true);
      const dataToSubmit = {
        customerId: customerDetails.id,
        process: true,
        orderIds: [id],
        serviceCode
      }

      const createOrder = await post('order/process', dataToSubmit);

      if (createOrder.status === 200) {
        window.open(`${process.env.PUBLIC_URL}/order/`+createOrder.data.data.orderId+``);

        resetState({ ...ordersTableData, data: null, setToPage: filterAndTableState.page })

        setIsLoading(false);

      } else message.error(createOrder)
  }

  const retrySubmit = async (id, serviceCode) => {
      setIsLoading(true);
      const dataToSubmit = {
        customerId: customerDetails.id,
        process: true,
        orderIds: [id],
        serviceCode
      }

      const createOrder = await post('order/process', dataToSubmit);

      if (createOrder.status === 200) {
        window.open(`${process.env.PUBLIC_URL}/order/`+createOrder.data.data.orderId+``);

        resetState({ ...ordersTableData, data: null, setToPage: filterAndTableState.page })

        setIsLoading(false);

      } else message.error(createOrder)
  }

  const refreshQuote = async (id) => {
      const refreshServicesRes = await get('order/'+id+'/refreshQuote');

      if (refreshServicesRes.status === 200) {
        resetState({ ...ordersTableData, data: null, setToPage: filterAndTableState.page })
      } else message.error(refreshServicesRes)
  }

  const cloneSubmit = async (id, serviceCode) => {
    setIsLoading(true);
    const dataToSubmit = {
      // scheduledAt,
      serviceCode,
      process: true
    }

    const cloneOrder = await post('order/'+id+'/clone', dataToSubmit);
    if (cloneOrder.status === 200) {
        resetState({ ...ordersTableData, data: null, setToPage: filterAndTableState.page })
        const res = cloneOrder.data.data;
        history.push({ pathname: `${process.env.PUBLIC_URL}/order/`+res.orderId+`` });
        window.location.reload(false);
    } else message.error(cloneOrder)
  }

  return [
    {
      title: transl('content.orderTableColumn.orderNo'),
      width: 120,
      dataIndex: "createdAt",
      key: "createdAt",
      render: (createdAt, data) => {
          return (
              <span>
              <Link
                style={{ width: 120 }}
                to={{
                  pathname: `${process.env.PUBLIC_URL}/order/${data.id}`,
                  state: { from: `${process.env.PUBLIC_URL}/orders/bulk-orders` }
                }}
              >
              {data.nanoId}
              </Link>
              <br/>
              <small>{moment(createdAt).fromNow()}</small><br/>
              {data.referenceNo}
              </span>
          );
      }
    },
    {
      title: transl('content.orderTableColumn.recipient'),
      dataIndex: "waypoint",
      key: "recipient",
      width: 120,
      render: (data, record) => {
        const selectedWaypoint = data.filter((item) => item.type === 'DROPOFF');
        if (selectedWaypoint.length > 0) {
          const { contact } = selectedWaypoint[0];
          const address = {
            unitNo: contact.unitNo,
            address1: contact.address1,
            address2: contact.address2,
            postcode: contact.postcode,
            city: contact.city,
            country: contact.country
          }
          const addressArr = [];
          Object.values(address).forEach((item, i) => {
            item && typeof item === 'string' && item.length > 0 && item !== '-' && addressArr.push(item);
          });
          const addressStr = generateAddressByKeys(['name','phone,','unitNo', 'address1', 'address2', 'postcode', 'city', 'state', 'country'], contact);
          return (
            <div>
                <Tooltip title={addressStr}>
                <div>
                  <Link
                    style={{ width: 120 }}
                    to={{
                      pathname: `${process.env.PUBLIC_URL}/order/${record.id}`,
                      state: { from: `${process.env.PUBLIC_URL}/orders/bulk-orders` }
                    }}
                  >
                  <b>{contact.name}</b><br/>
                  </Link>
                  <small>
                  <a href={"tel:"+contact.phone}>+{contact.phone}</a><br/>
                  {contact.city} {contact.country}
                  </small>
                </div>
                </Tooltip>
            </div>
          )
        } else return null;
      }
    },
    {
      title: transl('content.orderTableColumn.note'),
      dataIndex: "note",
      key: "note",
      width: 120,
      render: (note, record) =>
      (
          <div>
          <LimitText string={note} maxChar={30} />
          </div>
      )
    },
    {
      title: transl('content.orderTableColumn.package'),
      dataIndex: "itemType",
      key: "itemType",
      width: 120,
      render: (text, record) => {
        let optionItems = [];

        if(itemTypes)
        {
            optionItems = itemTypes.map((itemType, index) => (
              <Option value={itemType.name}>{itemType.name}</Option>
            ));
        }

        function handleChangeItemType(value) {
            record.itemType = value;

            const dataToSubmit = {
              itemType: record.itemType
            }
            updateOrder(record.id, dataToSubmit);
        }

        let wpWeight = record.weight ? record.weight.value : 0;

        function handleChangeWeight(value) {
            wpWeight = value;

            const dataToSubmit = {
               weight: {
                 'value': wpWeight,
                 'unit': record.weight.unit
               }
            }

            if(wpWeight > 0)
            {
              record.weight.value = wpWeight;
              updateOrder(record.id, dataToSubmit);
            }
        }

        return (
            <div>
              <Select defaultValue={record.itemType} style={{ width: 100 }} onChange={handleChangeItemType} size="small">
                  {optionItems}
              </Select>
              <br/>
              <InputNumber defaultValue={wpWeight} min={0.1} max={10000} step={0.1} onChange={handleChangeWeight}
                size="small" style={{ width: 100 }} formatter={value => `${value}kg`}
                parser={value => value.replace('kg', '')}
                />
            </div>
        );
      }
    },
    {
      title: transl('content.orderTableColumn.courier'),
      dataIndex: "serviceCode",
      key: "serviceCode",
      width: 180,
      render: (text, record) => {

        let theServiceCode = '';
        let theServiceName = '';
        let theServiceCompany = {};
        let theServiceLogo = '';

        let optionItems = [];

        if(record.quotes)
        {
            if(record.quotes.services)
            {
                optionItems = record.quotes.services.map((service, index) => (
                  <Option value={service.code}>{service.name}</Option>
                ));

                for (var x = 0; x < record.quotes.services.length; x++)
                {
                    if(record.serviceCode == record.quotes.services[x].code 
                        || record.serviceCode == record.quotes.services[x].serviceCompanyCode)
                    {
                          theServiceCode = record.quotes.services[x].code;
                          theServiceName = record.quotes.services[x].name;
                          theServiceCompany = getServiceCompany(record.quotes.services[x].serviceCompanyCode, serviceCompanies)
                          if(theServiceCompany)
                          {
                              theServiceLogo = theServiceCompany.logo;
                          }
                    }
                }
            }
        }

        function handleChange(value) {
            record.serviceCode = value;

            useLocalStorage.setItem("myServiceCode", record.serviceCode);

            const dataToSubmit = {
              serviceCode: record.serviceCode,
              // serviceAddon: [],
              // cod: { currency: record.price.currency, amount: 0 },
              // insurance: { currency: record.price.currency, amount: 0 }
            }

            updateOrder(record.id, dataToSubmit);
        }

        return (
            <div>
              { (optionItems.length > 0) ?
                <div>
                <Select defaultValue={theServiceCode} style={{ width: 175 }} onChange={handleChange}  size="small">
                  {optionItems}
                </Select>
                {theServiceLogo ?
                  <span>
                  <img src={IMAGE_URL_ROOT + '/' + theServiceLogo} style={{ height: 48, marginTop: 5, paddingLeft:25, textAlign:'center' }} />
                  </span>
                  :''
                }
                </div>
              : <span style={{ margin: 0, fontSize: 12, color: 'blue', textAlign: 'center' }}>Reload this page OR no service available. </span>
              }
            </div>
        );
      }
    },
    {
      title: transl('content.orderTableColumn.addon'),
      key: "addon",
      width: 180,
      render: (record) => {
        let quoteAddons = [];
        record.serviceAddon = record.serviceAddon ? record.serviceAddon : [];

        let isInsurance = false;
        let isCod = false;

        if(record.quotes)
        {
            if(record.quotes.services)
            {
                for(let i=0; i<record.quotes.services.length; i++)
                {
                    if(record.serviceCode == record.quotes.services[i].code
                        || record.serviceCode == record.quotes.services[i].serviceCompanyCode)
                    {
                        quoteAddons = record.quotes.services[i].addon;

                        for(let x=0; x<quoteAddons.length; x++)
                        {
                            quoteAddons[x].value = 0;
                            quoteAddons[x].qty = 1;

                            for(let y=0; y< record.serviceAddon.length; y++)
                            {
                                if(quoteAddons[x].id == record.serviceAddon[y].id)
                                {
                                    quoteAddons[x].value = record.serviceAddon[y].value;
                                }
                            }

                            if(quoteAddons[x].id == -1)
                            {
                                if(record.cod)
                                {
                                    if(record.cod.amount)
                                    {
                                        quoteAddons[x].value = record.cod.amount;
                                        isCod = true;
                                    }
                                }
                            }

                            if(quoteAddons[x].id == -3)
                            {
                                if(record.insurance)
                                {
                                    if(record.insurance.amount)
                                    {
                                        quoteAddons[x].value = record.insurance.amount;
                                        isInsurance = true;
                                    }
                                }
                            }
                        }
                        //
                    }
                }
            }
        }

        if(!isInsurance)
        {
            record.insurance = {
                currency: record.price.currency,
                amount: 0
            };
        }

        if(!isCod)
        {
            record.cod = {
                currency: record.price.currency,
                amount: 0
            };
        }

        function setRecordAddons(itemId, key, name, value) {
            let formAddon = [];

            Object.keys(quoteAddons).forEach((key) => {
              const addonCopy = { qty: 1, ...quoteAddons[key] };
              formAddon.push(addonCopy)
            })

            //assign values
            for(let i=0; i< formAddon.length; i++)
            {
                if(formAddon[i].id == itemId)
                {
                    if(key == 'qty')
                    {
                        formAddon[i] = {
                            'id': itemId,
                            'name': name,
                            'qty': value,
                            'value': 1,
                        }
                    }else {
                        formAddon[i] = {
                            'id': itemId,
                            'name': name,
                            'qty': 1,
                            'value': value
                        }
                    }

                    if(formAddon[i].id == -1)
                    {
                        record.cod = {
                            currency: record.price.currency,
                            amount: formAddon[i].value ? formAddon[i].value : 0
                        };
                    }

                    if(formAddon[i].id == -3)
                    {
                        record.insurance = {
                            currency: record.price.currency,
                            amount: formAddon[i].value ? formAddon[i].value : 0
                        };
                    }
                }
            }

            //cleanup
            let z = formAddon.length;
            while (z--)
            {
                if(formAddon[z].value === null || formAddon[z].value === 0 || formAddon[z].value === "0" || formAddon[z].value === "" || formAddon[z].value === ''  )
                {
                    formAddon.splice(z, 1);
                }
            }

            const dataToSubmit = {
              serviceAddon: formAddon,
              cod: record.cod,
              insurance: record.insurance
            }

            updateOrder(record.id, dataToSubmit);
        }

        return (
            <div>
              {
                quoteAddons?.map((item) => (
                  <Fragment>
                    <small>{item.name}</small>
                    {
                      item.required?.map((requiredItem) => (
                        <Input placeholder={requiredItem.name}
                          defaultValue={item.value}
                          size="small" style={{ width: 150 }}
                          onChange={(e) =>
                            {
                                setRecordAddons(item.id, requiredItem.key, item.name, roundCurrencyValue(record.price.currency,e.target.value) );
                            }
                          }  allowClear={true} />
                      ))
                    }
                  </Fragment>
                ))
              }
            </div>
        );
      }
    },
    {
      title: transl('content.orderTableColumn.price'),
      dataIndex: "price",
      key: "price",
      width: 100,
      render: (text, record) => {

        let thePriceCurrency = record.price ? record.price.currency : '';
        let thePriceAmount = record.price ? record.price.amount : '';
        let theServiceName = '';

        if(record.quotes)
        {
            if(record.quotes.services)
            {
                for(let i=0; i<record.quotes.services.length; i++)
                {
                    if(record.serviceCode == record.quotes.services[i].code
                      || record.serviceCode == record.quotes.services[i].serviceCompanyCode)
                    {
                        theServiceName = record.quotes.services[i].name;
                        thePriceCurrency = (record.price && record.price.currency) ? record.price.currency: record.quotes.services[i].price.currency;
                        thePriceAmount = (record.price && record.price.amount >0) ? record.price.amount : record.quotes.services[i].price.amount;
                    }
                }
            }
        }

        //for newly created order, if somehow price is 0, but weight > 0, then try update weight to update price
        // if(thePriceAmount == 0 && record.weight.value == 0)
        // {
        //     let wpWeight = 0;
        //     if(record.waypoint.length > 0)
        //     {
        //         for(let i=0; i<record.waypoint[0].inventory.length; i++)
        //         {
        //             if(record.waypoint[0].inventory[i].weight)
        //             {
        //                 if(record.waypoint[0].inventory[i].weight.value > 0
        //                   && record.waypoint[0].inventory[i].quantity > 0)
        //                 {
        //                     wpWeight = (record.waypoint[0].inventory[i].quantity * record.waypoint[0].inventory[i].weight.value);
        //                 }
        //             }
        //         }
        //     }
        //
        //     const dataToSubmit = {
        //        weight: {
        //          'value': wpWeight,
        //          'unit': record.weight.unit
        //        }
        //     }
        //     updateOrder(record.id, dataToSubmit);
        // }

        return (
            <div>
            <Tooltip title={theServiceName}>
              {thePriceCurrency}<br/>
              <h2>{thePriceAmount ? thePriceAmount.toFixed(2):0.00}</h2>
            </Tooltip>
            </div>
        );
      }
    },
    {
      title: transl('content.orderTableColumn.sender'),
      dataIndex: "waypoint",
      key: "sender",
      width: 120,
      render: (data) => {
        const selectedWaypoint = data.filter((item) => item.type === 'PICKUP');
        if (selectedWaypoint.length > 0) {
          const { contact } = selectedWaypoint[0];
          const address = {
            unitNo: contact.unitNo,
            address1: contact.address1,
            address2: contact.address2,
            postcode: contact.postcode,
            city: contact.city,
            country: contact.country
          }
          const addressArr = [];
          Object.values(address).forEach((item, i) => {
            item && typeof item === 'string' && item.length > 0 && item !== '-' && addressArr.push(item);
          });
          const addressStr = generateAddressByKeys(['name','phone','unitNo', 'address1', 'address2', 'postcode', 'city', 'state', 'country'], contact);
          return (
            <div>
            <Tooltip title={addressStr}>
            <b>{contact.name}</b><br/>
            <small>
            <a href={"tel:"+contact.phone}>+{contact.phone}</a><br/>
            {contact.city} {contact.country}
            </small>
            </Tooltip>
            </div>
          )
        } else return null;
      }
    },
    {
      title: transl('content.orderTableColumn.status'),
      dataIndex: "statusCode",
      key: "status",
      width: 120,
      render: (text, record) => {
        return (
          <div>
            <Tag color={orderStatusColor2(text).color}>
            <span>{capitalizeFirstLetter(orderStatusColor2(text).text)}</span>
            </Tag>
            { (record.status !== 'processing' && record.failedReason != '' && record.failedReason != null) ?
            <span>
            <small className="heading3" style={{ margin: 0, fontSize: 10, color: 'red' }}>
              Failed reason
            </small>
            <small style={{ margin: 0, fontSize: 10 }}>
              {record.failedReason}
            </small>
            </span>
            : ''}
          </div>
        );
      }
    },
    /*
    {
      title: transl('content.orderTableColumn.channel'),
      dataIndex: "source",
      key: "source",
      width: 120,
      render: (text, record) => {
        return (
          <div>
            <small style={{ margin: 0, fontSize: 8 }}>
              {record.source}
            </small>
          </div>
        );
      }
    },*/
    {
      title: transl('content.orderTableColumn.action'),
      key: "actions",
      fixed: "right",
      width: 180,
      render: (text, record) => {

          const allowPlaceMultipointOrder = record.source === 'web-customer-multipoint' || record.source === 'android-mover' || record.source === 'ios-mover' || record.waypoint.length > 2;

          const menu = (
            <Menu>
              <Menu.Item>
                  <Link
                    to={{
                      pathname: `${process.env.PUBLIC_URL}/order/${record.id}`,
                      state: { from: `${process.env.PUBLIC_URL}/orders/bulk-orders` }
                    }}
                  >
                  View order
                  </Link>
              </Menu.Item>
              <Menu.Item>
                <a onClick={() => {
                    history.push({
                      pathname: `${process.env.PUBLIC_URL}/orders/edit/${record.id}`,
                      state: {
                        orderDetails: record
                      }
                    })
                  }} >
                  Edit order
                </a>
              </Menu.Item>
              <Menu.Item>
                <a onClick={() => {
                    if (allowPlaceMultipointOrder) {
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/orders/multipoint`,
                        state: {
                          orderDetails: record
                        }
                      })
                    }else {
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/orders/new-order`,
                        state: {
                          orderDetails: record
                        }
                      })
                    }
                  }} >
                  Place order again
                </a>
              </Menu.Item>
              {/** record.serviceCode ?
              <Menu.Item>
                <a onClick={() => cloneSubmit(record.id, record.serviceCode)}>
                  Clone order
                </a>
              </Menu.Item>
              : ''**/}
              <Menu.Item>
                <a onClick={() => {
                    if (allowPlaceMultipointOrder) {
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/orders/multipoint`,
                        state: {
                          orderDetails: record,
                          returnOrder: true
                        }
                      })
                    }else {
                      history.push({
                        pathname: `${process.env.PUBLIC_URL}/orders/new-order`,
                        state: {
                          orderDetails: record,
                          returnOrder: true
                        }
                      })
                    }
                  }} >
                  Create return order
                </a>
              </Menu.Item>
              { (record.serviceCode && (record.statusCode == 10 || record.statusCode == 11 || record.statusCode == 99) ) ?
              <Menu.Item>
                <a onClick={() => retrySubmit(record.id, record.serviceCode)} >
                  Retry order
                </a>
              </Menu.Item>
              : ''}
              <Menu.Item>
                <a onClick={() => refreshQuote(record.id) } >
                  Refresh quote
                </a>
              </Menu.Item>
              <Menu.Item>
                <a onClick={() => onOpenCancelOrderModal({
                    setIsLoading,
                    setModalState,
                    selectedRowsIds: [record.id],
                    resetState,
                    ordersTableData,
                    modalState,
                    history,
                    transl
                })} >
                  Cancel order
                </a>
              </Menu.Item>
            </Menu>
        );

        return (
            <Row gutter={2} type="flex">
            <Col>
              <Button
                type="primary"
                onClick={() => onOpenConfirmOrderModal({
                   id: record.id,
                   selectedRowsIds,
                   setModalState,
                   modalState,
                   resetTableState: resetState,
                   history,
                   transl,
                   setIsLoading
                })}
              >
                {transl('content.bulkOrder.confirmOrder')}
              </Button>
            </Col>
            <Col>
                <Dropdown overlay={menu} placement="bottomRight" arrow>
                  <Button>
                  <i className="fas fa-angle-down" />
                  </Button>
                </Dropdown>
            </Col>
            </Row>
        );
      }
    }
  ]
};

export default ordersTableColumns;
