import React from "react";
import { Form, Input } from "antd";

const { TextArea } = Input;

const CustomizedTextarea = ({
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  name,
  placeholder,
  prefix,
  label,
  max,
  onChange,
  onBlur,
  disabled,
  className,
  autoSize,
  maxLength,
  rows,
  style
}) => (
  <Form.Item
    validateStatus={disabled ? null : errors[name] && "error"}
    help={disabled ? null : errors[name]}
    label={label}
    className={className}
    style={style}
  >
    <TextArea
      disabled={disabled}
      prefix={prefix}
      placeholder={placeholder}
      value={values[name]}
      maxLength={maxLength}
      rows={rows}
      onChange={
        onChange
          ? onChange
          : event => {
              if (event.target.value.length === max + 1) {
                return;
              }
              setFieldValue(name, event.target.value);
            }
      }
      onBlur={onBlur ? onBlur : () => setFieldTouched(name)}
      autoSize={autoSize}
    />
  </Form.Item>
);

export default CustomizedTextarea;
