import Axios from "axios";
import mapboxgl from "mapbox-gl";
import { message } from "antd";

import { mapsAccessToken } from "Util/Constant";

const getRoute = async (mapData, str) => {
  const response = await Axios.get(
    `https://api.mapbox.com/directions/v5/mapbox/driving/${str}?alternatives=true&geometries=geojson&steps=true&access_token=${mapsAccessToken}`
  );

  const geoData = {
    type: "FeatureCollection",
    features: [
      {
        type: "Feature",
        properties: {
          color: "#33C9EB" // blue
        },
        geometry: {
          type: "LineString",
          coordinates: response.data?.routes[0]?.geometry.coordinates
        }
      }
    ]
  };

  const coordinates = geoData.features[0].geometry.coordinates;
  const bounds = coordinates?.reduce(function (bounds, coord) {
    return bounds.extend(coord);
  }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

  mapData.fitBounds(bounds, {
    padding: 200
  });

  if (response.status === 200) {
    if (mapData.getSource("route")) {
      mapData.removeLayer("route");
      mapData.getSource("route").setData(geoData);
      mapData.addLayer({
        id: "route",
        type: "line",
        source: "route",
        paint: {
          "line-color": "#33C9EB",
          "line-opacity": 0.75,
          "line-width": 5
        }
      });
    } else {
      mapData.addSource("route", {
        type: "geojson",
        data: geoData
      });
      mapData.addLayer({
        id: "route",
        type: "line",
        source: "route",
        paint: {
          "line-color": "#33C9EB",
          "line-opacity": 0.75,
          "line-width": 5
        }
      });
    }
  } else message.error('error')
};

export default getRoute;
