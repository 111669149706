import React, { useState, useEffect, useContext } from "react";
import { Steps } from "antd";

import { GlobalContext } from "Store/store";
import TopupAmountStep from "./TopupSteps/TopupAmountStep";
import PaymentMethodStep from "./TopupSteps/PaymentMethod/PaymentMethodStep";
import ConfirmationStep from "./TopupSteps/ConfirmationStep";
import TopupSuccessfulStep from "./TopupSteps/TopupSuccessfulStep";
import MyTopupAmountStep from './TopupSteps/my-topup-amount-step/MyTopupAmountStep';

const { Step } = Steps;

const TopupTab = (props) => {
  const { transl, topupPlan } = props;
  const [currentStep, setCurrentStep] = useState(props.currentStep);
  const [selectedTopupAmount, setSelectedTopupAmount] = useState(null);
  const [paymentInfo, setPaymentInfo] = useState(null);
  const [store] = useContext(GlobalContext);
  const { companyDetails } = store.global

  const submitAmount = async values => {
    setSelectedTopupAmount(values.amount)
    setCurrentStep(currentStep + 1);
  };

  const selectPaymentMethod = (val) => {
    setPaymentInfo(val);
    setCurrentStep(currentStep + 1);
  }

  const selectTopupAmount = (val) => {
    setSelectedTopupAmount(val);
    setPaymentInfo(null);
    setCurrentStep(currentStep + 1);
  }

  const clickPrevious = () => {
    setCurrentStep(currentStep - 1);
  };

  const clickNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const clickBackHome = () => {
    setCurrentStep(currentStep - 3);
  };

  const clickReferFriend = () => {
      window.location = `${process.env.PUBLIC_URL}/refer-earn/`;
  };

  return (
    <div
      // className="ui-right-content ui-topup-r"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
    >
      <Steps
        onChange={(val) => setCurrentStep(val)}
        progressDot
        current={currentStep}
        style={{ marginBottom: 50, paddingLeft: 30, paddingRight: 30, maxWidth: 900, marginTop: 30 }}
      >
        <Step title={transl('content.topupTab.topupAmount')} />
        <Step title={transl('content.topupTab.paymentMethod')} disabled={!paymentInfo} />
        <Step title={transl('content.topupTab.confirmation')} disabled={!paymentInfo} />
        <Step disabled title={transl('content.topupTab.paymentStatus')} />
      </Steps>
      {
        {
          0: (
            companyDetails.code === 'my' || companyDetails.code === 'ph' || companyDetails.code === 'id' || companyDetails.code === 'sg' ||
            companyDetails.code === 'myhub' || companyDetails.code === 'phhub' || companyDetails.code === 'idhub' || companyDetails.code === 'sghub'  ?
            <MyTopupAmountStep
              selectTopupAmount={selectTopupAmount}
              isLoading={props.isLoading}
              topupPrice={props.topupPrice}
              submitAmount={submitAmount}
              clickNext={clickNext}
              topupPlan={topupPlan}
            /> :
            <TopupAmountStep
              selectTopupAmount={selectTopupAmount}
              isLoading={props.isLoading}
              topupPrice={props.topupPrice}
              submitAmount={submitAmount}
              clickNext={clickNext}
              topupPlan={topupPlan}
            />
          ),
          1: (
            <PaymentMethodStep
              selectPaymentMethod={selectPaymentMethod}
              clickNext={clickNext}
              clickPrevious={clickPrevious}
              paymentMethod={props.paymentMethod}
              selectedTopupAmount={selectedTopupAmount}
            />
          ),
          2: (
            <ConfirmationStep
              setLoading={props.setLoading}
              resetStep={() => this.setState({ currentStep: 0 })}
              paymentInfo={paymentInfo}
              clickNext={clickNext}
              clickPrevious={clickPrevious}
              selectedTopupAmount={selectedTopupAmount}
            />
          ),
          3: <TopupSuccessfulStep clickBackHome={clickBackHome} invoiceId={props.invoiceId} onRefreshAmount={props.onRefreshAmount} clickReferFriend={clickReferFriend} />
        }[currentStep]
      }
    </div>
  )
}

export default TopupTab;
