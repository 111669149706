import React, { createContext, useReducer } from "react";
import rootReducer from './reducer/rootReducer';

export const initialState = {
  global: {
    companyDetails: null,
    getStartedData: null,
    availableServiceCompany: null,
    multipointServices: null,
    userDetails: null,
    lang: 'en_US',
    antdLangFile: null,
    countryList: null,
    services: null,
    itemTypes: null,
    outlets: null,
    paymentMethods: null,
    allCustomerAccounts: null,
    customerDetails: null,
    customerId: null,
    topupPlan: null,
    isLoading: true,
    authCheck: false,
    isError: false,
    showError: false,
    error: {},
    message: null
  },
  orderForm: {
    new: false
  },
  sse: {
    message: null,
    isListen: false
  }
};

const GlobalContext = createContext();
const Store = ({ children }) => {
  const [state, dispatch] = useReducer(rootReducer, initialState);
  return (
    <GlobalContext.Provider value={[state, dispatch]}>
      {children}
    </GlobalContext.Provider>
  )
};

export { GlobalContext };
export default Store;
