import React from 'react';
import { Form, DatePicker } from 'antd';

import {
  TEXT_SELECT_DATE
} from "Util/Text";

const LabelledDatePicker = React.memo(({
  dateFormat,
  name,
  label,
  id,
  disabled,
  error,
  className,
  style,
  defaultValue,
  onChange,
  required,
  labelStyle,
  disabledDate
}) => {
  // console.log('LabelledDatePicker');
  return (
    <Form.Item
      validateStatus={disabled ? null : error && "error"}
      help={disabled ? null : error}
      label={label}
      className={className}
      style={labelStyle}
      noStyle
      required={required}
    >
      <DatePicker
        disabled={disabled}
        disabledDate={disabledDate}
        id={id}
        placeholder={TEXT_SELECT_DATE}
        defaultValue={defaultValue}
        onChange={onChange}
        format={dateFormat}
        style={{ width: '100%', ...style }}
      />
    </Form.Item>
  )
})

export default LabelledDatePicker;
