import React from "react";
import { useTranslation } from "react-i18next";

import { Table, List } from "antd";

const Addons = (props) => {
  const { t } = useTranslation('orderDetailsPage');

  const column = [
    {
      title: t('addons.tableColumn.name'),
      dataIndex: "name",
      key: "name"
    },
    {
      title: t('addons.tableColumn.quantity'),
      dataIndex: "qty",
      key: "quantity"
    },
    {
      title: t('addons.tableColumn.value'),
      dataIndex: "value",
      key: "value"
    }
  ];

  return (
    <List
      bordered
      style={{ backgroundColor: "white", marginBottom: "20px" }}
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="heading3" style={{ margin: 0 }}>
            {t('addons.title')}
          </p>
        </div>
      }
    >
      <Table
        loading={props.isLoading}
        columns={column}
        dataSource={props.selectedOrder.serviceAddon}
      />
    </List>
  )
}

export default Addons;
