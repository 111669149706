import React, { Component } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Upload, message, Button, Icon, Layout, Alert } from "antd";

import withStore from 'Util/withStore';
import { API_ROOT, renewToken } from 'Util/API';
import useLocalStorage from 'Util/useLocalStorage';
import PageWrapper from 'Components/PageWrapper';

import { Row, Col } from "antd";

import { mixPanelTrack } from "Util/CustomFunctions";

const { Content } = Layout;

class ImportOrder extends Component {
  state = {
    isLoading: true
  };

  componentDidMount() {
    this.setState({
      isLoading: false
    });
    mixPanelTrack('ViewBulkImport');
  }

  onChangeUpload = (info) => {
    if (info.file.status !== "uploading") {
      // console.log(info.file, info.fileList);
      this.setState({
        isLoading: true
      });
    }
    if (info.file.status === "done") {
      // message.success(`${info.file.name} file uploaded successfully`);
      this.setState({
        isLoading: true
      });

      if(info.file)
      {
          if(info.file.response)
          {
                const noOfOrders = info.file.response.length;
                const wait = noOfOrders;

                this.sleep(wait*500).then(r => {
                    this.setState({
                      isLoading: false
                    });
                    // do something
                    this.props.history.push({
                      pathname: `${process.env.PUBLIC_URL}/orders/bulk-orders`,
                      state: {
                        showMsg: {
                          type: 'success',
                          message: 'Your order has been uploaded.'
                        }
                      }
                    })
                });
          }
      }
    } else if (info.file.status === "error") {
      message.error(`${info.file.name} file upload failed. ${info.file.response?.error.message}`, 5);
    }
  }

  uploadProps = {
    name: "file",
    action: API_ROOT + 'order/import',
    data: {
      customerId: this.props.state.global.customerId
    },
    headers: {
      Authorization: `Bearer ` + useLocalStorage.getItem("accessToken"),
      // "Content-Type": "multipart/form-data"
    }
  };

  beforeUpload = async () => {
    const fetch = await renewToken();
    fetch.status !== 200 && message.error(fetch);
  }

  sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
  }

  render() {
    const { transl } = this.props;

    const templeteLink = (
      <a className="button-template" target="__blank" href={`${API_ROOT}/order/bulk_order_template.xlsx`}>
        {transl('instruction.1.template')}
      </a>
    );

    return (
      <PageWrapper loading={this.state.isLoading} currentPath={this.props.location.pathname}>
        <Layout>
          <Content className="ui-right-content form">
            <Layout
              style={{
                display: "block",
                paddingTop: 30,
                marginTop: 0
              }}
            >
              <Link
                to={`${process.env.PUBLIC_URL}/orders/bulk-orders`}
                style={{ paddingTop: "30px", paddingLeft: "30px" }}
              >
                <i className="fa fa-chevron-left" /> {transl('importedBulkOrders')}
              </Link>
            </Layout>
            <Layout className="ui-right-content no-margin-top">
              <p className="heading1" style={{ marginTop: 0 }}>
                {transl('title')}
              </p>
              <p className="heading3">{transl('instruction.label')}</p>
              <div
                className="whiteBColor"
                style={{
                  padding: 40,
                  minHeight: 200,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #cfdae6"
                }}
              >
                <div
                  style={{
                    float: "left",
                    width: "500px",
                    marginTop: 10
                  }}
                >
                  <p>
                    1. {transl('instruction.1.download')} <b>{templeteLink}</b>
                  </p>
                  <p>
                    2. {transl('instruction.2')}
                  </p>
                  <p dangerouslySetInnerHTML={{ __html: transl('instruction.3') }} />
                  <p>
                    4. {transl('instruction.4')}
                  </p>
                  <p>
                  <Alert message={transl('itemWeightNotice')} type="warning" />
                  </p>
                  <p>
                  <Alert message={transl('hsCodeNotice')} type="info" />
                  </p>
                </div>
              </div>

              <div
                className="whiteBColor"
                style={{
                  marginTop: 30,
                  padding: 40,
                  minHeight: 200,
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "1px solid #cfdae6"
                }}
              >
                <div>
                  <Upload {...this.uploadProps} onChange={this.onChangeUpload} beforeUpload={this.beforeUpload} className="button-upload">
                    <Button type="primary bg-blue">
                      <Icon type="upload" /> {transl('import')}
                    </Button>
                  </Upload>
                </div>
              </div>

            </Layout>
          </Content>
        </Layout>
      </PageWrapper>
    );
  }
}

const TranslWrapper = (props) => {
  const { t } = useTranslation('importBulkOrderPage');
  const history = useHistory();

  return (
    <ImportOrder { ...props } transl={t} history={history} />
  )
}

export default withStore(TranslWrapper);
