import React, { useContext, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import ReactGA from 'react-ga';

import { GlobalContext } from 'Store/store';
import useLocalStorage from 'Util/useLocalStorage';

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

const withAuthorization = (allowedToAccessPage, rootProps) => (Component) => {
  const Render = (routerProps) => {
    const [state] = useContext(GlobalContext);
    const { customerDetails, settings } = state.global;
    const { history } = routerProps;
    const currentPath = routerProps.match.path;
    const { redirectPath } = rootProps;
    const mustCompleteProfile = (
      rootProps.mustCompleteProfile === undefined ||
      rootProps.mustCompleteProfile
    );

    useEffect(() => {
      if (currentPath && settings?.["consumer_portal.dashboard"]?.data?.scripts_google_analyticid) {
        ReactGA.pageview(currentPath);
      }
    }, [currentPath])

    if (!allowedToAccessPage) {
      const pathname = redirectPath || `${process.env.PUBLIC_URL}/login`;
      return <Redirect to={{ pathname, prevPath: currentPath }} />
    }

    if (mustCompleteProfile) {
      const { type, name, identityNo, dob, industry } = customerDetails;
      const isCompleted = type && name && identityNo && dob && industry;
      if (!isCompleted) {
        return <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/update-profile`, prevPath: currentPath }} />
      }
    }

    if (window.location.pathname === `${process.env.PUBLIC_URL}/update-profile`) {
      const { type, name, identityNo, dob, industry } = customerDetails;
      const isCompleted = type && name && identityNo && dob && industry;
      if (isCompleted) {
        return <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/`, prevPath: currentPath }} />
      }
    }

    const accessToken = useLocalStorage.getItem("accessToken");
    const redirectUrl = useLocalStorage.getItem("redirectUrl");

    if (validURL(redirectUrl) && accessToken) {
      const href = new URL(redirectUrl);
      if (['api.delyva.app', 'staging-api.delyva.app'].includes(href.hostname)) {
        href.searchParams.set('jwt', accessToken);
        href.searchParams.set('customerId', customerDetails.id);
        useLocalStorage.setItem("redirectUrl", null); //clear redirectUrl
        window.location.href = href;
        return;
      }
    }

    const redirectToGetStarted = (history.location.prevPath === '/customer/login' || history.location.prevPath === '/customer/update-profile');
    if (redirectToGetStarted) {

      return (
        <Redirect to={{ pathname: `${process.env.PUBLIC_URL}/get-started`, state: { prevPath: currentPath } }} />
      )
    }

    return <Component {...routerProps} />;
  };

  return withRouter(Render);
};

export default withAuthorization;
