import { useEffect, useContext, useMemo, useState } from 'react';
import { message } from "antd";

import { GlobalContext } from "Store/store";
import { getInstantQuote } from 'Util/API';
import usePrevious from 'Util/usePrevious';

import checkPriceData from './getCheckPriceData';

const useUpdateServices = (form, setPriceLoading) => {
  const [state] = useContext(GlobalContext);
  const { customerDetails, companyDetails } = state.global;
  const { values, setFieldValue } = form;
  const {
    pickupDate,
    pickupTime,
    serviceDetails,
    addon,
    promoCode,
    waypoint,
    vehicleType,
    pickupNow
  } = values;

  const addonKeys = useMemo(() => Object.keys(addon).length, [addon]);
  const prevAddonKeys = usePrevious(addonKeys);
  const [fetch, setFetch] = useState();

  const onCheckPrice = async () => {
    setPriceLoading(true);
    const data = checkPriceData(
      companyDetails.id,
      form.values.waypoint,
      form.values
    )
    data.customerId = customerDetails?.id;
    const fetch = await getInstantQuote(null, data);

    if (fetch.status === 200) {
      const { data } = fetch.data;
      setFieldValue('serviceDetails', data.services[0]);
      const { errors } = fetch.data;
      errors[0] && message.error(errors[0].error.message)

      if(data.services.length == 0)
      {
          message.error("Service not available. Please choose another service or different location.");
      }

    } else {
      form.setFieldValue('serviceDetails', null)
      message.error(fetch);
    };
    setPriceLoading(false);
  }

  useEffect(() => {
    let mount = true;
    if (waypoint[0].location?.length > 0) {
      if (prevAddonKeys === addonKeys) {
        fetch && clearTimeout(fetch);
        mount && setFetch(setTimeout(() => onCheckPrice(), 750));
      } else {
        onCheckPrice()
      }
    }
    return () => {
      mount = false;
    }
  }, [addon]);

  useEffect(() => {
    setFieldValue('errors', [])
    if (waypoint[0].location?.length > 0) {
      onCheckPrice()
    }
  }, [
    pickupDate,
    pickupTime,
    pickupNow,
    promoCode,
    vehicleType[1].companyCode
  ]);
}

export default useUpdateServices;
