import React, { useContext } from "react";
import { Layout, Row, Col } from "antd";

import { GlobalContext } from "Store/store";

import ApiTableAndColumns from "./ApiTableAndColumns";
import CustomizedButton from "Components/CustomizedButton";
import AddNewApi from './AddNewApi';
import { useTranslation } from "react-i18next";

const { Content } = Layout;

const AddressBookTable = props => {
  const [state] = useContext(GlobalContext);
  const { modalState, setModalState } = state.global;
  const { setDataSource } = props;
  const { t } = useTranslation('settingsPage');

  return (
    <Layout>
      <Content>
        <div>
          <div
            className="whiteBColor"
            style={{
              height: "100%",
              border: "1px solid #cfdae6"
            }}
          >
            <Row style={{ margin: 20 }}>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <p className="heading3" style={{ margin: 0, float: "left" }}>
                  Webhooks
                </p>
              </Col>
              <Col
                xs={24}
                sm={24}
                md={12}
                lg={12}
                xl={12}
                className="navigation-table"
              >
                <CustomizedButton
                  text="Add new endpoint"
                  type="primary"
                  onClick={
                    () => {
                      setModalState({
                        ...modalState,
                        isModalVisible: true,
                        footer: false,
                        modal: {
                          title: 'Add new endpoint',
                          dialogContent: AddNewApi,
                          dialogContentProps: {
                            setModalState,
                            modalState,
                            setDataSource
                          },
                          maxWidth: 600
                        }
                      })
                    }
                  }
                />
              </Col>
            </Row>
            <ApiTableAndColumns
              setIsLoading={props.setIsLoading}
              setDataSource={setDataSource}
              getContacts={props.getContacts}
              transactionData={props.transactionData}
              transactionRowTotal={props.transactionRowTotal}
              isLoading={props.isLoading}
              handlePageSize={props.handlePageSize}
            />
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default AddressBookTable;
