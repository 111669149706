import firebase from 'firebase/app';
import 'firebase/messaging';

const config = {
  apiKey: "AIzaSyCLFKthMU4h48eo28Pgu-W2h9r99Ti8aSY",
  authDomain: "delyvax-c55b8.firebaseapp.com",
  databaseURL: "https://delyvax-c55b8.firebaseio.com",
  projectId: "delyvax-c55b8",
  storageBucket: "delyvax-c55b8.appspot.com",
  messagingSenderId: "testingsuhaimi",
  appId: "1:813365666589:web:43cad73e70885236"
};

firebase.initializeApp(config);

let messaging = null;
if (firebase.messaging.isSupported()) {
   messaging = firebase.messaging()
}
export { messaging };

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
      .then(function (registration) {
        messaging.useServiceWorker(registration);
        console.log('[SW]: SCOPE: ', registration.scope);
        return registration.scope;
      })
      .catch(function (err) {
        return err;
      });
  }
}
