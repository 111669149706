import React, { useEffect, useState, useContext, useMemo } from "react";

import { Row, Col, Form, Select, Input, Typography, Spin, message } from "antd";
import { useTranslation } from "react-i18next";

import "App.css";
import TextInput from "Components/TextInput";
import CustomizedButton from "Components/CustomizedButton";
import { GlobalContext } from 'Store/store';
import { getInstantQuote, get } from "Util/API";
import { getAddressAutoSuggestion } from 'Fetch';

import LocationTypeDropdown from './LocationTypeDropdown';
import "./form.css";

const { Text } = Typography;

const QuotationForm = ({ form, initialValues, defaultAddressSuggestion, setQuotation, cityList }) => {
  const [state] = useContext(GlobalContext);
  const { t } = useTranslation('homePage');
  const { companyDetails, customerDetails, countryList, itemTypes } = state.global;
  const [isLoading, setIsLoading] = useState(false);
  const { origin, destination, weight, itemType, codValue, insuranceValue } = form.values;
  const pathname = window.location.pathname;
  const formattedCountryList = countryList.map((item) => ({
    country: item.countryCode,
    countryName: item.name
  }))
  const defaultCountryCode = customerDetails?.country || companyDetails?.country || 'MY';
  const selectedCountryData = useMemo(() => countryList.filter((item) => (
    item.countryCode === defaultCountryCode
  )), [countryList])
  const [addressSuggestion, setAddressSuggestion] = useState(defaultAddressSuggestion);
  const [citySuggestion, setCitySuggestion] = useState({ origin: [], destination: [] });
  const [countrySuggestion, setCountrySuggestion] = useState({ origin: formattedCountryList, destination: formattedCountryList });
  const [selectedAddress, setSelectedAddress] = useState({ origin: {}, destination: {} });
  const [selectedCity, setSelectedCity] = useState({ origin: {}, destination: {} });
  const [selectedCountry, setSelectedCountry] = useState({ origin: {}, destination: {} });
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [delayFetch, setDelayFetch] = useState(null)

  const originOption = [
    { id: 1, label: t(`content.locationTypeDropdown.address`), icon: 'fas fa-home', value: "address" },
    { id: 2, label: t(`content.locationTypeDropdown.zipcode`), icon: 'fas fa-map-marker-alt', value: "zipcode" }
    // { id: 3, label: t(`content.locationTypeDropdown.country`), icon: 'fas fa-flag', value: 'country'},
    // { id: 4, label: t(`content.locationTypeDropdown.city`), icon: 'fas fa-city', value: 'city' }
  ];

  const destinationOption = [
    { id: 1, label: t(`content.locationTypeDropdown.address`), icon: 'fas fa-home', value: "address" },
    { id: 2, label: t(`content.locationTypeDropdown.zipcode`), icon: 'fas fa-map-marker-alt', value: "zipcode" }
    // { id: 3, label: t(`content.locationTypeDropdown.country`), icon: 'fas fa-flag', value: 'country'},
    // { id: 4, label: t(`content.locationTypeDropdown.city`), icon: 'fas fa-city', value: 'city' }
  ]

  useEffect(() => {
    let mount = true;
    if (!(
      pathname === process.env.PUBLIC_URL ||
      pathname === process.env.PUBLIC_URL + '/' ||
      pathname === process.env.PUBLIC_URL + '/check-rates' ||
      pathname === process.env.PUBLIC_URL + '/check-rates/'
    )) {
      setQuotation({ origin, destination, services: null });
      const fieldChecker = (value) => value && value.length > 0 ? value : "";
      let data = {
        companyId: companyDetails.id,
        origin: {
          address1: fieldChecker(origin.address1),
          address2: fieldChecker(origin.address2),
          city: fieldChecker(origin.city),
          state: fieldChecker(origin.state),
          postcode: fieldChecker(origin.zipcode),
          country: fieldChecker(origin.country)
        },
        destination: {
          address1: fieldChecker(destination.address1),
          address2: fieldChecker(destination.address2),
          city: fieldChecker(destination.city),
          state: fieldChecker(destination.state),
          postcode: fieldChecker(destination.zipcode),
          country: fieldChecker(destination.country)
        },
        itemType: itemType?.name,
        weight: {
          unit: "kg",
          value: weight
        },
      	serviceAddon: [{
      		id: -1, // COD
          qty: 1,
      		value: codValue ? codValue : 0,
      	}, {
          id: -3, //Insurance
      		qty: 1,
      		value: insuranceValue ? insuranceValue : 0,
      	}]
      };

      if (origin.lat && origin.lon) {
        data.origin.coord = {
          lat: Number(origin.lat),
          lon: Number(origin.lon)
        }
      }

      if (destination.lat && destination.lon) {
        data.destination.coord = {
          lat: Number(destination.lat),
          lon: Number(destination.lon)
        }
      }

      const fetch = async () => {
        setIsLoading(true);
        const response = await getInstantQuote(customerDetails?.id, data);
        if (mount && response.status === 200) {
          const { services, waypoints } = response.data.data;

          ///remove cod and insurance if entered
          let aservices = [];
          let count = 0;
          for(let i=0; i < services.length; i++)
          {
              let isCod = false;
              let isInsured = false;

              let serviceAddons = services[i].service.addon;
              for(let x=0; x<serviceAddons.length; x++)
              {
                  if(serviceAddons[x].id == -1)
                  {
                      isCod = true;
                  }
                  if(serviceAddons[x].id == -3)
                  {
                      isInsured = true;
                  }
              }

              if(codValue > 0 && insuranceValue > 0)
              {
                  if(isCod && isInsured)
                  {
                      aservices[count] = services[i];
                      count++;
                  }
              }else if(codValue > 0)
              {
                  if(isCod)
                  {
                      aservices[count] = services[i];
                      count++;
                  }
              }else if(insuranceValue > 0)
              {
                  if(isInsured)
                  {
                      aservices[count] = services[i];
                      count++;
                  }
              }else {
                  aservices[count] = services[i];
                  count++;
              }
          }
          //

          setQuotation({ services:aservices, origin: waypoints?.[0], destination: waypoints?.[1] });

          if (services.length === 0) {
            const geocodeError = response.data.errors?.[0]?.error?.code === 'GEOCODER_ERR';
            if (geocodeError) {
              const errorMessage = response.data.errors?.[0]?.error?.message;
              message.error(errorMessage ? 'Address not found' : ('Address not found: ' + errorMessage))
            } else {
              message.error('No available service.')
            }
          }
        } else {
          typeof response === 'string' && message.error(response);
          setQuotation({ services: [] });
        }
        setIsLoading(false);
      }
      (origin.address1 || origin.zipcode || origin.country || origin.city) &&
      (destination.address1 || destination.zipcode || destination.country || destination.city) &&
      fetch()
    } else {
      setQuotation({ origin: null, destination: null, services: [] });
    }
    return () => {
      mount = false;
    };
  }, [pathname])

  const handleTypeChange = (parentType, value) => {
    setAddressSuggestion({ ...addressSuggestion, [parentType]: [] })
    setSelectedAddress({ [parentType]: {} });
    let valueToInclude = {};
    if (value === 1 || value === 2 || value === 3) {
      valueToInclude = {
        country: selectedCountryData[0].countryCode,
        countryName: selectedCountryData[0].name
      }
    }
    form.setFieldValue(parentType, { ...valueToInclude, type: value })
  }

  const handleSearchAddress = async (name, value) => {
    if (value.length >= 4) {
      setIsDropdownLoading(true);
      setAddressSuggestion({
        ...addressSuggestion,
        [name]: []
      })
      const queryAddressRes = await getAddressAutoSuggestion(value, '3.141995380836217,101.69177970119735');
      if (queryAddressRes.status === 200) {
        const responseData = queryAddressRes.data.data;
        responseData?.length === 0 && message.error('No result found.');
        const serialize = responseData.map((item) => ({
          title: item.title,
          address1: item.address.address1,
          address2: item.address.address2,
          city: item.address.city,
          country: item.address.country,
          state: item.address.state,
          zipcode: item.address.postcode,
          lat: item.address.coord.lat,
          lon: item.address.coord.lon
        }))

        setAddressSuggestion({
          ...addressSuggestion,
          [name]: serialize
        })
      } else message.error(queryAddressRes)
      setIsDropdownLoading(false);
    }
  }

  const handleSearchCity = async (name, value) => {
    if (value.length >= 3) {
      setCitySuggestion({
        ...citySuggestion,
        [name]: []
      })
      setIsDropdownLoading(true);
      const queryCityRes = await get(`parameters/q/city?countryCode=${form.values[name].country}&city=${value}`);
      const responseData = queryCityRes?.data.data;
      responseData?.length === 0 && message.error('No result found.');
      setCitySuggestion({
        ...citySuggestion,
        [name]: responseData
      })
      setIsDropdownLoading(false);
    }
  }

  const handleSearchCountry = async (name, value) => {
    if (value.length >= 1) {
      const result = formattedCountryList.filter((item) => item.countryName.toLowerCase().includes(value) || item.countryName.includes(value))
      setCountrySuggestion({
        ...countrySuggestion,
        [name]: result
      })
    }
  }

  const handleCountryChange = (type, index) => {
    setSelectedCountry({
      ...selectedCountry,
      [type]: countrySuggestion[type][index]
    })
    form.setFieldValue(type, {
      ...countrySuggestion[type][index],
      type: form.values[type].type
    })
  };

  const handleSelectAddressChange = (type, index) => {
    setSelectedAddress({
      ...selectedAddress,
      [type]: addressSuggestion[type][index]
    })
    form.setFieldValue(type, {
      ...addressSuggestion[type][index],
      type: form.values[type].type
    })
  };

  const handleSelectCityChange = (type, index) => {
    setSelectedCity({
      ...selectedCity,
      [type]: citySuggestion[type][index]
    })
    form.setFieldValue(type, {
      ...citySuggestion[type][index],
      country: form.values[type].country,
      countryName: form.values[type].countryName,
      type: form.values[type].type
    })
  };

  const handleItemTypeChange = (index) => {
    form.setFieldValue('itemType', itemTypes[index])
  }

  const handleTextInputChange = (type, val, key) => {
    const childVal = {
      [key]: val.target.value,
      country: form.values[type].country,
      countryName: form.values[type].countryName,
      type: form.values[type].type
    };
    const newVal = {
      ...selectedAddress,
      [type]: childVal
    }
    setSelectedAddress(newVal);
    form.setFieldValue(type, childVal)
  }

  const addressOptions = (name) => addressSuggestion[name].map(
    (d, index) => (
      <Select.Option key={index}>
        <Text style={{ display: 'block' }}>{d.title}</Text>
        <Text type="secondary">
        {d.address1 ? d.address1: ''} {d.address2 ? d.address2: ''} {d.postcode ? d.postcode: ''} {d.city ? d.city: ''} {d.state ? d.state: ''} {d.country ? d.country: ''}
        </Text>
      </Select.Option>
    )
  );

  const cityOptions = (name) => citySuggestion[name].map(
    (d, index) => <Select.Option key={index}>{d.city}</Select.Option>
  );

  const countryOptions = (name) => countrySuggestion[name].map(
    (d, index) => <Select.Option key={index}>{d.countryName}</Select.Option>
  );

  const itemTypeOptions = () => itemTypes?.map(
    (d, index) => <Select.Option key={index}>{d.name}</Select.Option>
  );

  useEffect(() => {
      handleTypeChange("origin",1);
      handleTypeChange("destination",1);
  }, []);

  return (
    <Form onSubmit={form.handleSubmit} style={{ width: '100%' }}>
      <Row gutter={[20, 10]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div style={{ display: 'flex' }}>
            <LocationTypeDropdown
              data={originOption}
              selected={origin.type}
              setSelected={val => handleTypeChange("origin", val)}
            />
            <div style={{ width: '100%' }}>
              {
                originOption[origin.type].value === "zipcode" &&
                  <div style={{ width: '100%', display: 'flex' }}>
                    <Select
                      // style={{ width: '100%' }}
                      value={form.values.origin.countryName}
                      showSearch
                      placeholder="Country"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      onSearch={val => handleSearchCountry("origin", val)}
                      onChange={val => handleCountryChange("origin", val)}
                      notFoundContent={null}
                    >
                      {countryOptions("origin")}
                    </Select>
                    <Input
                      // style={{ margin: 0, width: '100%' }}
                      disabled={!form.values.origin.countryName}
                      value={form.values.origin.zipcode}
                      onChange={val => handleTextInputChange("origin", val, originOption[origin.type].value)}
                      placeholder="Postcode"
                      type="text"
                    />
                  </div>
                }
                {
                  originOption[origin.type].value === "address" &&
                    <Select
                      style={{ width: '100%' }}
                      value={form.values.origin.title}
                      showSearch
                      placeholder="Address"
                      defaultActiveFirstOption={false}
                      showArrow={false}
                      filterOption={false}
                      // onSearch={val => handleSearchAddress("origin", val)}
                      onSearch={val => {
                        clearTimeout(delayFetch);
                        setDelayFetch(setTimeout(() => handleSearchAddress("origin", val), 750))
                      }}
                      onChange={val => handleSelectAddressChange("origin", val)}
                      notFoundContent={
                        isDropdownLoading ? <div style={{ width: '100%', textAlign: 'center' }}><Spin size="small" tip="Searching..." /></div> : null}
                    >
                      {addressOptions("origin")}
                    </Select>
                }
                {
                  originOption[origin.type].value === "city" &&
                    <div style={{ width: '100%', display: 'flex' }}>
                      <Select
                        // style={{ width: '100%' }}
                        value={form.values.origin.countryName}
                        showSearch
                        placeholder="Country"
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        onSearch={val => handleSearchCountry("origin", val)}
                        onChange={val => handleCountryChange("origin", val)}
                        notFoundContent={null}
                      >
                        {countryOptions("origin")}
                      </Select>
                      <Select
                        style={{ width: '100%' }}
                        value={form.values.origin.city}
                        showSearch
                        placeholder={originOption[origin.type].label}
                        defaultActiveFirstOption={false}
                        showArrow={false}
                        filterOption={false}
                        // onSearch={val => setTimeout(() => handleSearchCity("origin", val), 750) }
                        onSearch={val => {
                          clearTimeout(delayFetch);
                          setDelayFetch(setTimeout(() => handleSearchCity("origin", val), 750))
                        }}
                        onChange={val => handleSelectCityChange("origin", val)}
                        notFoundContent={
                          isDropdownLoading ? <div style={{ width: '100%', textAlign: 'center' }}><Spin size="small" tip="Searching..." /></div> : null}
                      >
                        {cityOptions("origin")}
                      </Select>
                    </div>
                }
                {
                  originOption[origin.type].value === "country" &&
                  <Select
                    style={{ width: '100%' }}
                    value={form.values.origin.countryName}
                    showSearch
                    placeholder={originOption[origin.type].label}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={val => handleSearchCountry("origin", val)}
                    onChange={val => handleCountryChange("origin", val)}
                    notFoundContent={null}
                  >
                    {countryOptions("origin")}
                  </Select>
                }
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <div style={{ display: 'flex' }}>
            <LocationTypeDropdown
              data={destinationOption}
              selected={destination.type}
              setSelected={val => handleTypeChange("destination", val)}
            />
            <div style={{ width: '100%' }}>
              {
                destinationOption[destination.type].value === "zipcode" &&
                <div style={{ width: '100%', display: 'flex' }}>
                  <Select
                    // style={{ width: '100%' }}
                    value={form.values.destination.countryName}
                    showSearch
                    placeholder="Country"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={val => handleSearchCountry("destination", val)}
                    onChange={val => handleCountryChange("destination", val)}
                    notFoundContent={null}
                  >
                    {countryOptions("destination")}
                  </Select>
                  <Input
                    value={form.values.destination.zipcode}
                    // style={{ margin: 0, width: '100%' }}
                    onChange={val => handleTextInputChange("destination", val, destinationOption[destination.type].value)}
                    placeholder="Postcode"
                    type="text"
                    disabled={!form.values.destination.countryName}
                  />
                </div>
              }
              {
                destinationOption[destination.type].value === "address" &&
                  <Select
                    style={{ width: '100%' }}
                    value={form.values.destination.title}
                    showSearch
                    placeholder="Address"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    // onSearch={val => handleSearchAddress("destination", val)}
                    onSearch={val => {
                      clearTimeout(delayFetch);
                      setDelayFetch(setTimeout(() => handleSearchAddress("destination", val), 750))
                    }}
                    onChange={val => handleSelectAddressChange("destination", val)}
                    notFoundContent={
                      isDropdownLoading ? <div style={{ width: '100%', textAlign: 'center' }}><Spin size="small" tip="Searching..." /></div> : null}
                  >
                    {addressOptions("destination")}
                  </Select>
              }
              {
                destinationOption[destination.type].value === "city" &&
                <div style={{ width: '100%', display: 'flex' }}>
                  <Select
                    // style={{ width: '100%' }}
                    value={form.values.destination.countryName}
                    showSearch
                    placeholder="Country"
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={val => handleSearchCountry("destination", val)}
                    onChange={val => handleCountryChange("destination", val)}
                    notFoundContent={null}
                  >
                    {countryOptions("destination")}
                  </Select>
                  <Select
                    style={{ width: '100%' }}
                    value={form.values.destination.city}
                    showSearch
                    placeholder={destinationOption[destination.type].label}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={val => {
                      clearTimeout(delayFetch);
                      setDelayFetch(setTimeout(() => handleSearchCity("destination", val), 750))
                    }}
                    onChange={val => handleSelectCityChange("destination", val)}
                    notFoundContent={
                      isDropdownLoading ? <div style={{ width: '100%', textAlign: 'center' }}><Spin size="small" tip="Searching..." /></div> : null}
                    disabled={!form.values.destination.countryName}
                  >
                    {cityOptions("destination")}
                  </Select>
                </div>
              }
              {
                destinationOption[destination.type].value === "country" &&
                <Select
                  style={{ width: '100%' }}
                  value={form.values.destination.countryName}
                  showSearch
                  placeholder={destinationOption[destination.type].label}
                  defaultActiveFirstOption={false}
                  showArrow={false}
                  filterOption={false}
                  onSearch={val => handleSearchCountry("destination", val)}
                  onChange={val => handleCountryChange("destination", val)}
                  notFoundContent={null}
                >
                  {countryOptions("destination")}
                </Select>
              }
            </div>
          </div>
        </Col>
      </Row>
      { (companyDetails && companyDetails.code.length <= 5 && companyDetails.code != 'demo') ?
      <Row gutter={[20, 10]}>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <Select
            // style={{ width: '100%' }}
            value={form.values.itemType?.name}
            showSearch
            placeholder="Choose item type"
            defaultActiveFirstOption
            showArrow={false}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={val => handleItemTypeChange(val)}
            notFoundContent={null}
            allowClear
          >
            {itemTypeOptions()}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <TextInput
            style={{ margin: 0 }}
            {...form}
            name="weight"
            placeholder="Weight (kg)"
            type="number"
            step={0.001}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <TextInput
            style={{ margin: 0 }}
            {...form}
            name="insuranceValue"
            placeholder={"Insurance Value ("+companyDetails.currency+")"}
            type="number"
            step={0.05}
          />
        </Col>
        <Col xs={24} sm={24} md={24} lg={6} xl={6}>
          <TextInput
            style={{ margin: 0 }}
            {...form}
            name="codValue"
            placeholder={"COD Value ("+companyDetails.currency+")"}
            type="number"
            step={0.05}
          />
        </Col>
      </Row>
      :
      <Row gutter={[20, 10]}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <Select
            // style={{ width: '100%' }}
            value={form.values.itemType?.name}
            showSearch
            placeholder="Choose item type"
            defaultActiveFirstOption
            showArrow={false}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={val => handleItemTypeChange(val)}
            notFoundContent={null}
            allowClear
          >
            {itemTypeOptions()}
          </Select>
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <TextInput
            style={{ margin: 0 }}
            {...form}
            name="weight"
            placeholder="Weight (kg) or Volumetric Weight (kg) - Whichever is higher"
            type="number"
            step={0.001}
          />
        </Col>
      </Row>
      }
      <Row gutter={[20, 20]}>
        <Col span={24}>
          <CustomizedButton
            isLoading={isLoading}
            type="primary"
            htmlType="submit"
            text={cityList && t('content.instantQuote.submitBtn')}
            style={{ width: "100%" }}
          />
        </Col>
      </Row>
    </Form>
  )
}

export default QuotationForm;
