import React from "react";
import { List, Row } from "antd";
import { Link } from "react-router-dom";

const Note = (props) => {
  const { selectedOrder } = props;
  return (
    <List
      bordered
      style={{ backgroundColor: "white", marginBottom: "20px" }}
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="heading3" style={{ margin: 0 }}>
            Note
          </p>
        </div>
      }
    >
      <List.Item>
        <Row gutter={10} style={{ width: "100%" }}>
          <p
            className="heading3"
            style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
          >
            {selectedOrder.note ? <span dangerouslySetInnerHTML={{ __html: selectedOrder.note }} /> : '-'}
          </p>
          <p>
          <br/>
          </p>
          { selectedOrder.extTrackUrl ?
          <p
            className="heading3"
            style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
          >
            <Link to={{ pathname: selectedOrder.extTrackUrl }} target="_blank" >Courier Tracking</Link>
          </p>
          : ''}
        </Row>
      </List.Item>
    </List>
  )
}

export default Note;
