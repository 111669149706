import { post } from "Util/API";
import moment from 'moment';

const checkPrice = async (data, companyId, customerId) => {
  const {
    senderUnitNo,
    senderAddress1,
    senderAddress2,
    senderCity,
    senderState,
    senderPostcode,
    senderCountry,
    receiverCountry,
    receiverUnitNo,
    receiverAddress1,
    receiverAddress2,
    receiverCity,
    receiverState,
    receiverPostcode,
    weightValue,
    widthValue,
    lengthValue,
    heightValue,
    quantity,
    promoCode,
    itemTypeOption,
    selectedItemTypeIndex,
    selectedOutletIndex,
    dropAtOutlet,
    outlets,
    pickupDate,
    pickupTime,
    pickupNow,
    addon,
    services,
    selectedServiceIndex
  } = data;
  const serviceAddon = [];
  Object.keys(addon).forEach((key) => {
    const addonCopy = { qty: 1, ...addon[key] };
    addonCopy.id = Number(key);
    serviceAddon.push(addonCopy)
  })

  const formattedPickupDate = new Date(pickupDate);
  const formattedPickupTime = new Date(pickupTime);
  const pickupYear = formattedPickupDate.getFullYear();
  const pickupMonth = formattedPickupDate.getMonth();
  const pickupDay = formattedPickupDate.getDate();
  const pickupHour = formattedPickupTime.getHours();
  const pickupMinute = formattedPickupTime.getMinutes();
  const pickupSchedule = new Date(
    pickupYear,
    pickupMonth,
    pickupDay,
    pickupHour,
    pickupMinute
  );
  const formattedPickupSchedule = moment(pickupSchedule).format();

  let formattedData = {
    itemTypeId: itemTypeOption[selectedItemTypeIndex].id,
    weight: {
      unit: "kg",
      value: weightValue*parseInt(quantity)
    },
    inventory: [{
      weight: {
        unit: "kg",
        value: weightValue
      },
      dimension: {
        unit: "cm",
        width: widthValue ? widthValue : 1,
        length: lengthValue ? lengthValue : 1,
        height: heightValue ? heightValue : 1,
      },
      quantity:parseInt(quantity)
    }],
    serviceAddon,
    promoCode,
    companyId,
    customerId
  };

  if (selectedServiceIndex && services?.length > 0) {
    formattedData.serviceCode = services[Number(selectedServiceIndex)].service.code
  }

  let waypoints = [];
  if (dropAtOutlet && selectedOutletIndex) {
    waypoints.push({
      type: 'PICKUP',
      scheduledAt: pickupNow ? 'now' : formattedPickupSchedule,
      placeId: outlets[Number(selectedOutletIndex)].id
    })
  } else {
    waypoints.push({
      type: 'PICKUP',
      unitNo: senderUnitNo || ' ',
      address1: senderAddress1 || '',
      address2: senderAddress2 || ' ',
      city: senderCity,
      state: senderState,
      postcode: senderPostcode,
      country: senderCountry,
      scheduledAt: pickupNow ? 'now' : formattedPickupSchedule,
    })
  }

  waypoints.push({
    type: 'DROPOFF',
    unitNo: receiverUnitNo || ' ',
    address1: receiverAddress1 || '',
    address2: receiverAddress2 || ' ',
    city: receiverCity,
    state: receiverState,
    postcode: receiverPostcode,
    country: receiverCountry,
    scheduledAt: pickupNow ? 'now' : moment(pickupSchedule).add(1, 'hours').format(),
  })

  formattedData.waypoint = waypoints

  const response = await post("service/instantQuote/", formattedData);
  return response;
}

export default checkPrice;
