import React from "react";
import {
  Tag,
  Button,
  Row,
  Col,
  Modal,
} from "antd";

import { capitalizeFirstLetter } from "Util/CustomFunctions";
import status from './status';
import { API_ROOT } from 'Util/API';

const TableColumns = (props) => {
  const { state, resetState, transl } = props;
  const { modalState, setModalState } = state?.global;

  return [
    {
      title: transl('content.orderTableColumn.name'),
      dataIndex: "name",
      key: "name",
    },
    {
      title: transl('content.orderTableColumn.type'),
      dataIndex: "type",
      key: "type",
    },
    {
      title: transl('content.orderTableColumn.status'),
      dataIndex: "status",
      key: "status",
      width: 150,
      render: (statusCode, record) => {
        if (!statusCode) {
          return 'UNKNOWN';
        }

        const { metadata } = record;
        const { recurring_application_charge } = metadata;

        let shopifyBilling = 'INACTIVE';
        if (record.type === 'shopify' && recurring_application_charge) {
          shopifyBilling = recurring_application_charge.status;
        }

        return (
          <>
            <p>
              <Tag color={status[statusCode].color}>
              <span>{capitalizeFirstLetter(status[statusCode].text)}</span>
              </Tag>
            </p>
            <p>
              <span>{record.type === 'shopify' && shopifyBilling === 'INACTIVE' ? `Shopify billing ${shopifyBilling}, click on "Auth Billing" to activate` : ''}</span>
              <span>{record.type === 'shopify' && shopifyBilling !== 'INACTIVE' ? `Shopify billing ${shopifyBilling}` : ''}</span>
            </p>
          </>
        );
      }
    },
    {
      title: transl('content.orderTableColumn.action'),
      key: "actions",
      fixed: "right",
      width: 150,
      render: (text, record) => {
        const handleClick = async e => {
          const { default: EditSettings } = await import(`./edit-settings.js`);
          setModalState({
            ...modalState,
            isModalVisible: true,
            footer: false,
            modal: {
              title: `Modify settings ${record.name}`,
              dialogContent: EditSettings,
              dialogContentProps: {
                record,
                resetState,
                modalState,
                setModalState,
              }
            }
          })
        };

        function handleShopifyBilling() {
          Modal.confirm({
            title: 'Authorize Shopify Billing',
            content: <><p>To use this integration you need to authorize Shopify Billing, all charges coming from this integration except carrier charges will be billed to your Shopify Billing.</p><p>You\'ll be redireced to shopify to complete this request</p></>,
            okText: 'Agree & Continue',
            cancelText: 'Cancel',
            confirmLoading: true,
            onOk() {
              window.location.href = `${API_ROOT}shopify/billing/${record.name}`;
            }
          });
        }

        const { metadata } = record;
        const { recurring_application_charge } = metadata;

        let shopifyBillinActive = '';
        if ((!recurring_application_charge || recurring_application_charge.status !== 'active') && record.type === 'shopify') {
          shopifyBillinActive = <Button onClick={handleShopifyBilling}>Auth Billing</Button>;
        }

        return (
          <Row gutter={3}>
            <Col>
              <Button onClick={handleClick}>Settings</Button>
              {shopifyBillinActive}
            </Col>
          </Row>
        );
      }
    }
  ]
};

export default TableColumns;
