import React, { Fragment, useContext } from "react";
import { Spin ,message } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import CustomizedButton from "Components/CustomizedButton";
import useWindowSize from 'Util/useWindowSize';
import { GlobalContext } from 'Store/store';

import ShowOn from 'Util/ShowOn';
import VehicleType from "./VehicleType";
import SelectWaypoint from "./SelectWaypoint";
import AddOnService from "./AddOnService";

const OrderDetails = (props) => {
  const { t } = useTranslation('multiPointOrderPage');
  const [state] = useContext(GlobalContext);
  const { companyDetails } = state.global;
  const { form, multipointServices, mapData, handleOpenModal, handleCloseModal, setIsLoading, setCurrentStep, priceLoading, disableNext } = props;
  const size = useWindowSize();
  const availableVehicleTypes = multipointServices;
  const selectedVehicle = form.values.vehicleType[0];
  const totalPrice = form.values.serviceDetails ? form.values.serviceDetails.price.amount : 0;

  const handleChangeWaypoints = async (selectedWaypoint) => {
    if (selectedWaypoint) {
      mapData.flyTo({
        center: selectedWaypoint.location,
        essential: true
      });
    }
  };

  return (
    <Fragment>
      <div
        style={{
          padding: 30,
          background: "#ffffff",
          height: size[0] > 767 ? 'calc(100vh - 191.5px - 56px)' : 'unset',
          overflowY: size[0] > 767 ? "scroll" : 'hidden'
        }}
      >
        <Link to={`${process.env.PUBLIC_URL}/orders`}>
          <i className="fa fa-chevron-left" /> {t('orderDetails.backLink')}
        </Link>
        <p className="heading1">
          New instant delivery order
        </p>
        <p style={{ fontWeight: 600, marginTop: '2em', marginBottom: '2.5em' }}>
          {availableVehicleTypes.length > 0 ?
            t('orderDetails.chooseVehicle') :
            <span style={{ color: 'red' }}>{t('orderDetails.serviceUnavailable')}</span>
          }
        </p>
        <ShowOn on={availableVehicleTypes.length > 0}>
          <VehicleType
            form={form}
            availableVehicleTypes={availableVehicleTypes}
            selectedVehicle={selectedVehicle}
            setSelectedVehicle={(val) => form.setFieldValue('vehicleType', [val, multipointServices[val]])}
          />
        </ShowOn>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <p style={{ float: "left", fontWeight: 600, marginTop: '2em', marginBottom: '2.5em' }}>
            {t('orderDetails.manageRoutes', { stops: availableVehicleTypes[selectedVehicle].maxWaypoint })}
          </p>
          <SelectWaypoint
            mapData={mapData}
            form={form}
            submitOrder={form.submitOrder}
            values={null}
            handleChangeWaypoints={handleChangeWaypoints}
            setIsLoading={setIsLoading}
            handleOpenModal={handleOpenModal}
            modalState={props.modalState}
            handleCloseModal={handleCloseModal}
            maxWaypoint={availableVehicleTypes[selectedVehicle].maxWaypoint}
            multipointServices={multipointServices}
          />
        </div>
        <ShowOn on={form.values.serviceDetails?.service?.addon?.length > 0}>
          <p style={{ fontWeight: 600, marginTop: '2em', marginBottom: '2.5em' }}>{t('orderDetails.addServices')}</p>
          <AddOnService form={form} />
        </ShowOn>
      </div>
      <Spin spinning={priceLoading}>
        <div
          style={{ padding: 30, background: "#f0f2f5" }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ fontWeight: 600 }}>{t('orderDetails.totalPrice')}</span>
            <span
              className="heading1"
              style={{ fontSize: "2em" }}
            >
              {companyDetails.currency + ' ' + totalPrice}
            </span>
          </div>
          <CustomizedButton
            isDisabled={disableNext}
            style={{
              width: "100%",
              marginTop: 20,
              background: disableNext ? 'unset' : "#FAAD14",
              color: disableNext ? 'unset' : "#ffffff"
            }}
            onClick={() => {
              if (form.values.waypoint.length <= availableVehicleTypes[selectedVehicle].maxWaypoint) {
                form.validateForm({ ...form.values, paymentMethodId: '-1' }).then((err) => {
                  if (Object.keys(err).length === 0) {
                    setCurrentStep('confirmOrder');
                  }
                })
              } else {
                message.error(`Max waypoint is ${availableVehicleTypes[selectedVehicle].maxWaypoint}`)
              }
            }}
            text={t('orderDetails.next')}
          />
        </div>
      </Spin>
    </Fragment>
  )
}

export default OrderDetails;
