import React, { useContext, useCallback, useEffect, useState, useMemo } from "react";
import { Row, Col, Alert, Select, Typography, Spin, Form, message } from "antd";
import { useField } from 'formik';

import useGenerateDropdownItem from 'Util/useGenerateDropdownItem';
import {
  LabelledDropdown,
  LabelledInputNumber,
  LabelledTextInput,
  LabelledInputTextarea
} from "Components/optimized-input";
import CustomizedButton from "Components/CustomizedButton";
import { GlobalContext } from "Store/store";
import "App.css";
import { useTranslation } from "react-i18next";
import { get } from "Util/API";

const { Text } = Typography;

const ItemDetails = (props) => {
  const [state] = useContext(GlobalContext);
  const { companyDetails } = state.global;
  const { form } = state.newOrder;
  const { itemTypeOption, selectedItemTypeIndex } = form.values;
  const { values, errors, handleChange, setFieldValue } = form;
  const { t } = useTranslation('orderPage');
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [delayFetch, setDelayFetch] = useState(null)
  const [hsCodeSuggestion, setHsCodeSuggestion] = useState([]);

  useField('weightValue');
  useField('quantity');
  useField('widthValue');
  useField('lengthValue');
  useField('heightValue');
  useField('itemDetails');
  useField('selectedItemTypeIndex');
  useField('hsCode');
  useField('referenceNo');

  const minWeight = itemTypeOption[selectedItemTypeIndex]?.minWeight || undefined;
  const maxWeight = itemTypeOption[selectedItemTypeIndex]?.maxWeight || undefined;

  useEffect(() => {
    if (values.weightValue < minWeight || values.weightValue > maxWeight) {
      setFieldValue('weightValue', undefined);
    }
  }, [minWeight, maxWeight]);


  const handleSearchHsCode = async (value) => {

    if (value.length > 3) {

      const queryHsCode = await get(`parameters/hscode?keyword=${value}`);
      const responseData = queryHsCode?.data.data;

      // responseData?.length === 0 && message.error('No HS code result found.');

      setHsCodeSuggestion(responseData);

      if(responseData.length > 0) setFieldValue('hsCode', responseData[0].key);

      if(responseData?.length === 0) setFieldValue('hsCode', "-");

    }else {
      setFieldValue('hsCode', "-");
    }
  }

  const handleHsCodeChange = (val) => {
    if(hsCodeSuggestion)
    {
        setFieldValue('hsCode', val);
    }else {
        setFieldValue('hsCode', "-");
    }
  };

  return (
    <Row>
      <p className="heading3">{t('content.newOrder.itemDetail')}</p>
      <div style={{ backgroundColor: "white", padding: "30px", width: "100%" }}>
        <div>
        <Row xs={24} sm={24} md={24} lg={24} xl={24} gutter={20}>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
        <Alert message={t('content.newOrder.item.itemWeightNotice')} type="warning" />
        </Col>
        </Row>
        <br/>
        </div>
        <Row xs={24} sm={24} md={24} lg={24} xl={24} gutter={20}>
          <Col xs={8} sm={8} md={4} lg={4} xl={4}>
            <LabelledDropdown
              error={errors.selectedItemTypeIndex}
              value={values.selectedItemTypeIndex}
              onChange={useCallback(handleChange('selectedItemTypeIndex'), [])}
              optionFilterProp="children"
              id="selectedItemTypeIndex"
              children={useGenerateDropdownItem(itemTypeOption, 'description', null, 'name')}
              label={t('content.newOrder.item.itemType')}
              placeholder="Select item type"
              required
            />
          </Col>
          <Col xs={8} sm={8} md={4} lg={4} xl={4}>
            <LabelledInputNumber
              value={values.weightValue}
              step={0.1}
              label={t('content.newOrder.item.itemWeight')}
              min={minWeight}
              max={maxWeight}
              disabled={!values.selectedItemTypeIndex}
              error={errors.weightValue}
              onChange={useCallback((val) => form.setFieldValue('weightValue', val), [])}
              required
            />
          </Col>
          <Col xs={8} sm={8} md={4} lg={4} xl={4}>
            <LabelledInputNumber
              value={values.widthValue}
              label={t('content.newOrder.item.itemWidth')}
              min={1.0}
              step={0.1}
              error={errors.widthValue}
              onChange={useCallback((val) => form.setFieldValue('widthValue', val), [])}
            />
          </Col>
          <Col xs={8} sm={8} md={4} lg={4} xl={4}>
            <LabelledInputNumber
              value={values.lengthValue}
              label={t('content.newOrder.item.itemLength')}
              min={1.0}
              step={0.1}
              error={errors.lengthValue}
              onChange={useCallback((val) => form.setFieldValue('lengthValue', val), [])}
            />
          </Col>
          <Col xs={8} sm={8} md={4} lg={4} xl={4}>
            <LabelledInputNumber
              value={values.heightValue}
              label={t('content.newOrder.item.itemHeight')}
              min={1.0}
              step={0.1}
              error={errors.heightValue}
              onChange={useCallback((val) => form.setFieldValue('heightValue', val), [])}
            />
          </Col>
          <Col xs={8} sm={8} md={4} lg={4} xl={4}>
            <LabelledInputNumber
              label={t('content.newOrder.item.itemQuantity')}
              disabled={!values.selectedItemTypeIndex}
              value={values.quantity}
              step={1.0}
              min={1.0}
              error={errors.quantity}
              onChange={useCallback((val) => form.setFieldValue('quantity', val), [])}
              required
            />
          </Col>
        </Row>
        <Row xs={24} sm={24} md={24} lg={24} xl={24} gutter={20}>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <LabelledInputNumber
              step={0.01}
              label={t('content.newOrder.item.itemValue') + ` (${state.global.companyDetails?.currency})`}
              disabled={!values.selectedItemTypeIndex}
              value={values.itemValue}
              min={0.00}
              error={errors.itemValue}
              onChange={useCallback((val) => form.setFieldValue('itemValue', val), [])}
              required
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={16}>
            <LabelledTextInput
              error={errors.itemDetails}
              value={values.itemDetails}
              // onChange={useCallback(handleChange('itemDetails'), [])}
              onChange={(val) => {
                form.setFieldValue("itemDetails", val.target.value);
                handleSearchHsCode(val.target.value);
              }}
              label={t('content.newOrder.item.itemDetail')}
              required
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>

            <Form.Item
              label={companyDetails?.code == 'my'? t('content.newOrder.item.hsCode')+' (Including to Sabah & Sarawak)':t('content.newOrder.item.hsCode')}
            >
              <Select
                error={errors.hsCode}
                value={values.hsCode || undefined}
                placeholder="Type HS Code your item name..."
                showSearch
                defaultActiveFirstOption={true}
                showArrow={false}
                filterOption={false}
                onSearch={val => handleSearchHsCode(val)}
                onChange={val => handleHsCodeChange(val)}
                notFoundContent={null}
                style={{ width: '100%' }}
              >
                {useGenerateDropdownItem(hsCodeSuggestion, 'key', 'key', 'value')}
              </Select>
            </Form.Item>

            <Row xs={24} sm={24} md={24} lg={24} xl={24} gutter={20}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>

            {(companyDetails?.code == 'my' || companyDetails?.code?.length == 2) ?
            <Alert type="info" message={<div>{t('content.newOrder.item.hsCodeNotice')} <a href="https://delyva.com/my/help/international-shipping/hs-code/" target="_blank">More info</a></div>} />
            :
            <Alert type="info" message={<div>{t('content.newOrder.item.hsCodeNotice')} <a href="https://en.wikipedia.org/wiki/Harmonized_System" target="_blank">More info</a></div>} />
            }
            </Col>
            </Row>

          </Col>
        </Row>
        <LabelledInputTextarea
          error={errors.note}
          value={values.note}
          onChange={useCallback(handleChange('note'), [])}
          label={t('content.newOrder.item.note')}
        />
        <LabelledTextInput
          error={errors.referenceNo}
          value={values.referenceNo}
          onChange={useCallback(handleChange('referenceNo'), [])}
          label={t('content.newOrder.item.referenceNo')}          
        />
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <CustomizedButton
            text={t('content.newOrder.next')}
            onClick={props.onClickNext}
          />
        </div>
      </div>
    </Row>
  )
}

export default ItemDetails;
