import React, { useContext } from "react";
import {
  Table,
} from "antd";
import { useTranslation } from "react-i18next";

import EmptyData from "Components/EmptyData";
import withStore from 'Util/withStore';
import Accordion from './accordion';
import { GlobalContext } from 'Store/store';

const TableContent = (props) => {
  const { t } = useTranslation('integrationPage');
  const { ordersTableData, isLoading } = props;
  const { data: dataSource, rowTotal } = ordersTableData;
  const [state] = useContext(GlobalContext);
  const { modalState, setModalState } = state?.global;
  const handleTableChange = (pagination, filters, sorter) => {
    props.setOrdersTableData({
      page: pagination.current,
      limit: pagination.pageSize,
      sortBy: sorter.field,
      orderBy: sorter.order
    })
  }

  return (
    <Table
      // rowSelection={props.rowSelection}
      dataSource={dataSource}
      loading={isLoading}
      columns={props.columns}
      total={props.pageTotal}
      rowKey="id"
      pagination={{
        current: ordersTableData.page,
        total: props.ordersTableData.rowTotal,
        defaultPageSize: 10,
        showSizeChanger: false,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: (total, range) => `${range[1]} of ${total} ${t('content.tableContent.items')}`
      }}
      onChange={handleTableChange}
      // scroll={{ x: 1920 }}
      style={{ maxHeight: "60%" }}
      locale={{
        emptyText: (
          <EmptyData
            text={t('content.header.setup')}
            onClick={() => {
              setModalState({
                ...modalState,
                isModalVisible: true,
                footer: false,
                modal: {
                  title: 'New integration',
                  dialogContent: Accordion,
                  maxWidth: 1000
                }
              })
            }}
          />
        )
      }}
    />
  )
}

export default withStore(TableContent);
