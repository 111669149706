import React from "react";
import { Button } from "antd";

const CustomizedButton = ({
  type,
  htmlType,
  className,
  shape,
  text,
  onClick,
  isLoading,
  isBlockType,
  isGhostType,
  isDisabled,
  value,
  isBold,
  size,
  style,
  id
}) => (
  <Button
    id={id}
    type={type}
    htmlType={htmlType}
    block={isBlockType}
    className={className}
    onClick={onClick}
    shape={shape}
    loading={isLoading}
    ghost={isGhostType}
    disabled={isDisabled}
    value={value}
    size={size}
    style={style}
  >
    {isBold ? <b>{text}</b> : text}
  </Button>
);

export default CustomizedButton;
