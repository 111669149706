import React from "react";
import { Button } from "antd";
import moment from "moment";

import { API_ROOT } from 'Util/API';
import useLocalStorage from 'Util/useLocalStorage';

import PaymentMethodList from './PaymentMethodList';

const ordersTableColumns = (props) => {

  const { companyDetails, modalState, setModalState, transl, history } = props;

  return [
    {
      title:transl('content.header.dateTime'),
      dataIndex: "date",
      key: "date",
      width: 80,
      render: text => {
        return <div>
        {moment(text).format("D MMM YYYY")}<br/>
        {moment(text).format("h:mm a")}
        </div>;
      }
    },
    {
      title: transl('content.pageInvoice.invoiceNo'),
      dataIndex: "invoiceNo",
      key: "invoiceNo",
      width: 60,
      // render: text => (
      //   <Link to={`${process.env.PUBLIC_URL}/orders/new-order`}>{text}</Link>
      // )
    },
    {
      title: transl('content.header.status'),
      dataIndex: "status",
      key: "status",
      width: 50,
      render: text => {
        if (text === 0) {
          return <div style={{ color: 'red' }}>{transl('content.pageInvoice.status1')}</div>
        }
        if (text === 1) {
          return <div>{transl('content.pageInvoice.status2')}</div>
        }
        return <div>-</div>
      }
    },
    {
      title: transl('content.header.category'),
      key: "invoice_type.name",
      dataIndex: "invoice_type.name",
      width: 50,
      render: text => {
        return <div>{text ? text : "-"}</div>;
      }
    },
    {
      title: transl('content.header.description'),
      key: "desc",
      dataIndex: "desc",
      width: 100,
      render: text => {
        return <div>{text ? text : "-"}</div>;
      }
    },
    {
      title: transl('content.header.amount'),
      key: "totalPrice",
      dataIndex: "totalPrice",
      width: 100,
      render: text => {
        return <div>{companyDetails.currency + ' ' + text}</div>;
      }
    },
    {
      title: transl('content.header.tax'),
      key: "tax",
      dataIndex: "tax",
      width: 50,
      render: text => {
        return <div>{text ? text : "0"}</div>;
      }
    },
    {
      title: transl('content.header.action'),
      key: "actions",
      fixed: "right",
      width: 100,
      render: (text, record) => {
        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button type="primary" style={{ margin: '0 5px' }} target="_blank"  href={`${API_ROOT}wallet/invoice/${record.id}/print?output=pdf&jwt=${useLocalStorage.getItem("accessToken")}&companyId=${companyDetails.id} `}>
            {transl('content.pageInvoice.view')}
            </Button>
            <Button
              disabled={
                record.status !== 0
                || record.paymentTerm === 'CASHBYRECEIVER'
                || record.paymentTerm === 'CASHBYSENDER'
                || record.invoice_type?.type === 'CTOPUP'
              }
              onClick={() =>
                setModalState({
                  ...modalState,
                  isModalVisible: true,
                  footer: false,
                  modal: {
                    title: 'Select payment method',
                    dialogContent: PaymentMethodList,
                    dialogContentProps: {
                      record,
                      setIsLoading: props.setIsLoading,
                      setModalState,
                      modalState,
                      history
                    }
                  }
                })
              }
            >
              Pay invoice
            </Button>
          </div>
        );
      }
    }
  ]
}

export default ordersTableColumns;
