import React from "react";

function MapMarker(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      ariaHidden="true"
      className="svg-inline--fa fa-map-marker fa-w-12"
      data-icon="map-marker"
      data-prefix="fas"
      viewBox="0 0 384 512"
      width={30}
      height={30}
    >
      <path
        fill={props.color || "#1890FF"}
        d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"
      >
      </path>
      <text font-size="220" x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="white">{props.number}</text>
    </svg>
  );
}

export default MapMarker;
