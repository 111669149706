import React from "react";
import { Form, Select } from "antd";
import { useField } from 'formik';

const CustomizedDropdown = ({
  defaultValue,
  errors,
  name,
  label,
  handleFocus,
  handleBlur,
  className,
  placeholder,
  optionFilterProp,
  children,
  setFieldValue,
  values,
  mode,
  onChange,
  disabled,
  style,
  value,
  labelStyle
}) => {
  const [field] = useField({ name });

  return (
    <Form.Item
      label={label}
      className={className}
      validateStatus={errors[name] && "error"}
      help={errors[name]}
      style={labelStyle}
    >
      <Select
        {...field}
        defaultValue={defaultValue}
        style={style}
        value={values[name] || defaultValue}
        mode={mode}
        showSearch
        placeholder={placeholder}
        optionFilterProp={optionFilterProp}
        onChange={onChange ? onChange : value => setFieldValue(name, value)}
        onFocus={handleFocus}
        onBlur={() => {}}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        disabled={disabled}
      >
        {children}
      </Select>
    </Form.Item>
  )
};

export default CustomizedDropdown;
