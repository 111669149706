import axios from "axios";

import { MESSAGE_404 } from "Util/ErrorMessage";
import { API_ROOT } from './apiRoot';
import { getErrorMessage } from './getErrorMessage';

const getAddressAutoSuggestion = async (query, coord) => {
  try {
    const response = await axios.get(API_ROOT + `geocoder/autosuggest?q=${query}&at=${coord}`);

    if (!response.data.data) {
      console.error(`Missing 'data' key, geocoder/autosuggest?q=${query}&at=${coord}`);
      // return TEXT_SERVER_DOWN
    }

    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 401) {
      return e.response;
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export default getAddressAutoSuggestion;
