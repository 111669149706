import {
  TEXT_NAME,
  TEXT_IS_REQUIRED,
  TEXT_EMAIL,
  TEXT_NUMBERS,
  TEXT_PHONE_NUMBER,
  TEXT_SENDER_ADDRESS,
  TEXT_POSTAL_CODE,
  TEXT_CITY,
  TEXT_COUNTRY,
  TEXT_STATE,
  TEXT_RECEIVER_ADDRESS,
  TEXT_ITEM_TYPE,
  TEXT_WEIGHT,
  TEXT_ITEM_DETAILS,
  TEXT_ITEM_VALUE,
  TEXT_PICKUP_DATE,
  TEXT_PICKUP_TIME,
  TEXT_PAYMENT_METHOD,
  TEXT_DISCOUNT_CODE,
  TEXT_TRACKING_NO,
  TEXT_PLEASE,
  TEXT_NAME_ON_CARD,
  TEXT_PASSWORD,
  TEXT_ADDRESS,
  TEXT_ITEM_DESCRIPTION,
  TEXT_COMPANY_CODE,
  TEXT_UNIT_NO,
  TEXT_BUILDING_NAME_STREET_NAME,
  TEXT_PAYMENT_TERM,
  TEXT_API_ID,
  TEXT_API_KEY
} from "./Text";

export const MESSAGE_REMAKS_REQUIRED = `Remaks ${TEXT_IS_REQUIRED}`;
export const MESSAGE_TITLE_REQUIRED = `Title ${TEXT_IS_REQUIRED}`;
export const MESSAGE_RELATIONS_REQUIRED = `Relations ${TEXT_IS_REQUIRED}`;
export const MESSAGE_FULLNAME_REQUIRED = `Fullname ${TEXT_IS_REQUIRED}`;
export const MESSAGE_STREET_REQUIRED = `Street ${TEXT_IS_REQUIRED}`;
export const MESSAGE_UNIT_REQUIRED = `${TEXT_UNIT_NO} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_BUILDING_NAME_STREET_NAME_REQUIRED = `${TEXT_BUILDING_NAME_STREET_NAME} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_UNIT_BUILDING_REQUIRED = `Unit / Building ${TEXT_IS_REQUIRED}`;
export const MESSAGE_NOTE_REQUIRED = `Note ${TEXT_IS_REQUIRED}`;
export const MESSAGE_ITEM_DESCRIPTION_REQUIRED = `${TEXT_ITEM_DESCRIPTION} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_LEVEL_BLOCK_UNIT_REQUIRED = `Block / Unit / Level ${TEXT_IS_REQUIRED}`;
export const MESSAGE_ADDRESS_REQUIRED = `${TEXT_ADDRESS} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_CHANNEL_REQUIRED = `${TEXT_IS_REQUIRED}`;
export const MESSAGE_NAME_REQUIRED = `${TEXT_NAME} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_EMAIL_REQUIRED = `${TEXT_EMAIL} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_MAX_TEN_NUMBERS = `Must be exactly 10 ${TEXT_NUMBERS}`;
export const MESSAGE_PHONE_NUMBER_REQUIRED = `${TEXT_PHONE_NUMBER} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_SENDER_ADDRESS_REQUIRED = `${TEXT_SENDER_ADDRESS} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_MAX_FIVE_NUMBERS = `Must be exactly 5 ${TEXT_NUMBERS}`;
export const MESSAGE_POSTAL_CODE_REQUIRED = `${TEXT_POSTAL_CODE} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_CITY_REQUIRED = `${TEXT_CITY} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_STATE_REQUIRED = `${TEXT_STATE} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_COUNTRY_REQUIRED = `${TEXT_COUNTRY} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_RECEIVER_ADDRESS_REQUIRED = `${TEXT_RECEIVER_ADDRESS} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_CASH_VALUE_REQUIRED = `Cash value ${TEXT_IS_REQUIRED}`;
export const MESSAGE_ITEM_TYPE_REQUIRED = `${TEXT_ITEM_TYPE} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_WEIGHT_REQUIRED = `${TEXT_WEIGHT} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_ITEM_DETAILS_REQUIRED = `${TEXT_ITEM_DETAILS} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_ITEM_VAlUE_REQUIRED = `${TEXT_ITEM_VALUE} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_PICKUP_DATE_REQUIRED = `${TEXT_PICKUP_DATE} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_PICKUP_TIME_REQUIRED = `${TEXT_PICKUP_TIME} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_PAYMENT_METHOD_REQUIRED = `${TEXT_PAYMENT_METHOD} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_PAYMENT_TERM_REQUIRED = `${TEXT_PAYMENT_TERM} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_DISCOUNT_CODE_REQUIRED = `${TEXT_DISCOUNT_CODE} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_TRACKING_NO_REQUIRED = `${TEXT_TRACKING_NO} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_CARDHOLDER_NAME_REQUIRED = `${TEXT_NAME_ON_CARD} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_DEFAULT_CHECK_BOX = `${TEXT_PLEASE} check this box`;
export const MESSAGE_FIRST_COLUMN_REQUIRED = `First column ${TEXT_IS_REQUIRED}`;
export const MESSAGE_SECOND_COLUMN_REQUIRED = `Second column ${TEXT_IS_REQUIRED}`;
export const MESSAGE_THIRD_COLUMN_REQUIRED = `Third column ${TEXT_IS_REQUIRED}`;
export const MESSAGE_SEARCH_INPUT_REQUIRED = `Search input ${TEXT_IS_REQUIRED}`;
export const MESSAGE_CHANGE_EMAIL_REACHED_LIMIT =
  "You’ve reached the daily limit for changing your email address.";
export const MESSAGE_PENDING_EMAIL_VERIFICATION_FIRST =
  "We just need you to check your email ";
export const MESSAGE_PENDING_EMAIL_VERIFICATION_SECOND =
  " and confirm your password to verify its you and complete the update.";
export const MESSAGE_INVALID_EMAIL_ADDRESS = `Invalid email address`;
export const MESSAGE_PHONE_NUMBER_VERIFICATION_CODE =
  "5 digit verification code has been sent to ";
export const MESSAGE_VERIFICATION_CODE_REQUIRED = `Verification code ${TEXT_IS_REQUIRED}`;
export const MESSAGE_MINIMUM_PASSWORD = `${TEXT_PASSWORD} must contain at least 8 characters`;
export const MESSAGE_PASSWORD_MUST_NOT_MATCH =
  `New ` + `${TEXT_PASSWORD} `.toLowerCase() + `must not same as previous one`;
export const MESSAGE_PASSWORD_REQUIRED = `${TEXT_PASSWORD} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_404 = "File not found";
export const MESSAGE_403 = "Your do not have permission to access the server";
export const MESSAGE_400 = "Bad request";
export const MESSAGE_401 = "Unauthorized error";
export const MESSAGE_COMPANY_CODE_REQUIRED = `${TEXT_COMPANY_CODE} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_PROFILE_PHOTO_REQUIRED = `Profile picture ${TEXT_IS_REQUIRED}`;
export const MESSAGE_NEW_PASSWORD_REQUIRED =
  `New ` + `${TEXT_PASSWORD} ${TEXT_IS_REQUIRED}`.toLowerCase();

export const MESSAGE_API_ID_REQUIRED = `${TEXT_API_ID} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_API_KEY_REQUIRED = `${TEXT_API_KEY} ${TEXT_IS_REQUIRED}`;
export const MESSAGE_CONFIG_REQUIRED = `Configuration ${TEXT_IS_REQUIRED}`;
