import React, { useEffect, useState, useCallback, useContext } from "react";
import { message, Tabs, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import { get } from "Util/API";
import PageWrapper from "Components/PageWrapper";
import TablePage from 'Components/table-page/TablePage';
import { GlobalContext } from 'Store/store';

import transactionTableColumns from './transactionTableColumns';
import invoiceTableColumns from './invoiceTableColumns';
import BillingTable from './BillingTable';
import BillingFilters from './BillingFilters';
import BillingPageHeader from './BillingPageHeader';

import { mixPanelTrack } from "Util/CustomFunctions";

const TabPane = Tabs.TabPane;

const tab = [
  { label: 'Transaction', id: 'transaction' },
  { label: 'Invoice', id: 'invoice' }
]

const Billing = (props) => {
  const [state] = useContext(GlobalContext);
  const { customerDetails, companyDetails, modalState, setModalState } = state.global;
  const { t } = useTranslation('billingPage');
  const [isLoading, setIsLoading] = useState(true);
  const [activeTabKey] = useState(props.activeTabKey || "transaction");

  const getDataSource = async (tableDataKey) => {
    setIsLoading(true);
    tableDataKey.dateFrom = tableDataKey.dateFrom?.format('YYYY-MM-DD').toLocaleString();
    tableDataKey.dateTo = tableDataKey.dateTo?.format('YYYY-MM-DD').toLocaleString();
    // tableDataKey.accountId = props.driverDetail.walletId;
    delete tableDataKey.data;
    delete tableDataKey.pageTotal;
    delete tableDataKey.rowTotal;
    delete tableDataKey.dateRangeId;
    delete tableDataKey.tabKey;
    const params = [];
    Object.keys(tableDataKey).forEach((key, i) => {
      tableDataKey[key] && params.push((i > 0 ? '&' : '') + key + '=' + tableDataKey[key]);
    });

    getUnbilledAmount();

    if (activeTabKey === 'invoice') {
      return getInvoice(params)
    } else {
      return getTransactions(params)
    }
  }

  const getUnbilledAmount = async () => {
    const unbilledAmountRes = await get(
      "/customer/" + customerDetails.id+"/unbilledAmount"
    );

    if (unbilledAmountRes.status === 200) {
        customerDetails.unbilledAmount = unbilledAmountRes.data.data.unbilledAmount;
    } else message.error(unbilledAmountRes);
    setIsLoading(false)
  }

  const getTransactions = async (params) => {
    const txRes = await get(`wallet/tx?` + params.join(""));
    if (txRes.status === 200) {
      setIsLoading(false)
      return txRes.data
    } else message.error(txRes);
    setIsLoading(false)
  }

  const getInvoice = async (params) => {
    const invoiceRes = await get(`wallet/invoice?` + params.join(""));
    if (invoiceRes.status === 200) {
      setIsLoading(false)
      return invoiceRes.data
    } else message.error(invoiceRes);
    setIsLoading(false)
  }

  const onGetFiltersRenderer = useCallback((myProps) => <BillingFilters {...myProps} />, [])

  useEffect(() => {
    mixPanelTrack('ViewBilling');
  }, []);

  return (
    <PageWrapper loading={isLoading} currentPath={props.location.pathname} isCollapseSidebar={true}>
      <Row>
        <Col className="tablistBColor tablist-top">
          <Tabs defaultActiveKey={activeTabKey} animated={false} onChange={(key) => props.history.push(`${process.env.PUBLIC_URL}/billing/${key}`)}>
            {
              tab.map((item) => (
                <TabPane tab={item.label} key={item.id}>
                  <TablePage
                    rootStyle={{ margin: 20 }}
                    tabBarStyle={{ margin: 0 }}
                    initialState={{
                      status: activeTabKey === 'invoice' ? null : 'APPROVED',
                      sortBy: activeTabKey === 'invoice' ? 'date' : 'timestamp',
                      orderBy: 'desc',
                      customerId: customerDetails.id,
                      dateFrom: null,
                      dateTo: null,
                      dateRangeId: 0,
                    }}
                    filterKeys={['dateFrom', 'dateTo', 'dateRangeId']}
                    onFetchDataSource={getDataSource}
                    isLoading={props.isLoading}
                    setIsLoading={(val) => setIsLoading(val)}
                    onChangeTabKey="tabKey"
                    defaultActiveTabValue='ALL'
                    tabs={[
                      { name: 'All', key: 'ALL' },
                    ]}
                    generateColumn={(myProps) => {
                      return activeTabKey === 'invoice' ? invoiceTableColumns({ transl: t, companyDetails, modalState, setModalState, ...myProps }) : transactionTableColumns({ transl: t, ...myProps })
                    }}
                    tableRenderer={BillingTable}
                    headerRenderer={
                      (myProps) => <BillingPageHeader activeTabKey={activeTabKey} {...myProps} companyDetails={companyDetails} unbilledAmount={customerDetails.unbilledAmount}/>
                    }
                    filtersRenderer={onGetFiltersRenderer}
                  />
                </TabPane>
              ))
            }
          </Tabs>
        </Col>
      </Row>

    </PageWrapper>
  );
};

export default Billing;
