import React, { useState, useEffect, useContext } from "react";
import { Row, Col, Form, Button } from "antd";
import ReactMapboxGl from "react-mapbox-gl";

import { mapsAccessToken } from "Util/Constant";

import getRoute from './getRoute';
import OrderDetails from './OrderDetails';
import ConfirmOrder from './ConfirmOrder';
import useUpdateServices from './useUpdateServices';
import useGenerateDropdownItem from 'Util/useGenerateDropdownItem';
import { GlobalContext } from 'Store/store';

const Map = ReactMapboxGl({ accessToken: mapsAccessToken });
const styleMap = { style: "mapbox://styles/mapbox/light-v10" };

const MultiPointOrderForm = (props) => {
  const [state] = useContext(GlobalContext);
  const { form, multipointServices } = props;
  const [center, setCenter] = useState([101.6869, 3.139]); // [long, lat]
  const [mapData, setMapData] = useState(null);
  const [zoom, setZoom] = useState([10])
  const availableVehicleTypes = multipointServices;
  const [paymentMethods, setPaymentMethods] = useState(null);
  const paymentMethodChildren = useGenerateDropdownItem(paymentMethods, 'id', 'id', 'name')
  const disableJumpPage = availableVehicleTypes.length === 0;
  useUpdateServices(form, props.setPriceLoading);

  useEffect(() => {
    form.setFieldValue('addon', {});
  }, [form.values.vehicleType])

  useEffect(() => {
    form.setFieldValue('paymentMethodId', null)
  }, [form.values.serviceDetails?.service.id])

  useEffect(() => {
    if (mapData) {
      props.setIsLoading(false)
    }
  }, [mapData])

  useEffect(() => {
    if (mapData) {
      const centerCoord = mapData.getCenter();
      setCenter([centerCoord.lng, centerCoord.lat])
    }
    if (form.values.waypoint.length > 1) {
      const newArr = form.values.waypoint.map(item => item.location);
      const str = newArr.join(";");
      getRoute(mapData, str);
    } else if (mapData && mapData.getLayer("route")) {
      mapData.removeLayer("route");
    }
  }, [form.values.waypoint])

  return (
    <Form onSubmit={form.handleSubmit}>
      <Row>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          {
            {
              selectWaypoint: (
                <OrderDetails
                  form={form}
                  multipointServices={multipointServices}
                  mapData={mapData}
                  setIsLoading={props.setIsLoading}
                  handleOpenModal={props.handleOpenModal}
                  modalState={props.modalState}
                  handleCloseModal={props.handleCloseModal}
                  setCurrentStep={props.setCurrentStep}
                  priceLoading={props.priceLoading}
                  disableNext={disableJumpPage}
                />
              ),
              confirmOrder: (
                <ConfirmOrder
                  form={form}
                  setCurrentStep={props.setCurrentStep}
                  setIsLoading={props.setIsLoading}
                  priceLoading={props.priceLoading}
                  disableBack={disableJumpPage}
                  paymentMethodChildren={paymentMethodChildren}
                  paymentMethods={paymentMethods}
                  setPaymentMethods={setPaymentMethods}
                />
              )
            }[props.currentStep]
          }
        </Col>
        <Col xs={24} sm={24} md={12} lg={12} xl={12}>
          <Map
            onStyleLoad={map => setMapData(map)}
            zoom={zoom}
            center={center}
            style={styleMap.style}
            containerStyle={{
              display: "block",
              height: "calc(100vh - 60px)",
              width: "100%"
            }}
          />
        </Col>
        {
          state.global.footerWidth === '100%' &&
          <Button
            onClick={() => window.scrollTo(0, 0)}
            style={{
              position: 'fixed',
              bottom: 0,
              right: 0,
              margin: 10
            }}
            icon="up"
          />
        }
      </Row>
    </Form>
  )
}

export default MultiPointOrderForm;
