import React from "react";
import { Form, Select } from "antd";

const LabelledDropdown = React.memo(({
  defaultValue,
  error,
  name,
  label,
  onFocus,
  className,
  placeholder,
  optionFilterProp,
  children,
  value,
  mode,
  onChange,
  disabled,
  style,
  labelStyle,
  id,
  getPopupContainer,
  dropdownMatchSelectWidth,
  onDropdownVisibleChange,
  notFoundContent,
  required,
  showSearch,
  allowClear,
  onClear
}) => {
  // console.log('LabelledDropdown');

  return (
    <Form.Item
      validateStatus={disabled ? null : error && "error"}
      help={disabled ? null : error}
      label={label}
      className={className}
      style={labelStyle}
      required={required}
      noStyle
    >
      <Select
        allowClear={allowClear}
        onClear={onClear}
        id={id}
        defaultValue={value}
        style={{ width: '100%', ...style }}
        value={value || (value === 0 ? value : undefined)}
        mode={mode}
        showSearch={showSearch}
        placeholder={placeholder}
        optionFilterProp={optionFilterProp}
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={() => {}}
        getPopupContainer={getPopupContainer}
        dropdownMatchSelectWidth={dropdownMatchSelectWidth}
        onDropdownVisibleChange={onDropdownVisibleChange}
        notFoundContent={notFoundContent}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
      >
        {children}
      </Select>
    </Form.Item>
  )
})

export default LabelledDropdown;
