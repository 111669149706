import React, { useState, useContext, useEffect } from "react";
import { Layout, Spin } from "antd";


import SideBar from "Components/Sidebar";
import Navbar from "Components/NavBar";
import { GlobalContext } from "Store/store";
import useWindowSize from 'Util/useWindowSize';

const { Footer } = Layout;

const PageWrapper = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const [collapsedSidebar, setCollapsedSidebar] = useState(false);
  const { loading, loadingMsg, isCollapseSidebar } = props;
  const size = useWindowSize();

  useEffect(() => {
    if(isCollapseSidebar)
    {
        setCollapsedSidebar(true);
    }
    if (loading) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [loading])

  const toggleSidebar = () => {
    setCollapsedSidebar(!collapsedSidebar);
  };

  const globalContextValue = {
    ...state.global,
    collapsedSidebar,
    footerWidth: size[0] < 769 ? '100%' : collapsedSidebar ? size[0] - 80 : size[0] - 245
  };

  return (
    <GlobalContext.Provider value={[{ ...state, global: globalContextValue }, dispatch]}>
      <Layout className={collapsedSidebar ? "ui-sidebar-minimize" : "ui-container"}>
        <Navbar
          toggleSidebar={toggleSidebar}
          collapsedSidebar={collapsedSidebar}
          {...props}
        />
        <Layout>
          <SideBar
            toggleSidebar={toggleSidebar}
            collapsedSidebar={collapsedSidebar}
            autoMinimizeOnBreakpoint={setCollapsedSidebar}
            currentPath={props.currentPath}
          />
          <Spin
            style={{ top: window.pageYOffset, maxHeight: '40vh' }}
            wrapperClassName="ui-main-content"
            spinning={props.loading}
            tip={loadingMsg || 'Loading..'}
          >
            {props.children}
          </Spin>
        </Layout>
      </Layout>
      <Footer style={{ textAlign: 'center' }}>Powered by <a href="https://delyvax.com" target="_blank"><b>DelyvaX</b></a></Footer>
    </GlobalContext.Provider>
  )
}

export default PageWrapper;
