import moment from "moment";
import { post, API_ROOT } from "Util/API";
import { message } from 'antd';

const submitOrder = async data => {
  const {
    state: { global },
    publicUrl,
    history,
    closeLoading,
    pickupDate,
    pickupTime,
    pickupNow,
    promoCode,
    waypoint,
    vehicleType,
    addon,
    notes,
    paymentMethodId,
    setLoadingMsg
  } = data;
  const { countryList, customerDetails, companyDetails } = global;

  const formattedPickupDate = new Date(pickupDate);
  const formattedPickupTime = new Date(pickupTime);
  const pickupYear = formattedPickupDate.getFullYear();
  const pickupMonth = formattedPickupDate.getMonth();
  const pickupDay = formattedPickupDate.getDate();
  const pickupHour = formattedPickupTime.getHours();
  const pickupMinute = formattedPickupTime.getMinutes();
  const pickupSchedule = new Date(
    pickupYear,
    pickupMonth,
    pickupDay,
    pickupHour,
    pickupMinute
  );
  const scheduledAt = moment(pickupSchedule).format();
  const defaultCountryCode = customerDetails.country || companyDetails.country || 'MY';
  const selectedCountryData = countryList.filter((item) => (
    item.countryCode === defaultCountryCode
  ));

  const newWaypoint = waypoint.map((item, index) => {
    const contact = {
      address1: item?.address1?.length > 0 ? item.address1 : item.address2,
      address2: item?.address1?.length > 0 ? (item.address2 || "") : "",
      postcode: item.postcode || "",
      state: item.state || "",
      city: item.city || "",
      country: item.country || selectedCountryData[0]?.countryCode,
      mobile: item.phone,
      name: item.name,
      phone: item.phone,
      coord: {
        lat: item.location[1].toString(),
        lon: item.location[0].toString()
      }
    };
    if (item.unitNo?.length > 0) {
      contact.unitNo = item.unitNo
    }
    const type = waypoint.length === 1 ? 'DROPOFF' : (index === 0 ? 'PICKUP' : 'DROPOFF');
    const inventory = [{
      name: 'Parcel',
      type: "PARCEL",
      quantity: 1,
      weight: {
        unit: vehicleType[1].maxWeight.unit,
        value: vehicleType[1].maxWeight.value
      },
      description: "-"
    }];

    return {
      contact,
      type,
      inventory,
      scheduledAt: index === 0 ? (pickupNow ? 'now' : scheduledAt) : (pickupNow ? 'now' : moment(scheduledAt).add(1, 'hours').format())
    }
  })

  const serviceAddon = [];
  Object.keys(addon).forEach((key) => {
    const addonCopy = { qty: 1, ...addon[key] };
    addonCopy.id = Number(key);
    serviceAddon.push(addonCopy)
  })

  const dataToSubmit = {
    customerId: global.customerDetails.id,
    process: true,
    serviceCode: vehicleType[1].companyCode,
    promoCode,
    serviceAddon,
    paymentMethodId,
    source: 'web-customer-multipoint',
    waypoint: newWaypoint
  };

  if (notes?.length > 0) {
    dataToSubmit.note = notes;
  }

  const createOrder = await post("/order", dataToSubmit);
  if (createOrder.status === 200) {
    const { statusCode, invoiceId, orderId, id } = createOrder.data.data;
    if (statusCode === 10) {
      setLoadingMsg('Do not close this page. Redirecting to payment gateway. Please wait..');
      const doneUrl = window.location.origin + `${publicUrl}/after-payment/multipoint/${orderId}`;
      const paymentUrl = API_ROOT + `wallet/invoice/${invoiceId}/payment/?doneUrl=${doneUrl}&companyId=${global.companyDetails.id}`;
      history.replace(`${publicUrl}/orders/multipoint`, { resetState: true, orderId: orderId || id });
      window.open(paymentUrl);
    } else {
      history.replace(`${publicUrl}/orders/multipoint`, { resetState: true, orderId: orderId || id });
    }
  } else {
    message.error(createOrder, 5);
    closeLoading()
  }
};

export default submitOrder;
