import React, { Component } from "react";
import { Button, Icon } from "antd";
import { Link } from "react-router-dom";

import logo from "images/Logo/delyvax-logo-white.svg";
import withStore from 'Util/withStore';
import { IMAGE_URL_ROOT } from 'Util/API';

import RightMenu from "./RightMenu";
import MobileSidebar from "./MobileSidebar";
import styles from './navBar.module.css';

class Navbar extends Component {
  state = {
    visible: false,
    clickedSearchBar: false
  };

  toggleDrawer = () => {
    this.setState({
      visible: !this.state.visible
    });
  };

  onClose = () => {
    this.setState({
      visible: false
    });
  };

  toggleSearchBar = () => {
    this.setState({
      clickedSearchBar: !this.state.clickedSearchBar
    });
  };

  render() {
    const { visible } = this.state;
    const { companyDetails } = this.props.state.global;
    const { tertiaryColor } = companyDetails.personalization;

    return (
      <nav className={styles.navbar}>
        <Button
          type="primary"
          onClick={this.toggleDrawer}
          className="barsMenu"
          // style={{ color: (!tertiaryColor || tertiaryColor === "#FFFFFF") ? 'black' : 'white' }}
          icon="menu"
        />
        <div className="logo">
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <img
              style={{ height: 35, margin: '0 25px' }}
              src={
                companyDetails.logo ?
                  IMAGE_URL_ROOT + ((!tertiaryColor || tertiaryColor === "#FFFFFF") ? companyDetails.logob : companyDetails.logo)  :
                  logo
                }
              alt="company logo"
            />
          </Link>
          <Button
            className="button-sidebar button__transparent color__white"
            onClick={this.props.toggleSidebar}
            style={{ marginLeft: 2, width: 64 }}
          >
            <Icon
              className="sidebar-btn-icon"
              style={{ color: (!tertiaryColor || tertiaryColor === "#FFFFFF") ? "#434343" : '#FFFFFF'  }}
              type={this.props.collapsedSidebar ? "menu-unfold" : "menu-fold"}
            />
          </Button>
          <div className={styles.mobileRightMenu}>
            <RightMenu displayName={false} />
          </div>
        </div>
        <div className="navbar-container">
          <div className="leftMenu">

          </div>
          <div className="rightMenu">
            <RightMenu />
          </div>
          <MobileSidebar
            visible={visible}
            toggleDrawer={this.toggleDrawer}
            onClose={this.onClose}
            companyCode={companyDetails.code}
          />
        </div>
      </nav>
    );
  }
}

export default withStore(Navbar);
