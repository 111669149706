import React, { Component } from "react";

import { Formik } from "formik";
import * as Yup from "yup";

import { MESSAGE_TRACKING_NO_REQUIRED } from "Util/ErrorMessage";
import withStore from "Util/withStore";
import PublicHeader from "Components/PublicHeader";

import TrackingForm from "./TrackingForm";

const trackingformik = {
  initialValues: {
    trackingNo: ""
  },
  validationSchema: Yup.object().shape({
    trackingNo: Yup.string().required(MESSAGE_TRACKING_NO_REQUIRED)
  })
};

class PublicTrackingList extends Component {
  state = {
    isRateModalVisible: false,
    pageNotFound: false
  };

  handleCancel = () => {
    this.setState({
      isRateModalVisible: false
    });
  };

  submitFeedback = values => {
    // console.log(values);
  };

  onSubmit = values => {
    let trackingNoArr = [];

    if (values.trackingNo.indexOf(",") >= 0) {
      trackingNoArr = values.trackingNo.split(/[ ,]+/);
    } else if (values.trackingNo.indexOf(" ") >= 0) {
      trackingNoArr = values.trackingNo.split(" ").map(item => {
        return item.trim();
      });
    } else {
      trackingNoArr = values.trackingNo.split("\n").map(item => {
        return item.trim();
      });
    }

    // this.props.history.push(`/track?trackingNo=${trackingNoArr.join()}`)
  };

  render() {
    return (
      <PublicHeader>
        <Formik {...trackingformik} onSubmit={this.onSubmit}>
          {form => <TrackingForm form={form} history={this.props.history} />}
        </Formik>
      </PublicHeader>
    );
  }
}

export default withStore(PublicTrackingList);
