import React from "react";
import { Form, Switch } from "antd";

const CustomizedSwitch = ({
  className,
  errors,
  name,
  checked,
  touched,
  label,
  style,
  setFieldValue,
  onChange,
  disabled,
  onClick
}) => {
  return (
    <Form.Item
      validateStatus={touched[name] && errors[name] && "error"}
      help={touched[name] && errors[name]}
      label={label}
      className={className}
      style={style}
    >
      <Switch
        checked={checked}
        onChange={onChange ? onChange : checked => setFieldValue(name, checked)}
        disabled={disabled}
        onClick={onClick}
      />
    </Form.Item>
  );
};

export default CustomizedSwitch;
