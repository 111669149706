import React, { Component } from "react";
import { Divider, Layout, Typography, message } from "antd";
import { Redirect, Link } from "react-router-dom";
import { TEXT_APPS } from "Util/Text";

import { post, update } from "Util/API";

import Settings from "./Settings";
import SettingsPickupp from "./SettingsPickupp";
import SettingsJtexpress from "./SettingsJtexpress";
import SettingsDhlecommerce from "./SettingsDhlecommerce";
import SettingsFlashEx from "./SettingsFlashEx";
import SettingsLalamove from "./SettingsLalamove";
import SettingsGdex from "./SettingsGdex";
import SettingsNinjaVan from "./SettingsNinjaVan";
import SettingsPoslaju from "./SettingsPoslaju";
import SettingsPandago from "./SettingsPandago";
import SettingsGrab from "./SettingsGrab";
import SettingsCityLinkExMy from "./SettingsCityLinkExMy";
import SettingsLineClearMy from "./SettingsLineClearMy";
import SettingsKexMy from "./SettingsKexMy";

const { Content } = Layout;
const { Title } = Typography;

class EditAppsContent extends Component {
  state = {
    currentChange: 1
  };

  render() {
    if (this.props.app === undefined && this.props.record === undefined) {
      return (
        <Redirect
          to={{
            pathname: `${process.env.PUBLIC_URL}/apps/list`
          }}
        />
      );
    }

    return (
      <div className="ui-content-container">
        <Content className="ui-content">
          <Link
            to={`${process.env.PUBLIC_URL}/apps/list`}
            className="ui-back-link"
          >
            <i className="fas fa-chevron-left" /> {TEXT_APPS}
          </Link>
          <Title level={2} className="ui-page-title">
            {this.props.name}
          </Title>
          <Divider />
          {
            (this.props.app?.path == 'flash-express' || this.props.record?.path == 'flash-express') ?
            <SettingsFlashEx
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'ninja-van' || this.props.record?.path == 'ninja-van') ?
            <SettingsNinjaVan
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'lalamove-v3' || this.props.record?.path == 'lalamove-v3') ?
            <SettingsLalamove
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'jnt-express' || this.props.record?.path == 'jnt-express') ?
            <SettingsJtexpress
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'pos-usc' || this.props.record?.path == 'pos-usc') ?
            <SettingsPoslaju
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'dhl-ecommerce-my' || this.props.record?.path == 'dhl-ecommerce-my') ?
            <SettingsDhlecommerce
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'mygdex-prime' || this.props.record?.path == 'mygdex-prime') ?
            <SettingsGdex
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'pickupp' || this.props.record?.path == 'pickupp') ?
            <SettingsPickupp
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'pickupp' || this.props.record?.path == 'pickupp') ?
            <SettingsPickupp
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'pandago' || this.props.record?.path == 'pandago') ?
            <SettingsPandago
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'grab-express' || this.props.record?.path == 'grab-express') ?
            <SettingsGrab
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'citylinkexpress' || this.props.record?.path == 'citylinkexpress') ?
            <SettingsCityLinkExMy
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'abx-express' || this.props.record?.path == 'abx-express') ?
            <SettingsKexMy
              app={this.props.app}
              record={this.props.record}
            />
            :(this.props.app?.path == 'line-clear' || this.props.record?.path == 'line-clear') ?
            <SettingsLineClearMy
              app={this.props.app}
              record={this.props.record}
            />
            :
            <Settings
              app={this.props.app}
              record={this.props.record}
            />
          }
        </Content>
      </div>
    );
  }
}

export default EditAppsContent;
