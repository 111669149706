import React, { useEffect, useState, useContext } from "react";
import { Button, Spin, Result, Typography, notification } from "antd";
import { useTranslation } from "react-i18next";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { Link } from "react-router-dom";
// import mixpanel from 'mixpanel-browser';
import TiktokPixel from 'tiktok-pixel';
import TagManager from 'react-gtm-module'

import { GlobalContext } from "Store/store";
import ShowOn from 'Util/ShowOn';
import { get } from 'Util/API';



const { Paragraph, Text } = Typography;

const TopupSuccessfulStep = (props) => {
  const [store] = useContext(GlobalContext);
  const { settings, companyDetails } = store.global;
  const { invoiceId } = props;
  const [invoiceDetails, setInvoiceDetails] = useState(null)
  const [paidStatus, setPaidStatus] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingMsg, setLoadingMsg] = useState('Loading...');
  const [fetchStatusCount, setFetchStatusCount] = useState(0);
  const { t } = useTranslation('topupPage');

  const getPaymentStatus = () => {
    setIsLoading(true)
    setTimeout(async () => {
      setLoadingMsg("Please wait. Waiting for payment status..")
      const fetch = await get(`wallet/invoice/${invoiceId}`);
      if (fetch.status === 200) {
        setInvoiceDetails(fetch.data.data);
        setPaidStatus(fetch.data.data.status);
        if (fetch.data.data.status === 0) {
          setFetchStatusCount(fetchStatusCount + 1);
        } else {
          props.onRefreshAmount();
          setIsLoading(false);
        }
      } else setLoadingMsg('Error ' + fetch)
    }, 3000)
  }

  const openReferNotification = () => {
      const key = `open${Date.now()}`;
      const btn = (
        <Button type="primary" size="small" onClick={props.clickReferFriend}>
          Refer a friend
        </Button>
      );
      notification.info({
        message: "Love using "+companyDetails.name+"?",
        description:
          "Get free credits recommending "+companyDetails.name+" to your friends.",
        btn,
        key,
        duration:0
      });
  };

  useEffect(() => {
    fetchStatusCount >= 9 && setIsLoading(false);
  }, [fetchStatusCount])

  useEffect(() => {
    let mount = true;
    mount && invoiceId && fetchStatusCount < 9 && getPaymentStatus();
    return () => {
      mount = false;
    }
  }, [fetchStatusCount])

  useEffect(() => {
    if (invoiceDetails?.status === 1) {
      const fbPixelId = settings["consumer_portal.dashboard"]?.data?.scripts_fb_pixelid;
      const googleAnalyticsPixelId = settings["consumer_portal.dashboard"]?.data?.scripts_google_analyticid;
      const googleAdsPixelId = settings["consumer_portal.dashboard"]?.data?.scripts_google_adwordsid;

      if(companyDetails.code.length < 5) openReferNotification();

      if (fbPixelId) {
        ReactPixel.track(
          'Purchase',
          {
            value: Number(invoiceDetails.totalPaid),
            currency: companyDetails.currency
          }
        );
      }
      if(googleAnalyticsPixelId)
      {
          ReactGA.event({
            category: 'Conversion',
            action: 'Purchase',
            label: 'Topup Credits',
            value: Number(invoiceDetails.totalPaid)
          });

          ///ecommerce
          ReactGA.plugin.execute('ecommerce', 'addItem', {
            id: invoiceDetails.id,
            name: 'Topup Credits',
            sku: '--',
            price: invoiceDetails.totalPaid,
            category: 'Topup',
            quantity: 1
          });
          ReactGA.plugin.execute('ecommerce', 'addTransaction', {
            id: invoiceDetails.id,
            affiliation: 'Topup Credits',
            revenue: invoiceDetails.totalPaid,
            shipping: 0,
            tax: 0,
            currency: companyDetails.currency
          });
          ReactGA.plugin.execute('ecommerce', 'send');
          ReactGA.plugin.execute('ecommerce', 'clear');

          //gtag('config', 'AW-380626459');

          //
      }

      if(googleAdsPixelId)
      {
          const tagManagerArgs = {
              gtmId: googleAdsPixelId,
              dataLayer:  {
                'event': 'purchase',
                'conversionValue': Number(invoiceDetails.totalPaid),
                'conversionCurrency': companyDetails.currency
              }
          }

          TagManager.initialize(tagManagerArgs);
      }

      const tiktokPixelId = (companyDetails.code == 'my')  ? 'CIJ2V2RC77UCKS2E036G': null;

      if(tiktokPixelId)
      {
          const advancedMatching = {
              email: invoiceDetails?.payer?.email,
              phone_number: invoiceDetails?.payer?.phone
          }; // optional
                
          const options = {
            debug: false, // enable logs
          };

          TiktokPixel.init(tiktokPixelId, advancedMatching, options);

          TiktokPixel.track('CompletePayment', {
            // content_type: 'product',
            quantity: 1,
            // content_name: 'Double Cheese Burger',
            // content_id: '2',
            currency: companyDetails.currency,
            value: Number(invoiceDetails.totalPaid),
          });
      }

    }
  }, [invoiceDetails])

  return (
    <div style={{ marginTop: 0, textAlign: "center" }}>
      <Spin spinning={isLoading} tip={loadingMsg}>
          { !isLoading && paidStatus === 1 ?
          <Result
            status="success"
            title={t('content.topupSuccess.thankYou')}
            subTitle={t('content.topupSuccess.paymentSuccess')}
            extra={[
              <Paragraph>
                <h3><span id="totalPaidCurrency">{companyDetails.currency}</span><span id="totalPaidAmount">{invoiceDetails ? invoiceDetails.totalPaid:''}</span></h3>
                <br/>
              </Paragraph>,
              <Link to={`${process.env.PUBLIC_URL}/orders/new-order`}>
              <Button type="primary" key="console" onClick={props.clickBackHome}>
                {t('content.header.newOrder')}
              </Button>
              </Link>,
              <Button key="buy" onClick={props.clickBackHome}>{t('content.topupSuccess.topupPage')}</Button>,
            ]}
          />
          :
          <Result
            status="warning"
            title={t('content.topupSuccess.paymentPending')}
            subTitle="Waiting for payment status"
            extra={[
              <Button type="primary" key="console" onClick={getPaymentStatus} >
                {t('header.checkStatus')}
              </Button>
            ]}
          />
          }
      </Spin>
    </div>
  )
}

export default TopupSuccessfulStep;
