import React, { useState, useEffect } from "react";
import { message } from "antd";

import { get } from "Util/API";
import PageWrapper from 'Components/PageWrapper';

import TopupContent from "./TopupContent";

import { mixPanelTrack } from "Util/CustomFunctions";

const featuresSort = {
  'serviceincluded': null,
  'freecredit': null,
  'freeflyer': null,
  'smsnotifyrate': null
}

const Topup = (props) => {
  const [fetchItems, setFetchItems] = useState(null);
  const currentStep = 0;
  const [isLoading, setIsLoading] = useState(true);

  async function allFetch() {
    const getTopupPlan = get("topup/plan/customer");
    const getPaymentMethod = await get("topup/paymentMethod");

    return {
      getTopupPlan: await getTopupPlan,
      getPaymentMethod: await getPaymentMethod
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const fetchAll = await allFetch();
      const { getTopupPlan, getPaymentMethod } = fetchAll;
      if (getTopupPlan.status === 200) {
        const { feature } = getTopupPlan.data.data;
        const sortedFeaturesObj = feature.reduce((acc, item) => {
          acc[item.key] = {...item}
          return acc;
        }, featuresSort)
        const sortedFeatures = [];
        Object.keys(sortedFeaturesObj).forEach((item) => sortedFeaturesObj[item] && sortedFeatures.push(sortedFeaturesObj[item]));
        getTopupPlan.data.data.feature = sortedFeatures;
        setFetchItems({
          topupPlan: getTopupPlan.data.data,
          paymentMethod: getPaymentMethod.data.data
        })
      } else message.error("Request time out. Please try again.")
      setIsLoading(false)
    }
    fetch()
  }, [])

  useEffect(() => {
    mixPanelTrack('ViewTopupPlan');
  }, []);

  return (
    <PageWrapper loading={isLoading} currentPath={props.location.pathname} isCollapseSidebar={true}>
      {
        fetchItems
        ? <TopupContent
          isLoading={isLoading}
          setLoading={(val) => setIsLoading(val)}
          paymentMethod={fetchItems?.paymentMethod}
          topupPlan={fetchItems?.topupPlan}
          currentStep={props.step || currentStep}
          invoiceId={props.match.params?.invoiceId}
        />
        : <div style={{ width: '100%', height: '100%' }} />
      }

    </PageWrapper>
  )
}

export default Topup;
