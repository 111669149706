import React, { useContext, Fragment, useCallback, useMemo, useEffect, useState } from 'react';
import { Row, Col, Form, DatePicker, TimePicker, Input, Alert, Spin, message } from 'antd';
import moment from "moment";
import { useTranslation } from "react-i18next";

import CustomizedButton from "Components/CustomizedButton";
import { GlobalContext } from 'Store/store';
import useWindowSize from 'Util/useWindowSize';
import { LabelledDropdown, LabelledCheckbox } from 'Components/optimized-input';
import ShowOn from 'Util/ShowOn';
import { LabelledInputTextarea } from 'Components/input';
import { get } from 'Util/API';

const ConfirmOrder = (props) => {
  const { t } = useTranslation('multiPointOrderPage');
  const { setCurrentStep, form, disableBack } = props;
  const { errors, values } = form;
  const dateFormat = "DD-MM-YYYY";
  const [state] = useContext(GlobalContext);
  const [showItem, setShowItem] = useState(false);
  const { customerDetails, companyDetails, settings, topupPlan } = state.global;

  const size = useWindowSize();
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: null,
    description: null,
    type: 'error'
  });
  const totalPrice = form.values.serviceDetails ? form.values.serviceDetails.price.amount : 0;
  const sortedTopupPlan = topupPlan.prices?.sort(function(a, b){return a.value - b.value}) || [];
  const sortedTopupPlanPrices = sortedTopupPlan.map((item) => item.value);
  const minOrderAmout = sortedTopupPlanPrices[0] || settings["orders.create_an_order"]?.data?.min_order_amount;
  const [disableHourList, setDisableHourList] = useState([]);
  const [coupon, setCoupon] = useState(values.promoCode);

  function disabledDate(current) {
    let customDate = moment(new Date()).format(dateFormat);
    return current && current < moment(customDate, dateFormat);
  }

  useEffect(() => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    const pickupDate = moment(values.pickupDate).format("YYYY-MM-DD");
    if (today === pickupDate) {
      const toDisable = new Date(moment(values.pickupTime));
      const newDisableHourList = [];
      let i = 0;
      for (i; i < toDisable.getHours(); i++) {
        newDisableHourList.push(i);
      }
      setDisableHourList(newDisableHourList);
    } else {
      setDisableHourList([])
    }
  }, [values.pickupDate])

  useEffect(() => {
    if (values.errors.length > 0) {
      setShowAlert({
        ...showAlert,
        show: true,
        message: 'Errors!',
        description: (
          <ul>
            {
              values.errors.map((item) => (
                <li>{item.error.message}</li>
              ))
            }
          </ul>
        )
      })
    }
  }, [values.errors]);

  const onCloseAlert = () => {
    setShowAlert({
      show: false,
      message: null,
      description: null,
      type: 'error'
    })
  }

  useEffect(() => {
    if (showItem) {
      props.setPaymentMethods(null);
      form.setFieldValue('paymentMethodId', null)
      getOrderPaymentMethod()
    }
  }, [showItem])

  const getOrderPaymentMethod = async () => {
    const fetch = await get(`order/paymentMethod?customerId=${customerDetails.id}&serviceId=${form.values.serviceDetails?.service.id}`);
    if (fetch.status === 200) {
      let paymentMethodList = fetch.data.data;
      if (Number(customerDetails.walletBalance) < totalPrice) {
        paymentMethodList = paymentMethodList.map((item) => {
          if (item.id === 0) {
            item.disabled = true;
          }
          return item
        })
      }
      props.setPaymentMethods(paymentMethodList);
      props.setIsLoading(false);

      if(paymentMethodList.length > 0) form.setFieldValue('paymentMethodId', paymentMethodList[0].id)

    } else {
      message.error(fetch);
      props.setPaymentMethods([]);
      form.setFieldValue('paymentMethodId', null)
      props.setIsLoading(false);
    }
  }

  return (
    <Fragment>
    <div
      style={{
        padding: 30,
        background: "#ffffff",
        height: size[0] > 767 ? 'calc(100vh - 191.5px - 56px)' : 'unset',
        overflowY: size[0] > 767 ? "scroll" : 'hidden'
      }}
    >
      <div style={{ color: '#1890FF', cursor: 'pointer' }} onClick={() => setCurrentStep('selectWaypoint')}>
        <i className="fa fa-chevron-left" /> {t('confirmOrder.backLink')}
      </div>
      <div className="container-form">
        <p className="heading1" style={{ marginTop: 0 }}>
          {t('confirmOrder.pageTitle')}
        </p>
      </div>
      <div className="container-form">
        <p style={{ fontWeight: 600 }}>{t('confirmOrder.contactDetails')}</p>
      </div>
        <LabelledCheckbox
          text="Now"
          checked={values.pickupNow}
          onChange={e => { form.setFieldValue('pickupNow', e.target.checked) }}
        />
        <Row xs={24} sm={24} md={24} lg={24} xl={24} gutter={20}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              validateStatus={
                form.touched["pickupDate"] &&
                form.errors["pickupDate"] &&
                "error"
              }
              help={
                form.touched["pickupDate"]
                  ? form.errors["pickupDate"]
                  : ""
              }
              label="Package ready date"
            >
              <DatePicker
                disabled={!!values.pickupNow}
                disabledDate={disabledDate}
                placeholder="Date"
                defaultValue={form.values.pickupDate}
                style={{ width: '100%' }}
                onChange={(date, dateString) => {
                  form.setFieldValue(
                    "pickupDate",
                    moment(date)
                  );
                }}
                format={dateFormat}
              />
            </Form.Item>
          </Col>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <Form.Item
              label="Package ready time"
              validateStatus={
                form.touched["pickupTime"] &&
                form.errors["pickupTime"] &&
                "error"
              }
              help={
                form.touched["pickupTime"]
                  ? form.errors["pickupTime"]
                  : ""
              }
            >
              <TimePicker
                disabled={!!values.pickupNow}
                disabledHours={() => disableHourList}
                minuteStep={1}
                placeholder="Time"
                onChange={(time, timeString) => {
                  form.setFieldValue(
                    "pickupTime",
                    moment(time)
                  );
                }}
                defaultValue={form.values.pickupTime}
                use12Hours
                format="h:mm a"
              />
            </Form.Item>
          </Col>
        </Row>
        <div className="container-form">
          <p style={{ fontWeight: 600 }}>{t('confirmOrder.deliveryNotes')}</p>
        </div>
        <LabelledInputTextarea
          {...form}
          name="notes"
          value={form.values.notes}
          onChange={(val) => {
            form.setFieldValue('notes', val.target.value)
          }}
          label={t('confirmOrder.notes')}
        />
        <div className="container-form">
          <p style={{ fontWeight: 600 }}>{t('confirmOrder.paymentOption')}</p>
        </div>
        <LabelledDropdown
          error={errors.paymentMethodId}
          value={values.paymentMethodId}
          dropdownMatchSelectWidth={false}
          placeholder={t('confirmOrder.selectPaymentMethod')}
          // getPopupContainer={useCallback(() => document.getElementById('footer'), [])}
          labelStyle={useMemo(() => ({ marginBottom: 10 }), [])}
          children={props.paymentMethodChildren}
          onChange={useCallback((val) => form.setFieldValue('paymentMethodId', val), [])}
          notFoundContent={
            !props.paymentMethods ? <div style={{ width: '100%', textAlign: 'center' }}><Spin size="small" tip="Get available payment method..." /></div> : null}
          onDropdownVisibleChange={useCallback((val) => setShowItem(val), [])}
          required
        />
        <Input.Search
          placeholder={t('confirmOrder.discountCode')}
          enterButton={t('confirmOrder.apply')}
          allowClear
          value={coupon}
          onChange={val => setCoupon(val.target.value)}
          onSearch={value => form.setFieldValue('promoCode', value)}
          style={useMemo(() => ({ top: 0, marginBottom: 10 }), [])}
        />
        <ShowOn on={showAlert.show && values.errors.length > 0}>
          <Alert
            message={showAlert.message}
            description={showAlert.description}
            type={showAlert.type}
            closable
            onClose={onCloseAlert}
          />
        </ShowOn>
    </div>
      <Spin spinning={props.priceLoading}>
        <div
          style={{ padding: 30, background: "#f0f2f5" }}
        >
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <span style={{ fontWeight: 600 }}>{t('confirmOrder.totalPrice')}</span>
            <span
              className="heading1"
              style={{ fontSize: "2em" }}
            >
              {companyDetails.currency} {totalPrice}
            </span>
          </div>
          <div style={{ display: size[0] > 767 ? 'flex' : 'unset', marginTop: 20 }}>
            <CustomizedButton
              style={{ width: '100%' }}
              onClick={() => setCurrentStep('selectWaypoint')}
              text={t('confirmOrder.back')}
              isDisabled={disableBack}
            />
            <CustomizedButton
              style={{ margin: size[0] > 767 ? '0 0 0 10px' : '10px 0 0 0' }}
              className="button-success button-fullWidth"
              onClick={
                () => {
                  if (form.values.waypoint.length >= form.values.vehicleType[1].minWaypoint) {
                    if (((values.paymentMethodId > 0 && (Number(totalPrice) >= minOrderAmout)) || values.paymentMethodId <= 0)) {
                        form.handleSubmit()
                    } else message.error(`Your order must exceed ${companyDetails.currency} ${minOrderAmout} to use payment gateway.`)
                  } else message.error('Min waypoint is ' + form.values.vehicleType[1].minWaypoint)
                }
              }
              text={t('confirmOrder.confirmOrder')}
            />
          </div>
        </div>
      </Spin>
    </Fragment>
  )
}

export default ConfirmOrder;
