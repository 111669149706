import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Layout, Row, Col, Divider, Menu, Affix } from "antd";

import AccountInformation from "./AccountInformation";
import PageWrapper from "Components/PageWrapper";
import CustomizedButton from "Components/CustomizedButton";

import { mixPanelTrack } from "Util/CustomFunctions";

const { Content } = Layout;

const NewAddressBook = (props) => {
  const { t } = useTranslation('addressBookPage');
  const [isLoading, setIsLoading] = useState(true);
  const { contactId, customerId } = props.match.params;

  useEffect(() => {
    if(contactId)
    {
        mixPanelTrack('ViewAddressBookEdit');
    }else {
        mixPanelTrack('ViewAddressBookAdd');
    }
  }, []);

  return (
    <PageWrapper loading={isLoading} currentPath={props.location}>
      <Layout>
        <Content
          className="ui-right-content form"
          style={{
            minHeight: "unset",
            marginBottom: 0,
            flex: "initial"
          }}
        >
          <Row style={{ marginTop: 20 }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <div style={{ margin: 0, display: 'flex', flexDirection: 'column' }}>
                <Link to={props.prevPath || process.env.PUBLIC_URL + '/contacts'}>
                  <i className="fas fa-angle-left" style={{ marginRight: 5 }} />
                  Address Book
                </Link>
              </div>
            </Col>
          </Row>
          <Row style={{ marginTop: 20 }}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12} style={{ marginTop: 20 }}>
              <p className="heading1" style={{ margin: 0, float: "left" }}>
                Contact information
              </p>
            </Col>
            <Col
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={12}
              className="navigation-table"
            >
              <Link to={`${process.env.PUBLIC_URL}/contacts/add`}>
                <CustomizedButton
                  text={t('content.header.addNewContact')}
                  type="primary"
                />
              </Link>
            </Col>
          </Row>
        </Content>
        <Divider style={{ marginTop: 0 }} />
        <Content className="ui-right-content form" style={{ paddingTop: 0 }}>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={7} xl={7}>
              <Affix offsetTop={70}>
                <Menu
                  defaultOpenKeys={["1"]}
                  mode="inline"
                  defaultSelectedKeys={["1"]}
                >
                  <Menu.Item key="1">
                    {t('content.form.contactInformation')}
                  </Menu.Item>
                </Menu>
              </Affix>
            </Col>
            <Col xs={24} sm={24} md={24} lg={17} xl={17}>
              <AccountInformation
                setIsLoading={setIsLoading}
                isLoading={isLoading}
                contactId={contactId}
                contactCustomerId={customerId}
                {...props}
              />
            </Col>
          </Row>
        </Content>
      </Layout>
    </PageWrapper>
  )
}

export default NewAddressBook;
