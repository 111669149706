import React from "react";
import { Form, Input } from "antd";

const LabelledTextInput = React.memo(({
  error,
  placeholder,
  type,
  prefix,
  suffix,
  label,
  addonAfter,
  addonBefore,
  size,
  disabled,
  viewOnly,
  className,
  style,
  step,
  value,
  onChange,
  id,
  required
}) => {
  // console.log('LabelledTextInput', value);
  return (
    <Form.Item
      validateStatus={disabled ? null : error && "error"}
      required={required}
      help={disabled ? null : error}
      label={label}
      className={className}
      style={style}
      noStyle
    >
      <Input
        id={id}
        disabled={disabled}
        style={{ color: viewOnly && '#000000a6', border: viewOnly && 'none' }}
        prefix={prefix}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        type={type}
        size={size}
        step={step}
        suffix={suffix}
      />
    </Form.Item>
  );
});

export default LabelledTextInput;
