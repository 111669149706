import React, { Fragment, useContext, useEffect, useState, useCallback, useMemo } from 'react';
import { Row, Select, Spin, Form, Alert, Statistic, Input, Tooltip, message } from "antd";

import { LabelledDropdown, LabelledCheckbox } from 'Components/optimized-input'
import ShowOn from 'Util/ShowOn';
import useValidateField from 'Util/Hooks/useValidateField'
import { GlobalContext } from "Store/store";
import { IMAGE_URL_ROOT, get } from 'Util/API';
import useGenerateDropdownItem from 'Util/useGenerateDropdownItem';
import useUpdateServices from './useUpdateServices';

import checkPrice from './checkPrice';
import { useTranslation } from "react-i18next";

import { displayDurationValue, roundCurrencyValue } from "Util/CustomFunctions";

const ChooseService = (props) => {
  const [state] = useContext(GlobalContext);
  const { customerDetails, companyDetails } = state.global;
  const { t } = useTranslation('orderPage');
  const { form } = state.newOrder;
  const { values, errors, handleChange, setFieldValue, validateField } = form;
  const [dropdownChildren, setDropdownChildren] = useState(null);
  const [showItem, setShowItem] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const { selectedServiceIndex, services } = values;
  const selectedService = selectedServiceIndex && services && services[Number(selectedServiceIndex)];
  const [sectionLoading, setSectionLoading] = useState(false);
  const [showAlert, setShowAlert] = useState({
    show: false,
    message: null,
    description: null,
    type: 'error'
  });
  useUpdateServices(form, setSectionLoading);
  useValidateField('selectedServiceIndex', selectedServiceIndex, validateField, true);

  useEffect(() => {
    if (values.errors.length > 0) {
      setShowAlert({
        ...showAlert,
        show: true,
        message: 'Errors!',
        description: (
          <ul>
            {
              values.errors.map((item) => (
                <li>{item.error.message}</li>
              ))
            }
          </ul>
        )
      })
    }
  }, [values.errors]);

  useEffect(() => {
    let mount = true;
    if (selectedService && mount) {
      setFieldValue('addon', {});
      getOrderPaymentMethod();
    }
    return () => {
      mount = false;
    }
  }, [selectedServiceIndex])

  useEffect(() => {
    let arr = [];
    if (values.services) {
      if (values.services?.length > 0) {
        values.services.forEach((item, i) => {
          arr.push(
            <Select.Option
              key={item.service.code}
              value={i.toString()}
            >
              {item.service.name}
            </Select.Option>
          );
        });
      } else message.error('No available service.')
    }
    setDropdownChildren(arr);
    setIsLoading(false)
  }, [values.services])

  useEffect(() => {
    if (showItem) {
      setIsLoading();
      onGetAvailableServices()
    }
  }, [showItem])

  const onCloseAlert = () => {
    setShowAlert({
      show: false,
      message: null,
      description: null,
      type: 'error'
    })
  }

  const getOrderPaymentMethod = async () => {
    const fetch = await get(`order/paymentMethod?customerId=${customerDetails.id}&serviceId=${selectedService.service.id}`);
    if (fetch.status === 200) {
      let paymentMethodList = fetch.data.data;
      const price = (selectedServiceIndex && services?.[Number(selectedServiceIndex)]?.price.amount) || 0;
      if (Number(customerDetails.walletBalance) < price) {
        paymentMethodList = paymentMethodList.map((item) => {
          if (item.id === 0) {
            item.disabled = true;
          }
          return item
        })
      }
      // if (price)
      setPaymentMethods(paymentMethodList);

      if(paymentMethodList.length > 0) setFieldValue('paymentMethodId', paymentMethodList[0].id)

    } else message.error(fetch);
  }

  const onGetAvailableServices = () => {
    setIsLoading(true);
    form.validateForm({ ...form.values, selectedServiceIndex: '-1', paymentMethodId: '-1' })
      .then(async (result) => {
        const formValues = { ...form.values, selectedServiceIndex: null };
        if (Object.keys(result).length === 0 || (Object.keys(result).length === 1 && Object.keys(result)[0] === 'selectedServiceIndex')) {
          const fetch = await checkPrice(formValues, companyDetails.id, customerDetails.id);
          if (fetch.status === 200) {
            const { data } = fetch.data;
            form.setFieldValue('services', data.services);
          } else {
            message.error(fetch);
          };
        } else message.error('Please complete your form before select service.')
        setShowItem(false);
        setIsLoading(false)
      })
  };

  const showAddon = selectedServiceIndex && services && services[Number(selectedServiceIndex)]?.service.addon.length > 0;
  const price = (selectedServiceIndex && services?.[Number(selectedServiceIndex)]?.price.amount) || 0;

  const onChangeAddon = (item, checked) => {
    if (!checked) {
      const addonCopy = values.addon;
      delete addonCopy[item.id];
      setFieldValue('addon', { ...addonCopy });
    } else {
      setFieldValue('addon', { ...values.addon, [item.id]: { ...values.addon[item.id] } })
    }
  }

  return (
      <Row>
        <p className="heading3">{t('content.newOrder.chooseService')}</p>
        <Spin spinning={sectionLoading}>
          <div style={{ backgroundColor: "white", padding: "30px", width: "100%" }}>
            <LabelledDropdown
              id="selectedServiceIndex"
              optionFilterProp="children"
              children={dropdownChildren}
              label={t('content.newOrder.service.service')}
              placeholder={t('content.newOrder.service.selectService')}
              labelStyle={useMemo(() => ({ marginBottom: 10 }), [])}
              error={errors.selectedServiceIndex}
              value={values.selectedServiceIndex || undefined}
              notFoundContent={
                isLoading ? <div style={{ width: '100%', textAlign: 'center' }}><Spin size="small" tip="Searching available services..." /></div> : null}
              onDropdownVisibleChange={useCallback((val) => setShowItem(val), [])}
              onChange={useCallback(handleChange('selectedServiceIndex'), [])}
              required
            />
            <ShowOn on={selectedServiceIndex && services?.[Number(selectedServiceIndex)]?.service.serviceCompany.logo}>
            <div style={{marginTop:20}}>
            <img src={IMAGE_URL_ROOT + '/' + services?.[Number(selectedServiceIndex)]?.service.serviceCompany.logo} height="32" style={{ marginRight:10 }} />
            <b>{services?.[Number(selectedServiceIndex)]?.service.serviceCompany.name}</b> <br/><br/>

              { (companyDetails.code.length <= 5) ?
              <span>
              { (services?.[Number(selectedServiceIndex)]?.duration) ?
              <Tooltip title="The estimate is based on the service performance for the past 30 days">
              <b>Estimate (?)</b>  &nbsp;
              {displayDurationValue(services?.[Number(selectedServiceIndex)]?.duration)}
              <br/><br/>
              </Tooltip>
              :''}
              </span>
              :''}

            </div>
            </ShowOn>
            <ShowOn on={selectedServiceIndex && services?.[Number(selectedServiceIndex)]?.service.custDescription}>
              <div style={{ marginBottom: 24 }}>
                <Alert
                  message={t('content.newOrder.service.serviceInfo')}
                  description={<div dangerouslySetInnerHTML={{ __html: `${services?.[Number(selectedServiceIndex)]?.service.custDescription}` }} />}
                  type="info"
                />
              </div>
            </ShowOn>
            <ShowOn on={showAddon}>
              <Form.Item label={t('content.newOrder.service.addOns')}>
                {
                  services?.[Number(selectedServiceIndex)]?.service.addon?.map((item) => (
                    <Fragment>
                      <LabelledCheckbox
                        id={item.id}
                        text={item.name}
                        checked={!!values.addon[item.id]}
                        description={item.description}
                        onChange={e => onChangeAddon(item, e.target.checked)}
                      />
                      {
                        !!values.addon[item.id] && item.required?.map((requiredItem) => (
                          <Input
                            placeholder={requiredItem.name}
                            onChange={e =>
                              {
                                  setFieldValue('addon', { ...values.addon, [item.id]: { ...values.addon[item.id], [requiredItem.key]: roundCurrencyValue(companyDetails.currency,e.target.value) } });
                              }
                            }
                          />
                        ))
                      }
                    </Fragment>
                  ))
                }
              </Form.Item>
            </ShowOn>
            <ShowOn on={selectedServiceIndex}>
              <LabelledDropdown
                error={errors.paymentMethodId}
                value={values.paymentMethodId}
                dropdownMatchSelectWidth={false}
                placeholder={t('content.newOrder.service.paymentMethod')}
                getPopupContainer={useCallback(() => document.getElementById('footer'), [])}
                labelStyle={useMemo(() => ({ marginBottom: 10 }), [])}
                children={useGenerateDropdownItem(paymentMethods, 'id', 'id', 'name')}
                onChange={useCallback((val) => setFieldValue('paymentMethodId', val), [])}
                required
              />
              <Input.Search
                placeholder="Discount code"
                enterButton="Apply"
                allowClear
                onSearch={value => form.setFieldValue('promoCode', value)}
                style={useMemo(() => ({ top: 0, marginBottom: 10 }), [])}
              />
              <ShowOn on={showAlert.show && values.errors.length > 0}>
                <Alert
                  message={showAlert.message}
                  description={showAlert.description}
                  type={showAlert.type}
                  closable
                  onClose={onCloseAlert}
                />
              </ShowOn>
              <div style={{ marginTop: 10, padding: '10px', boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1px 3px 1px', display: 'flex', justifyContent: 'center' }}>
                <Statistic title={t('content.newOrder.service.totalPrice')} value={companyDetails.currency + ' ' + price} />
              </div>
            </ShowOn>
          </div>
        </Spin>
      </Row>

  )
}

export default ChooseService;
