import React, { useContext, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Layout, Form, Divider, Card, Spin, message, Alert } from "antd";
import { Link } from "react-router-dom";

import mixpanel from 'mixpanel-browser';

import useLocalStorage from 'Util/useLocalStorage';
import { login } from "Util/API";
import { GlobalContext } from 'Store/store';
import TextInput from "Components/TextInput";
import PasswordInput from "Components/PasswordInput";
import CheckBox from "Components/Checkbox2";
import CustomizedButton from "Components/CustomizedButton";
import ShowOn from 'Util/ShowOn';
import {
  TEXT_EMAIL_ADDRESS,
  TEXT_PASSWORD,
  TEXT_REMEMBER_ME,
  TEXT_LOG_IN,
  TEXT_COMPANY_CODE
} from "Util/Text";
import {
  MESSAGE_INVALID_EMAIL_ADDRESS,
  MESSAGE_EMAIL_REQUIRED,
  MESSAGE_PASSWORD_REQUIRED
} from "Util/ErrorMessage";

import { IMAGE_URL_ROOT, post } from 'Util/API';
import SocialLogin from './SocialLogin';
import useShowMessage from 'Util/useShowMessage';

const LoginPage = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { companyDetails } = state.global;
  const [isLoading, setIsLoading] = useState(false);
  const { socialNetwork } = companyDetails;
  const [initVal, setInitVal] = useState({});
  const [showVerifyAcc, setShowVerifyAcc] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showInfoMsg, setShowInfoMsg] = useState(null);

  useShowMessage(isLoading, props.history.location?.state)

  useEffect(() => {
    setInitVal({
      username: useLocalStorage.getItem("username"),
      password: useLocalStorage.getItem("password"),
      remember: useLocalStorage.getItem("remember")
    })

    const infoMsg = new URLSearchParams(window.location.search).get('infoMsg');
    if (infoMsg) {
      setShowInfoMsg(infoMsg);
    }

  }, [])

  const onSubmitLogin = async (values) => {
    setIsLoading(true);
    let data = {
      companyCode: values.companyCode,
      username: values.email,
      password: values.password,
      remember: values.remember
    };
    const signinResponse = await login("auth/login", data);
    if (signinResponse.status === 200) {
      const { accessToken, refreshToken, companyId } = signinResponse.data.data;
      useLocalStorage.clear();
      useLocalStorage.setItem("companyId", companyId);
      useLocalStorage.setItem("accessToken", accessToken);
      useLocalStorage.setItem("refreshToken", refreshToken);
      if (values.remember) {
        useLocalStorage.setItem("username", data.username);
        useLocalStorage.setItem("password", data.password);
        useLocalStorage.setItem("remember", true);
      }

      //mixpanel
      let mpptoken = useLocalStorage.getItem("mpToken");      
      if(mpptoken)
      {
          mixpanel.init(mpptoken);
          mixpanel.track('Log in - Web', {
            'userId': signinResponse.data.data.userId
          });
      }
      //mixpanel

      dispatch({ type: 'LOGIN_SUCCESS' })
    } else if (signinResponse.error?.code === "ERR_AUTH_U00") {
      handleNeedActivationError(signinResponse.data.userId, signinResponse.error.message)
    } else message.error(signinResponse.error.message);
    setIsLoading(false);
  }

  const onRequestNewCode = async (email) => {
    const requestNewCode = await post('auth/verify/email', { userId, email });
    if (requestNewCode.status === 200) {
      message.success('Request successfull. Check your email.')
    } else message.error(requestNewCode);
  }

  const handleNeedActivationError = (uid, errorMsg) => {
    setShowVerifyAcc(true);
    setUserId(uid)
    setIsLoading(false);
    message.error(errorMsg);
  }

  return (
    <Spin spinning={isLoading} size="large">
      <Layout style={{ minHeight: "100vh" }}>
        <div className="center">
          <div className="logo-company">
            <img
              style={{ height: 64, margin: '0 20px' }}
              src={
                companyDetails.logob ?
                  IMAGE_URL_ROOT + companyDetails.logob :
                  'https://cdn.delyva.app/assets/delyvax-logo-b.svg'
                }
              alt="company logo"
            />
          </div>
          <p
            className="heading2"
            style={{ textAlign: "center", fontWeight: 400 }}
          >
            Log in
          </p>

          {showInfoMsg && (<Alert
            style={{ 'margin-bottom': '20px' }}
            description={showInfoMsg}
            type="info"
            showIcon
          />)}

            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                companyCode: companyDetails.code,
                email: initVal.username,
                password: initVal.password,
                remember: initVal.remember
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string()
                  .email(MESSAGE_INVALID_EMAIL_ADDRESS)
                  .required(MESSAGE_EMAIL_REQUIRED),
                password: Yup.string().required(MESSAGE_PASSWORD_REQUIRED)
              })}
              onSubmit={onSubmitLogin}
            >
              {form => (
                <Form onSubmit={form.handleSubmit} className="login-form">
                  <Card>
                    {/*<ShowOn on={(socialNetwork && (socialNetwork.google.enabled || socialNetwork.facebook.enabled))}>*/}
                    <ShowOn on={false}>
                      <SocialLogin
                        dispatch={dispatch}
                        socialNetwork={socialNetwork}
                        handleNeedActivationError={handleNeedActivationError}
                      />
                      <Divider> or </Divider>
                    </ShowOn>
                    {companyDetails.id ? null : (
                      <span>
                        <TextInput
                          {...form}
                          name="companyCode"
                          placeholder={TEXT_COMPANY_CODE}
                          type="text"
                          className="marginBottom-10"
                        />
                      </span>
                    )}
                    <TextInput
                      {...form}
                      name="email"
                      placeholder={TEXT_EMAIL_ADDRESS}
                      type="text"
                      className="marginBottom-10"
                    />
                    <PasswordInput
                      {...form}
                      name="password"
                      placeholder={TEXT_PASSWORD}
                      type="password"
                      className="marginBottom-10"
                    />
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: 10 }}>
                      <CheckBox
                        {...form}
                        name="remember"
                        checked={form.values.remember}
                        text={TEXT_REMEMBER_ME}
                        style={{ marginBottom: 0 }}
                      />
                      <Link
                        to={`${process.env.PUBLIC_URL}/forgot-password`}
                      >
                        <CustomizedButton
                          type="link"
                          text="Forgot Password"
                        />
                      </Link>
                    </div>
                    <CustomizedButton
                      type="primary"
                      htmlType="submit"
                      isBlockType={true}
                      className="login-form-button"
                      text={TEXT_LOG_IN}
                      isLoading={isLoading}
                    />
                  </Card>
                  {showVerifyAcc && (
                    <div style={{ display: 'flex', alignItems: 'baseline', justifyContent: 'space-between', padding: 24, paddingBottom: 0 }}>
                      <div>Verification code not received?</div>
                      <CustomizedButton
                        text="Request new code"
                        type="primary"
                        onClick={() => onRequestNewCode(form.values.email)}
                      />
                    </div>
                  )}
                  <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 10 }}>
                    <div>
                      <span>{"Don't have an account?"}</span>
                      <Link to={`${process.env.PUBLIC_URL}/signup`}>
                        <CustomizedButton
                          type="link"
                          text={"Sign up"}
                        />
                      </Link>
                    </div>
                    <Link to={`${process.env.PUBLIC_URL}/verify`}>
                      <CustomizedButton
                        text="Verify your account"
                        type="link"
                      />
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
        </div>
      </Layout>
    </Spin>
  )
}

export default LoginPage;
