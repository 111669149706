import React, { useContext } from "react";
import { Row, Col, message } from "antd";

import { GlobalContext } from "Store/store";
import CustomizedButton from "Components/CustomizedButton";
import useWindowSize from 'Util/useWindowSize';
import withStore from 'Util/withStore';
import { useTranslation } from "react-i18next"

const styles = {
  footer: {
    boxShadow: "1px -2px 5px 1px rgb(0, 0, 0, 0.15)",
    backgroundColor: "white",
    display: "flex",
    justifyContent: 'center',
    padding: '20px',
    bottom: 0,
    right: 0,
    zIndex: 1
  },
  footerRow: {
    width: "100%",
    margin: 0
  }
}

const SubmitFooter = (props) => {
  const { t } = useTranslation('orderPage');
  const [state] = useContext(GlobalContext);
  const { footerWidth, companyDetails, customerDetails, settings, topupPlan } = state.global;
  const { form, onOpenConfirmOrderSuccessDialog } = state.newOrder;
  const { selectedServiceIndex, services, paymentMethodId } = form.values;
  const size = useWindowSize();

  const sortedTopupPlan = topupPlan.prices?.sort(function(a, b){return a.value - b.value}) || [];
  const sortedTopupPlanPrices = sortedTopupPlan.map((item) => item.value);
  const minOrderAmout = sortedTopupPlanPrices[0] || settings["orders.create_an_order"]?.data?.min_order_amount;
  const price = (selectedServiceIndex && services?.[Number(selectedServiceIndex)]?.price.amount) || 0;

  return (
    <div id="footer" style={{ ...styles.footer, width: footerWidth }}>
      <Row style={{ width: footerWidth, display: size[0] > 768 ? 'flex' : 'initial', flexDirection: 'row-reverse' }} gutter={[20, 20]}>
        <Col xs={24} sm={24} md={16} lg={8} xl={6}>
          <CustomizedButton
            style={{ width: '100%' }}
            className="button-success button-fullWidth"
            text={t('content.newOrder.footer.confirmOrder')}
            isDisabled={form.values.errors.length > 0}
            onClick={() => {
              const data = form.values;

              if(customerDetails && customerDetails.billing && customerDetails.billing.paymentTerm == 'debit' && customerDetails.walletBalance < Number(price))
              {
                  message.error('Please top-up your account. You have insufficient credit balance. Balance: '+companyDetails.currency+' '+customerDetails.walletBalance+' vs Price: '+companyDetails.currency+' '+Number(price)+'.')
              }else if ((paymentMethodId > 0 && (Number(price) >= minOrderAmout)) || paymentMethodId <= 0) {
                data.process = true;
                data.onOpenConfirmOrderSuccessDialog = (id, statusCode) => onOpenConfirmOrderSuccessDialog(id, statusCode)
                form.handleSubmit(data);
              } else message.error(`Your order must exceed ${companyDetails.currency} ${minOrderAmout} to use payment gateway`)
            }}
          />
        </Col>
        <Col xs={24} sm={24} md={8} lg={16} xl={12}>
          <Row gutter={[20, 0]}>
            <Col xs={24} sm={12} md={24} lg={12} xl={12}>
              <CustomizedButton
                style={{ width: '100%', marginBottom: 10 }}
                text={t('content.newOrder.footer.saveAndAdd')}
                onClick={() => {
                  const data = form.values;
                  data.process = false;
                  // data.selectedServiceIndex = '-1';
                  data.paymentMethodId = '-1';
                  data.addAnother = true;
                  form.handleSubmit(data);
                }}
              />
            </Col>
            <Col xs={24} sm={12} md={24} lg={12} xl={12}>
              <CustomizedButton
                style={{ width: '100%', marginBottom: 10 }}
                text={t('content.newOrder.footer.savedraft')}
                onClick={() => {
                  const data = form.values;
                  data.process = false;
                  // data.selectedServiceIndex = '-1';
                  data.paymentMethodId = '-1';
                  form.handleSubmit(data);
                }}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  )
}

export default withStore(SubmitFooter);
