import React, { Component } from "react";
import { Drawer, Menu, Dropdown, Icon } from "antd";
import { Link } from "react-router-dom";
import { submenus, sidebarMenus, sidebarPageMenus } from "../Util/ArrayObjects";
import { TEXT_ROAD_TAX } from "Util/Text";

const submenu = (
  <Menu>
    {submenus.map(submenu => (
      <Menu.Item key={submenu.key}>
        <Link to={submenu.path}>
          <i className={submenu.icon} />
          {submenu.title}
        </Link>
      </Menu.Item>
    ))}
  </Menu>
);

class MobileSidebar extends Component {
  render() {
    return (
      <Drawer
        placement="left"
        closable={false}
        onClose={this.props.onClose}
        visible={this.props.visible}
      >
        <Menu key="1" mode="vertical" style={{ paddingTop: 10 }}>
          <Menu.Item style={{ background: "#fff" }}>
            <Link to={`${process.env.PUBLIC_URL}/orders/new-order`}>
              <span
                className="button-new-order small"
                style={{ whiteSpace: "nowrap" }}
              />
              <Dropdown.Button
                trigger={["click"]}
                type="primary"
                className="button-full-width"
                overlay={submenu}
                icon={<Icon type="down" />}
              >
                New Order
              </Dropdown.Button>
            </Link>
          </Menu.Item>

          <Menu.Item key="sectionActions" className="menu">
            <span>Actions</span>
          </Menu.Item>

          {sidebarMenus.map(menu => 
          {
            return menu.path.includes('http') ?
            <Menu.Item key={menu.key} className={menu.menu}>              
              <a href= {menu.path} target="_blank" >
                <i className={menu.icon} />
                <span>{menu.title}</span>
              </a>
            </Menu.Item>
            :
            <Menu.Item key={menu.key} className={menu.menu}>
              <Link
                to={menu.path}
                className={menu.key === "2" ? menu.link : null}
              >
                <i className={menu.icon} />
                <span>{menu.title}</span>
              </Link>
            </Menu.Item>
          }
          )}

          <Menu.Item key="sectionActions" className="menu">
            <span>Pages</span>
          </Menu.Item>

          {sidebarPageMenus.map(menu => (
            (menu.key != 'apps') ?
            <Menu.Item key={menu.key} className={menu.menu}>
              <Link
                to={menu.path}
                className={menu.key === "2" ? menu.link : null}
              >
                <i className={menu.icon} />
                <span>{menu.title}</span>
              </Link>
            </Menu.Item>
            :''
          ))}
        </Menu>
      </Drawer>
    );
  }
}

export default MobileSidebar;
