import React, { useContext } from "react";

import { Table, List } from "antd";

import { GlobalContext } from "Store/store";
import { useTranslation } from "react-i18next";

const Inventories = (props) => {
  const { t } = useTranslation('orderPage');
  const [state] = useContext(GlobalContext);
  const { companyDetails } = state.global;

  let dataSource = [];
  const pickupPoint = props.selectedOrder.waypoint.filter((item) => item.type === 'PICKUP');
  const dropoffPoint = props.selectedOrder.waypoint.filter((item) => item.type === 'DROPOFF');
  const selectedPoint = pickupPoint.length > 0 ? pickupPoint : dropoffPoint;
  selectedPoint.forEach((item) => {
    dataSource = dataSource.concat(item.inventory)
  })

  const column = [
    {
      title: t('content.orderDetail.iventory.type'),
      dataIndex: "type",
      key: "type"
    },
    {
      title: 'Name',
      dataIndex: "name",
      key: "name"
    },
    {
      title: t('content.orderDetail.iventory.weight'),
      dataIndex: "weight",
      key: "weight",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.weight?.value - b.weight?.value,
      render: text => (
        <p>
          {text.value} {text.unit}
        </p>
      )
    },
    {
      title: t('content.orderDetail.iventory.dimension'),
      dataIndex: "dimension",
      key: "dimension",
      render: (value, record) => (
        <div>
          Width: {value ? value.width.toFixed(2) : '-'} {value ? value.unit:'cm'}<br/>
          Length: {value ? value.length.toFixed(2) : '-'} {value ? value.unit:'cm'}<br/>
          Height: {value ? value.height.toFixed(2) : '-'} {value ? value.unit:'cm'}
        </div>
      )
    },
    {
      title: t('content.orderDetail.iventory.quantity'),
      dataIndex: "quantity",
      key: "quantity",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.quantity - b.quantity
    },
    {
      title: t('content.orderDetail.iventory.value'),
      dataIndex: "price",
      key: "value",
      defaultSortOrder: "descend",
      sorter: (a, b) => a.value.amount - b.value.amount,
      render: text => (
        <p>
          {companyDetails.currency + ' ' + text.amount}
        </p>
      )
    },
    {
      title: t('content.orderDetail.iventory.taxShipping'),
      dataIndex: "taxShipping",
      key: "taxShipping",
      render: text => {
        return (
          <span>{text || 'N/A'}</span>
        );
      }
    },
    {
      title: t('content.orderDetail.iventory.total'),
      dataIndex: "price",
      key: "total",
      defaultSortOrder: "descend",
      render: (text, record) => (
        <p>
          {text.currency + ' ' + text.amount * record.quantity}
        </p>
      )
    },
    {
      title: 'HS Code',
      dataIndex: "hsCode",
      key: "hsCode"
    },
    {
      title: 'Description',
      dataIndex: "description",
      key: "description",
      width: 250,
      render: (description, record) =>
      (
          <div>
          {description ? <span dangerouslySetInnerHTML={{ __html: description }} /> : '-'}
          </div>
      )
    }
  ];

  return (
    <List
      bordered
      style={{ backgroundColor: "white", marginBottom: "20px" }}
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="heading3" style={{ margin: 0 }}>
            {t('content.orderDetail.iventory.title')}
          </p>
        </div>
      }
    >
      <Table
        loading={props.isLoading}
        columns={column}
        dataSource={dataSource}
        scroll={{ x: 1290 }}
      />
    </List>
  )
}

export default Inventories;
