import React, { useContext, useState, useEffect } from 'react';
import { Input, Timeline, message, Layout, Menu, Card, Col, Row, Result, Button, Rate, Alert, Modal, Form, Avatar, Tooltip  } from 'antd';
import { Link, useParams } from "react-router-dom";
import moment from 'moment'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import PublicHeader from 'Components/PublicHeader';
import CustomizedButton from 'Components/CustomizedButton';
import { GlobalContext } from 'Store/store';
import { IMAGE_URL_ROOT } from 'Util/API';
import { post, get } from 'Util/API';
import CustomizedModal from "Components/CustomizedModal";
import useWindowSize from 'Util/useWindowSize';

import { Formik } from "formik";
import ReactHtmlParser from 'react-html-parser';

import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module'

import { displayEstimatedDeliveryDate } from 'Util/CustomFunctions';

const { Header, Content, Footer } = Layout;
const { TextArea } = Input;

const timelineColor = statusCode => {
  switch (statusCode) {
    case 100:
      return "#445A64";
    case 110:
      return "#445A64";
    case 200:
      return "#445A64";
    case 475:
      return "#E91E63";
    case 500:
      return "#ff8000";
    case 650:
      return "#E91E63";
    case 651:
      return "#E91E63";
    case 655:
      return "#E91E63";
    case 700:
      return "#00C853";
    case 900:
      return "#ff0000";
    case 1000:
      return "#00C853";
    default:
      return "#ff8000";
  }
};

const calculateETA = date => {

};

const modalStateInitialVal = {
  isModalVisible: false,
  footer: true,
  bodyStyle: null,
  maskStyle: null,
  closable: true,
  modal: {
    type: null,
    title: null,
    onOk: null,
    onOkData: null,
    okText: 'Confirm',
    onCancel: null,
    text: null,
    maxWidth: 400,
    dialogContent: null,
    dialogContentProps: {}
  }
}

const SecureTracking = (props) => {
  const [state] = useContext(GlobalContext);
  const { companyDetails, customerDetails } = state.global;
  const { history } = props;
  const [promo, showPromo] = useState(false);

  const urlParams = new URLSearchParams(props.location.search);

  // Get ID from URL
  const params = useParams();
  const nanoId = params.nanoid;

  let trackingNo = urlParams.get("trackingNo");
  const [consignmentNo, setConsignmentNo] = useState(trackingNo || '');
  const [trackingData, setTrackingData] = useState({ histories: [], description: null });
  const [orderData, setOrderData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [modalState, setModalState] = useState(modalStateInitialVal);
  const { dialogContent: DialogContent } = modalState.modal;
  const size = useWindowSize();

  const [brandingData, setBrandingData] = useState({
        logo: companyDetails.logob,
        name: companyDetails.brandName ? companyDetails.brandName:companyDetails.name,
        description: companyDetails.name,
        url: companyDetails.websiteUrl,
        colorPrimary: companyDetails.personalization.primaryColor,
        // colorBg: companyDetails.personalization.secondaryColor,
        colorBg: '#f0f2f5',
        supportEmail: companyDetails.supportEmail,
        supportPhone: companyDetails.supportPhone,
        supportUrl: companyDetails.supportUrl});
  const [promoBanner, setPromoBanner] = useState({ img: null, title:null, description: null, url: null });
  const [popupBanner, setPopupBanner] = useState({ img: null, title:null, description: null, url: null });
  const [notice, setNotice] = useState({ title: null, description:null, cta: null, url: null });
  const [pixels, setPixels] = useState({ pixelFb: null, pixelGoogleAnalytics:null, pixelGoogleAds: null, pixelMixpanel: null, pixelTiktok: null });

  useEffect(() => {
    document.title = brandingData.name+' Secure Track & Trace for '+consignmentNo;
  }, [])

  useEffect(() => {
    getSecuredTrackingDetails();
  }, [nanoId,trackingNo])

  const getSettings = async () => {
      // const alertSettings = await get(`company/${companyDetails.id}/guest-settings/?name=consumer_portal.customer_mobileapp`);
      // if (alertSettings.status === 200) {
      //     console.log('alertSettings',alertSettings);
      // }
      //
      // const feedSettings = await get(`company/${companyDetails.id}/guest-settings/?name=consumer_portal.dashboard`);
      // if (feedSettings.status === 200) {
      //     console.log('feedSettings',feedSettings);
      // }

      const bannerSettings = await get(`company/${companyDetails.id}/guest-settings/?name=consumer_app.banner_settings`);
      if (bannerSettings.status === 200) {
          const consumer_app_banner_settings = bannerSettings.data.data.length >0 ? bannerSettings.data.data[0]:null;
          if(consumer_app_banner_settings.data.length > 0)
          {
              setPromoBanner({img: consumer_app_banner_settings.data[0].imageURL, title: consumer_app_banner_settings.data[0].title, description: consumer_app_banner_settings.data[0].description, url: consumer_app_banner_settings.data[0].linkURL})
          }
      }
  }

  const getSecuredTrackingDetails = async () => {
    setIsLoading(true);
    setOrderData({});

    if (nanoId?.length > 3)
    {
        const fetch = await get("etrack/"+nanoId);
        if (fetch.status === 200) {
          setOrderData(fetch.data.data);
          trackingNo = fetch.data.data.consignmentNo;
          setConsignmentNo(fetch.data.data.consignmentNo);
          setTrackingData(fetch.data.data.tracking);

          const orderData = fetch.data.data;

          if(orderData.branded)
          {
              //set branding data
              setBrandingData({
                  logo: "/"+orderData.branding.logo,
                  name: orderData.branding.name,
                  description: orderData.branding.description,
                  url: orderData.branding.url,
                  colorPrimary: orderData.branding.colorPrimary,
                  colorBg: orderData.branding.colorBg,
                  supportEmail: orderData.branding.supportEmail,
                  supportPhone: orderData.branding.supportPhone,
                  supportUrl: orderData.branding.supportUrl
              });

              //alert-announcement
              setNotice({ title: orderData.branding.promoNoticeTitle, description:orderData.branding.promoNoticeDescription, cta: orderData.branding.promoNoticeCTA, url: orderData.branding.promoNoticeUrl });

              //promoBanner
              setPromoBanner({img: "/"+orderData.branding.promoBannerImg, title: orderData.branding.promoBannerDesc, description: orderData.branding.promoBannerDesc, url: orderData.branding.promoBannerUrl})
              //popupBanner
              setPopupBanner({img: "/"+orderData.branding.promoPopupImg, title: orderData.branding.promoPopupDesc, description: orderData.branding.promoPopupDesc, url: orderData.branding.promoPopupUrl})

              if(orderData.branding.promoPopupImg)
              {
                  showPromo(true);
              }

              //pixels
              if(orderData.branding.pixelFb)
              {
                ReactPixel.init(orderData.branding.pixelFb, {}, { autoConfig: true, debug: false })
                ReactPixel.pageView();
              }
              if(orderData.branding.pixelGoogleAnalytics)
              {
                  ReactGA.initialize(orderData.branding.pixelGoogleAnalytics, { debug: false });
                  ReactGA.plugin.require('ecommerce', { path: '/log', debug: false });
              }
              if(orderData.branding.pixelGoogleAds)
              {
                  const tagManagerArgs = {
                    gtmId: orderData.branding.pixelGoogleAds
                  }
                  TagManager.initialize(tagManagerArgs)
              }
              //
          }else {
              getSettings();
          }

          !fetch.data.data && message.error('No order data available')

        } else message.error(fetch);
    }else {
        getSettings();
    }

    setIsLoading(false)
  }

  const onSubmitRate = async (values) => {
    !isLoading && setIsLoading(true);

    const rateData = { ...values };

    const rateOrderRes = await post("order/"+orderData.id+"/rate",
      { rating:rateData.rating, ratingNote:rateData.ratingNote, nanoId:orderData.nanoId, companyId:companyDetails.id }
    );

    if (rateOrderRes.status === 200) {

      message.success("Awesome thank you!");
    } else message.error(rateOrderRes);

    isLoading && setIsLoading(false);
  }

  return (

    <Layout className="layout" theme="light" style={{ backgroundColor: brandingData.colorBg }}>
      <Header style={{ zIndex: 1, width: '100%', backgroundColor:'#ffffff' }}>
         <div className="logo" >
           <a href={brandingData.url} target="_blank">
             <img
               style={{ height: 35, marginBottom: 10 }}
               src={
                 brandingData.logo ?
                   IMAGE_URL_ROOT + brandingData.logo :
                   'https://cdn.delyva.app/assets/delyvax-logo-b.svg'
                 }
               alt={brandingData.name}
             />
           </a>
         </div>
         <Menu theme="light" mode="horizontal" style={{marginTop: 10}}>
            {brandingData.url?
            <Menu.Item>
            <a href={brandingData.url} target="_blank">
            Visit website
            </a>
            </Menu.Item>
            :''}

            { (brandingData.supportPhone || brandingData.supportEmail || brandingData.supportUrl) ?
            <Menu.SubMenu key="supportSubMenu" title="Contact Support" >
              {brandingData.supportUrl ?
              <Menu.Item key="supportUrl" >
                <a href={brandingData.supportUrl} target="_blank">Chat Support</a>
              </Menu.Item>
              :''}
              {brandingData.supportEmail ?
              <Menu.Item key="supportEmail" >
                <a href={"mailto:"+brandingData.supportEmail}>E-mail Support</a>
              </Menu.Item>
              :''}
              {brandingData.supportPhone?
              <Menu.Item key="supportPhone" >
                <a href={"tel:"+brandingData.supportPhone}>Phone Support</a>
              </Menu.Item>
              :''}
              {brandingData.supportPhone?
              <Menu.Item key="supportWA" >
                <a href={"https://api.whatsapp.com/send/?text="+consignmentNo+"&phone="+brandingData.supportPhone} target="_blank">WhatsApp Support</a>
              </Menu.Item>
              :''}
            </Menu.SubMenu>
            :''}

          </Menu>
       </Header>
       { nanoId?
       <Content style={{ padding: '0 50px' }}>

          {notice.title ?
           <Alert
                message={ReactHtmlParser("<b>"+notice.title+"</b>")}
                description={ReactHtmlParser(notice.description+" <b><a href='"+notice.url+"' target='_blank'>"+notice.cta+"</b><a>")}
                type="info"
                showIcon
                style={{ marginTop: '10px' }}
            />
          :''}

          <div className="site-card-wrapper"  style={{ marginTop: '10px' }}>
            <Row>
              <Col xl={12} xs={24} >
                <Card title={nanoId} bordered={true}>
                <p className="heading1" style={ { color: timelineColor(trackingData.statusCode), textTransform:'uppercase' } }>
                {trackingData.statusText}
                </p>
                {/**<p>Estimated Delivery Date: <span>27th April 2022</span></p> **/}

                {(trackingData.etaDuration && trackingData.etaDuration > 0) ?
                <Tooltip title="The estimate is based on the service performance for the past 30 days. Subject to service provider's working days and volume.">
                <p><b>Estimated Delivery Date (?)</b><br/>{displayEstimatedDeliveryDate(trackingData)} </p>
                </Tooltip>
                :''}

                <p><b>Destination</b><br/>{trackingData.destination} </p>

                { trackingData.extTrackUrl ?
                  <Link to={{ pathname: trackingData.extTrackUrl }} target="_blank" >Courier Tracking</Link>
                : ''}
                </Card>

                { (trackingData.personnel && trackingData.personnel.name && trackingData.personnel.name != 'N/A' && trackingData.statusCode >= 200 && trackingData.statusCode < 700) ?
                <Card bordered={true}>

                  <div style={{ backgroundColor: '#eeeeee', padding: '10px 15px', fontSize: 13, fontWeight: 'bold' }}>
                    <span><i className="fa fa-user" style={{ fontSize: 16 }} /> &nbsp; {trackingData.personnel.name} &nbsp; {trackingData.personnel.vehicleRegNo}</span>
                    { trackingData.personnel.phone ?
                      <span className="pull-right">
                      <a href={"tel:" + trackingData.personnel.phone} >
                      <button className="button__transparent" style={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }}>
                        <i className="fa fa-phone" style={{ fontSize: 16 }} />
                      </button>
                      </a>
                      <a href={"sms:" + trackingData.personnel.phone} >
                      <button className="button__transparent" style={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }} >
                        <i className="fa fa-comment" style={{ fontSize: 16 }} />
                      </button>
                      </a>
                      <a href={"https://api.whatsapp.com/send/?text=Hello!&phone=" + trackingData.personnel.phone} target="_blank">
                      <button className="button__transparent" tyle={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }} >
                        <i className="fa fa-whatsapp" style={{ fontSize: 16 }} />
                      </button>
                      </a>
                  </span>
                  : ''}
                  </div>

                  { (trackingData?.coord?.lon && trackingData?.coord?.lat
                      && (trackingData.statusCode >= 400 && trackingData.statusCode < 500)
                          || (trackingData.statusCode >= 600 && trackingData.statusCode < 700)
                    ) ?
                      <div className="container">
                        <iframe src={window.location.origin+ '/track/rmap?trackingNo=' + trackingData.consignmentNo} width="100%" height="280px" frameborder="0" allowfullscreen></iframe>
                      </div>
                    : ''
                  }
                </Card>
                : ''}

                { orderData.service ?
                <Card bordered={true}>
                  <Col xl={6} xs={6} style={{textAlign:'center'}}>
                      <img src={IMAGE_URL_ROOT + "/"+ orderData.service.logo} width="100%" style={{padding:'20px'}} alt={orderData.service.name} title={orderData.service.name} />
                  </Col>
                  <Col xl={18} xs={18} >
                    <p>
                    {trackingData.consignmentNo? <span><b>Tracking No:</b> {trackingData.consignmentNo} </span>:''}<br/>
                    {orderData.customer? <span><b>Sender:</b> {orderData.customer.name} </span>:''}<br/>
                    {orderData.service ? <span><b>Service:</b> {orderData.service.name} </span>:''}
                    </p>
                    <div style={{ display: 'flex' }}>
                      <CopyToClipboard text={window.location.href}>
                        <button
                          className="button__transparent"
                          style={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }}
                          onClick={() => message.success('Tracking URL copied!')}
                        >
                          <i className="far fa-copy" style={{ fontSize: 16 }} />
                        </button>
                      </CopyToClipboard>

                      &nbsp;

                      <button
                        className="button__transparent"
                        style={{ padding: '0 5px', height: 'auto', cursor: 'pointer' }}
                        onClick={async () => {
                          const { default: dialogContent } = await import(`Pages/Tracking/SecureTracking/SocialSharing.js`);
                          setModalState({
                            ...modalState,
                            isModalVisible: true,
                            footer: false,
                            bodyStyle: { backgroundColor: 'transparent' },
                            maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.76)' },
                            modal: {
                              maxWidth: 600,
                              dialogContent
                            }
                          })
                        }}
                      >
                        <i className="far fa-share-square" style={{ fontSize: 16 }} />
                      </button>

                    </div>
                  </Col>
                </Card>
                :''}

                <Card>
                  <div style={{ height: '360px', padding: '40px 30px', overflow:'auto' }}>
                    <Timeline mode={"mode"}>
                      {
                        trackingData.histories.map((item) => (
                          <Timeline.Item color={timelineColor(item.statusCode)} key={item.id}>
                            <div style={{ fontWeight: 'bold' }}>{item.statusText}</div>
                            <div>{item.description}</div>
                            <div>{item.location}</div>
                            <div style={{ fontSize: 13 }}>{moment(item.createdAt).format("D MMM YYYY, h:mm a")}</div>
                          </Timeline.Item>
                        ))
                      }
                    </Timeline>
                  </div>
                </Card>
              </Col>
              <Col xl={12} xs={24} >

                <Card bordered={true} title="How would you rate your delivery experience?">
                  <Formik
                    enableReinitialize
                    initialValues={{
                      rating: '',
                      ratingNote: ''
                    }}
                    onSubmit={onSubmitRate}
                  >
                    {form => (
                      <Form layout="vertical" onSubmit={form.handleSubmit}>
                      <Row>
                        <Col xl={24} xs={24} >
                        <Rate {...form}
                          name="rating"
                          count={5}
                          style={{ fontSize: '36px', width: "100%" }}
                          onChange={value => {
                            form.setFieldValue("rating", value);
                          }}
                        />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop:'30px'}}>
                        <Col xl={24} xs={24} >
                        <TextArea {...form}
                          name="ratingNote"
                          rows={5}
                          placeholder="Any other feedback?"
                          onChange={event => {
                            form.setFieldValue("ratingNote", event.target.value);
                          }}
                        />
                        </Col>
                      </Row>
                      <Row style={{ paddingTop:'20px'}}>
                        <Col xl={24} xs={24} >
                          <CustomizedButton
                            text="Submit"
                            type="primary"
                            htmlType="submit"
                          />
                        </Col>
                      </Row>
                      </Form>
                    )}
                  </Formik>
                </Card>
                { promoBanner.img ?
                <Card bordered={true} style={{textAlign:'center'}}>
                  <a href={promoBanner.url} target="_blank" title={promoBanner.description}>
                  { promoBanner.img.startsWith('http')?
                  <img src={promoBanner.img} width="100%" alt={promoBanner.description} title={promoBanner.description} />
                  :
                  <img src={IMAGE_URL_ROOT + promoBanner.img} width="100%" alt={promoBanner.description} title={promoBanner.description} />
                  }
                  </a>
                </Card>
                : ''}
              </Col>
            </Row>
          </div>
       </Content>
       :
       <Content style={{ padding: '0 50px' }}>

       {notice.title ?
        <Alert
             message={ReactHtmlParser("<b>"+notice.title+"</b>")}
             description={ReactHtmlParser(notice.description+" <b><a href='"+notice.url+"' target='_blank'>"+notice.cta+"</b><a>")}
             type="info"
             showIcon
             style={{ marginTop: '10px' }}
         />
       :''}

       <div className="site-card-wrapper"  style={{ marginTop: '10px' }}>
         <Row>
           <Col xl={24} xs={24} >
               <Card bordered={true}>
                   <Result
                      title="Sorry, the page you visited does not exist."
                      extra={<Button type="primary"><a href={brandingData.url}>Back Home</a></Button>}
                    />
               </Card>
              { promoBanner.img ?
                 <Card bordered={true} style={{textAlign:'center'}}>
                   <a href={promoBanner.url} target="_blank" title={promoBanner.description}>
                   { promoBanner.img.startsWith('http') ?
                   <img src={promoBanner.img} height="360px" alt={promoBanner.description} title={promoBanner.description} />
                   :
                   <img src={IMAGE_URL_ROOT + promoBanner.img} height="360px" alt={promoBanner.description} title={promoBanner.description} />
                  }
                   </a>
                 </Card>
               : ''}
            </Col>
           </Row>
         </div>
       </Content>
      }

       <Footer style={{ textAlign: 'center' }}>
       Powered by &nbsp;
       <a href={companyDetails.websiteUrl} target="_blank">
       {companyDetails.brandName ? companyDetails.brandName: companyDetails.name}
       </a>
       </Footer>

       <Modal
         centered
         visible={promo}
         onOk={() => showPromo(false)}
         onCancel={() => showPromo(false)}
         width={720}
         footer={[]}
       >
        { popupBanner.img ?
          <div>
           <a href={popupBanner.url} target="_blank" title={popupBanner.description}  >
           { popupBanner.img.startsWith('http')?
            <img src={popupBanner.img} height="360px" alt={popupBanner.description} title={popupBanner.description}  />
            :
           <img src={IMAGE_URL_ROOT + popupBanner.img} height="360px" alt={popupBanner.description} title={popupBanner.description}  />
           }
           </a>
           <br/>&nbsp;<br/>
           <p><b>{popupBanner.title}</b></p>
           <small>{popupBanner.description}</small>
         </div>
        :''
       }
       </Modal>

       <CustomizedModal
         type={modalState.modal.type}
         title={modalState.modal.title}
         onOk={modalState.modal.onOk}
         onOkData={modalState.modal.onOkData}
         maxWidth={modalState.modal.maxWidth}
         onCancel={() => {
           modalState.closable && setModalState({ ...modalStateInitialVal })
           if (modalState.modal.onCancel) {
             modalState.modal.onCancel()
           }
         }}
         text={modalState.modal.text}
         okText={modalState.modal.okText}
         okButtonProps={{ type: "danger" }}
         visible={modalState.isModalVisible}
         footer={modalState.footer}
         bodyStyle={modalState.bodyStyle}
         maskStyle={modalState.maskStyle}
         closable={modalState.closable}
       >
         <DialogContent
           {...modalState.modal.dialogContentProps}
         />
       </CustomizedModal>

     </Layout>
  )
}

export default SecureTracking;
