import React, { Component } from "react";

import { List, Card } from "antd";

class PaymentMethodList extends Component {
  render() {
    return (
      <List
        grid={{ gutter: 16, xs: 2, sm: 2, md: 4, lg: 4, xl: 4 }}
        dataSource={this.props.paymentMethod}
        renderItem={item => (
          <List.Item
            style={{
              border: "none",
              display: "flex",
              flexWrap: "wrap"
            }}
          >
            <Card
              hoverable={true}
              style={{ width: "100%", height: 120, minWidth: 'min-content', textAlign: "center" }}
              onClick={() => this.props.selectPaymentMethod(item)}
            >
              <i
                className={
                  item.fontAwesomeIcon ? item.fontAwesomeIcon : "fas fa-truck"
                }
              />
              <p style={{ marginTop: "10%" }}>{item.name}</p>
            </Card>
          </List.Item>
        )}
      />
    );
  }
}

export default PaymentMethodList;
