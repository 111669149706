import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Layout, Button, Row, Col } from "antd";
import { useTranslation } from "react-i18next";

import CustomizedButton from "Components/CustomizedButton";

import AddressBookTableConfig from "./AddressBookTableConfig";
import { API_ROOT } from 'Util/API';
import useLocalStorage from 'Util/useLocalStorage';

const { Content } = Layout;

const AddressBookTable = props => {
  const {t} = useTranslation('addressBookPage');
  const [exportLink, setExportLink] = useState(null);

  useEffect(() => {
    getExportLink()
  })

  const getExportLink = async () => {
    const jwt = useLocalStorage.getItem("accessToken");
    const exportLimit = props.transactionRowTotal;

    setExportLink(`${API_ROOT}contacts/export?limit=`+exportLimit+`&jwt=` + jwt);
  }

  return (
    <Content className="ui-right-content form">
    <Layout>
      <Content>
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <p className="heading1" style={{ margin: 0, float: "left" }}>
            {t('content.pageTitleAddress')}
            </p>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="navigation-table"
          >
            <Button
              className="button-bg-transparent"
              style={{ padding: 0, marginRight: 15 }}
              target="_blank"
              href={exportLink}
            >
              {t('content.header.export')} <i className="fa fa-upload" style={{ marginLeft: 5 }} />
            </Button>

            <Link to={`${process.env.PUBLIC_URL}/contacts/add`}>
              <CustomizedButton
                text={t('content.header.addNewContact')}
                type="primary"
              />
            </Link>
          </Col>
        </Row>
        <div style={{ paddingTop: 30 }}>
          <div
            className="whiteBColor"
            style={{
              height: "100%",
              border: "1px solid #cfdae6"
            }}
          >
            <AddressBookTableConfig
              setIsLoading={props.setIsLoading}
              getContacts={props.getContacts}
              transactionData={props.transactionData}
              transactionRowTotal={props.transactionRowTotal}
              isLoading={props.isLoading}
              handlePageSize={props.handlePageSize}
            />
          </div>
        </div>
      </Content>
    </Layout>
    </Content>
  );
};

export default AddressBookTable;
