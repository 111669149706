const status = {
  '1': {
    color: '#00C853',
    text: 'ACTIVE'
  },
  '2': {
    color: '#607D8B',
    text: 'INACTIVE'
  }
};

export default status