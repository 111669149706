import { useEffect, useState, useRef } from 'react';
import { message as msg } from 'antd';

import { get } from 'Util/API';

const useInvoiceStatusListener = (currentInvoiceId, message) => {
  const [invoiceStatusRes, setInvoiceStatusRes] = useState(message);
  let __timeout = useRef();

  useEffect(() => {
    setInvoiceStatusRes(message)
  }, [message])

  useEffect(() => {
    if (currentInvoiceId) {
      __timeout.current = setTimeout(() => {
        const getInvoiceStatus = async () => {
          const fetch = await get(`wallet/invoice/${currentInvoiceId}/paymentStatus`);
          if (fetch.status === 200) {
            const data = fetch.data.data;
            data.status = data.statusCode;
            data.event = 'invoice.status';
            setInvoiceStatusRes(data)
          } else msg.error(fetch);
        }
        getInvoiceStatus();
      }, 5000);
    }
  }, [currentInvoiceId])

  const isCurrentInvoicePaid = invoiceStatusRes?.id === currentInvoiceId && invoiceStatusRes?.event === 'invoice.status';
  useEffect(() => {
    if (isCurrentInvoicePaid) {
      clearTimeout(__timeout.current);
    }
  }, [invoiceStatusRes])

  return invoiceStatusRes;
}

export default useInvoiceStatusListener;
