import React from "react";
import { List, Row } from "antd";
import { Link } from "react-router-dom";

const Rating = (props) => {
  const { selectedOrder } = props;
  return (
    <List
      bordered
      style={{ backgroundColor: "white", marginBottom: "20px" }}
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="heading3" style={{ margin: 0 }}>
            Rating
          </p>
        </div>
      }
    >
      <List.Item>
        <Row gutter={10} style={{ width: "100%" }}>
        <p
          className="heading3"
          style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
        >
          Score: {selectedOrder.rating}
          </p>
          <p
            className="heading3"
            style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
          >
          Feedback: {selectedOrder.ratingNote ? <span dangerouslySetInnerHTML={{ __html: selectedOrder.ratingNote }} /> : '-'}
          </p>
        </Row>
      </List.Item>
    </List>
  )
}

export default Rating;
