import React from 'react';
import { TimePicker, Form } from 'antd';

import {
  TEXT_SELECT_TIME
} from "Util/Text";

const LabelledTimePicker = React.memo(({
  disabled,
  error,
  label,
  className,
  style,
  defaultValue,
  format,
  onChange,
  required,
  minuteStep,
  disabledSeconds,
  disabledMinutes,
  disabledHours
}) => {
  // console.log('LabelledTimePicker');

  return (
    <Form.Item
      validateStatus={disabled ? null : error && "error"}
      help={disabled ? null : error}
      label={label}
      className={className}
      style={style}
      noStyle
      required={required}
    >
      <TimePicker
        disabled={disabled}
        disabledMinutes={disabledMinutes}
        disabledSeconds={disabledSeconds}
        disabledHours={disabledHours}
        placeholder={TEXT_SELECT_TIME}
        onChange={onChange}
        defaultValue={defaultValue}
        use12Hours
        format={format}
        minuteStep={minuteStep}
      />
    </Form.Item>
  )
})

export default LabelledTimePicker;
