import React, {
  // useContext,
  useReducer,
  useEffect,
  useMemo,
} from 'react';
import {
  Layout,
} from 'antd';
import { useTranslation } from "react-i18next";

import PageWrapper from "Components/PageWrapper";
import { get } from 'Util/API';
// import { GlobalContext } from "Store/store";
import Header from 'Components/integration/header';
import TableContent from 'Components/integration/TableContent'
import TableColumn from 'Components/integration/tableColumns'
import ServerSidePaginationTable from 'Components/table/ServerSidePaginationTable'
import ErrorBoundary from 'Components/ErrorBoundary'

import { mixPanelTrack } from "Util/CustomFunctions";

const { Content } = Layout;

function init (initial) {
  return initial;
}

function reducer (state, action) {
  switch (action.type) {
    case 'hydrate':
      return {
        tableRowsData: action.data,
        loading: false
      };
    case 'modify':
      return {
        tableRowsData: { ...state.tableRowsData, ...action.data },
        loading: action.loading
      };
    case 'loading':
      return {
        ...state,
        loading: action.loading
      };
    case 'reset':
      return init(action.payload);
    default:
      throw new Error();
  }
}

const initialState = {
  tableRowsData: {
    data: [],
    limit: 10,
    page: 1,
    pageTotal: 0,
    rowTotal: 0,
    orderBy: 'desc',
    sortBy: 'createdAt',
    status: 'ALL',
    keyword: null,
    dateFrom: null,
    dateTo: null,
    dateRangeId: 0,
  },
  loading: true
}

const IntegrationSetupPage = (props) => {
  const uri = new URLSearchParams(props.location.search);
  const page = uri.get('page');
  // https://gitlab.delyva.com/saas/core/-/blob/staging/modules/dx-customers/controllers/crud/integration.js#L15
  const status = uri.get('status');
  const orderBy = uri.get('orderBy');
  const sortBy = uri.get('sortBy');
  const limit = uri.get('limit');
  const history = props.history;
  const { t } = useTranslation('integrationPage');
  // const [store] = useContext(GlobalContext);
  // const { userDetails, companyDetails, customerDetails } = store?.global;
  const [state, dispatch] = useReducer(reducer, initialState, init);
  const getAllRows = async (props) => {
    // console.log('getAllRows props',props);
  }
  const renderServerSidePaginationTable = useMemo(() => {
    if (!page || !status) {
      return null;
    }
    const tab = [
      { name: t('content.tableContent.tab0'), key: 'ACTIVE' },
      { name: t('content.tableContent.tab1'), key: 'PENDING' },
      { name: t('content.tableContent.tab2'), key: 'ALL' }
    ];

    return (
      <ServerSidePaginationTable
        data={state.tableRowsData}
        setData={(data) => {
          history.push(`/customer/integration?page=${data.page}&status=${data.status}&orderBy=${data.orderBy}&sortBy=${data.sortBy}&limit=${data.limit}`)
          dispatch({
            type: 'modify',
            loading: true
          })
        }}
        getAllRowsIds={getAllRows}
        allRowsIds={[]}
        setAllRowsIds={() => {}}
        location={props.location}
        history={props.history}
        isLoading={state.loading}
        setIsLoading={(bool) => {
          dispatch({
            type: 'loading',
            loading: bool
          })
        }}
        generateColumn={(genprops) => TableColumn({ ...genprops, transl: t })}
        itemRenderer={TableContent}
        defaultActiveKey={status}
        tab={tab}
      />
    )
  }, [state, page, status, getAllRows, dispatch, t, props, history]);

  useEffect(() => {
    async function getTableRows () {
      try {
        const pg = parseInt(page, 0);
        let endpoint = `integration?page=${pg}`; //in-active
        if (status === 'ACTIVE') {
          endpoint = `integration?page=${pg}&status=1`;
        } else if (status === 'PENDING') {
          endpoint = `integration?page=${pg}&status=2`;
        }
        if (orderBy) {
          if (['asc', 'desc'].includes(orderBy)) {
            endpoint += `&orderBy=${orderBy}`;
          }
        }
        if (sortBy) {
          if (['createdAt'].includes(sortBy)) {
            endpoint += `&sortBy=${sortBy}`;
          }
        }
        if (limit) {
          const lmt = parseInt(limit, 10);
          if (lmt > 9) {
            endpoint += `&limit=${parseInt(limit, 10)}`;
          }
        }
        const integrationsReqs = await get(endpoint);

        if (integrationsReqs instanceof Error) {
          return Promise.reject(new Error('Error while requesting data'));
        }
        const integrationsResp = integrationsReqs?.data;
        const data = integrationsResp.page
        ? {
          data: integrationsResp.data,
          status,
          orderBy,
          sortBy,
          limit,
          page,
        }
        : {
            data: [{ ...integrationsResp.data }],
            status,
            orderBy,
            sortBy,
            limit,
            page,
        };
        dispatch({
          type: 'hydrate',
          data
        })

      } catch (err) {
        throw new Error(err.message)
      }
    }
    if (page && status) {
      if (state.loading) {
        getTableRows();
      }
    }
  }, [state, page, status, orderBy, sortBy, limit])

  useEffect(() => {
    if (!page || !status) {
      history.push(`/customer/integration?page=${state.tableRowsData.page}&status=${state.tableRowsData.status}&orderBy=${state.tableRowsData.orderBy}&sortBy=${state.tableRowsData.sortBy}&limit=${state.tableRowsData.limit}`)
    }
  }, [page, status, history, state.tableRowsData]);

  useEffect(() => {
    mixPanelTrack('ViewIntegrations');
  }, []);

  return (
    <PageWrapper loading={state.loading} currentPath={props.location.pathname}>
      <Layout>
        <Content className="ui-right-content form">
          <Header />
          <div
            className="whiteBColor"
            style={{
              height: "100%",
              border: "1px solid #cfdae6",
              position: "relative"
            }}
          >
            <ErrorBoundary>
              {renderServerSidePaginationTable}
            </ErrorBoundary>
          </div>
        </Content>
      </Layout>
    </PageWrapper>
  )
}

export default IntegrationSetupPage;
