import React from "react";
import { Form, Checkbox } from "antd";
import CustomizedButton from "./CustomizedButton";
import { Link } from "react-router-dom";
import { TEXT_FORGOT_PASSWORD } from "../Util/Text";

const CheckBox = ({
  errors,
  name,
  text,
  isForgotPassword,
  setFieldValue,
  setFieldTouched,
  touched,
  onChange,
  style,
  className,
  checkboxStyle,
  checked
}) => (
  <Form.Item
    validateStatus={touched[name] && errors[name] && "error"}
    help={touched[name] ? errors[name] : ""}
    style={style}
    className={className}
  >
    <Checkbox
      onChange={
        onChange
          ? onChange
          : e => {
              setFieldValue(name, e.target.checked);
              setFieldTouched(name, true, false);
            }
      }
      checked={checked}
      style={checkboxStyle}
    >
      {text}
    </Checkbox>
    {isForgotPassword && (
      <Link
        to={`${process.env.PUBLIC_URL}/forgot-password`}
        className="forgot-password-button"
      >
        <CustomizedButton
          className="button__transparent color__blue"
          text={TEXT_FORGOT_PASSWORD}
        />
      </Link>
    )}
  </Form.Item>
);

export default CheckBox;
