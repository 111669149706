import React, { useContext } from 'react';
import {
  Row,
  Col,
  Button,
} from 'antd';
import { useTranslation } from "react-i18next";

import { GlobalContext } from "Store/store";
import Accordion from './accordion'

import s from './header.module.css'

const Header = () => {
  const [store] = useContext(GlobalContext);
  const { modalState, setModalState } = store?.global
  const { t } = useTranslation('integrationPage');
  return (
    <Row className={s['mb-px']}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <p className={s.title}>
          {t('content.header.title')}
        </p>
      </Col>
      <Col
        xs={24}
        sm={24}
        md={12}
        lg={12}
        xl={12}
        className="navigation-table"
      >
        <Button
          onClick={() => {
            setModalState({
              ...modalState,
              isModalVisible: true,
              footer: false,
              modal: {
                title: 'New integration',
                dialogContent: Accordion,
                maxWidth: 1000
              }
            })
          }}
        >
          {t('content.header.setup')}
        </Button>
      </Col>
    </Row>
  )
}

export default Header