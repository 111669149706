import React from "react";
import { Form } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const LabelledPhoneNumberInput = React.memo(({
  error,
  name,
  placeholder,
  label,
  onChange,
  disabled,
  viewOnly,
  className,
  enableSearchField,
  dropdownStyle,
  searchPlaceholder,
  marginTop,
  touched,
  country,
  style,
  value,
  addonAfter,
  addonBefore,
  required
}) => {
  // console.log('LabelledPhoneNumberInput');
  return (
    <Form.Item
      validateStatus={disabled ? null : error && "error"}
      help={disabled ? null : error}
      label={label}
      className={className}
      style={style}
      required={required}
      noStyle
    >
      <ReactPhoneInput
        searchPlaceholder={searchPlaceholder}
        country={country || 'my'}
        enableSearchField={enableSearchField}
        enableLongNumbers={true}
        inputStyle={
          error
            ? { border: "1px solid red", width: "100%", height: "32px", ...style }
            : { width: "100%", color: disabled && '#C4C4C4', backgroundColor: (viewOnly || disabled) && '#F5F5F5', border: viewOnly && 'none', height: "32px", ...style }
        }
        dropdownStyle={dropdownStyle}
        buttonStyle={
          error
            ? { border: "1px solid red", height: "32px", marginTop: marginTop }
            : { height: "32px", marginTop: marginTop, border: viewOnly && 'none' }
        }
        disableDropdown={disabled}
        disabled={disabled}
        placeholder={placeholder}
        value={value || undefined}
        onChange={onChange()}
      />
    </Form.Item>
  )
})

export default LabelledPhoneNumberInput;
