import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useField } from 'formik';
import { Steps, Dropdown, Button, Menu, message } from "antd";
import { useLocation } from 'react-router-dom';

import CustomizedButton from "Components/CustomizedButton";
import { get } from "Util/API";
import { GlobalContext } from 'Store/store';
import MapMarker from 'Icons/MapMarker.jsx';
import { post, getInstantQuote } from 'Util/API';
import { getAddressAutoSuggestion } from 'Fetch';

import WaypointInput from './WaypointInput';
import checkPriceData from './getCheckPriceData';

const { Step } = Steps;

const addressGenerator = (keys, data) => {
  let str;
  keys.forEach((item) => {
    if (data[item]) {
      if (str) {
        str = str + ', ' + data[item]
      } else {
        str = data[item]
      }
    }
  })
  return str
}

const getOrderDetailsFromSource = (orderDetails, services, companyId) => {
  if (orderDetails) {
    const waypoint = orderDetails.waypoint.map((item) => ({
      address1: item.contact.address1,
      address2: item.contact.address2,
      city: item.contact.city,
      coord: item.contact.coord,
      country: item.contact.country,
      label: item.contact.address1,
      postcode: item.contact.postcode,
      state: item.contact.state,
      phone: item.contact.phone,
      name: item.contact.name,
      title: addressGenerator(['address1', 'address2', 'city', 'state'], item.contact),
      location: [parseFloat(item.contact.coord?.lon), parseFloat(item.contact.coord?.lat)],
      id: item.contact.coord.lon,
      companyId: companyId,
    }))
    return waypoint
  }
  return null;
}

const SelectWaypoint = (props) => {
  const { t } = useTranslation('multiPointOrderPage');
  const [state] = useContext(GlobalContext);
  const { companyDetails, customerDetails } = state.global;
  const { form, maxWaypoint, mapData } = props;
  const location = useLocation();

  const { values, setFieldValue } = form;
  const [activeStep, setActiveStep] = useState(0);
  const [formValues, setFormValues] = useState({ ...values });
  const [addressSuggestion, setAddressSuggestion] = useState([]);
  useField({ name: 'waypoint' });
  const [formTouched, setFormTouched] = useState(Object.keys(form.touched).length > 0)
  const [dragItem, setDragItem] = useState({ index: null, location: null });

  useEffect(() => {
    if (mapData && location.state?.orderDetails) {
      const waypointFromSources = getOrderDetailsFromSource(
        location.state?.orderDetails,
        props.multipointServices,
        companyDetails.id
      );
      handleMapWaypointFromSources(waypointFromSources)
    }
  }, [location.state, mapData])

  useEffect(() => {
    if (typeof dragItem.index === 'number') {
      searchAddressByCoord(dragItem.location, dragItem.index)
    }
  }, [dragItem]);

  useEffect(() => {
    if (formTouched) {
      setFormValues({ ...values })
    }
  }, [values.waypoint])

  useEffect(() => {
    setFormTouched(Object.keys(form.touched).length > 0)
  }, [form.touched])

  const handleMapWaypointFromSources = async (waypointsCopy) => {
    const data = checkPriceData(
      companyDetails.id,
      waypointsCopy,
      values
    )
    data.customerId = customerDetails?.id;
    const checkPrice = await getInstantQuote(null, data);
    if (checkPrice.status === 200) {
      if (checkPrice.data.errors?.length !== 0) {
        message.error(checkPrice.data.errors[0].error.message)
      }
      const services = checkPrice.data.data.services[0];
      form.setFieldValue('serviceDetails', services)
      setFieldValue('waypoint', waypointsCopy);
      setFormValues({ ...formValues, waypoint: waypointsCopy })
      props.handleChangeWaypoints(waypointsCopy[0])
    } else {
      form.setFieldValue('serviceDetails', null)
      setFieldValue('waypoint', waypointsCopy);
      setFormValues({ ...formValues, waypoint: waypointsCopy })
      props.handleChangeWaypoints(waypointsCopy[0])
      message.error(checkPrice);
    }
  }

  const handleSearchAddress = async (value) => {
    if (value.length >= 4) {
      const centerCoord = mapData.getCenter();
      const queryAddressRes = await getAddressAutoSuggestion(encodeURIComponent(value), centerCoord.lat + ',' + centerCoord.lng);
      if (queryAddressRes.data?.data) {
        setAddressSuggestion(queryAddressRes.data?.data)
      } else {
        setAddressSuggestion([])
        message.error('Auto suggest error!')
      }
    }
  }

  const onSelectAddress = async (addressIndex, waypointIndex) => {
    props.setIsLoading(true);
    const waypointsCopy = [...values.waypoint];
    const { address, ...rest } = addressSuggestion[addressIndex];

    waypointsCopy[waypointIndex] = {
      ...waypointsCopy[waypointIndex],
      ...rest,
      ...address,
      location: [address.coord.lon, address.coord.lat],
      id: addressSuggestion[addressIndex].id,
      companyId: companyDetails.id
    };

    await onCheckPrice(waypointsCopy, waypointIndex)
    props.setIsLoading(false);
    setAddressSuggestion([]);
  }

  const searchAddressByCoord = async (location, index) => {
    props.setIsLoading(true);
    const geocoderRes = await post("service/geocoder", {
      coord: {
        lat: location.lat,
        lon: location.lng
      }
    });

    const waypointsCopy = [...formValues.waypoint];
    if (geocoderRes.status === 200) {
      const latlng = geocoderRes.data.coord;
      waypointsCopy[index] = {
        ...geocoderRes.data,
        location: [latlng.lon, latlng.lat],
        id: geocoderRes.data.id,
        companyId: geocoderRes.data.companyId
      };

      await onCheckPrice(waypointsCopy, index);
      props.setIsLoading(false);
      setAddressSuggestion([]);
    }
  }

  const onCheckPrice = async (waypointsCopy, waypointIndex) => {
    const data = checkPriceData(
      companyDetails.id,
      waypointsCopy,
      values
    )
    data.customerId = customerDetails?.id;
    const checkPrice = await getInstantQuote(null, data);
    if (checkPrice.status === 200) {
      if (checkPrice.data.errors?.length !== 0) {
        message.error(checkPrice.data.errors[0].error.message)
      }
      const services = checkPrice.data.data.services[0];
      form.setFieldValue('serviceDetails', services)
      setFieldValue('waypoint', waypointsCopy);
      props.handleChangeWaypoints(waypointsCopy[waypointIndex])
    } else {
      form.setFieldValue('serviceDetails', null)
      setFieldValue('waypoint', waypointsCopy);
      props.handleChangeWaypoints(waypointsCopy[waypointIndex])
      message.error(checkPrice);
    }
  }

  const onDeleteWaypoint = async (waypointIndex) => {
    const waypointsCopy = [...values.waypoint];
    if (waypointsCopy[waypointIndex]) {
      waypointsCopy.splice(waypointIndex, 1);
    }
    setFieldValue('waypoint', waypointsCopy);
    onCheckPrice(waypointsCopy, waypointIndex)
  }

  const onSelectAddressBook = async (val, waypointIndex) => {
    props.setIsLoading(true);
    const waypointsCopy = [...values.waypoint];
    const latlng = val.coord;
    waypointsCopy[waypointIndex] = {
      ...val,
      phone: val.mobile,
      title: addressGenerator(['address1', 'address2', 'city', 'state'], val),
      location: [parseFloat(latlng.lon), parseFloat(latlng.lat)],
      id: latlng.lon,
      companyId: companyDetails.id,
    };

    await onCheckPrice(waypointsCopy, waypointIndex)
    props.setIsLoading(false);
    props.handleCloseModal()
    setAddressSuggestion([]);
  }

  const handleOpenSearchAddressModal = async (index) => {
    setFormTouched(true);
    const { default: dialogContent } = await import(`Pages/Order/SearchAddressDialogContent.js`);
    props.handleOpenModal({
      ...props.modalState,
      isModalVisible: true,
      footer: false,
      modal: {
        title: "Search address book",
        onCancel: props.handleCloseModal,
        dialogContent,
        maxWidth: 800,
        dialogContentProps: {
          onSelectAddress: (val) => onSelectAddressBook(val, index)
        }
      }
    });
  }

  const handleOpenEditAddressModal = async (index) => {
    const { default: dialogContent } = await import(`./EditDestinationDetails.js`);

    const title = (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <MapMarker number={index + 1} />
        <span style={{ marginLeft: 10 }}>
          {t('orderDetails.deliveryInfo.title')}
        </span>
      </div>
    )

    const onSaveAddress = (data) => {
      const waypointsCopy = [...formValues.waypoint];
      waypointsCopy[index] = {
        ...waypointsCopy[index],
        ...data
      }
      setFormValues({ ...formValues, waypoint: waypointsCopy });
      form.setFieldValue('waypoint', waypointsCopy);
      props.handleChangeWaypoints(waypointsCopy[index])
      props.handleCloseModal()
    }

    props.handleOpenModal({
      isModalVisible: true,
      footer: false,
      modal: {
        title,
        onCancel: props.handleCloseModal,
        dialogContent,
        maxWidth: 500,
        dialogContentProps: {
          handleCloseModal: props.handleCloseModal,
          onSaveAddress,
          selectedWaypointIndex: index,
          form
        }
      }
    });
  }

  return (
    <div>
      <Steps
        direction="vertical"
        current={activeStep}
        onChange={setActiveStep}
      >
        {
          formValues.waypoint.map((item, index) => {
            return (
              <Step
                title={
                  <div style={{ display: 'flex' }}>
                    <WaypointInput
                      index={index}
                      item={item}
                      title={item.location && (item.title || addressGenerator(['address1', 'address2', 'city', 'state'], item))}
                      mapData={props.mapData}
                      onDragEnd={setDragItem}
                      onSearch={val => handleSearchAddress(val)}
                      onChange={val => onSelectAddress(val, index)}
                      onFocus={form.setFieldTouched}
                      addressSuggestion={addressSuggestion}
                    />
                    <Dropdown
                      placement="bottomRight"
                      trigger={['click']}
                      overlay={
                        <Menu>
                          <Menu.Item onClick={() => handleOpenSearchAddressModal(index)}>
                            <i className="fas fa-address-book" style={{ marginRight: 10 }} />
                            Search address book
                          </Menu.Item>
                          <Menu.Item onClick={() => handleOpenEditAddressModal(index)} disabled={!formValues.waypoint[index].location}>
                            <i className="fas fa-edit" style={{ marginRight: 10 }} />
                            {t('orderDetails.editAddress')}
                          </Menu.Item>
                          {
                            index > 0 &&
                            <Menu.Item onClick={() => onDeleteWaypoint(index)}>
                              <i className="far fa-trash-alt" style={{ marginRight: 10 }} />
                              {t('orderDetails.delete')}
                            </Menu.Item>
                          }
                        </Menu>
                      }
                    >
                      <Button>
                        <i className="fas fa-ellipsis-v" />
                      </Button>
                    </Dropdown>
                  </div>
                }
                description={
                  item.location && (
                    <div>
                      <div>{ addressGenerator(['address1', 'address2', 'postcode', 'city', 'state', 'country'], item)}</div>
                      <span style={{ color: !item.name?.length > 0 && 'red' }}>{item.name || t('orderDetails.errors.nameIsRequired')} | </span>
                      <span style={{ color: !item.phone?.length > 0 && 'red' }}>{item.phone || t('orderDetails.errors.phoneIsRequired')}</span>
                      {
                        item.unitNo?.length > 0 &&
                        <span> | {item.unitNo}</span>
                      }
                    </div>
                  )
                }
                icon={<MapMarker number={index + 1} />}
              />
            )
          })
        }
      </Steps>
      <CustomizedButton
        style={{ marginBottom: 20 }}
        onClick={() => {
          const waypointLastIndex = formValues.waypoint.length - 1;
          if (formValues.waypoint.length < maxWaypoint && formValues.waypoint[waypointLastIndex].companyId) {
            const waypointsCopy = [...formValues.waypoint];
            waypointsCopy.push({ price: 0 })
            setFormValues({
              ...formValues,
              waypoint: waypointsCopy
            })
          } else message.error(`Please fill/remove empty location field. Max ${maxWaypoint} route points.`)
        }}
        text={t('orderDetails.addDestination')}
      />
      {/*// <LabelledCheckbox
      //   text="Optimized route"
      //   checked={optimizedRoute}
      //   description=""
      //   onChange={e => setOptimizedRoute(!optimizedRoute)}
      // />*/}
    </div>
  )
}

export default SelectWaypoint;
