import React, { useState, useContext, useEffect } from "react";
import { Route } from "react-router-dom";
import { Spin } from 'antd';

import withAuthorization from "Pages/Auth/withAuthorization";
import { GlobalContext } from "Store/store";
import { get } from "Util/API";

const WithDefaultSidebar = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { customerDetails, allCustomerAccounts } = state.global;
  const [fetchItems, setFetchItems] = useState(null);
  const urlParams = new URLSearchParams(window.location.search);
  const referral = urlParams.get("refer");

  async function allFetch() {
    const getAllCustomerAccount = await get("/user/customers");
    const getServiceCompanies = get("/service/serviceCompanies?limit=1000");
    const getAvailableServiceCompanyId = await get(`/customer/${customerDetails.id}/allServiceCompany`)

    return {
      getAllCustomerAccount: await getAllCustomerAccount,
      getServiceCompanies: await getServiceCompanies,
      getAvailableServiceCompanyId: await getAvailableServiceCompanyId
    }
  }

  const getMultipointServices = async () => {
    const { allCustomerAccounts, serviceCompanies, availableServiceCompanyId } = fetchItems;
    const availableServiceCompany = serviceCompanies.filter(x => availableServiceCompanyId.includes(x.id));
    const multipointServices = availableServiceCompany.filter((item) => {
      return item.source.find((item) => item === "web-customer-multipoint")
    })
  
    dispatch({
      type: "AUTH_SUCCESS",
      payload: {
        ...state.global,
        serviceCompanies,
        allCustomerAccounts,
        availableServiceCompany,
        multipointServices,
        authFinish: true
      }
    })
  }

  useEffect(() => {
    const fetch = async () => {
      const fetchAll = await allFetch();
      const { getAllCustomerAccount, getServiceCompanies, getAvailableServiceCompanyId } = fetchAll;
      setFetchItems({
        allCustomerAccounts: getAllCustomerAccount.status === 200 ? getAllCustomerAccount.data.data : [],
        serviceCompanies: getServiceCompanies.status === 200 ? getServiceCompanies.data.data : [],
        availableServiceCompanyId: getAvailableServiceCompanyId.status === 200 ? getAvailableServiceCompanyId.data.data : []
      })
    }
    !allCustomerAccounts && fetch()
  }, [props.path])

  useEffect(() => {
    if (fetchItems) {
      getMultipointServices()
    }
  }, [fetchItems])

  return (
    allCustomerAccounts ?
    props.children :
    <div>
      <Spin
        className={referral === 'SSL' && 'mb-spin'}
        style={{ width: '100vw', top: '50vh', position: 'absolute' }}
      />
    </div>
  )
}

const routeInit = (globalState, routes) => {
  return routes.map((item) => {
    const { component: Component, exact, path, isAuthorized, unauthorizedPath, mustCompleteProfile, withSidebar, otherProps } = item;
    const ComponentRenderer = (props) => withSidebar ? <WithDefaultSidebar path={path}><Component {...props} {...otherProps} /></WithDefaultSidebar> : <Component {...props} {...otherProps} />;
    return (
      <Route
        exact={exact}
        path={process.env.PUBLIC_URL + path}
        component={
          item.withAuthorization ?
            withAuthorization(
              isAuthorized,
              {
                redirectPath: process.env.PUBLIC_URL + unauthorizedPath,
                mustCompleteProfile,
                withSidebar
              }
            )(ComponentRenderer) :
            ComponentRenderer
        }
      />
    )
  })
}

export default routeInit;
