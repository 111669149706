import { useEffect } from 'react';
import usePrevious from 'Util/Hooks/usePrevious';
import { useField } from 'formik';

const useValidateField = (name, val, validateField, isValidate) => {
  useField({ name });
  const prevVal = usePrevious(val);
  useEffect(() => {
    let mount = true;
    if (isValidate && (prevVal || val)) {
      mount && validateField(name);
    }
    return () => {
      mount = false;
    };
  }, [val]);

  return null;
};

export default useValidateField;
