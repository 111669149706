import axios from "axios";

import { MESSAGE_404 } from "Util/ErrorMessage";
import { getErrorMessage } from './getErrorMessage';
import { get } from 'Util/API';

const getCustomerDetails = async (companyId, customerId) => {
  try {
    const response = await get(customerId ? `customer/${customerId}?companyId=${companyId}&retrieve=branding` : "customer?retrieve=branding");

    if (!response.data.data) {
      console.error(`Missing 'data' key, service/itemTypes`);
      // return TEXT_SERVER_DOWN
    }

    return response;
  } catch (e) {
    if (e.response?.status === 400) {
      return getErrorMessage(e);
    }
    if (e.response?.status === 401) {
      return e.response;
    }
    if (e.response?.status === 404) {
      return MESSAGE_404;
    } else return getErrorMessage(e);
  }
};

export default getCustomerDetails;
