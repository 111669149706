import React from "react";
import { List, Row, Col } from "antd";

import { useTranslation } from "react-i18next";

const CustomerInformation = (props) => {
  const { selectedOrder } = props;
  const { t } = useTranslation('orderPage');

  const selectedWaypoint = selectedOrder.waypoint[0];
  const { contact } = selectedWaypoint;

  return (
    <List
      bordered
      style={{ backgroundColor: "white", marginBottom: "20px" }}
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="heading3" style={{ margin: 0 }}>
            {t('content.orderDetail.customerInfo.title')}
          </p>
        </div>
      }
    >
      <List.Item>
        <Row gutter={10} style={{ width: "100%" }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p
              className="heading3"
              style={{ fontSize: 14, margin: 0, float: "right" }}
            >
              {t('content.orderDetail.customerInfo.name')}
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p
              className="heading3"
              style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
            >
              {contact.name}
            </p>
          </Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={10} style={{ width: "100%" }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p
              className="heading3"
              style={{ fontSize: 14, margin: 0, float: "right" }}
            >
              {t('content.orderDetail.customerInfo.phone')}
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p
              className="heading3"
              style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
            >
              {contact.phone}
            </p>
          </Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={10} style={{ width: "100%" }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p
              className="heading3"
              style={{ fontSize: 14, margin: 0, float: "right" }}
            >
              {t('content.orderDetail.customerInfo.email')}
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p
              className="heading3"
              style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
            >
              {contact.email || "N/A"}
            </p>
          </Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={10} style={{ width: "100%", height: 21 }} />
      </List.Item>
    </List>
  )
}

export default CustomerInformation;
