import React, { useState, useContext, useEffect, useMemo } from "react";
import { Formik } from "formik";
import moment from "moment";
import { Button, Spin, message } from 'antd';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

import { GlobalContext } from "Store/store";
import PageWrapper from 'Components/PageWrapper';
import ShowOn from 'Util/ShowOn';
import CustomizedButton from "Components/CustomizedButton";
import { API_ROOT, get } from 'Util/API';
import useGetOrderStatusFallback from './useGetOrderStatusFallback';
import generateAddressByKeys from 'Util/generateAddressByKeys';

import OrderForm from "./OrderForm";
import validationSchema from "./validationSchema";
import submitOrder from "./submitOrder";
import { useTranslation } from "react-i18next"

import { getItemTypes, getCustomerDetails } from 'Fetch';

import useLocalStorage from 'Util/useLocalStorage';

import { mixPanelTrack } from "Util/CustomFunctions";

function getDefaultDate() {
  return moment()
}

function getOrderDetailsFromSource(orderDetails, returnOrder, itemTypeOption, outlets) {
  if (orderDetails) {

    let senderDetails = orderDetails.waypoint.filter((item) => item.type === 'PICKUP')[0];
    let receiverDetails = orderDetails.waypoint.filter((item) => item.type === 'DROPOFF')[0];

    if(returnOrder)
    {
        senderDetails = orderDetails.waypoint.filter((item) => item.type === 'DROPOFF')[0];
        receiverDetails = orderDetails.waypoint.filter((item) => item.type === 'PICKUP')[0];
    }

    const selectedItemTypeIndex = itemTypeOption.findIndex((item) => item.name === senderDetails.inventory[0].type);
    const selectedOutletIndex = outlets.findIndex((item) => item.id === senderDetails.placeId);
    return {
      dropAtOutlet: !!senderDetails.placeId,
      selectedOutletIndex: selectedOutletIndex > -1 ? selectedOutletIndex.toString() : null,

      senderName: senderDetails.contact.name,
      senderEmail: senderDetails.contact.email,
      senderPhone: senderDetails.contact.phone,
      senderMobile: senderDetails.contact.phone,
      senderUnitNo: senderDetails.contact.unitNo,
      // senderAddress1: generateAddressByKeys(['address1', 'address2'], senderDetails.contact),
      senderAddress1: generateAddressByKeys(['address1'], senderDetails.contact),
      senderAddress2: generateAddressByKeys(['address2'], senderDetails.contact),
      senderPostcode: senderDetails.contact.postcode,
      senderCountry: senderDetails.contact.country,

      receiverName: receiverDetails.contact.name,
      receiverEmail: receiverDetails.contact.email,
      receiverPhone: receiverDetails.contact.phone,
      receiverMobile: receiverDetails.contact.phone,
      receiverUnitNo: receiverDetails.contact.unitNo,
      receiverAddress1: receiverDetails.contact.address1,
      receiverAddress2: receiverDetails.contact.address2,
      receiverPostcode: receiverDetails.contact.postcode,
      receiverCountry: receiverDetails.contact.country,

      itemDetails: senderDetails.inventory[0]?.name,
      weightValue: senderDetails.inventory[0]?.weight.value,
      widthValue: senderDetails.inventory[0]?.width,
      lengthValue: senderDetails.inventory[0]?.length,
      heightValue: senderDetails.inventory[0]?.height,
      quantity: senderDetails.inventory[0]?.quantity,
      itemValue: senderDetails.inventory[0]?.price?.amount,
      hsCode: senderDetails.inventory[0]?.hsCode,

      note: orderDetails.note,
      referenceNo: orderDetails.referenceNo,
      selectedItemTypeIndex: selectedItemTypeIndex > -1 ? selectedItemTypeIndex.toString() : null
    }
  }
  return null;
}

function getInitialFormState(selectedCountryData) {
  const start = moment();
  const remainder = 30 - (start.minute() % 30);

  const pickupTime = moment(start).add(remainder + 30, "minutes")

  return {
    senderName: null,
    senderEmail: null,
    senderPhone: null,
    senderMobile: null,
    senderCompany: null,
    senderUnitNo: null,
    senderAddress1: null,
    senderAddress2: null,
    senderCity: null,
    senderState: null,
    senderPostcode: null,
    senderCountry: selectedCountryData[0]?.countryCode,
    outletAddress: null,
    receiverName: null,
    receiverEmail: null,
    receiverPhone: null,
    receiverMobile: null,
    receiverCompany: null,
    receiverUnitNo: null,
    receiverAddress1: null,
    receiverAddress2: null,
    receiverCity: null,
    receiverState: null,
    receiverPostcode: null,
    receiverCountry: selectedCountryData[0]?.countryCode,
    selectedPickupOption: 2,
    dropAtOutlet: false,
    selectedOutletIndex: null,
    selectedItemTypeIndex: '0',
    itemDetails: null,
    weightValue: 0.1,
    widthValue: 10,
    lengthValue: 10,
    heightValue: 1,
    quantity: 1,
    itemValue: 0.00,
    hsCode: null,
    cod: false,
    codValue: 0.00,
    insurance: false,
    pickupDate: getDefaultDate(),
    pickupTime,
    pickupNow: true,
    promoCode: "",
    services: null,
    selectedServiceIndex: null,
    addon: {},
    paymentMethodId: null,
    note: null,
    referenceNo: null,
    errors: []
  }
}

const FetchWrapper = (props) => {
  const [state] = useContext(GlobalContext);
  const [fetchItems, setFetchItems] = useState(null);

  async function allFetch() {
    const companyId = state.global.companyDetails.id;
    const customerId = state.global.customerDetails.id;

    const getItemTypesRes = await getItemTypes(companyId);
    const getCustomerDetailsRes = await getCustomerDetails(companyId, customerId);

    // const getOutletsRes = await get("fleet/hubs");

    return {
      getItemTypesRes: await getItemTypesRes,
      // getOutletsRes: await getOutletsRes,
      getCustomerDetailsRes: await getCustomerDetailsRes
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const fetchAll = await allFetch();
      const { getItemTypesRes, getCustomerDetailsRes } = fetchAll;

      if (getItemTypesRes.status === 200 && getCustomerDetailsRes.status === 200) {

        // customerDetails = getCustomerDetailsRes.data.data;
        useLocalStorage.setItem("customerDetails", getCustomerDetailsRes.data.data)

        const itemTypesDataSource = getItemTypesRes.data.data;
        const parcelIndex = itemTypesDataSource.findIndex((item) => item.name === 'PARCEL');
        if (parcelIndex !== -1) {
          const parcelData = itemTypesDataSource[parcelIndex];
          itemTypesDataSource.splice(parcelIndex, 1);
          itemTypesDataSource.splice(0, 0, parcelData);
        }
        setFetchItems({
          itemTypes: itemTypesDataSource,
          outlets: [], //getOutletsRes.data.data
        })
      } else message.error('Request time out. Please try again.')
    }
    fetch()
  }, [])

  return fetchItems
        ? <NewOrder wrapper={{ ...fetchItems }} {...props} />
        : (
          <PageWrapper loading={!fetchItems} loadingMsg="loading...">
            <div style={{ width: '100%', height: '100%' }} />
          </PageWrapper>
        )
}

const NewOrder = props => {
  const { t } = useTranslation('orderPage');
  const [state, dispatch] = useContext(GlobalContext);
  const { outlets, itemTypes: itemTypeOption } = props.wrapper;
  const { companyDetails, customerDetails, countryList } = state.global;
  const location = useLocation();
  const { match } = props;
  const selectedOrder = {
    destination: location.state?.destination,
    origin: location.state?.origin,
    weight: location.state?.weight
  }
  const currentOrder = {
    orderId: location.state?.orderId || match.params.orderId
  }
  const [isLoading, setIsLoading] = useState(!!currentOrder.orderId);
  const [loadingMsg, setLoadingMsg] = useState(null);
  const defaultCountryCode = customerDetails.country || companyDetails.country || 'MY';
  const selectedCountryData = useMemo(() => countryList.filter((item) => (
    item.countryCode === defaultCountryCode
  )), [countryList])
  const getSelectedServiceIndex = (weight) => itemTypeOption.findIndex((item, index) => weight >= Number(item.minWeight) && weight <= Number(item.maxWeight))
  const initialFormState = useMemo(() => getInitialFormState(selectedCountryData), [selectedCountryData]);
  const returnOrder = location.state?.returnOrder ? location.state?.returnOrder : false;

  const orderDetailsFromSource = getOrderDetailsFromSource(location.state?.orderDetails, returnOrder, itemTypeOption, outlets);

  const [formState, setFormState] = useState({
    ...initialFormState,
    itemTypeOption,
    outlets,
    paymentMethod: [],
    countryList,
    senderName: customerDetails.name,
    senderEmail: customerDetails.email,
    senderPhone: customerDetails.phone,
    senderMobile: customerDetails.phone,
    senderUnitNo: selectedOrder.origin?.unitNo || customerDetails.unitNo,
    // senderAddress1: generateAddressByKeys(['address1', 'address2'], selectedOrder.origin) || generateAddressByKeys(['address1', 'address2'], customerDetails),
    senderAddress1: generateAddressByKeys(['address1'], selectedOrder.origin) || generateAddressByKeys(['address1'], customerDetails),
    senderAddress1: generateAddressByKeys(['address2'], selectedOrder.origin) || generateAddressByKeys(['address2'], customerDetails),
    senderPostcode: selectedOrder.origin?.postcode || customerDetails.postcode,
    senderCity: selectedOrder.origin?.city || customerDetails.senderCity,
    senderState: selectedOrder.origin?.state || customerDetails.state,
    senderCountry: selectedOrder.origin?.country || selectedCountryData[0]?.countryCode,
    receiverUnitNo: selectedOrder.destination?.unitNo,
    receiverAddress1: selectedOrder.destination?.address1,
    receiverAddress2: selectedOrder.destination?.address2,
    receiverCity: selectedOrder.destination?.city,
    receiverState: selectedOrder.destination?.state,
    receiverPostcode: selectedOrder.destination?.postcode,
    receiverCountry: selectedOrder.destination?.country || selectedCountryData[0]?.countryCode,
    weightValue: selectedOrder.weight || initialFormState.weightValue,
    widthValue: initialFormState.widthValue ? initialFormState.widthValue : 10,
    lengthValue: initialFormState.lengthValue ? initialFormState.lengthValue : 10,
    heightValue: initialFormState.heightValue ? initialFormState.heightValue : 1,
    selectedItemTypeIndex: getSelectedServiceIndex(selectedOrder.weight || initialFormState.weightValue).toString(),
    ...orderDetailsFromSource
  });


  useEffect(() => {
    currentOrder.orderId && setLoadingMsg('Processing your order. Please wait...');
  }, [currentOrder])

  useEffect(() => {
    if (!formState) {
      const newState = getInitialFormState(selectedCountryData);
      setFormState({
        ...newState,
        itemTypeOption,
        outlets,
        paymentMethod: [],
        countryList,
        senderName: customerDetails.name,
        senderEmail: customerDetails.email,
        senderPhone: customerDetails.phone,
        senderMobile: customerDetails.phone,
        senderUnitNo: customerDetails.unitNo,
        senderAddress1: generateAddressByKeys(['address1'], customerDetails),
        senderAddress2: generateAddressByKeys(['address2'], customerDetails),
        senderPostcode: customerDetails.postcode,
        senderCity: customerDetails.city,
        senderState: customerDetails.state,
        senderCountry: selectedCountryData[0]?.countryCode
      })
      setIsLoading(false);
    }
  }, [formState])

  // Set when new order form
  useEffect(() => {
    if (state.orderForm.new) {
      window.scrollTo(0,0)
      props.history.replace(`${process.env.PUBLIC_URL}/orders/new-order`)
      setFormState(null);
      dispatch({ type: 'NEW_FORM', payload: { new: false } })
    }
  }, [state.orderForm])

  const handleSubmit = (val) => {
    setIsLoading(true);
    submitOrder({
      ...val,
      state,
      publicUrl: process.env.PUBLIC_URL,
      history: props.history,
      orderId: currentOrder.orderId,
      closeLoading: () => setIsLoading(false),
      resetState: () => dispatch({ type: 'NEW_FORM', payload: { new: true } }),
      setLoadingMsg
    })
  }

  useEffect(() => {
    mixPanelTrack('ViewNewSingleOrder');
  }, []);

  return (
    <PageWrapper loading={isLoading} loadingMsg={loadingMsg} isCollapseSidebar={true}>
      <ShowOn on={formState}>
        <Formik
          {...validationSchema}
          enableReinitialize={true}
          initialValues={formState}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={handleSubmit}
        >
          {(form) => (
            <ContextWrapper
              value={{
                form,
                formState,
                routerProps: {
                  match: props.match,
                  location: props.location,
                  history: props.history
                },
                setIsLoading,
                resetState: () => dispatch({ type: 'NEW_FORM', payload: { new: true } }),
                currentOrder
              }}
            />
          )}
        </Formik>
      </ShowOn>
    </PageWrapper>
  );
};

const ContextWrapper = ({ value }) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { modalState, setModalState, companyDetails, settings } = state.global;
  const { sse } = state;
  const { routerProps, currentOrder, setIsLoading } = value;
  const orderStatusRes = useGetOrderStatusFallback(currentOrder, sse.message);

  useEffect(() => {
    if (currentOrder.orderId && orderStatusRes?.orderId === currentOrder.orderId) {
      setIsLoading(false);
      const fbPixelId = settings["consumer_portal.dashboard"]?.data?.scripts_fb_pixelid;
      const googleAnalyticsPixelId = settings["consumer_portal.dashboard"]?.data?.scripts_google_analyticid;

      if (fbPixelId && orderStatusRes.statusCode === 100) {
          // ReactPixel.track(
          //   'Order',
          //   {
          //     value: orderStatusRes.price.amount,
          //     currency: orderStatusRes.price.currency
          //   }
          // );
      }
      if (googleAnalyticsPixelId && orderStatusRes.statusCode === 100) {
        ReactGA.event({
          category: 'Conversion',
          action: 'Order',
          label: 'Single Order',
          value: Number(orderStatusRes.price.amount)
        });
      }

      onOpenConfirmOrderSuccessDialog(orderStatusRes)
    }
  }, [orderStatusRes])

  const onOpenConfirmOrderSuccessDialog = async (order) => {
    const DialogContent = () => (
      <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        <ShowOn on={order.statusCode >= 100}>
          <Button
            style={{ width: '100%', marginBottom: 10, maxWidth: 300 }}
            type="primary"
            target="_blank"
            href={`${API_ROOT}order/${order.orderId || order.id}/label?companyId=${companyDetails.id}`}
          >
            Print label
          </Button>
        </ShowOn>
        <CustomizedButton
          style={{ width: '100%', marginBottom: 10, maxWidth: 300 }}
          text="View order"
          type="primary"
          onClick={() => {
            setModalState({
              ...modalState,
              isModalVisible: false
            })
            routerProps.history.push({
              pathname: `${process.env.PUBLIC_URL}/order/${order.orderId || order.id}`,
            })
          }}
        />
      </div>
    )

    function capitalizeFirstLetter(string) {
      return string ? string.charAt(0).toUpperCase() + string.slice(1) : null;
    }

    let selectedOrder = {}
    switch (order.statusCode) {
      case 100:
        selectedOrder.color = "#00C853";
        selectedOrder.text = 'Successfull. Your order has been created.';
        break;
      case 99:
        selectedOrder.color = "#E91E63";
        selectedOrder.text = capitalizeFirstLetter(order.status);
        break;
      case 10:
        selectedOrder.color = "#FADB14";
        selectedOrder.text = capitalizeFirstLetter(order.status) + '. View your order to monitor the status.';
        break;
      default:
        selectedOrder.color = "#607D8B";
        selectedOrder.text = (capitalizeFirstLetter(order.status) || 'Failed to get order status') + '. View your order to monitor the status.';
        break;
    }

    setModalState({
      ...modalState,
      isModalVisible: true,
      footer: false,
      maskStyle: { backdropFilter: 'blur(4px)' },
      modal: {
        title: selectedOrder.text,
        dialogContent: DialogContent,
        maxWidth: 600,
        onCancel: () => dispatch({ type: 'NEW_FORM', payload: { new: true } })
      }
    });
  }

  return (
    <GlobalContext.Provider value={[{ ...state, newOrder: { ...value, onOpenConfirmOrderSuccessDialog } }, dispatch]}>
      <OrderForm />
    </GlobalContext.Provider>
  )
}

export default FetchWrapper;
