import React, { useEffect, useContext, useState, useMemo } from "react";
import { Row, Col, Layout, message } from "antd";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import ShowOn from 'Util/ShowOn';
import useGenerateDropdownItem from 'Util/useGenerateDropdownItem';
import useWindowSize from 'Util/useWindowSize';
import { GlobalContext } from "Store/store";

import SubmitFooter from "./SubmitFooter";
import ContactDetailsWrapper from "./ContactDetailsWrapper";
import ItemDetails from "./ItemDetails";
import ReadyDateAndTime from './ReadyDateAndTime';
import ChooseService from './ChooseService';
import dummyFormData from './dummyFormData.json';

const { Content } = Layout;

const getInputArray = (type, transl) => {
  return [
    { label: transl('newOrderForm.copyPaste.fullName'), placeholder: 'John Woo', accessor: 'Name', fullAccessor: type + 'Name', line: 1, required: true },
    { label: transl('newOrderForm.copyPaste.phone'), placeholder: '60199999999', accessor: 'Mobile', fullAccessor: type + 'Mobile', line: 1, required: true },
    { label: transl('newOrderForm.copyPaste.email'), placeholder: 'yourname@gmail.com', accessor: 'Email', fullAccessor: type + 'Email', line: 1, required: false },
    { label: transl('newOrderForm.copyPaste.company'), placeholder: 'Delyva Sdn Bhd', accessor: 'Company', fullAccessor: type + 'Company', line: 1, required: false },
    { label: transl('newOrderForm.copyPaste.unitNo'), placeholder: 'Suite 8.01, Level 8', accessor: 'UnitNo', fullAccessor: type + 'UnitNo', line: 1, required: false },
    { label: transl('newOrderForm.copyPaste.address1'), placeholder: 'KLCC', accessor: 'Address1', fullAccessor: type + 'Address1', line: 3, required: false },
    { label: transl('newOrderForm.copyPaste.address2'), placeholder: 'Kuala Lumpur City Centre', accessor: 'Address2', fullAccessor: type + 'Address2', line: 1, required: false },
    { label: transl('newOrderForm.copyPaste.postcode'), placeholder: '50450', accessor: 'Postcode', fullAccessor: type + 'Postcode', line: 1, required: true },
    { label: transl('newOrderForm.copyPaste.city'), placeholder: 'Kuala Lumpur', accessor: 'City', fullAccessor: type + 'City', line: 1, required: true },
    { label: transl('newOrderForm.copyPaste.state'), placeholder: 'Selangor', accessor: 'State', fullAccessor: type + 'State', line: 0, required: true },
  ]
}

const sections = {
  1: [
    'senderName',
    'senderMobile',
    'senderUnitNo',
    'senderCity',
    'senderState',
    'senderPostcode',
    'senderCountry',
    'selectedOutletIndex'
  ],
  2: [
    'receiverName',
    'receiverMobile',
    'receiverUnitNo',
    'receiverCity',
    'receiverState',
    'receiverPostcode',
    'receiverCountry',
  ],
  3: [
    'selectedItemTypeIndex',
    'itemDetails',
    'weightValue',
    'quantity',
  ],
  4: [
    'pickupDate'
  ],
  5: [
    'selectedServiceIndex'
  ]
}

const getOffsetTop = element => {
  let offsetTop = 0;
  while(element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}

const OrderForm = (props) => {
  const [state] = useContext(GlobalContext);
  const { t } = useTranslation('orderPage');
  const { form } = state.newOrder;
  const size = useWindowSize();
  const [validateSection, setValidateSection] = useState(null);
  const { validateField, validateForm, isValidating, values, errors, setFieldValue, isSubmitting } = form;
  const senderInputArray = useMemo(() => getInputArray("sender", t), []);
  const receiverInputArray = useMemo(() => getInputArray("receiver", t), []);
  const {
    senderPostcode,
    senderCity,
    receiverCity,
    senderState,
    receiverState,
    receiverPostcode,
    weightValue,
    quantity,
    selectedItemTypeIndex,
    dropAtOutlet,
    selectedOutletIndex
  } = values;

  useEffect(() => {
    if (!isSubmitting && Object.keys(errors).length > 0) {
      message.error('There is an error in your order form.')
    }
  }, [isSubmitting])

  useEffect(() => {
    setFieldValue('selectedServiceIndex', null)
    setFieldValue('addon', {})
    setFieldValue('promoCode', "")
    setFieldValue('errors', [])
    setFieldValue('services', null)
  }, [
    senderPostcode,
    senderCity,
    receiverCity,
    senderState,
    receiverState,
    receiverPostcode,
    weightValue,
    quantity,
    selectedItemTypeIndex,
    dropAtOutlet,
    selectedOutletIndex
  ]);

  useEffect(() => {
    validateSection && sections[validateSection].forEach((item, i) => {
      validateField(item)
    });

    let keyToReplace = {}
    if (validateSection) {
      Object.entries(dummyFormData).filter((entry) => {
        if (Number(entry[0]) !== validateSection) {
          keyToReplace = {
            ...keyToReplace,
            ...entry[1]
          }
        }
      }
    )
      validateForm({ ...form.values, ...keyToReplace })
        .then((result) => {
          const activeKeyErrors = Object.keys(result).reduce((acc, key) => {
            return sections[validateSection].includes(key) ? acc.concat(key) : acc;
          }, []);
          if (activeKeyErrors.length === 0) {
            const elem = document.getElementById(sections[validateSection + 1][0]);
            const pos = getOffsetTop(elem);
            window.scrollTo(0, pos - 190);
            elem.focus();
          }
          setValidateSection(null)
        })
    }
  }, [validateSection])

  const countryDropdownChildren = useGenerateDropdownItem(
    values.countryList,
    'countryCode',
    'countryCode',
    'name'
  );

  return (
    <ShowOn on={countryDropdownChildren}>
      <div>
        <Content className="ui-right-content form" style={{ display: 'inline' }}>
          <Link
            to={`${process.env.PUBLIC_URL}/orders/bulk-orders`}
            style={{ maxWidth: 100, minWidth: 55, width: 55, }}
          >
            <i className="fa fa-chevron-left" style={{  marginTop: 20 }} /> {t('content.header.title')}
          </Link>
          <Row style={{ paddingLeft: 20 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <p className="heading1">
              { values.nanoId ?
                <span>{t('content.newOrder.editOrder')}: {values.nanoId} </span>
                :<span>{t('content.newOrder.title')}</span>
              }
              </p>
            </Col>
          </Row>
          <Row style={{ padding: 20 }}>
            <Col xs={24} sm={24} md={24} lg={24} xl={15}>
              <Row gutter={[20, 20]}>
                <Col>
                  <ContactDetailsWrapper
                    countryDropdownChildren={countryDropdownChildren}
                    header={t('content.newOrder.senderDetail')}
                    inputArray={senderInputArray}
                    type="sender"
                    onClickNext={() => setValidateSection(1)}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col>
                  <ContactDetailsWrapper
                    countryDropdownChildren={countryDropdownChildren}
                    header={t('content.newOrder.receiverDetail')}
                    inputArray={receiverInputArray}
                    type="receiver"
                    onClickNext={() => setValidateSection(2)}
                  />
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col>
                  <ItemDetails onClickNext={() => setValidateSection(3)} />
                </Col>
              </Row>
              <Row gutter={[20, 20]}>
                <Col>
                  <ReadyDateAndTime onClickNext={() => setValidateSection(4)} />
                </Col>
              </Row>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={9} style={{ position: size[0] > 1200 ? 'sticky' : null, top: size[0] > 1200 ? 55 : 0 }}>
              <Row style={{ padding: size[0] > 1200 ? '0 20px' : '20px 0 0 0' }}>
                <Col>
                  <ChooseService />
                </Col>
              </Row>
            </Col>
          </Row>
        </Content>
        <SubmitFooter />
      </div>
    </ShowOn>
  )
}

export default OrderForm;
