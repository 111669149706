import React, { useContext, Suspense } from 'react';
import { Button, Collapse } from 'antd';

import { GlobalContext } from 'Store/store';
import CopyInput from 'Components/input-v2/CopyInput';

import instabookLogo from 'images/Logo/instabook.png';
import woocommerceLogo from 'images/Logo/woocommerce-logo.png';
import magentoLogo from 'images/Logo/magento-logo.png';
import shopifyLogo from 'images/Logo/shopify-logo.png';
import easystoreLogo from 'images/Logo/easystore-logo.png';



const { Panel } = Collapse;
const Shopify = React.lazy(() => import('./accordion.shopify'));
const Instabook = React.lazy(() => import('./accordion.instabook'));

const genExtra = () => <Button>Setup</Button>;

const Accordion = () => {
  const [store] = useContext(GlobalContext);
  const { customerDetails, userDetails, companyDetails } = store?.global;
  return (
    <Collapse accordion>
      <Panel
        header={
          <div style={{ width: '100%' }}>
            <img
              style={{ height: 35 }}
              src={instabookLogo}
              alt="Instabook"
            />
          </div>
        }
        key="1"
        extra={genExtra()}
        showArrow={false}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Instabook />
        </Suspense>
      </Panel>
      {companyDetails.code === 'my' ?
      <Panel
        header={
          <div style={{ width: '100%' }}>
            <img
              style={{ height: 35 }}
              src={shopifyLogo}
              alt="Shopify"
            />
          </div>
        }
        key="2"
        extra={genExtra()}
        showArrow={false}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <Shopify />
        </Suspense>
      </Panel>
      : ''}
      <Panel
        header={
          <div style={{ width: '100%' }}>
            <img
              style={{ height: 35 }}
              src={woocommerceLogo}
              alt="Woocommerce"
            />
          </div>
        }
        key="3"
        extra={genExtra()}
        showArrow={false}
      >
        <div style={{ margin: 20 }}>
          <h4>Instruction for Woocommerce</h4>
          <ol>
            <li>Install Delyva Woocommerce Plugin - <a href="https://wordpress.org/plugins/delyvax" target="_blank">[Click Here]</a></li>
            <li>
              Copy these parameters:
              <ul>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>Company code: <CopyInput data={companyDetails.code} /></div></li>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>Company ID: <CopyInput data={companyDetails.id} /></div></li>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>User ID: <CopyInput data={userDetails.id} /></div></li>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>Customer ID: <CopyInput data={customerDetails.id} /></div></li>
              </ul>
            </li>
            <li>Get and copy your API key in Settings &gt; Integrations &gt; Add new key</li>
            <li>Please follow instructions in this page - <a href="https://delyva.com/my/blog/woocommerce-store-set-up-ecommerce-domestic-and-international-shipping-with-delyva/" target="_blank" rel="noreferrer">[Click Here]</a></li>
          </ol>
        </div>
      </Panel>
      <Panel
        header={
          <div style={{ width: '100%' }}>
            <img
              style={{ height: 35 }}
              src={magentoLogo}
              alt="Magento"
            />
          </div>
        }
        key="4"
        extra={genExtra()}
        showArrow={false}
      >
        <div style={{ margin: 20 }}>
          <h4>Instruction for Magento</h4>
          <ol>
            <li>Install Delyva Magento Plugin - <a href="https://github.com/delyva/magento" target="_blank">[Click Here]</a></li>
            <li>
              Copy these parameters:
              <ul>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>Company code: <CopyInput data={companyDetails.code} /></div></li>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>Company ID: <CopyInput data={companyDetails.id} /></div></li>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>User ID: <CopyInput data={userDetails.id} /></div></li>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>Customer ID: <CopyInput data={customerDetails.id} /></div></li>
              </ul>
            </li>
            <li>Get and copy your API key in Settings &gt; Integrations &gt; Add new key</li>
            <li>Please follow instructions in this page - <a href="https://delyva.com/my/blog/set-up-your-magento-2-store-for-ecommerce-domestic-and-international-shipping-with-delyva/" target="_blank" rel="noreferrer">[Click Here]</a></li>
          </ol>
        </div>
      </Panel>
      <Panel
        header={
          <div style={{ width: '100%' }}>
            <img
              style={{ height: 35 }}
              src={easystoreLogo}
              alt="EasyStore"
            />
          </div>
        }
        key="5"
        extra={genExtra()}
        showArrow={false}
      >
        <div style={{ margin: 20 }}>
          <h4><a href="https://www.easystore.co/en-my/apps/delyva-my" target="_blank">Click here</a> to navigate to EasyStore App store and install Delyva app.</h4>
        </div>
      </Panel>
      <Panel
        header={
          <div style={{ width: '100%' }}>
            <h3>Custom API Integration</h3>
          </div>
        }
        key="6"
        extra={genExtra()}
        showArrow={false}
      >
        <div style={{ margin: 20 }}>
          <h4>Notes for Custom API Integration</h4>
          <ol>
            <li>Refer Delyva API Documentation - <a href="https://docs.api.delyva.com/?version=latest" target="_blank" rel="noreferrer">[Click Here]</a></li>
            <li>
              Take note of these parameters:
              <ul>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>Company code: <CopyInput data={companyDetails.code} /></div></li>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>Company ID: <CopyInput data={companyDetails.id} /></div></li>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>User ID: <CopyInput data={userDetails.id} /></div></li>
                <li><div style={{ display: 'flex', alignItems: 'center' }}>Customer ID: <CopyInput data={customerDetails.id} /></div></li>
              </ul>
            </li>
            <li>Get and copy your API key in Settings &gt; Integrations &gt; Add new key</li>
            <li>To display a list of service providers and the rates, use Services:Price Quote API. Customers will choose a service and your system to store the 'service.service_code'.</li>
            <li>To create delivery order, you can use the selected 'service.service_code' in #4, and use Create Order API to create delivery order as draft and then use Process/Confirm Order API to process the order. Store order.id and order.cn (tracking no) from the response.</li>
            <li>To Print delivery label (AWB), create a hyperlink with open in new tab/window and use Create Order:Print label API and use order.id above.</li>
            <li>To get the delivery order latest status and tracking history, you can use Tracking:Order history API and use order.cn from #5.</li>
            <li>To get the delivery order details, you can use Demo:3.Get Order Details API and use order.id from #5.</li>
            <li>To receive order status updates in real-time, subscribe to webhook in settings &gt; Webhook &gt; Add new endpoint: order.tracking_updates and handle the webhook responses when you receive one on your side.</li>
          </ol>
        </div>
      </Panel>
    </Collapse>
  )
}

export default Accordion
