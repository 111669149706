import React from "react";
import { Button, Empty } from "antd";
import { Link } from "react-router-dom";

const EmptyData = ({ path, text, type, description, onClick }) => (
  <Empty
    imageStyle={{
      height: 60
    }}
    description={description}
  >
    {path ? (
      <Link to={path}>
        <Button type={type ? type : "primary"}>{text}</Button>
      </Link>
    ) : (
      <Button type={type ? type : "primary"} onClick={onClick}>{text}</Button>
    )}
  </Empty>
);

export default EmptyData;
