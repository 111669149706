import React, { Component, Fragment } from "react";
import { Button, message } from "antd";

import { login } from "Util/API";
import withStore from 'Util/withStore';
import ShowOn from 'Util/ShowOn';
import useLocalStorage from 'Util/useLocalStorage';

const GOOGLE_BUTTON_ID = "my-signin2";

class Login extends Component {
  state = {
    checked: false,
    isLoginFinished: false,
    isLoading: false,
    isCompanyCodeExist: true,
    pageNotFound: false,
    companyCode: this.props.state.global.companyDetails.code
  };

  /**
   * Prepare Google Login button
   */
  prepareLoginButton = () => {
    this.auth2.attachClickHandler(
      document.getElementById(GOOGLE_BUTTON_ID),
      {},
      async googleUser => {
        let profile = googleUser.getBasicProfile();
        const data = {
          companyCode: this.state.companyCode,
          id: profile.getId(),
          token: googleUser.getAuthResponse().id_token,
          email: profile.getEmail()
        };

        try {
          const googleResponse = await login("auth/exchangeToken/google", data);
          if (googleResponse.status === 200) {
            useLocalStorage.clear();
            useLocalStorage.setItem("accessToken", googleResponse.data.data.accessToken);
            useLocalStorage.setItem("refreshToken", googleResponse.data.data.refreshToken);
            useLocalStorage.setItem("companyId", googleResponse.data.data.companyId);
            this.props.dispatch({ type: 'LOGIN_SUCCESS' })
          } else if (googleResponse.error?.code === "ERR_AUTH_U00") {
            this.props.handleNeedActivationError(googleResponse.data.userId, googleResponse.error.message)
          } else message.error(googleResponse.error.message);
        } catch (e) {
          message.error(e);
        }
      },
      error => {
        if (!error.error === "popup_closed_by_user") {
          message.error(error.error);
        }
      }
    );
  };

  componentDidMount() {
    // Load Google Platform Library
    if (this.props.socialNetwork.google.enabled && window.gapi) {
      window.gapi.load("auth2", () => {
        this.auth2 = window.gapi.auth2.init({
          client_id: this.props.socialNetwork.google.clientId,
          cookiepolicy: "single_host_origin",
          scope: "profile email"
        });

        this.prepareLoginButton(document.getElementById(GOOGLE_BUTTON_ID));
      });
    }

    // Initialize FB Login
    if (this.props.socialNetwork.facebook.enabled) {
      document.addEventListener("FBObjectReady", this.initializeFacebookLogin);
    }
  }

  componentWillUnmount() {
    if (this.props.socialNetwork.facebook.enabled) {
      document.removeEventListener("FBObjectReady", this.initializeFacebookLogin);
    }
  }

  /**
   * Login via Facebook
   *
   * Init FB object and check Facebook Login status
   */
  initializeFacebookLogin = () => {
    this.FB = window.FB;
    this.checkLoginStatus();
  };

  /**
   * Check login status
   */
  checkLoginStatus = () => {
    this.FB.getLoginStatus(this.facebookLoginHandler);
  };

  /**
   * Check login status and call login api is user is not logged in
   */
  facebookLogin = () => {
    this.setState({
      isLoading: true
    });

    if (!this.FB) {
      this.initializeFacebookLogin();
    }

    this.FB.getLoginStatus(response => {
      if (response.status === "connected") {
        this.facebookLoginHandler(response);
      } else {
        this.FB.login(this.facebookLoginHandler, {
          scope: "public_profile, email"
        });
      }
    });
  };

  /**
   * Handle login response
   */
  facebookLoginHandler = response => {
    if (response.status === "connected") {
      this.FB.api("/me", userData => {
        var authUser = this.FB.getAuthResponse();
        this.connectToFacebookAPI(authUser);
      });
    } else {
      this.setState({
        isLoading: false
      });
    }
  };

  /**
   * Connect to custom login API
   */
  connectToFacebookAPI = async result => {
    const data = {
      companyCode: this.state.companyCode,
      id: result.userID,
      token: result.accessToken
    };

    const FBresponse = await login("auth/exchangeToken/facebook", data);

    if (FBresponse.status === 200) {
      useLocalStorage.clear();
      useLocalStorage.setItem("accessToken", FBresponse.data.data.accessToken);
      useLocalStorage.setItem("refreshToken", FBresponse.data.data.refreshToken);
      useLocalStorage.setItem("companyId", FBresponse.data.data.companyId);
      this.props.dispatch({ type: 'LOGIN_SUCCESS' })
    } else if (FBresponse.error?.code === "ERR_AUTH_U00") {
      this.props.handleNeedActivationError(FBresponse.data.userId, FBresponse.error.message)
    } else message.error(FBresponse.error.message);
  };

  render() {
    return (
      <Fragment>
        <ShowOn on={this.props.socialNetwork.google.enabled}>
          <Button
            id={GOOGLE_BUTTON_ID}
            block
            className="button-google"
            ref="googleLoginBtn"
          >
            <i className="fab fa-google" style={{ marginRight: 10 }} />
            Log in with Google
          </Button>
        </ShowOn>
        <ShowOn on={this.props.socialNetwork.facebook.enabled}>
          <Button
            block
            className="button-facebook"
            onClick={this.facebookLogin}
          >
            <i
              className="fab fa-facebook-square"
              style={{ marginRight: 10 }}
            />
            Log in with Facebook
          </Button>
        </ShowOn>
      </Fragment>
    );
  }
}

export default withStore(Login);
