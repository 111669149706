import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import useWindowSize from 'Util/useWindowSize';

const CopyInput = ({ data }) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const size = useWindowSize();

  return (
    <div style={{ display: size[0] > 488 ? 'flex' : 'block', alignItems: 'center', backgroundColor: '#F5F5F5', padding: '0px 10px', justifyContent: 'space-between', margin: '2px 10px' }}>
      <span>{data}</span>
      <CopyToClipboard text={data}>
        <button style={{ borderWidth: '1px 0', borderRadius: 0, marginLeft: 10 }} className="copyButton" onClick={() => setCopySuccess(true)}>
          {copySuccess ? 'Copied!' : 'Copy'}
        </button>
      </CopyToClipboard>
    </div>
  )
}

export default CopyInput;
