import React, { Component } from "react";
import { Layout, message } from "antd";
// import Navbar from "Components/NavBar";
// import SideBar from "../../Home/Sidebar";
// import { NProgress } from "@tanem/react-nprogress";
// import Container from "../../Components/NProgress/Container";
// import Bar from "../../Components/NProgress/Bar";
import AppsContent from "./AppsContent";
import PageWrapper from "Components/PageWrapper";
import useLocalStorage from "Util/useLocalStorage";

import { get, remove, update } from "Util/API";

import { GlobalContext } from "Store/store";

let installedAppsData = [];
let availableAppsData = [];
let installedRowTotal = 0;
let availableRowTotal = 0;

class Apps extends Component {
  state = {
    collapsedSidebar:
      typeof Storage !== "undefined"
        ? useLocalStorage.getItem("toggleSider") === "true"
          ? true
          : false
        : true,
    isLoading: true
  };

  // const [state, dispatch] = useContext(GlobalContext);
  // const { customerDetails } = state.global;

  componentDidMount() {
    this.setState({
      isLoading: false
    });
    const { filterStatus, queryParams } = this.state;

    this.getAvailable(1, 1000, queryParams, filterStatus, "name", "ascend");
    this.getInstalled(1, 1000, queryParams, filterStatus, "ascend", "name");

    // if (JSON.parse(useLocalStorage.getItem("isAdmin")) === true) {
    //   this.getAvailable(1, 1000, queryParams, filterStatus, "name", "ascend");
    //   this.getInstalled(1, 1000, queryParams, filterStatus, "ascend", "name");
    // } else {
    //   installedAppsData = [];
    //   installedRowTotal = 0;
    //   availableAppsData = [];
    //   availableRowTotal = 0;
    // }
  }

  getInstalled = async (page, limit, queryParams, status, orderBy, sortBy) => {
    this.setState({
      isLoading: true,
      filterStatus: status,
      isSearching: queryParams ? true : false,
      queryParams: queryParams ? queryParams : "",
    });

    let orderByType = orderBy === "ascend" ? "asc" : "desc";
    if (!page && !limit && !queryParams && !orderBy && !sortBy) {
      installedAppsData = []
    }

    let params = ""
    const url = new URLSearchParams(params);
    url.set('page', page);
    url.set('limit', limit);
    url.set('orderBy', orderByType);

    if (queryParams) url.set("keyword", queryParams);
    if (status) url.set('status', status);
    if (sortBy) url.set('sortBy', sortBy);

    // const response = await get(`plugin/installed?customerId=1&${url.toString()}`);
    const response = await get(`plugin/installed?${url.toString()}`);

    if (response.status === 200) {
      let resData = [];
      let count = 0;
      for(let i=0; i< response.data.data.length; i++)
      {
          if(response.data.data[i].path === 'flash-express' || response.data.data[i].path === 'ninja-van' || response.data.data[i].path === 'lalamove-v3'
            || response.data.data[i].path === 'jnt-express' || response.data.data[i].path === 'pos-usc' || response.data.data[i].path === 'dhl-ecommerce-my'
            || response.data.data[i].path === 'mygdex-prime' || response.data.data[i].path === 'pandago'
            || response.data.data[i].path === 'pickupp' || response.data.data[i].path === 'grab-express' 
            || response.data.data[i].path === 'citylinkexpress' || response.data.data[i].path === 'abx-express' 
            || response.data.data[i].path === 'line-clear' 
          )
          {
              resData[count] = response.data.data[i];
              count++;
          }
      }

      installedRowTotal = resData.length;
      installedAppsData = resData;

    } else message.error(response);

    this.setState({
      isLoading: false,
    });
  };

  getAvailable = async (page, limit, queryParams, status, orderBy, sortBy) => {
    this.setState({
      isLoading: true,
      filterStatus: status,
      isSearching: queryParams ? true : false,
      queryParams: queryParams ? queryParams : "",
    });

    let sortByType = sortBy === "ascend" ? "asc" : "desc";
    if (!page && !limit && !queryParams && !orderBy && !sortBy) {
      availableAppsData = []
    }

    let params = "";
    const url = new URLSearchParams(params);
    url.set('page', page);
    url.set('limit', limit);
    url.set('sortBy', sortByType);

    if (orderBy) url.set('orderBy', orderBy);

    const response = await get(`plugin/apps?${url.toString()}`);

    if (response.status === 200) {
      let resData = [];
      let count = 0;
      for(let i=0; i< response.data.data.length; i++)
      {
          if(response.data.data[i].path === 'flash-express' || response.data.data[i].path === 'ninja-van' || response.data.data[i].path === 'lalamove-v3'
            || response.data.data[i].path === 'jnt-express' || response.data.data[i].path === 'pos-usc' || response.data.data[i].path === 'dhl-ecommerce-my'
            || response.data.data[i].path === 'mygdex-prime' || response.data.data[i].path === 'pandago'
            || response.data.data[i].path === 'pickupp' || response.data.data[i].path === 'grab-express'  
            || response.data.data[i].path === 'citylinkexpress' || response.data.data[i].path === 'abx-express' 
            || response.data.data[i].path === 'line-clear' 
          )
          {
              resData[count] = response.data.data[i];
              count++;
          }
      }

      availableRowTotal = resData.length;
      availableAppsData = resData;

    } else message.error(response);

    this.setState({
      isLoading: false,
    });
  };

  toggleSidebar = () => {
    useLocalStorage.getItem("toggleSider") === "true"
      ? this.setState({ collapsedSidebar: false })
      : this.setState({ collapsedSidebar: true });

    useLocalStorage.getItem("toggleSider") === "true"
      ? useLocalStorage.setItem("toggleSider", "false")
      : useLocalStorage.setItem("toggleSider", "true");
  };

  autoMinimizeOnBreakpoint = broken => {
    this.setState({ collapsedSidebar: broken });
    useLocalStorage.setItem("toggleSider", broken);
  };

  render() {
    return (
      <div>
        <PageWrapper loading={this.state.isLoading} isCollapseSidebar={false}>
          <Layout>
            <AppsContent
              getInstalled={this.getInstalled}
              installedAppsData={installedAppsData}
              installedRowTotal={installedRowTotal}
              getAvailable={this.getAvailable}
              availableAppsData={availableAppsData}
              availableRowTotal={availableRowTotal}
            />
          </Layout>
        </PageWrapper>
      </div>
    );
  }
}

export default Apps;
