import React, { useContext, useState, useMemo } from 'react';
import { Layout, Spin } from 'antd';
import { Link } from 'react-router-dom';

import { GlobalContext } from 'Store/store';

import PageWrapper from 'Components/PageWrapper';
const jwt = require("jsonwebtoken");

const { Content } = Layout;

const Frame = ({ iframeUrl, setHideSpinner }) => {
  return (
    <iframe
      title="Analytics"
      src={iframeUrl}
      frameborder="0"
      style={{ width: '-webkit-fill-available', height: '280vh' }}
      allowtransparency
      onLoad={() => setHideSpinner(true)}
    />
  )
}

const AnalyticsPage = (props) => {
  const [state] = useContext(GlobalContext);
  const [hideSpinner, setHideSpinner] = useState(false);
  const companyId = state.global.companyDetails.id;
  const customerId = state.global.customerDetails.id;
  // const accountNumber = state.global.customerDetails.accountNumber;

  const METABASE_SITE_URL = "https://bi.delyva.app";
  const METABASE_SECRET_KEY = "3cbbb00f5be97b1a043704d2aea38768da2f2f36df6c11cf8230c6b17513b670";

  const payload = {
    resource: { dashboard: props.location.state.id },
    params: {
      "company_id": [companyId],
      "customer_id": [customerId]
    },
    exp: Math.round(Date.now() / 1000) + (10 * 60) // 10 minute expiration
  };

  const token = jwt.sign(payload, METABASE_SECRET_KEY);

  const iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#bordered=true&titled=true";

  return (
    <PageWrapper loading={false} currentPath={props.location}>
      <Layout>
        <Content className="ui-right-content form">
          <Link
            to={`${process.env.PUBLIC_URL}/analytics`}
            style={{ maxWidth: 100, minWidth: 55, width: 55, }}
          >
            <i className="fa fa-chevron-left" style={{  marginTop: 20 }} /> Back to Analytics
          </Link>
          <Spin tip="Loading..." spinning={!hideSpinner} style={{ backgroundColor: '#F0F2F5' }}>
            <div>
              {useMemo(() => <Frame iframeUrl={iframeUrl} setHideSpinner={setHideSpinner} />, [])}
            </div>
          </Spin>
        </Content>
      </Layout>
    </PageWrapper>
  )
}

export default AnalyticsPage;
