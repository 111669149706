import React, { Component } from "react";
import { Form } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  MESSAGE_EMAIL_REQUIRED,
  MESSAGE_INVALID_EMAIL_ADDRESS
} from "Util/ErrorMessage";
import TextInput from "Components/TextInput";
import CustomizedButton from "Components/CustomizedButton";

const formik = {
  initialValues: {
    newEmail: ""
  },
  validationSchema: Yup.object().shape({
    newEmail: Yup.string()
      .email(MESSAGE_INVALID_EMAIL_ADDRESS)
      .required(MESSAGE_EMAIL_REQUIRED)
  })
};

class ChangeEmailAddressForm extends Component {
  render() {
    return (
      <div style={{ marginTop: 20 }}>
        <Formik {...formik} onSubmit={this.props.submitNewEmail}>
          {form => (
            <Form onSubmit={form.handleSubmit} className="manage-auth-form">
              <TextInput
                {...form}
                name="newEmail"
                label={this.props.transl('content.changeEmailAddress.form.newEmailAddress')}
              />
              <Form.Item className="manage-auth-form-item ">
                <CustomizedButton
                  text={this.props.transl('content.changeEmailAddress.form.change')}
                  type="primary"
                  htmlType="submit"
                  isLoading={this.props.isLoading}
                />
              </Form.Item>
            </Form>
          )}
        </Formik>
      </div>
    );
  }
}

export default ChangeEmailAddressForm;
