import React, { useState, useEffect, useContext } from "react";
import { Table, Popover, message, Badge } from "antd";
import { useTranslation } from "react-i18next";

import { GlobalContext } from "Store/store";
import AddNewApi from './AddNewApi';

import CustomizedButton from "Components/CustomizedButton";
import { remove } from 'Util/API';

const columns = ({ setModalState, modalState, setDataSource, onDeleteEndpoint, transl }) => [
  {
    title: 'Endpoint',
    dataIndex: "url",
    width: 500,
    key: "url",
    render: (text, record) => (
      <div>{text}</div>
    )
  },
  {
    title: 'Event type',
    width: 150,
    key: 'event',
    render: (text, record) => (
      <div>{record.event}</div>
    )
  },
  {
    title: 'Status',
    width: 150,
    key: 'status',
    render: (text, record) => (
      <div><Badge
        status={record.status === 1 ? "success" : "warning"}
        text={record.status === 1 ? "OK" : "Disabled"}
      />
      <p>{record.failedCount > 0 ? record.failedReason : ""}</p>
      </div>
    )
  },
  {
    title: transl('content.integration.tableColumn.action'),
    width: 100,
    key: "actions",
    fixed: window.innerWidth < 768 && "right",
    render: (text, record, index) => (
      <div style={{ display: "flex", alignItems: "center", justifyContent: 'space-around' }}>
        <CustomizedButton
          text="Edit"
          onClick={
            () => {
              setModalState({
                ...modalState,
                isModalVisible: true,
                footer: false,
                modal: {
                  title: 'Edit endpoint',
                  dialogContent: AddNewApi,
                  dialogContentProps: {
                    setModalState,
                    modalState,
                    setDataSource,
                    record
                  },
                  maxWidth: 600
                }
              })
            }
          }
        />
        <CustomizedButton
          text="Delete"
          onClick={
            () => {
              setModalState({
                ...modalState,
                isModalVisible: true,
                modal: {
                  title: "Delete endpoint",
                  text: <span>Are you sure you want to delete this endpont? <p style={{ fontWeight: 700 }}>{record.url}</p></span>,
                  onOk: () => onDeleteEndpoint(record.id)
                }
              })
            }
          }
        />
      </div>
    )
  }
]

const AddressBookTableConfig = props => {
  const [state] = useContext(GlobalContext);
  const { t } = useTranslation('settingsPage');
  const { modalState, setModalState } = state.global;

  const onDeleteEndpoint = async (id) => {
    props.setIsLoading(true);
    setModalState({
      ...modalState,
      isModalVisible: false
    })
    const createToken = await remove('webhook/' + id);
    if (createToken.status === 200) {
      message.success('Deleted.')
      props.setDataSource(null)
    } else message.error(createToken);
    props.setIsLoading(false);
  }

  return (
    <Table
      dataSource={props.transactionData}
      columns={columns({ setModalState, modalState, setDataSource: props.setDataSource, transl: t, onDeleteEndpoint })}
      rowKey={record => record.id}
      loading={props.isLoading}
      scroll={{ x: window.innerWidth < 768 && 400 }}
      style={{ maxHeight: "60%" }}
    />
  );
};

export default AddressBookTableConfig;
