import { useEffect, useState } from 'react';

import { get } from 'Util/API';

const useOrderUpdateListener = (orderIds, message) => {
  const [updatedOrder, setUpdatedOrder] = useState(null);
  const [blockSseUpdateUi, setBlockSseUpdateUi] = useState(false);

  useEffect(() => {
    const isRelatedEvent = message &&
      (
        message.event === 'order.status' ||
        message.event === 'order.failed' ||
        message.event === 'order.updated' ||
        message.event === 'order.created'
      );
    if (orderIds && isRelatedEvent && !blockSseUpdateUi) {
      const getOrder = async () => {
        const fetch = await get(`order/${message.orderId}`);
        if (fetch.status === 200) {
          const data = fetch.data.data;
          setUpdatedOrder(data)
        } else message.error(fetch);
        setBlockSseUpdateUi(true);
      }
      const relatedOrderIdIndex = orderIds.findIndex((item) => item === message.orderId);
      relatedOrderIdIndex !== -1 && getOrder();
    }
  }, [message])


  return updatedOrder;
}

export default useOrderUpdateListener;
