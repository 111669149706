import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Table, Tabs, Input, Tag, Row, Col, Select, message } from "antd";
import { Link } from "react-router-dom";

import CustomizedButton from "Components/CustomizedButton";
import CustomizedModal from "Components/CustomizedModal";
import { remove } from 'Util/API';

import generateAddressByKeys from 'Util/generateAddressByKeys';

const TabPane = Tabs.TabPane;
const { Option } = Select;


const columns = (props) => [
  {
    title: props.transl('content.addressBookTableColumn.name'),
    dataIndex: "id",
    key: "id",
    width: 50,
    render: (text, record) => (
      <div>
      <Link
        to={{
          pathname: `${process.env.PUBLIC_URL}/contacts/customer/${record.customerId}/contact/${record.id}/edit`,
        }}
      >
        {text}
      </Link>
      </div>
    )
  },
  {
    title: props.transl('content.addressBookTableColumn.name'),
    dataIndex: "name",
    key: "name",
    width: 150,
    render: (text, record) => (
      <div>
      <Link
        to={{
          pathname: `${process.env.PUBLIC_URL}/contacts/customer/${record.customerId}/contact/${record.id}/edit`,
        }}
      >
        {text}
      </Link>  &nbsp;
        { record.mobile ?
        <span><a href={`tel:+${record.mobile}`}><i className="fa fa-phone"></i> </a> &nbsp;
        <a href={`https://api.whatsapp.com/send?phone=${record.mobile}&subject=Hi%20${record.name}`} target="_blank">
        <i className="fa fa-whatsapp" aria-hidden="true"></i>
        </a> &nbsp;
        </span>
        :''}
        { record.email ? <a href={`mailto:${record.email}?subject=Hi%20${record.name}&body=Hi%20${record.name}`}><i className="fa fa-envelope"></i></a> :''}
      </div>
    )
  },
  {
    title: props.transl('content.addressBookTableColumn.address'),
    key: "fullAddress",
    width: 250,
    render: (data) => {
        const addressStr = generateAddressByKeys(['unitNo', 'address1', 'address2', 'postcode', 'city', 'state', 'country'], data);
        return (
          <div>
          {addressStr}
          </div>
        )
    }
  },
  {
    title: props.transl('content.addressBookTableColumn.tags'),
    key: "tags",
    dataIndex: "tags",
    width: 120,
    render: (tags) => {
        return (
          <div>
          { tags ?
              (tags.map((item, index) => (
                <Tag color="blue">{item}</Tag>
              ))) : ''
          }
          </div>
        )
    }
  },
  {
    title: props.transl('content.addressBookTableColumn.action'),
    key: "actions",
    width: 100,
    fixed: "right",
    render: (text, record) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Link
          to={{
            pathname: `${process.env.PUBLIC_URL}/contacts/customer/${record.customerId}/contact/${record.id}/edit`,
          }}
        >
          <CustomizedButton
            text={
              <span>
                <i className="far fa-edit" />
              </span>
            }
            className="button__transparent"
          />
        </Link>
        <CustomizedButton
          text={
            <span>
              <i className="fas fa-trash-alt" />
            </span>
          }
          className="button__transparent"
          onClick={() => props.cancel({ contactId: record.id, contactCustomerId: record.customerId })}
        />
      </div>
    )
  }
]

const AddressBookTableConfig = props => {
  const { t } = useTranslation('addressBookPage');
  const { setIsLoading, getContacts } = props;
  const [modalState, setModalState] = useState({
    isModalVisible: false,
    modal: {
      title: null,
      onOk: null,
      onOkData: null,
      onCancel: null,
      text: null
    }
  })

  const tagList = ['Any','Sender','Receiver'];

  const onCloseModal = () => {
    setModalState({
      ...modalState,
      isModalVisible: false
    });
  };

  const onOpenCancelOrderModal = (id) => {
    setModalState({
      ...modalState,
      isModalVisible: true,
      modal: {
        title: t('content.deleteContact.delete'),
        onOk: onConfirmCancelOrder,
        onOkData: id,
        onCancel: onCloseModal,
        text: t('content.deleteContact.delete1')+" ID:"+id.contactId
      }
    });
  };

  const onConfirmCancelOrder = async (id) => {
    const { contactId, contactCustomerId } = id;
    setIsLoading(true);
    setModalState({
      isModalVisible: false,
      modal: {
        title: null,
        onOk: null,
        onCancel: null,
        text: null
      }
    })
    const orderResponse = await remove(`contact/${contactId}?customerId=${contactCustomerId}`);
    if (orderResponse.status === 200 || orderResponse.status === 204) {
      getContacts("", 1, 10);
      message.success(t('content.deleteContact.success'))
    } else message.error(orderResponse);
    setIsLoading(false);
  };

  return (
    <div>
      <Tabs defaultActiveKey="1" animated={false}>
        <TabPane tab={t('content.header.tab1')} key="1">
          <div style={{ marginBottom: 16, paddingLeft: 20, paddingRight: 20 }}>
          <Row>
          <Col span={18}>
            <Input.Search
              enterButton={t('content.header.search')}
              placeholder={t('content.header.name')}
              allowClear
              onSearch={(val) => props.getContacts({ keyword: val })}
            />
          </Col>
          <Col span={6}>
            <Select
              placeholder="Tag"
              style={{ paddingLeft: 10, width: '100%' }}
              onChange={(val) => props.getContacts({ tag: val })}
              children={
                tagList.map((item, index) => (
                  <Select.Option key={index} value={item}>
                    {item}
                  </Select.Option>
                ))
              }
            >
           </Select>
          </Col>
          </Row>
          </div>
          <Table
            onChange={pagination => props.handlePageSize(pagination)}
            dataSource={props.transactionData}
            columns={columns({ cancel: onOpenCancelOrderModal, transl: t })}
            rowKey={record => record.id}
            loading={props.isLoading}
            pagination={{
              defaultPageSize: 10,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "40", "50", "100"],
              showTotal: (total, range) =>
                `${range[1]} of ${total} ${t('content.contact')}`,
              total: props.transactionRowTotal
            }}
            style={{ maxHeight: "60%" }}
          />
        </TabPane>
      </Tabs>
      <CustomizedModal
        visible={modalState.isModalVisible}
        title={modalState.modal.title}
        onOk={() => modalState.modal.onOk(modalState.modal.onOkData)}
        onCancel={modalState.modal.onCancel}
        text={modalState.modal.text}
        okButtonProps={{ type: "danger" }}
        okText={t('content.deleteContact.confirm')}
      />

    </div>
  );
};

export default AddressBookTableConfig;
