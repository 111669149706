import React, { useContext, Fragment } from "react";

import { Layout, Tabs, Row, Col, Input, Button, message } from "antd";
import { Formik } from 'formik';
import * as Yup from "yup";
import { CopyToClipboard } from 'react-copy-to-clipboard';

// import Earnings from "./Earnings/EarningsContent";
// import PromoMaterial from "./PromoMaterials/PromoMaterialsContent";
// import { Button } from "antd/lib/radio";
import AddressBookTableConfig from "./AddressBookTableConfig";
import { post } from 'Util/API';
import { GlobalContext } from "Store/store";

import { mixPanelTrack } from "Util/CustomFunctions";

function socialShare(type, url, title, winWidth, winHeight) {
  const winTop = (window.innerHeight / 2) - (winHeight / 2);
  const winLeft = (window.innerWidth / 2) - (winWidth / 2);
  const getUrl = () => {
    switch (type) {
      case 'fb':
        return 'https://www.facebook.com/sharer.php?t=' + title + '&u=' + url;
      case 'twitter':
        return `https://twitter.com/intent/tweet?text=${title}&url=${url}`;
      case 'ws':
        return `https://api.whatsapp.com/send?text=` + title + ' ' + url;
      default:
        return null;
    }
  }

  window.open(getUrl(), 'sharer', 'top=' + winTop + ',left=' + winLeft + ',toolbar=0,status=0,width='+winWidth+',height='+winHeight);
}

const { Content } = Layout;
const TabPane = Tabs.TabPane;
const Search = Input.Search;

const validationSchema = Yup.object().shape({
  email: Yup.string().email().nullable().required()
});

const ReferEarnContent = (props) => {
  const [state] = useContext(GlobalContext);
  const { userDetails, customerDetails, settings } = state.global;
  const { promo_title, promo_description } = settings["referral.customer"].data;
  const { transactionData, transactionRowTotal, isLoading, setIsLoading, handlePageSize } = props;
  const referralUrl = window.location.origin + process.env.PUBLIC_URL + '/signup?referralCode=' + customerDetails.referralCode;

  const promoText = 'Get any kind of delivery done with just a few clicks at';

  const onSendInvites = async (values, form) => {
    setIsLoading(true);
    const fetch = await post('/referral/invite', { emails: values.email, type: 'C' });
    if (fetch.status === 200) {
      if (fetch.data.data.success > 0) {
        message.success('Invitation email send success.');
        form && form.setFieldValue('email', null);
      } else {
        message.error('Invitation email send error.')
      }
    } else message.error(fetch);
    setIsLoading(false)
    mixPanelTrack('ReferSendInvite');
  }

  return (
    <Row>
      <Col className="tablistBColor tablist-top">
        <Tabs defaultActiveKey="1" animated={false}>
          <TabPane tab="Referral" key="1">
            <Row gutter={20} style={{ position: "relative" }}>
              <Col>
                <div
                  style={{
                    position: "absolute",
                    background: "#8595A6",
                    height: 250,
                    width: "100%",
                    zIndex: 1,
                    top: 0
                  }}
                />
                <div
                  style={{
                    padding: "30px 40px 20px 40px",
                    margin: 0,
                    zIndex: 2,
                    position: "relative"
                  }}
                >
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <p
                      style={{
                        fontSize: 30,
                        fontWeight: 'bold',
                        textAlign: "center",
                        color: "white",
                        marginBottom: 0
                      }}
                    >
                      {promo_title}
                    </p>
                    <p
                      className="heading3 wraptext"
                      style={{
                        textAlign: "center",
                        color: "white",
                        fontSize: 14,
                        fontWeight: "400",
                        marginTop: 0,
                        maxWidth: 500,
                        marginLeft: "auto",
                        marginRight: "auto"
                      }}
                    >
                    <p></p>
                      {promo_description}
                    </p>
                  </div>
                  <div style={{ display: "inline-block", width: "100%" }}>
                    <div className="box-container">
                   {/*   <Formik
                        initialValues={{ email: null }}
                        validationSchema={validationSchema}
                        onSubmit={onSendInvites}
                        validateOnChange={false}
                      >
                        {
                          form => (
                            <Fragment>
                              <Search
                                placeholder="Email address"
                                enterButton="Send Invites"
                                size="large"
                                value={form.values.email}
                                onChange={(val) => { form.setFieldValue('email', val.target.value); }}
                                onSearch={form.handleSubmit}
                              />
                              <div style={{ color: 'red' }}>{form.errors.email}</div>
                            </Fragment>
                          )
                        }
                      </Formik>

                      <p
                        className="heading3"
                        style={{
                          textAlign: "center",
                          fontSize: 14,
                          fontWeight: "400"
                        }}
                      >
                        Other ways to invite
                      </p>*/}
                      <p>Your unique referral code is <b>{customerDetails?.referralCode}</b></p>
                      <Row gutter={[5, 5]}>
                        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                          <CopyToClipboard text={referralUrl}>
                            <Button style={{ width: '100%' }} onClick={props.copyReferralLink}>
                              Copy link
                            </Button>
                          </CopyToClipboard>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                          <Button style={{ width: '100%' }} onClick={() => socialShare('fb', referralUrl, promoText, 500, 500)}>
                            Share on
                            <i style={{ marginLeft: 5 }} className="fab fa-facebook-square color_facebook"></i>
                          </Button>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                          <Button style={{ width: '100%' }} onClick={() => socialShare('twitter', referralUrl, promoText, 500, 500)}>
                            Share on
                            <i style={{ marginLeft: 5 }} className="fab fa-twitter-square color_twitter"></i>
                          </Button>
                        </Col>
                        <Col xs={24} sm={12} md={12} lg={6} xl={6}>
                          <Button style={{ width: '100%' }} onClick={() => socialShare('ws', referralUrl, promoText, 800, 800)}>
                            Share on
                            <i style={{ marginLeft: 5 }} className="fab fa-whatsapp-square color_whatsapp"></i>
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Content className="ui-right-content form">
              <Row>
                <div style={{ paddingTop: 30 }}>
                  <div
                    className="whiteBColor"
                    style={{
                      height: "100%",
                      border: "1px solid #cfdae6"
                    }}
                  >
                    <AddressBookTableConfig
                      // setIsLoading={setIsLoading}
                      onSendInvites={onSendInvites}
                      transactionData={transactionData}
                      transactionRowTotal={transactionRowTotal}
                      isLoading={isLoading}
                      handlePageSize={handlePageSize}
                    />
                  </div>
                </div>
              </Row>
            </Content>
          </TabPane>
          {/*<TabPane tab="Earnings" key="2">
            <Earnings />
          </TabPane>
          <TabPane tab="Promo Materials" key="3">
            <PromoMaterial />
          </TabPane>*/}
        </Tabs>
      </Col>
    </Row>
  )
}

export default ReferEarnContent;
