import React, { useState, useEffect } from "react";
import * as MapboxGl from 'mapbox-gl';
import { Select, Typography } from "antd";

const { Text } = Typography;

const WaypointInput = (props) => {
  const { title, onSearch, onChange, onFocus, addressSuggestion, onDragEnd, index } = props;
  const [viewFullDetails, setViewFullDetails] = useState(!!title);

  useEffect(() => {
    title && setViewFullDetails(true);
  }, [title])

  useEffect(() => {
    let mount = true;
    let marker;
    const el = document.createElement('div');
    el.className = `marker${index}`;
    el.innerHTML = `<svg
    xmlns="http://www.w3.org/2000/svg"
    ariaHidden="true"
    className="svg-inline--fa fa-map-marker fa-w-12"
    data-icon="map-marker"
    data-prefix="fas"
    viewBox="0 0 384 512"
  >
    <path
      fill="#1890FF"
      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"
    >
    </path>
    <text font-size="220" x="50%" y="50%" dominant-baseline="middle" text-anchor="middle" fill="white">${index + 1}</text>`
    el.style.width =  '20px';
    el.style.height = '20px';
    if (mount && props.item.location) {
      marker = new MapboxGl.Marker({ draggable: true, element: el })
        .setLngLat(props.item.location)
        .addTo(props.mapData);

      marker.on('dragend', () => {
        onDragEnd && onDragEnd({ location: marker.getLngLat(), index });
      });
    }
    return () => {
      mount = false;
      marker && marker.remove();
    };
  }, [props.item.location])

  const addressOptions = () => addressSuggestion.map(
    (d, index) => (
      <Select.Option key={index}>
        <Text style={{ display: 'block' }}>{d.title}</Text>
        <Text type="secondary">
        {d.address.address1 ? d.address.address1: ''} {d.address.address2 ? d.address.address2: ''}
        {d.address.postcode ? d.address.postcode: ''} {d.address.city ? d.address.city: ''} {d.address.state ? d.address.state: ''}
        {d.address.country ? d.address.country: ''}
        </Text>
      </Select.Option>
    )
  );

  return viewFullDetails ?
    <div style={{ width: '100%', cursor: 'pointer' }} onClick={() => setViewFullDetails(false)}>
      {title || 'Click to search'}
    </div> :
    <Select
      style={{ width: '100%' }}
      value={title}
      showSearch
      defaultActiveFirstOption={false}
      showArrow={false}
      filterOption={false}
      onSearch={onSearch}
      onChange={onChange}
      onBlur={() => {
        title && setViewFullDetails(true);
      }}
      onMouseLeave={() => {
        title && addressSuggestion.length === 0 && setViewFullDetails(true);
      }}
      notFoundContent={null}
      onFocus={onFocus}
    >
      {addressOptions()}
    </Select>
}

export default WaypointInput;
