import React from "react";
import { Form } from "antd";
import ReactPhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { Field } from 'formik';

const PhoneNumberInput = ({
  setFieldValue,
  values,
  errors,
  name,
  placeholder,
  label,
  onChange,
  disabled,
  viewOnly,
  className,
  enableSearchField,
  dropdownStyle,
  searchPlaceholder,
  marginTop,
  touched,
  country,
  style,
  value
}) => (
  <Form.Item
    validateStatus={disabled ? null : touched[name] && errors[name] && "error"}
    help={disabled ? null : touched[name] ? errors[name] : ""}
    label={label}
    className={className}
  >
    <Field name={name} value={value} style={{ display: 'none' }} />
    <ReactPhoneInput
      searchPlaceholder={searchPlaceholder}
      country={country || 'my'}
      enableSearchField={enableSearchField}
      inputStyle={
        errors[name]
          ? { border: "1px solid red", width: "100%", height: "32px", ...style }
          : { width: "100%", color: disabled && '#C4C4C4', backgroundColor: (viewOnly || disabled) && '#F5F5F5', border: viewOnly && 'none', height: "32px", ...style }
      }
      dropdownStyle={dropdownStyle}
      buttonStyle={
        errors[name]
          ? { border: "1px solid red", height: "32px", marginTop: marginTop }
          : { height: "32px", marginTop: marginTop, border: viewOnly && 'none' }
      }
      disableDropdown={disabled}
      disabled={disabled}
      placeholder={placeholder}
      value={value || undefined}
      onBlur={() => {
        setFieldValue(name, value)
      }}
      onChange={
        onChange
          ? onChange
          : event => {
              setFieldValue(name, event);
            }
      }
    />
  </Form.Item>
);

export default PhoneNumberInput;
