import React from 'react';
import { Menu, Dropdown, Button } from 'antd';

const MenuItems = ({ data, onSelect }) => (
  <Menu>
    {
      data.map((item, index) => (
        <Menu.Item key={item.id} onClick={() => onSelect(index)}>
          <i className={item.icon} style={{ marginRight: 10 }}></i>
          {item.label}
        </Menu.Item>
      ))
    }
  </Menu>
);

const SearchAddress = ({ data, selected, setSelected }) => {

  return (
    <div id="components-dropdown-demo-dropdown-button">
      <Dropdown overlay={<MenuItems data={data} onSelect={setSelected} />}>
        <Button>
          <i className={data[selected].icon}></i>
        </Button>
      </Dropdown>
    </div>
  )
}

export default SearchAddress;
