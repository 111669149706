import React, { useState, useContext, useEffect } from "react";
import { Alert, Card, Row, Col, message } from "antd";
import { useTranslation } from "react-i18next";

import { update } from "Util/API";
import { GlobalContext } from "Store/store";
import PendingPhoneNumberVerification from "./PendingPhoneNumberVerification";
import ChangePhoneNumberForm from "./ChangePhoneNumberForm";

import { mixPanelTrack } from "Util/CustomFunctions";

const ChangePhoneNumber = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { userDetails, allCustomerAccounts, customerDetails } = state.global;
  const [currentPhoneNumber, setCurrentPhoneNumber] = useState(userDetails.mobile)
  const isVerificationCodeSent = false
  const isPhoneNumberVerificationSuccess = false
  const isLoading = false
  const { t } = useTranslation('settingsPage');

  const submitNewPhoneNumber = async (values) => {
    props.setIsLoading(true);
    let userData = {
      mobile: values.newPhoneNumber
    };

    const userResponse = await update("user", userData);
    if (userResponse.status === 200) {
      dispatch({
        type: "UPDATE_PROFILE",
        payload: {
          userDetails: userResponse.data.data,
          allCustomerAccounts,
          customerDetails,
        }
      })
      message.success(t('content.changePhoneNo.update'))
    } else message.error(userResponse);
    props.setIsLoading(false)
  };

  useEffect(() => {
    mixPanelTrack('ViewChangePhone');
  }, []);

  return (
    <Row>
      <Col className="personal-container">
        <Card className="manage-auth-container">
          <div
            className="whiteBColor"
            style={{ padding: "10px 20px", minHeight: "auto" }}
          >
            <p className="heading3" style={{ margin: 0, marginBottom: 10 }}>
            {t('content.changePhoneNo.title')}
            </p>
            {isVerificationCodeSent ? null : (
              <Alert
                description={
                  <span>
                    {t('content.changePhoneNo.currentPhoneNo')}
                    <b>+{currentPhoneNumber}</b>
                  </span>
                }
                type={isPhoneNumberVerificationSuccess ? "success" : "info"}
                showIcon
                className="manage-auth-alert"
              />
            )}
            {isVerificationCodeSent ? (
              <PendingPhoneNumberVerification
                currentPhoneNumber={currentPhoneNumber}
              />
            ) : (
              <ChangePhoneNumberForm
                submitNewPhoneNumber={submitNewPhoneNumber}
                isLoading={isLoading}
                transl={t}
              />
            )}
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default ChangePhoneNumber;
