import React, { useContext, useState, useEffect } from "react";

import { List, Card, message } from "antd";
import { GlobalContext } from "Store/store";
import { post, get, API_ROOT } from 'Util/API';

const PaymentMethodList = (props) => {
  const [store] = useContext(GlobalContext);
  const { record, history } = props;
  const { companyDetails, customerDetails, settings } = store.global;
  const [paymentMethods, setPaymentMethods] = useState([]);
  const minOrderAmout = settings["orders.create_an_order"]?.data?.min_order_amount;

  useEffect(() => {
    getPaymentMethods()
  }, []);

  const getPaymentMethods = async () => {
    // const getOrder = await get('order?invoiceId=' + record.id);
    // if (getOrder.status === 200) {
      // if (getOrder.data?.data?.[0]) {
        // const { serviceCode } = getOrder.data.data?.[0];
        const fetch = await get(`wallet/paymentMethod`);
        if (fetch.status === 200) {
          let paymentMethodList = fetch.data.data;
          if (Number(customerDetails.walletBalance) < Number(record.totalPrice)) {
            paymentMethodList = paymentMethodList.filter((item) => item.id !== 0)
          }
          setPaymentMethods(paymentMethodList);
        } else message.error(fetch);
      // } else message.error('Unable to get service code.')
    // } else message.error(getOrder);
  }

  const selectPaymentMethod = async (item) => {
    const paymentMethodId = item.id;
    props.setIsLoading(true);
    props.setModalState({
      ...props.modalState,
      isModalVisible: false
    })
    const fetch = await post(`wallet/invoice/${record.id}/paymentmethod`, { paymentMethodId });
    if (fetch.status === 200) {
      if (paymentMethodId > 0) {
        const doneUrl = window.location.origin + `${process.env.PUBLIC_URL}/after-payment/billing/${record.id}`;
        const paymentUrl = API_ROOT + `wallet/invoice/${record.id}/payment/?doneUrl=${doneUrl}&companyId=${companyDetails.id}`;
        props.setIsLoading(false);
        // window.open(paymentUrl);
        window.location.href = paymentUrl;
      } else {
        history.push(`${process.env.PUBLIC_URL}/after-payment/billing/${record.id}`)
      }
    } else message.error(fetch)
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', margin: -10 }}>
      {
        paymentMethods.map((item) => (
          <Card
            hoverable={true}
            style={{ width: 150, minHeight: 120, textAlign: "center", margin: 10 }}
            onClick={() => {
              if ((item.id > 0 && (Number(record.totalPrice) >= minOrderAmout)) || item.id <= 0) {
                selectPaymentMethod(item)
              } else message.error(`Invoice total price must exceed ${companyDetails.currency} ${minOrderAmout}`)
            }}
          >
            <i
              className={
                item.fontAwesomeIcon ? item.fontAwesomeIcon : "fas fa-truck"
              }
            />
            <p style={{ marginTop: "10%" }}>{item.name}</p>
          </Card>
        ))
      }
    </div>
  )
}

export default PaymentMethodList;
