import React from "react";
import { Form, InputNumber } from "antd";
import { useField } from 'formik';

const Input = ({
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  name,
  placeholder,
  type,
  prefix,
  label,
  max,
  onChange,
  addonAfter,
  addonBefore,
  onBlur,
  size,
  disabled,
  className,
  style,
  touched,
  step,
  min,
  inputStyle,
  defaultValue,
  value,
  ...rest
}) => {
  const [field] = useField({ name });

  return (
    <Form.Item
      hasFeedback={addonAfter || addonBefore ? null : !!errors[name]}
      validateStatus={disabled ? null : touched[name] && errors[name] && "error"}
      help={disabled ? null : touched[name] ? errors[name] : ""}
      label={label}
      className={className}
      style={style}
    >
      <InputNumber
        {...field}
        style={{ width: '100%', ...inputStyle }}
        step={step}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={() => {
          setFieldValue(name, value)
        }}
        min={min}
        max={max}
        {...rest}
      />
    </Form.Item>
  )
};

export default Input;
