import React, { useContext, useState, useEffect, Fragment } from "react";
import moment from "moment";
import { Layout, Row, Col, Tabs, Dropdown, Menu, Tag, Button, Spin, message, Popconfirm } from "antd";
import { Link, Redirect, useHistory } from "react-router-dom";
import { get, post } from "Util/API";

import { API_ROOT, IMAGE_URL_ROOT } from 'Util/API';
import { GlobalContext } from "Store/store";
import { orderStatusColor2 } from 'Util/orderStatusColor';
import { capitalizeFirstLetter, getServiceCompany } from 'Util/CustomFunctions';
import ShowOn from 'Util/ShowOn';
import useLocalStorage from 'Util/useLocalStorage';
import { onOpenCancelOrderModal, onOpenCloneOrderModal, onOpenFulfilOrderModal, onOpenRejectDriverModal } from 'Pages/Order/ordersHelper';

import OverviewContent from "./Tabs/OverviewContent/OverviewContent";
import { useTranslation } from "react-i18next";

import CustomizedButton from "Components/CustomizedButton";

const TabPane = Tabs.TabPane;
const { Content } = Layout;

const OrderDetailsContent = (props) => {
  const { selectedOrder, onCancelOrder, onCloneOrder, onFulfilOrder, onRejectDriver, rejectReasons } = props;
  const [state] = useContext(GlobalContext);
  const { sse } = state;
  const { companyDetails, customerDetails, modalState, setModalState, serviceCompanies } = state.global;
  const [orderDetails, setOrderDetails] = useState(selectedOrder);
  const selectedWaypoint = orderDetails.waypoint.find((item) => item.type === 'PICKUP');
  const { t } = useTranslation('orderPage');
  const history = useHistory();

  const theServiceCompany = getServiceCompany(orderDetails.service?.serviceCompanyId, serviceCompanies);

  const onUpdateOrder = async (id) => {
    const fetch = await get(`order/${id}`);
    if (fetch.status === 200) {
      const data = fetch.data.data;
      setOrderDetails(data);
    } else message.error(fetch);
  }

  const onRetryProcessOrder = async () => {
    const dataToSubmit = {
      customerId: customerDetails.id,
      process: true,
      orderIds: [orderDetails.id],
      serviceCode: orderDetails.serviceCode
    }
    props.setIsLoading(true);
    const processOrder = await post('order/process', dataToSubmit);
    if (processOrder.status === 200) {
      const res = processOrder.data.data;
      const updatedOrderDetails = { ...orderDetails, status: res.status, statusCode: res.statusCode };
      setOrderDetails(updatedOrderDetails);
    } else message.error(processOrder)
    props.setIsLoading(false);
  }

  const fulfilSubmit = async (id, serviceCode) => {
      props.setIsLoading(true);

      const dataToSubmit = {
        customerId: customerDetails.id,
        process: true,
        orderIds: [orderDetails.id],
        serviceCode: serviceCode
      }

      const processOrder = await post('order/process', dataToSubmit);
      if (processOrder.status === 200) {
        const res = processOrder.data.data;
        const updatedOrderDetails = { ...orderDetails, status: res.status, statusCode: res.statusCode };
        setOrderDetails(updatedOrderDetails);
      } else message.error(processOrder)
      props.setIsLoading(false);
  }

  const cloneSubmit = async (id, serviceCode) => {
    props.setIsLoading(true);
    const dataToSubmit = {
      // scheduledAt,
      serviceCode,
      process: true
    }

    const cloneOrder = await post('order/'+id+'/clone', dataToSubmit);
    if (cloneOrder.status === 200) {
        props.setIsLoading(false);
        const res = cloneOrder.data.data;
        history.push({ pathname: `${process.env.PUBLIC_URL}/order/`+res.orderId });
        window.location.reload(false);
    } else message.error(cloneOrder)

    props.setIsLoading(false);
  }

  const refreshServices = async (id) => {
    props.setIsLoading(true);

    const refreshServicesRes = await get('order/'+id+'/refreshQuote');
    if (refreshServicesRes.status === 200) {
        props.setIsLoading(false);
        message.success('Refresh quotes successful.')
        const res = refreshServicesRes.data.data;

        if(res)
        {
            window.location = `${process.env.PUBLIC_URL}/order/`+id;
        }
      } else message.error(refreshServicesRes)

      props.setIsLoading(false);
  }

  useEffect(() => {
    const isCorrectMessage =
    sse.isListen &&
    sse.message &&
    (
      sse.message.event === 'order.status' ||
      sse.message.event === 'order.failed' ||
      sse.message.event === 'order.updated' ||
      sse.message.event === 'order.created'
    ) &&
    sse.message.orderId === orderDetails.id;

    if (isCorrectMessage) {
      onUpdateOrder(orderDetails.id);
    }
  }, [sse])

  const allowPlaceMultipointOrder = orderDetails.source === 'web-customer-multipoint' || orderDetails.source === 'android-mover' || orderDetails.source === 'ios-mover' || orderDetails.waypoint.length > 2;

  return (
    <Layout>
      <Content
        className="ui-right-content form whiteBColor"
        style={{ minHeight: "unset", margin: 0 }}
      >
        <Row>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div style={{ margin: 0, display: 'flex', flexDirection: 'column' }}>
              <Link to={props.prevPath || process.env.PUBLIC_URL + '/orders'}>
                <i className="fas fa-angle-left" style={{ marginRight: 5 }} />
                {t('content.orderDetail.title')}
              </Link>
            </div>
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="navigation-table"
          >
            <div>
              <Dropdown
                overlay={
                  <Menu  style={{ display: 'flex', flexDirection: 'column' }}>

                    { (orderDetails.quotes != null && orderDetails.statusCode < 100 && orderDetails.status !== 'processing') ?
                    <Button
                      style={{ border: "none" }}
                      onClick={() => onOpenFulfilOrderModal({
                        id: orderDetails.id,
                        serviceCode: orderDetails.serviceCode,
                        modalState,
                        setModalState,
                        onConfirmFulfilOrder: onFulfilOrder,
                        transl: t,
                        services: orderDetails.quotes.services
                      })}
                    >
                      <strong>{t('content.orderDetail.dropdown.confirm')}</strong>
                    </Button>
                    : ''}

                    {orderDetails.statusCode < 100 && (
                      <Button
                        style={{ border: "none" }}
                        onClick={() => {
                            history.push({
                              pathname: `${process.env.PUBLIC_URL}/orders/edit/${orderDetails.id}`,
                              state: {
                                orderDetails: orderDetails
                              }
                            })
                          }}
                      >
                        {t('content.newOrder.editOrder')}
                      </Button>
                    )}

                    {orderDetails.consignmentNo && (
                        <Button
                          style={{ border: "none" }}
                          target="_blank"
                          href={`${process.env.PUBLIC_URL}/strack?trackingNo=${orderDetails.consignmentNo}`}
                        >
                          {t('content.orderDetail.dropdown.trackOrder')}
                        </Button>
                    )}

                    {orderDetails.statusCode >= 100 && (
                      <Fragment>
                        <Button
                          style={{ border: "none" }}
                          target="_blank"
                          href={`${API_ROOT}order/${orderDetails.id}/label?companyId=${companyDetails.id}`}
                        >
                          {t('content.orderDetail.dropdown.printLabel')}
                        </Button>
                        <Button
                          style={{ border: "none" }}
                          target="_blank"
                          href={`${API_ROOT}order/${orderDetails.id}/label?companyId=${companyDetails.id}&type=A4-3`}
                        >
                          {t('content.orderDetail.dropdown.printLabelA4')}
                        </Button>
                        <Button
                          style={{ border: "none" }}
                          target="_blank"
                          href={`${API_ROOT}order/${orderDetails.id}/label?companyId=${companyDetails.id}&type=A4-Q4`}
                        >
                          {t('content.orderDetail.dropdown.printLabelA42')}
                        </Button>
                        <Button
                          style={{ border: "none" }}
                          target="_blank"
                          href={`${API_ROOT}order/${orderDetails.id}/label?companyId=${companyDetails.id}&type=A5-2`}
                        >
                          {t('content.orderDetail.dropdown.printLabelA5')}
                        </Button>
                        <Button
                          style={{ border: "none" }}
                          target="_blank"
                          href={`${API_ROOT}order/${orderDetails.id}/label?companyId=${companyDetails.id}&type=A6-1`}
                        >
                          {t('content.orderDetail.dropdown.printLabelA6')}
                        </Button>
                      </Fragment>
                    )}
                      <Button
                        style={{ border: "none" }}
                        target="_blank"
                        disabled={!orderDetails.invoiceId}
                        href={`${API_ROOT}wallet/invoice/${orderDetails.invoiceId}/print?output=pdf&jwt=${useLocalStorage.getItem("accessToken")}&companyId=${companyDetails.id} `}
                      >
                        {t('content.orderDetail.dropdown.printInvoice')}
                      </Button>
                      <Button
                          style={{ border: "none" }}
                          target="_blank"
                          href={`${API_ROOT}order/${orderDetails.id}/packlist?companyId=${companyDetails.id}&type=A6-1`}
                        >
                          Print Packing list
                      </Button>
                      <Button
                          style={{ border: "none" }}
                          target="_blank"
                          href={`${API_ROOT}order/${orderDetails.id}/inv_invoice?companyId=${companyDetails.id}&type=A6-1`}
                        >
                          Print Commercial Invoice
                      </Button>
                      <Button
                          style={{ border: "none" }}
                          target="_blank"
                          href={`${API_ROOT}order/${orderDetails.id}/invoice_packlist?companyId=${companyDetails.id}&type=A6-1`}
                        >
                          Com. Invoice + Packing list
                      </Button>
                      <Button
                        style={{ border: "none" }}
                        onClick={() => {
                          if (allowPlaceMultipointOrder) {
                            history.push({
                              pathname: `${process.env.PUBLIC_URL}/orders/multipoint`,
                              state: {
                                orderDetails
                              }
                            })
                          }else {
                            history.push({
                              pathname: `${process.env.PUBLIC_URL}/orders/new-order`,
                              state: {
                                orderDetails
                              }
                            })
                          }
                        }}
                      >
                        Place order again
                      </Button>
                      { (orderDetails.quotes == null ) ?
                      <Button
                        style={{ border: "none" }}
                        onClick={() => cloneSubmit(orderDetails.id, orderDetails.serviceCode)}
                      >
                        Clone order
                      </Button>
                      : ''}
                      { (orderDetails && orderDetails.quotes != null && orderDetails.serviceCode != null) ?
                        <Button
                          style={{ border: "none" }}
                          onClick={() => onOpenCloneOrderModal({
                            id: orderDetails.id,
                            serviceCode: orderDetails.serviceCode,
                            modalState,
                            setModalState,
                            onConfirmCloneOrder: onCloneOrder,
                            transl: t,
                            services: orderDetails.quotes.services
                          })}
                        >
                          Clone order
                        </Button>
                      : ''}
                      { (orderDetails && orderDetails.statusCode >= 200 && orderDetails.statusCode < 500 && orderDetails.serviceCode.includes("LLM")) ?
                        <Button
                          style={{ border: "none" }}
                          onClick={() => onOpenRejectDriverModal({
                            id: orderDetails.id,
                            modalState,
                            setModalState,
                            onConfirmRejectDriver: onRejectDriver,
                            transl: t,
                            rejectReasons: rejectReasons,
                          })}
                        >
                          Reject driver
                        </Button>
                      : ''}
                      <Button
                        style={{ border: "none" }}
                        onClick={() => {
                          if (allowPlaceMultipointOrder) {
                            history.push({
                              pathname: `${process.env.PUBLIC_URL}/orders/multipoint`,
                              state: {
                                orderDetails,
                                returnOrder: true
                              }
                            })
                          }else {
                            history.push({
                              pathname: `${process.env.PUBLIC_URL}/orders/new-order`,
                              state: {
                                orderDetails,
                                returnOrder: true
                              }
                            })
                          }
                        }}
                      >
                        Create return order
                      </Button>
                      <Button
                        style={{ border: "none" }}
                        disabled={orderDetails.statusCode >= 100}
                        onClick={onRetryProcessOrder}
                      >
                        Retry order
                      </Button>
                      <Button
                        style={{ border: "none" }}
                        onClick={() => refreshServices(orderDetails.id)}
                      >
                        Refresh quotes
                      </Button>
                      <Button
                        style={{ border: "none" }}
                        onClick={() => onOpenCancelOrderModal({
                          id: orderDetails.id,
                          modalState,
                          setModalState,
                          onConfirmCancelOrder: onCancelOrder,
                          transl: t
                        })}
                      >
                        {t('content.orderDetail.dropdown.cancel')}
                      </Button>
                  </Menu>
                }
                trigger={["click"]}
                className="action-dropdown"
              >
                <Button
                  className="button__transparent"
                  style={{ padding: 0, marginRight: 15 }}
                >
                  {t('content.orderDetail.dropdown.action')}
                  <i
                    className="fas fa-angle-down"
                    style={{ marginLeft: "10px" }}
                  />
                </Button>
              </Dropdown>
            </div>
          </Col>
        </Row>
        <Row style={{ marginTop: 20 }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <p
              className="heading2"
              style={{ display: "inline", paddingRight: "10px" }}
            >
              {orderDetails.consignmentNo ? orderDetails.consignmentNo: "N/A"}
            </p>

            <small> &nbsp; {orderDetails.nanoId} &nbsp; {orderDetails.referenceNo} &nbsp; </small>
            {
              orderDetails.status !== 'processing' ?
              <span>
                <Tag color={orderStatusColor2(orderDetails.statusCode).color}>
                {capitalizeFirstLetter(orderStatusColor2(orderDetails.statusCode).text)}
                </Tag>
              </span> :
              <span style={{ width: 83.27 }}><Spin /></span>
            }

            { (orderDetails.quotes != null && orderDetails.statusCode < 100 && orderDetails.status !== 'processing') ?
              <CustomizedButton
                type="primary"
                text={t('content.orderDetail.dropdown.confirm')}
                onClick={() => onOpenFulfilOrderModal({
                  id: orderDetails.id,
                  serviceCode: orderDetails.serviceCode,
                  modalState,
                  setModalState,
                  onConfirmFulfilOrder: onFulfilOrder,
                  transl: t,
                  services: orderDetails.quotes.services
                })}
              />
            : ''}

            <ShowOn on={orderDetails.status !== 'processing' && orderDetails.failedReason}>
              <Row style={{ marginTop: 20 }}>
                <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                  <p className="heading3" style={{ margin: 0, fontSize: 14, color: 'red' }}>
                    Failed reason
                  </p>
                  <p style={{ margin: 0, fontSize: 14 }}>
                    {orderDetails.failedReason}
                  </p>
                </Col>
              </Row>
            </ShowOn>
            <Row gutter={[10, 10]} style={{ marginTop: 10 }}>
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <p className="heading3" style={{ margin: 0, fontSize: 14 }}>
                  Created Date
                </p>
                <p style={{ margin: 0, fontSize: 14 }}>
                  {orderDetails.createdAt
                    ? moment(orderDetails.createdAt).format("Do MMM YYYY, h:mm a")
                    : "N/A"}
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <p className="heading3" style={{ margin: 0, fontSize: 14 }}>
                  {t('content.orderDetail.date')}
                </p>
                <p style={{ margin: 0, fontSize: 14 }}>
                  {selectedWaypoint?.scheduledAt
                    ? moment(selectedWaypoint?.scheduledAt).format("Do MMM YYYY, h:mm a")
                    : "N/A"}
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <p className="heading3" style={{ margin: 0, fontSize: 14 }}>
                  {t('content.orderDetail.service')}
                </p>
                <p style={{ margin: 0, fontSize: 14 }}>
                  {theServiceCompany?.logo ?
                    <span>
                    <img src={IMAGE_URL_ROOT + '/' + theServiceCompany?.logo} height="24" style={{marginTop:10,marginBottom:10}} />
                    </span>
                    :''
                  }
                  <br/>
                  <small><b>{orderDetails.service?.name}</b></small>
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <p className="heading3" style={{ margin: 0, fontSize: 14 }}>
                  {t('content.orderDetail.price')}
                </p>
                <p style={{ margin: 0, fontSize: 14 }}>
                  {orderDetails.price &&
                  orderDetails.price.currency ? (
                    <span>
                      {orderDetails.price.currency} <span style={{fontSize:18}}>{orderDetails.price.amount?orderDetails.price.amount.toFixed(2):0.00}</span>
                    </span>
                  ) : (
                    "N/A"
                  )}
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={3} xl={3}>
                <p className="heading3" style={{ margin: 0, fontSize: 14 }}>
                  {t('content.orderDetail.status')}
                </p>
                <p style={{ margin: 0, fontSize: 14 }}>
                  {capitalizeFirstLetter(orderDetails.status)}
                </p>
              </Col>
              <Col xs={12} sm={12} md={6} lg={4} xl={4}>
                <p className="heading3" style={{ margin: 0, fontSize: 14 }}>
                  Source
                </p>
                <p style={{ margin: 0, fontSize: 14 }}>
                  {orderDetails.source}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </Content>
      <Tabs
        defaultActiveKey="1"
        style={{ padding: "0 20px 20px" }}
        animated={false}
      >
        <TabPane tab={t('content.orderDetail.overview')} key="1">
          <OverviewContent
            selectedOrder={orderDetails}
            isLoading={props.isLoading}
            />
        </TabPane>
      </Tabs>
    </Layout>
  )
}

export default OrderDetailsContent;
