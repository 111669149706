import React from "react";
import { List, Row, Col, Badge, Tag} from "antd";
import { useTranslation } from "react-i18next";

import orderStatusColor from 'Util/orderStatusColor';
import { capitalizeFirstLetter } from 'Util/CustomFunctions';

const ServiceInformation = (props) => {
  const { t } = useTranslation('orderDetailsPage');

  return (
    <List
      bordered
      style={{
        backgroundColor: "white",
        marginBottom: "20px",
        overflow: "hidden"
      }}
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="heading3" style={{ margin: 0 }}>
            {t('serviceInformation.title')}
          </p>
        </div>
      }
    >
      <List.Item>
        <Row gutter={10} style={{ width: "100%" }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p
              className="heading3"
              style={{ fontSize: 14, margin: 0, float: "right" }}
            >
              {t('serviceInformation.content.service')}
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p
              className="heading3"
              style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
            >
              {props.selectedOrder.serviceCode}
            </p>
          </Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row gutter={10} style={{ width: "100%" }}>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p
              className="heading3"
              style={{ fontSize: 14, margin: 0, float: "right" }}
            >
              {t('serviceInformation.content.totalWeight')}
            </p>
          </Col>
          <Col xs={12} sm={12} md={12} lg={12} xl={12}>
            <p
              className="heading3"
              style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
            >
              {props.selectedOrder.weight
                ? (props.selectedOrder.weight.value ? props.selectedOrder.weight.value.toFixed(2):0.00) +
                  " " +
                  props.selectedOrder.weight.unit
                : "N/A"}
            </p>
          </Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row gutter={10}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  className="heading3"
                  style={{ fontSize: 14, margin: 0, float: "right" }}
                >
                  {t('serviceInformation.content.totalPrice')}
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  className="heading3"
                  style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
                >
                  {props.selectedOrder.price
                    ? props.selectedOrder.price.currency +
                      " " +
                      props.selectedOrder.price.amount
                    : "N/A"}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row gutter={10}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  className="heading3"
                  style={{ fontSize: 14, margin: 0, float: "right" }}
                >
                  {t('serviceInformation.content.totalCollectCash')}
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  className="heading3"
                  style={{ fontSize: 14, fontWeight: 400, margin: 0 }}
                >
                  {props.selectedOrder.cod
                    ? props.selectedOrder.price.currency + " " +props.selectedOrder.cod.amount
                    : "N/A"}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
      </List.Item>
      <List.Item>
        <Row style={{ width: "100%" }}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24}>
            <Row gutter={10}>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <p
                  className="heading3"
                  style={{ fontSize: 14, margin: 0, float: "right" }}
                >
                  {t('serviceInformation.content.reconciliationStatus')}
                </p>
              </Col>
              <Col xs={12} sm={12} md={12} lg={12} xl={12}>
                <div className="info-detail">
                      { (props.selectedOrder.codStatus == 0)? <Tag color="grey">Pending Delivery</Tag>
                        : (props.selectedOrder.codStatus == 1)? <Tag color="orange">Cash Collected</Tag>
                        : (props.selectedOrder.codStatus == 2)? <Tag color="blue">Processing</Tag>
                        : (props.selectedOrder.codStatus == 3)? <Tag color="green">Settled</Tag>
                        : <span>N/A</span>
                      }
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </List.Item>
    </List>
  )
}

export default ServiceInformation;
