import React, { useState, useContext, useEffect } from "react";
import { Alert, Card, Row, Col, message } from "antd";

import ChangeEmailAddressForm from "./ChangeEmailAddressForm";
import PendingEmailVerification from "./PendingEmailVerification";
import { update } from "Util/API";
import { GlobalContext } from "Store/store";
import { useTranslation } from "react-i18next";

import { mixPanelTrack } from "Util/CustomFunctions";

const ChangeEmailAddress = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { userDetails, allCustomerAccounts, customerDetails } = state.global;
  const [currentEmail, setCurrentEmail] = useState(userDetails.email);
  const isChangeEmailReachLimit = false;
  const isChangeEmailSuccess = false;
  const isVerificationPending = false;
  const isLoading = false;
  const { t } = useTranslation('settingsPage');

  const submitNewEmail = async (values) => {
    props.setIsLoading(true);
    let userData = {
      email: values.newEmail
    };

    const userResponse = await update("user", userData);
    if (userResponse.status === 200) {
      dispatch({
        type: "UPDATE_PROFILE",
        payload: {
          userDetails: userResponse.data.data,
          allCustomerAccounts,
          customerDetails,
        }
      })
      message.success(t('content.changeEmailAddress.update'))
    } else message.error(userResponse);
    props.setIsLoading(false)
  };

  const submitVerificationCode = values => {

  };

  useEffect(() => {
    mixPanelTrack('ViewChangeEmail');
  }, []);

  return (
    <Row>
      <Col className="personal-container">
        <Card className="manage-auth-container">
          <div
            className="whiteBColor"
            style={{ padding: "10px 20px", minHeight: "auto" }}
          >
            <p className="heading3" style={{ margin: 0, marginBottom: 10 }}>
            {t('content.changeEmailAddress.title')}
            </p>
            <Alert
              description={
                <span>
                  {t('content.changeEmailAddress.currentEmail')} <b>{currentEmail}</b>
                </span>
              }
              type={isChangeEmailSuccess ? "success" : "info"}
              showIcon
              className="manage-auth-alert "
            />
            {isChangeEmailReachLimit && (
              <Alert
                description={t('content.changeEmailAddress.limitEmailChange')}
                type="error"
                showIcon
                className="manage-auth-alert "
              />
            )}
            {isVerificationPending ? (
              <PendingEmailVerification
                currentEmail={currentEmail}
                submitVerificationCode={submitVerificationCode}
              />
            ) : (
              <ChangeEmailAddressForm
                submitNewEmail={submitNewEmail}
                isLoading={isLoading}
                transl={t}
              />
            )}
          </div>
        </Card>
      </Col>
    </Row>
  )
}

export default ChangeEmailAddress;
