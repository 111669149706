import React, { useContext, useEffect, useState } from 'react';
import { Card, Spin } from "antd";
import axios from "axios";

import useLocalStorage from 'Util/useLocalStorage';
import { GlobalContext } from 'Store/store';
import PublicHeader from 'Components/PublicHeader';
import ShowOn from 'Util/ShowOn';

import { mixPanelTrack } from "Util/CustomFunctions";

const getCompanyAppUrl = async () => {
  try {
    const response = await axios.get('https://delyvax.com/external/webs.json');
    return response;
  } catch (e) {
    const response = { status: 400 }
    return response;
  }
};

const GetStartedPage = (props) => {
  const [store] = useContext(GlobalContext);
  const { history } = props;
  const [getStartedData, setGetStartedData] = useState(null);
  const { companyDetails, userDetails, customerDetails } = store.global;
  const { primaryColor } = companyDetails.personalization;

  const fetchStartedData = async () => {
    const fetch = await getCompanyAppUrl();
    const getStartedData = fetch?.status === 200 ? fetch.data : [];
    const getStartedDataObj = getStartedData.reduce((acc, item) => {
      acc[item.company_code] = item;
      return acc;
    }, {});
    const newGetStartedData = getStartedDataObj[companyDetails.code] || getStartedDataObj.default || {};
    setGetStartedData(newGetStartedData);
  }

  useEffect(() => {
    fetchStartedData()
  }, [])


  useEffect(() => {
    if (getStartedData && !getStartedData?.web_url) {
      props.history.push(`${process.env.PUBLIC_URL}/`)
    }
  }, [getStartedData])

  useEffect(() => {
    mixPanelTrack('ViewGetStarted');
  }, []);

  return getStartedData?.web_url ?
    <PublicHeader>
      <h2 style={{ fontSize: 30, fontWeight: 700 }}>Let's get started!</h2>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'center', margin: 20, flexWrap: 'wrap' }}>
        <Card
          hoverable={true}
          style={{ margin: 20, width: 200, textAlign: "center" }}
          onClick={getStartedData?.web_url === 'default' ? () => history.push(`${process.env.PUBLIC_URL}/`) : () => window.open(getStartedData?.web_url + `?token=${useLocalStorage.getItem("accessToken")}&company_id=${companyDetails.id}&user_id=${userDetails.id}&customer_id=${customerDetails.id}`)}
        >
          <i className="fas fa-arrow-circle-right" style={{ fontSize: 40, color: primaryColor }}></i>
          <p style={{ marginTop: "10%", fontWeight: 'bold' }}>Web portal</p>
        </Card>
        <ShowOn on={getStartedData?.android_url?.length > 0}>
          <Card
            hoverable={true}
            style={{ margin: 20, width: 200, textAlign: "center" }}
            onClick={() => { window.open(getStartedData?.android_url) }}
          >
            <i className="fab fa-android" style={{ fontSize: 40, color: '#A4C639' }}></i>
            <p style={{ marginTop: "10%", fontWeight: 'bold' }}>Download app</p>
          </Card>
        </ShowOn>
        <ShowOn on={getStartedData?.iphone_url?.length > 0}>
          <Card
            hoverable={true}
            style={{ margin: 20, width: 200, textAlign: "center" }}
            onClick={() => { window.open(getStartedData?.iphone_url) }}
          >
            <i className="fab fa-apple" style={{ fontSize: 40, color: 'black' }}></i>
            <p style={{ marginTop: "10%", fontWeight: 'bold' }}>Download app</p>
          </Card>
        </ShowOn>
      </div>
    </PublicHeader> :
    <Spin style={{ width: '100vw', top: '50vh', position: 'absolute' }} />
};

export default GetStartedPage;
