import React, { useState, useMemo, useContext, useEffect } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import { message, notification, ConfigProvider } from "antd";
import InnerHTML from 'dangerously-set-html-content'

import { IMAGE_URL_ROOT } from 'Util/API';
import { post } from 'Util/API';
import CustomizedModal from "Components/CustomizedModal";
import { GlobalContext } from "Store/store";
import withAuthentication from "Pages/Auth/withAuthentication";
import ScrollToTop from 'Util/ScrollToTop';

import GetStartedPage from 'Pages/GetStarted/GetStartedPage';
import Home from "Pages/Home/Home";
import InstantQuotePage from "Pages/InstantQuote/InstantQuotePage";

import SignUp from "Pages/Auth/SignUp";
import VerifyAccount from 'Pages/Auth/VerifyAccount';
import UpdateProfile from "Pages/Profile/UpdateProfile";
import LoginPage from "Pages/Auth/LoginPage";
import ForgotPassword from "Pages/Auth/ForgotPassword";
import ResetPassword from "Pages/Auth/ResetPassword";
import DeleteAccount from "Pages/Auth/DeleteAccount";

import Orders from "Pages/Order/Orders/Orders";
import COD from "Pages/Order/COD/Orders";
import NewOrder from "Pages/Order/NewOrder/NewOrder";
import EditOrder from "Pages/Order/NewOrder/EditOrder";
import MultiPointOrder from "Pages/Order/MultiPointOrder/MultiPointOrder";
import MultipointOrderAfterPayment from 'Pages/Order/MultiPointOrder/AfterPayment/MultipointOrderAfterPayment';
import OrderDetails from "Pages/Order/OrderDetails/OrderDetails";
import BulkOrder from "Pages/Order/BulkOrder/BulkOrders";
import BulkOrderAfterPayment from 'Pages/Order/BulkOrder/AfterPayment/BulkOrderAfterPayment';
import ImportOrder from "Pages/Order/BulkOrder/ImportOrder";

import AddressBook from "Pages/AddressBook/AddressBook";
import NewAddressBook from "Pages/AddressBook/NewAddressBook/NewAddressBook";
import PublicTrackingList from "Pages/Tracking/PublicTracking/PublicTrackingList";
import SingleTracking from "Pages/Tracking/SingleTracking/SingleTracking";
import SecureTracking from "Pages/Tracking/SecureTracking/SecureTracking";
import TrackingMap from 'Pages/TrackingMap/TrackingMap';
import Payment from "Pages/Topup/Topup";
import Billing from "Pages/Billing/Billing";
import BillingAfterPayment from 'Pages/Billing/after-payment/BillingAfterPayment';
import Settings from "Pages/Settings/Settings";
import ReferEarn from "Pages/ReferEarn/ReferEarn";
import AnalyticsPage from 'Pages/Analytics-2/AnalyticsPage.jsx';
import AnalyticItem from 'Pages/Analytics-2/AnalyticItem.jsx';
import InstabookPage from 'Pages/Ecommerce/InstabookPage.jsx';
import IntegrationSetupPage from 'Pages/Integration/IntegrationSetupPage';
import AffiliatesItem from 'Pages/Analytics-2/AffiliatesItem.jsx';
import Apps from "Pages/Apps/Apps";
import EditApp from "Pages/Apps/EditApp/EditApps";

import useMessageListener from 'Util/useMessageListener';

import { requestFirebaseNotificationPermission } from './firebaseInit';
import { messaging } from './serviceWorker';
import routeInit from './routeInit';

import useLocalStorage from 'Util/useLocalStorage';

const modalStateInitialVal = {
  isModalVisible: false,
  footer: true,
  bodyStyle: null,
  maskStyle: null,
  closable: true,
  modal: {
    type: null,
    title: null,
    onOk: null,
    onOkData: null,
    okText: 'Confirm',
    onCancel: null,
    text: null,
    maxWidth: 600,
    dialogContent: null,
    dialogContentProps: {}
  }
}

const Router = () => {
  const [state, dispatch] = useContext(GlobalContext);
  const { customerDetails, companyDetails, message: msg, antdLangFile, settings } = state.global;
  const customerId = customerDetails?.id;
  const [sseMessage, isListen] = useMessageListener(customerId);
  const [modalState, setModalState] = useState(modalStateInitialVal);
  const { dialogContent: DialogContent } = modalState.modal;
  const globalContextValue = {
    ...state.global,
    modalState,
    setModalState,
    resetModalState: () => setModalState(modalStateInitialVal)
  };

  const urlParams = new URLSearchParams(window.location.search);
  const redirectUrl = urlParams.get('redirectUrl');

  useEffect(() => {
    if (redirectUrl) {
      useLocalStorage.setItem("redirectUrl", redirectUrl);
    }
  })

  useEffect(() => {
    dispatch({
      type: 'SSE_MESSAGE_RECEIVED',
      payload: {
        message: sseMessage,
        isListen
      }
    })
  }, [sseMessage, isListen])

  useEffect(() => {
    if (companyDetails?.personalization) {
      const { primaryColor, secondaryColor, tertiaryColor } = companyDetails.personalization;
      primaryColor && document.documentElement.style.setProperty('--primary-color', primaryColor);
      secondaryColor && document.documentElement.style.setProperty('--secondary-color', primaryColor);
      tertiaryColor && document.documentElement.style.setProperty('--tertiary-color', tertiaryColor);
    }
    if (companyDetails?.icon) {
      const favicon = document.getElementById("favicon");
      favicon.href = IMAGE_URL_ROOT + companyDetails.icon;
    }
  }, [companyDetails])

  useEffect(() => {
    msg && message.success(msg)
  }, [msg])

  useEffect(() => {
    if (customerId) {
      requestFirebaseNotificationPermission()
        .then((firebaseToken) => {
          post(`user/fcmToken`, { token: firebaseToken, device: 'web', type: 'customer' });
        })
        .catch((err) => {
          return err;
        });
    }
  }, [customerId])

  if (messaging) {
    messaging.onMessage((payload) => {
      const { body, title } = payload.data;
      // const { body, title } = payload.notification;
      notification.info({
        message: title,
        description: body,
      });
    });
  }

  const routes = useMemo(() => routeInit(state.global, [
    {
      path: '/get-started',
      component: GetStartedPage,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      isAuthorized: customerDetails?.id
    },
    {
      path: '/',
      component: Home,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      isAuthorized: customerDetails?.id,
      withSidebar: true
    },
    {
      path: '/login',
      component: LoginPage,
      exact: false,
      withAuthorization: true,
      unauthorizedPath: '/',
      isAuthorized: !customerDetails?.id,
      mustCompleteProfile: false
    },
    {
      path: '/signup',
      component: SignUp,
      withAuthorization: true,
      unauthorizedPath: '/',
      isAuthorized: !customerDetails?.id,
      mustCompleteProfile: false
    },
    {
      path: '/signup:params',
      component: SignUp,
      withAuthorization: true,
      unauthorizedPath: '/',
      isAuthorized: !customerDetails?.id,
      mustCompleteProfile: false
    },
    {
      path: '/update-profile',
      component: UpdateProfile,
      withAuthorization: true,
      unauthorizedPath: '/login',
      isAuthorized: customerDetails?.id,
      mustCompleteProfile: false
    },
    // {
    //   path: '/ib',
    //   component: InstabookPage
    // },
    {
      path: '/ib/:id',
      component: InstabookPage
    },
    {
      path: '/verify',
      component: VerifyAccount,
      exact: true
    },
    {
      path: '/verify:params',
      component: VerifyAccount,
      exact: true
    },
    {
      path: '/forgot-password',
      component: ForgotPassword
    },
    {
      path: '/reset-password',
      component: ResetPassword
    },
    {
      path: '/delete-account',
      exact: true,
      component: DeleteAccount,
      withAuthorization: true,
      unauthorizedPath: '/login',
      isAuthorized: customerDetails?.id,
      withSidebar: false
    },
    {
      path: '/check-rates',
      component: InstantQuotePage,
      exact: true
    },
    {
      path: '/check-rates/:params',
      component: InstantQuotePage,
      exact: true
    },
    {
      path: '/contacts',
      exact: true,
      component: AddressBook,
      withAuthorization: true,
      unauthorizedPath: '/login',
      isAuthorized: customerDetails?.id,
      withSidebar: true
    },
    {
      path: '/contacts/add',
      component: NewAddressBook,
      withAuthorization: true,
      unauthorizedPath: '/login',
      isAuthorized: customerDetails?.id,
      withSidebar: true
    },
    {
      path: '/contacts/customer/:customerId/contact/:contactId/edit',
      component: NewAddressBook,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/orders',
      component: Orders,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/orders/cod',
      component: COD,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/orders/new-order',
      component: NewOrder,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/orders/new-order/:orderId',
      component: NewOrder,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/orders/edit/:orderId',
      component: EditOrder,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/orders/multipoint',
      component: MultiPointOrder,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/after-payment/multipoint',
      component: MultipointOrderAfterPayment,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/after-payment/multipoint/:orderId',
      component: MultipointOrderAfterPayment,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/order/:orderId',
      component: OrderDetails,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/orders/bulk-orders',
      component: BulkOrder,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/after-payment/bulk-order',
      component: BulkOrderAfterPayment,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/after-payment/bulk-order/:invoiceId',
      component: BulkOrderAfterPayment,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/orders/import-order',
      component: ImportOrder,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/track',
      component: PublicTrackingList
    },
    {
      path: '/track:params',
      component: PublicTrackingList
    },
    {
      path: '/strack',
      component: SingleTracking,
      exact: true
    },
    {
      path: '/strack:params',
      component: SingleTracking,
      exact: true
    },
    {
      path: '/etrack',
      component: SecureTracking,
      exact: true
    },
    {
      path: '/etrack/:nanoid',
      component: SecureTracking,
      exact: true
    },
    {
      path: '/rmap',
      component: TrackingMap,
      exact: true
    },
    {
      path: '/rmap:params',
      component: TrackingMap,
      exact: true
    },
    {
      path: '/payment',
      component: Payment,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/payment/:invoiceId',
      component: Payment,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      isAuthorized: customerDetails?.id,
      withSidebar: true,
      otherProps: {
        step: 3
      }
    },
    {
      path: '/billing',
      component: Billing,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/billing/transaction',
      component: Billing,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id,
      otherProps: {
        activeTabKey: "transaction"
      }
    },
    {
      path: '/billing/invoice',
      component: Billing,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id,
      otherProps: {
        activeTabKey: "invoice"
      }
    },
    {
      path: '/after-payment/billing',
      component: BillingAfterPayment,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      isAuthorized: customerDetails?.id
    },
    {
      path: '/after-payment/billing/:invoiceId',
      component: BillingAfterPayment,
      exact: true,
      withAuthorization: true,
      unauthorizedPath: '/login',
      isAuthorized: customerDetails?.id
    },
    {
      path: '/settings',
      component: Settings,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/refer-earn',
      component: ReferEarn,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/analytics',
      exact: true,
      component: AnalyticsPage,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/analytics/:path',
      component: AnalyticItem,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/affiliates/:path',
      component: AffiliatesItem,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/apps/list',
      component: Apps,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/apps/install',
      component: EditApp,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/apps/edit/:path',
      component: EditApp,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    },
    {
      path: '/integration',
      component: IntegrationSetupPage,
      withAuthorization: true,
      unauthorizedPath: '/login',
      withSidebar: true,
      isAuthorized: customerDetails?.id
    }
  ]), [state.global]);

  let scriptHtmlString = settings?.["consumer_portal.dashboard"]?.data?.["scripts_other_codes"]
  if (!scriptHtmlString) scriptHtmlString = ' ';
  return (
    <main>
      <InnerHTML html={scriptHtmlString} />
      <GlobalContext.Provider value={[{ ...state, global: globalContextValue }, dispatch]}>
        <ConfigProvider locale={antdLangFile}>
          <BrowserRouter>
            <ScrollToTop>
              <Switch>
                {routes}
              </Switch>
            </ScrollToTop>
          </BrowserRouter>
          <CustomizedModal
            type={modalState.modal.type}
            title={modalState.modal.title}
            onOk={modalState.modal.onOk}
            onOkData={modalState.modal.onOkData}
            maxWidth={modalState.modal.maxWidth}
            onCancel={() => {
              modalState.closable && setModalState({ ...modalStateInitialVal })
              if (modalState.modal.onCancel) {
                modalState.modal.onCancel()
              }
            }}
            text={modalState.modal.text}
            okText={modalState.modal.okText}
            okButtonProps={{ type: "danger" }}
            visible={modalState.isModalVisible}
            footer={modalState.footer}
            bodyStyle={modalState.bodyStyle}
            maskStyle={modalState.maskStyle}
            closable={modalState.closable}
          >
            <DialogContent
              {...modalState.modal.dialogContentProps}
            />
          </CustomizedModal>
        </ConfigProvider>
      </GlobalContext.Provider>
    </main>
  );
};

export default withAuthentication(Router);
