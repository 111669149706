export let API_ROOT;
let image_url_root;

if (
  localStorage.getItem('dxStagingApi') === 'Y' ||
  window.location.hostname.includes("staging-my.delyva.app") ||
  window.location.hostname.includes("localhost")
) {
  API_ROOT = "https://staging-api.delyva.app/v1.0/";
  image_url_root = "https://md-test.sgp1.digitaloceanspaces.com";
  // API_ROOT = "https://api.delyva.app/v1.0/";
  // image_url_root = "https://cdn.delyva.app";
} else {
  API_ROOT = "https://api.delyva.app/v1.0/";
  image_url_root = "https://cdn.delyva.app";
}

export const IMAGE_URL_ROOT = image_url_root;
