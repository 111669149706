import React, { useContext, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Layout, Form, Card, Spin, message } from "antd";
import { Link } from "react-router-dom";

import { GlobalContext } from 'Store/store';
import TextInput from "Components/TextInput";
import CustomizedButton from "Components/CustomizedButton";
import { IMAGE_URL_ROOT, post } from 'Util/API';
import PasswordInput from 'Components/PasswordInput';

const ResetPassword = (props) => {
  const [state] = useContext(GlobalContext);
  const { companyDetails } = state.global;
  const [isLoading, setIsLoading] = useState(false);
  const searchParams = window.location.search;
  const urlParams = new URLSearchParams(searchParams);
  const email = urlParams.get('email');
  const code = urlParams.get('code');
  const showAllField = !email && !code;

  const onSubmit = async (data) => {
    onSetNewPassword(data);
  }

  const onSetNewPassword = async (data) => {
    setIsLoading(true)
    const fetch = await post("user/resetPassword", { companyId: companyDetails.id, ...data })
    if (fetch.status === 200) {
      props.history.push({
        pathname: `${process.env.PUBLIC_URL}/login`,
        state: {
          showMsg: {
            type: 'success',
            time: 7,
            message: 'Password has been reset successfully. Please login with your new password.'
          }
        }
      })
    } else message.error(fetch);
    setIsLoading(false)
  }

  return (
    <Spin spinning={isLoading} size="large">
      <Layout style={{ minHeight: "100vh" }}>
        <div className="center">
          <div className="logo-company">
            <img
              style={{ height: 64, margin: '0 20px' }}
              src={
                companyDetails.logob ?
                  IMAGE_URL_ROOT + companyDetails.logob :
                  'https://cdn.delyva.app/assets/delyvax-logo-b.svg'
                }
              alt="company logo"
            />
          </div>
          <p
            className="heading2"
            style={{ textAlign: "center", fontWeight: 400 }}
          >
            Reset Your Password
          </p>
          <Card>
            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                email: urlParams.get('email'),
                code: urlParams.get('code')
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().nullable().required("Email")
              })}
              onSubmit={onSubmit}
            >
              {form => (
                <Form onSubmit={form.handleSubmit} className="login-form">
                  {email && <div style={{ marginBottom: 20 }}>For account {email}</div>}
                  {
                    showAllField &&
                    <TextInput
                      {...form}
                      name="email"
                      placeholder="Email"
                      type="text"
                      className="marginBottom-10"
                    />
                  }
                  {
                    showAllField &&
                    <TextInput
                      {...form}
                      name="code"
                      placeholder="Code"
                      type="text"
                      className="marginBottom-10"
                    />
                  }
                  <PasswordInput
                    {...form}
                    name="newPassword"
                    placeholder="New password"
                    type="text"
                    className="marginBottom-10"
                  />
                  <CustomizedButton
                    type="primary"
                    htmlType="submit"
                    isBlockType={true}
                    text="Submit"
                  />
                </Form>
              )}
            </Formik>
          </Card>
          <p className="footer-link">
            <Link to={`${process.env.PUBLIC_URL}/login`}>
              <CustomizedButton
                type="link"
                text="Sign in"
              />
            </Link>
          </p>
        </div>
      </Layout>
    </Spin>
  )
}

export default ResetPassword;
