import React, { useContext } from "react";
import {
  Input,
  Row,
  Col,
  Select
} from "antd";
import moment from 'moment';
import { useTranslation } from "react-i18next";

import withStore from 'Util/withStore';
import useWindowSize from 'Util/useWindowSize';
import DateFilter from 'Pages/Order/DateFilter';
import { GlobalContext } from 'Store/store';

const { Option } = Select;

const OrdersTableContent = (props) => {
  const size = useWindowSize();
  const { filterAndTableState: ordersTableData, handleSetFilterAndTableState } = props;
  const [state] = useContext(GlobalContext);
  const { serviceCompanies } = state.global;
  const {t} = useTranslation('orderPage');

  return (
    <div style={{ backgroundColor: 'white', padding: 20 }}>
      <Input.Search
        style={{ marginBottom: 10 }}
        allowClear
        enterButton={t('content.tableContent.search')}
        placeholder={t('content.tableContent.search')+"..."}
        onSearch={(val) => handleSetFilterAndTableState({ keyword: val })}
      />
      <Row gutter={[10, 10]} style={{ display: 'flex', flexDirection: size[0] > 472 ? 'row' : 'column', alignItems: size[0] > 472 ? 'center' : 'unset' }}>
        <Col>
          <div>{t('content.tableContent.filters')}:</div>
        </Col>
        <Col>
          <Select
            allowClear
            dropdownMatchSelectWidth={false}
            onChange={(val) => {
              handleSetFilterAndTableState({ serviceCompanyId: val === undefined ? null : val })
            }}
            placeholder={t('content.tableContent.services')}
            style={{ width: 120 }}
          >
            {
              serviceCompanies.map((item) => (
                <Option key={item.id} value={item.id}>{item.name}</Option>
              ))
            }
          </Select>
        </Col>
        <Col>
          <DateFilter
            currentId={ordersTableData.dateRangeId}
            handleModalRangeDate={(dateFrom, dateTo, id) => {
              handleSetFilterAndTableState({
                dateFrom: dateFrom && moment(dateFrom),
                dateTo: dateTo && moment(dateTo),
                dateRangeId: id
              })
            }}
            handleCustomDateRange={props.onOpenDateFilter}
          />
        </Col>
      </Row>
    </div>
  )
}

export default withStore(OrdersTableContent);
