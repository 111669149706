import React, { useContext } from "react";
import {
  Button,
  Dropdown,
  Menu,
  Radio,
  Checkbox,
  Row,
  Col,
} from "antd";
import { useTranslation } from "react-i18next";
import { get } from 'Util/API';
import { message } from "antd";

import withStore from 'Util/withStore';
import useWindowSize from 'Util/useWindowSize';
import { GlobalContext } from 'Store/store';
import { onOpenCancelOrderModal, onOpenConfirmOrderModal } from 'Pages/Order/ordersHelper';

const OrdersTableContent = (props) => {
  const size = useWindowSize();
  const { filterAndTableState: ordersTableData, resetState, history, setIsLoading } = props;
  const { rowTotal } = ordersTableData;
  const [state] = useContext(GlobalContext);
  const { modalState, setModalState } = state.global;
  const {t} = useTranslation('orderPage');
  let allSelectedRows = [...props.allSelectedRows];
  let orderIds = allSelectedRows.map((item) => item.id);

  const getAllOrders = async () => {
    setIsLoading(true);
    const dataCopy = { ...ordersTableData };
    dataCopy.dateFrom = dataCopy.dateFrom?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.dateTo = dataCopy.dateTo?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.page = 1;
    delete dataCopy.limit;
    delete dataCopy.data;
    delete dataCopy.pageTotal;
    delete dataCopy.rowTotal;
    delete dataCopy.dateRangeId;
    const params = [];
    Object.keys(dataCopy).forEach((key, i) => {
      dataCopy[key] && params.push((i > 0 ? '&' : '') + key + '=' + dataCopy[key]);
    });
    const orderResponse = await get(`order?limit=${rowTotal}&` + params.join(""));
    setIsLoading(false);
    if (orderResponse.status === 200) {
      return orderResponse.data.data;
    } else {
      message.error(orderResponse);
      return [];
    };
  }

  const refreshQuote = async (ids) => {
      setIsLoading(true);

      const refreshServicesRes = await get('order/'+ids+'/refreshQuote');

      if (refreshServicesRes.status === 200) {
        message.success('Refresh quotes successful.')
        resetState({ ...ordersTableData, data: null, setToPage: ordersTableData.page });
        setIsLoading(false);
      } else message.error(refreshServicesRes)
  }

  return (
    <Row gutter={[10, 10]} style={{ backgroundColor: 'white', padding: 20, paddingTop: 0, display: 'flex', flexDirection: size[0] > 870 ? 'row' : 'column', alignItems: size[0] > 870 ? 'center' : 'unset' }}>
      <Col>
        <Radio.Group defaultValue="a">
          <Radio.Button>
            {props.totalSelectedRows} {t('content.tableContent.selected')}
          </Radio.Button>
          <Radio.Button value="b">
            <Checkbox
              checked={props.selectAllChecked}
              className="table-checkbox"
              onChange={() => props.handleSelectAll()}
            >
              {t('content.tableContent.selectAll')} {rowTotal + " "}
              {t('content.tableContent.orders')}
            </Checkbox>
          </Radio.Button>
          <Radio.Button value="c">
            <Dropdown
              overlay={
                <Menu style={{ display: 'flex', flexDirection: 'column' }}>
                  <Button
                    style={{ border: 'none' }}
                    disabled={!(orderIds.length > 0)}
                    onClick={async () => {

                      if (Number(props.totalSelectedRows) === Number(rowTotal)) {
                        const rows = await getAllOrders();
                        orderIds = rows.map((item) => item.id);
                      }

                      refreshQuote(orderIds);
                    }}
                  >
                    {t('content.tableContent.refresh')}
                  </Button>
                  <Button
                    style={{ border: 'none' }}
                    disabled={!(orderIds.length > 0)}
                    onClick={async () => {

                      if (Number(props.totalSelectedRows) === Number(rowTotal)) {
                        const rows = await getAllOrders();
                        orderIds = rows.map((item) => item.id);
                      }

                      onOpenCancelOrderModal({
                        setIsLoading,
                        setModalState,
                        selectedRowsIds: orderIds,
                        resetState,
                        ordersTableData,
                        modalState,
                        history,
                        transl: t
                      })
                    }}
                  >
                    {t('content.tableContent.cancel')}
                  </Button>
                </Menu>
              }
              trigger={["click"]}
              className="action-dropdown"
            >
              <span>
                {t('content.tableContent.action')} <i className="fas fa-chevron-down" />
              </span>
            </Dropdown>
          </Radio.Button>
        </Radio.Group>
      </Col>
      <Col>
        <Button
          type="primary"
          disabled={!(orderIds.length > 0)}
          onClick={async () => {

            if (Number(props.totalSelectedRows) === Number(rowTotal)) {
              const rows = await getAllOrders();
              orderIds = rows.map((item) => item.id);
              allSelectedRows = [...rows];
            }

            onOpenConfirmOrderModal({
               selectedRowsIds: orderIds,
               allSelectedRows,
               setModalState,
               modalState,
               resetTableState: resetState,
               history,
               transl: t
            })
          }}
        >
          {t('content.bulkOrder.confirmOrders')}
        </Button>
      </Col>
    </Row>
  )
}

export default withStore(OrdersTableContent);
