import React, { Component } from "react";
import { Button, Table, Layout, Form, Tag } from "antd";
import {
  TEXT_DELETE,
  TEXT_SEARCH,
  TEXT_SEARCH_APPS
} from "../../Util/Text";
import TextInput from "../../Components/TextInput";
import { Formik } from "formik";
import * as Yup from "yup";
import { MESSAGE_SEARCH_INPUT_REQUIRED } from "../../Util/ErrorMessage";
import CustomizedModal from "../../Components/CustomizedModal";

const selectAfter = (
  <Button size="small" className="button__transparent" htmlType="submit">
    {TEXT_SEARCH}
  </Button>
);

const formik = {
  initialValues: {
    search: ""
  },
  validationSchema: Yup.object().shape({
    search: Yup.string().required(MESSAGE_SEARCH_INPUT_REQUIRED)
  })
};

class AppsListTable extends Component {
  state = {
    searchResult: "",
    isFilterModalVisible: false,
    appsData: this.props.appsData
  };

  submitSearch = values => {
    this.setState({
      searchResult: values.search
    });
  };

  handleCancel = () => {
    this.setState({
      isFilterModalVisible: false,
      isDeleteCoverageModalVisible: false
    });
  };

  render() {
    return (
      <Layout className="table-tab-container">
        <div className="ui-table-container">
          {/*<Formik {...formik} onSubmit={this.submitSearch}>
            {form => (
              <Form
                onSubmit={form.handleSubmit}
                className="ui-search-control-bar"
              >
                <TextInput
                  {...form}
                  name="search"
                  addonAfter={selectAfter}
                  placeholder={TEXT_SEARCH_APPS}
                />
              </Form>
            )}
          </Formik>*/}
          <div className="ui-table-checkall">
            {this.state.searchResult && <Tag>{this.state.searchResult}</Tag>}
          </div>
          <Table
            columns={this.props.appsColumns}
            dataSource={this.props.appsData}
            total={this.props.total}
            className="ui-table"
            pagination={{
              defaultPageSize: 1000,
              showSizeChanger: true,
              pageSizeOptions: ["10", "20", "30", "40", "50", "100", "500","1000"],
              showTotal: (total, range) => `${range[1]} of ${total} items`
            }}
          />
        </div>
        <CustomizedModal
          title={TEXT_DELETE + " " + this.props.deletedApp.name}
          visible={this.props.isDeleteAppModalVisible}
          onOk={this.props.deleteApp}
          onCancel={this.props.handleCancel}
          text={TEXT_DELETE}
          okButtonProps={{ type: "danger" }}
          okText={TEXT_DELETE}
        />
      </Layout>
    );
  }
}

export default AppsListTable;
