import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Menu, Dropdown, Icon, Layout } from "antd";

import "App.css";
import { submenus, sidebarMenus, sidebarPageMenus } from "Util/ArrayObjects";
import { GlobalContext } from "Store/store";
import { TEXT_ROAD_TAX } from "Util/Text";

const { Sider } = Layout;

const Sidebar = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { companyDetails, multipointServices, settings } = state.global;
  const { data: referralData } = settings["referral.customer"];
  const t = {};
  t.pageWrapper = useTranslation('pageWrapper').t;

  const activeSubmenus = multipointServices.length > 0 ? submenus : submenus.filter((item) => (item.key !== 'multipoint' && item.key !== 'sameday') )
  
  const activeSidebarMenus = sidebarMenus.filter((item) => {
    if (item.key === 'earn') {
      return referralData?.promo_title?.length > 0;
    }
    if (item.key === 'apps') {
      return (companyDetails?.code?.length < 5 || companyDetails?.code?.includes('staging'));
    }
    if (item.key === 'return') {
      item.path = 'https://'+companyDetails?.code+".delyva.app/return";
    }

    return true
  })

  const activeSidebarPageMenus = sidebarPageMenus.filter((item) => {
    if (item.key === 'earn') {
      return referralData?.promo_title?.length > 0;
    }
    if (item.key === 'apps') {
      return (companyDetails?.code?.length < 5 || companyDetails?.code?.includes('staging'));
    }
    if (item.key === 'return') {
      item.path = 'https://'+companyDetails?.code+".delyva.app/return";
    }

    return true
  })

  const orderDropdownSubmenu = (
    <Menu>
      {activeSubmenus.map(submenu => (
        <Menu.Item key={submenu.key}>
          <Link
            to={submenu.path}
            onClick={() => {
              submenu.path === `${process.env.PUBLIC_URL}/orders/multiple` &&
              dispatch({ type: 'NEW_FORM', payload: { new: true } })
            }}
          >
            <i className={submenu.icon} />
            {t.pageWrapper(`sidebarOrderMenus.${submenu.key}`)}
          </Link>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Sider
      className="ui-sidebar"
      trigger={null}
      collapsible
      collapsed={props.collapsedSidebar}
      breakpoint="lg"
      collapsedWidth="64"
      onBreakpoint={
        props.autoMinimizeOnBreakpoint
          ? broken => {
              props.autoMinimizeOnBreakpoint(broken);
            }
          : props.autoMinimizeOnBreakpoint
      }
      width={230}
    >
      <Menu
        mode="inline"
        className="home-menu"
        selectedKeys={[props.currentPath]}
      >
        <Menu.Item
          style={{ marginTop: 10, marginBottom: 10 }}
          key="1"
          className="home-submenu-item"
        >
          <Link to={`${process.env.PUBLIC_URL}/orders/new-order`}>
            <span
              className="button-new-order small"
              style={{ whiteSpace: "nowrap" }}
            />
            <Dropdown.Button
              onClick={() => dispatch({ type: 'NEW_FORM', payload: { new: true } })}
              trigger={["click"]}
              type="primary"
              className="button-full-width"
              overlay={orderDropdownSubmenu}
              icon={<Icon type="down" />}
            >
              {t.pageWrapper('newOrder')}
            </Dropdown.Button>
          </Link>
        </Menu.Item>
        <Menu.Item
          style={{
            borderBottom: "1px solid #cfdae6"
          }}
          key="2"
          className="home-submenu-item"
        >
          <Link
            to={`${process.env.PUBLIC_URL}/payment`}
            style={{ whiteSpace: "nowrap" }}
          >
            <i
              className="icon fa fa-credit-card  fa-fw"
              style={{ width: "40px" }}
            />
            <span>
            {t.pageWrapper('credit')} <b>{companyDetails.currency + ' ' + state.global.customerDetails.walletBalance}</b>
            </span>
          </Link>
        </Menu.Item>

        <Menu.Item key="sectionActions" className="menu">
          <span>{t.pageWrapper(`sidebarMenus.actions`)}</span>
        </Menu.Item>

        {
          activeSidebarMenus.map(menu => 
            {
              return menu.path.includes('http') ?
                <Menu.Item
                  key={menu.path}
                  className={menu.menu}
                >
                  <a href= {menu.path} target="_blank" >
                    <i className={menu.icon} />
                    <span>{t.pageWrapper(`sidebarMenus.${menu.key}`)}</span>
                  </a>
                </Menu.Item>
              :
                <Menu.Item
                  key={menu.path}
                  className={menu.menu}
                >
                  <Link
                    to={menu.path}
                  >
                    <i className={menu.icon} />
                    <span>{t.pageWrapper(`sidebarMenus.${menu.key}`)}</span>
                  </Link>
                </Menu.Item>
            }
        )
        }

        <Menu.Item key="sectionPages" className="menu">
          <span>{t.pageWrapper(`sidebarMenus.other`)}</span>
        </Menu.Item>

        {
          activeSidebarPageMenus.map(menu => (
          <Menu.Item
            key={menu.path}
            className={menu.menu}
          >
            <Link
              to={menu.path}
            >
              <i className={menu.icon} />
              <span>{t.pageWrapper(`sidebarMenus.${menu.key}`)}</span>
            </Link>
          </Menu.Item>
        ))
        }
      </Menu>
    </Sider>
  )
}

export default Sidebar;
