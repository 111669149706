import React from "react";
import { Form, InputNumber } from "antd";

const Input = ({
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  name,
  placeholder,
  type,
  prefix,
  label,
  max,
  onChange,
  addonAfter,
  addonBefore,
  onBlur,
  size,
  disabled,
  className,
  style,
  touched,
  step,
  min,
  inputStyle,
  defaultValue,
  ...rest
}) => (
  <Form.Item
    hasFeedback={addonAfter || addonBefore ? null : !!errors[name]}
    validateStatus={disabled ? null : touched[name] && errors[name] && "error"}
    help={disabled ? null : touched[name] ? errors[name] : ""}
    label={label}
    className={className}
    style={style}
  >
    <InputNumber
      style={inputStyle}
      step={step}
      disabled={disabled}
      placeholder={placeholder}
      value={values[name] || defaultValue}
      onChange={
        onChange
          ? onChange
          : event => {
              setFieldValue(name, event);
            }
      }
      min={min}
      max={max}
      onBlur={onBlur ? onBlur : () => setFieldTouched(name)}
      {...rest}
    />
  </Form.Item>
);

export default Input;
