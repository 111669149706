import React, { useContext, useState, useEffect } from "react";
import { Card, Form, DatePicker, Layout, Spin, Select, message } from "antd";
import moment from 'moment';
import * as Yup from "yup";
import { Formik } from "formik";

import CustomizedButton from "Components/CustomizedButton";
import CustomFormItem from 'Components/CustomFormItem';
import TextInput from "Components/TextInput";
import CustomizedDropdown from "Components/CustomizedDropdown";
import { TEXT_SAVE, TEXT_SUBMIT } from "Util/Text";
import { IMAGE_URL_ROOT, update, get } from "Util/API";
import useGenerateDropdownItem from 'Util/useGenerateDropdownItem';
import useLocalStorage from 'Util/useLocalStorage';
import { GlobalContext } from "Store/store";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";
import languages from 'languages.json';

const Option = Select.Option;

const labelType = ['A4-3', 'A4-Q4', 'A5-2', 'A6-1'];

const priceQuotePrefOption = [];
priceQuotePrefOption.push(<Option key="default" value="default">Default</Option>);
priceQuotePrefOption.push(<Option key="cheapest" value="cheapest">Cheapest</Option>);
priceQuotePrefOption.push(<Option key="fastest" value="fastest">Fastest</Option>);
priceQuotePrefOption.push(<Option key="best" value="best">Reputation</Option>);

const validationSchema = {
  validationSchema: Yup.object().shape({
    type: Yup.string().nullable().required("Account type is required"),
    name: Yup.string().nullable().required("Full name -OR- Business name is required"),
    identityNo: Yup.string().nullable().required("Identification No. -OR- Business Registration No. id is required"),
    dob: Yup.string().nullable().required("D.O.B. -OR- Date of Incorporation is required"),
    industry: Yup.string()
    .nullable()
    .when("type", {
      is: (val) => val !== "personal",
      then: Yup.string()
        .nullable()
        .required("Industry is required field")
    }),
    websiteUrl: Yup.string().nullable(),
    defaultLabelType: Yup.string() || 'A4-3',
    lang: Yup.string()
  })
};

const FetchWrapper = (props) => {
  const [fetchItems, setFetchItems] = useState(null);

  async function allFetch() {
    const getCustomerType = get("/parameters/customerType");
    const getIndustryType = get("/parameters/industry");

    return {
      getCustomerType: await getCustomerType,
      getIndustryType: await getIndustryType
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const fetchAll = await allFetch();
      const { getCustomerType, getIndustryType } = fetchAll;
      if (getCustomerType.status === 200 && getIndustryType.status === 200) {
        setFetchItems({
          customerType: getCustomerType.data.data,
          industryType: getIndustryType.data.data
        })
      } else message.error('Request time out. Please try again.')
    }
    fetch()
  }, [])

  return fetchItems
    ? <ProfileInformation wrapper={{ ...fetchItems }} {...props} />
    : <Spin spinning><div styles={{ width: '100%' }} /></Spin>
}

const ContactForm = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useContext(GlobalContext);
  const { userDetails, companyDetails, customerDetails, allCustomerAccounts } = state.global;

  const lang = useLocalStorage.getItem("lang") || state.global.lang || "en_US";

  const priceQuotePref = customerDetails.priceQuotePref || 'fastest';

  const {
    name,
    type,
    identityNo,
    dob,
    industry,
    defaultLabelType,
  } = customerDetails;

  const checkDob = customerDetails.dob?.length > 0 && customerDetails.dob;
  const newDob = new Date(checkDob)
  const dateFormat = 'DD-MM-YYYY';
  const formattedDate = moment(newDob, dateFormat).format(dateFormat)

  useEffect(() => {
    name &&
    type &&
    identityNo &&
    dob &&
    industry &&
    defaultLabelType &&
    lang &&
    props.history.push(`${process.env.PUBLIC_URL}/`)
  }, [
    name,
    type,
    identityNo,
    dob,
    industry
  ])

  useEffect(() => {
      if(userDetails.status === 0)
      {
          message.warning('Account is inactive, please check your email and/or phone for verification code (or spam folder).');
      }
  }, [])

  const onSubmit = async (values) => {
    !isLoading && setIsLoading(true);
    const dobToSubmit = moment(values.dob, dateFormat).format('YYYY-MM-DD');
    values.dob = dobToSubmit;

    const { lang, ...rest } = values;
    const customerData = { ...rest, industry: values.industry || "-", websiteUrl: values.websiteUrl || "-", id: customerDetails.id, companyId: companyDetails.id };

    const updateCustomerDetails = await update("customer", customerData)
    if (updateCustomerDetails.status === 200) {

      useLocalStorage.setItem("lang", lang);

      // const newCustomersAccount = allCustomerAccounts.slice(0);
      // const currentCustomerDetailsIndex = newCustomersAccount.findIndex((item) => item.id === updateCustomerDetails.data.data.id);
      // newCustomersAccount[currentCustomerDetailsIndex] = updateCustomerDetails.data.data;
      // TODO: Remove this after api response correct bankInformation after updateCustomerDetails
      const getCustomerDetails = await get(`/customer/${customerDetails.id}?companyId=${companyDetails.id}`);
      if (getCustomerDetails.status === 200) {
        setIsLoading(false);
        dispatch({
          type: "UPDATE_PROFILE",
          payload: {
            userDetails,
            // allCustomerAccounts: newCustomersAccount,
            customerDetails: getCustomerDetails.data.data,
          }
        })
        message.success('Profile updated.')
      } else message.error(getCustomerDetails);
    } else message.error(updateCustomerDetails);

    setIsLoading(false);
  }

  return (
    <Spin spinning={isLoading} size="large">
      <Layout style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh' }}>
        <img
          style={{ height: 35, margin: 20 }}
          src={
            companyDetails.logob ?
              IMAGE_URL_ROOT + companyDetails.logob :
              'https://cdn.delyva.app/assets/delyvax-logo-b.svg'
            }
          alt="company logo"
        />
        <div style={{ minHeight: 'calc(100vh - 90px)', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
          <p style={{ fontSize: 25, color: 'black', fontWeight: 400 }}>Let us know you better</p>
          <Card style={{ width: '90vw', maxWidth: 600, marginBottom: '1em' }}>
            <Formik
              {...validationSchema}
              enableReinitialize
              initialValues={{
                name: customerDetails.name,
                type: customerDetails.type,
                identityNo: customerDetails.identityNo,
                dob: formattedDate,
                industry: customerDetails.industry,
                websiteUrl: customerDetails.websiteUrl,
                defaultLabelType: customerDetails.defaultLabelType,
                priceQuotePref: customerDetails.priceQuotePref
              }}
              onSubmit={onSubmit}
            >
              {form => (
                <FetchWrapper
                  form={form}
                  dob={newDob}
                  dateFormat={dateFormat}
                />
              )}
            </Formik>
          </Card>
        </div>
      </Layout>
    </Spin>
  )
}

const ProfileInformation = (props) => {
  const [state] = useContext(GlobalContext);
  const { customerType, industryType } = props.wrapper;
  const { lang, customerDetails, companyDetails } = state.global;
  const { form, dob, dateFormat } = props;
  const { t } = useTranslation('settingsPage');

  return (
    <Form layout="vertical" onSubmit={form.handleSubmit}>
      <div
        className="whiteBColor"
        style={{ padding: "10px 20px", minHeight: "auto" }}
      >
        <CustomizedDropdown
          {...form}
          name="lang"
          defaultValue={lang}
          optionFilterProp="children"
          children={useGenerateDropdownItem(languages, null, null, 'label')}
          label={t('content.profileInfo.language')}
          placeholder="Choose language"
          onChange={(value) => { useLocalStorage.setItem("lang", value); window.location.reload(false); }}
        />
        <CustomizedDropdown
          {...form}
          name="type"
          optionFilterProp="children"
          children={useGenerateDropdownItem(customerType)}
          defaultValue={customerDetails.type}
          label={t('content.profileInfo.type')}
          onBlur={() => {}}
        />

        <TextInput {...form} name="name" type="text" label="Personal:Full name -OR- Business:Registered name" />

        <TextInput {...form} name="identityNo" type="text" label="Personal:Identification No. -OR- Business:Registration No." />

        <CustomFormItem {...form} label="Personal:Date of Birth -OR- Business:Date of Incorporation">
          <DatePicker
            style={{ width: '100%' }}
            onChange={(val) => {
              const formattedDate = moment(val).format(dateFormat);
              form.setFieldValue(
                "dob",
                formattedDate
              );
            }}
            defaultValue={moment(dob, dateFormat)}
            format={dateFormat}
            name="dob"
          />
        </CustomFormItem>

        <CustomizedDropdown
          {...form}
          name="industry"
          optionFilterProp="children"
          children={useGenerateDropdownItem(industryType)}
          defaultValue={customerDetails.industry}
          label={t('content.profileInfo.industry')+" (optional)"}
          onBlur={() => {}}
        />

        <TextInput {...form} name="websiteUrl" type="text" label={t('content.profileInfo.websiteUrl')} />

        {(companyDetails?.code.length <= 5) ?
        <CustomizedDropdown
          {...form}
          name="priceQuotePref"
          optionFilterProp="children"
          children={priceQuotePrefOption}
          label={t('content.profileInfo.sortServices')}
          placeholder={t('content.profileInfo.sortServices')}
        />
        :''}

        <CustomizedDropdown
          {...form}
          name="defaultLabelType"
          optionFilterProp="children"
          children={useGenerateDropdownItem(labelType)}
          label={t('content.profileInfo.defaultLabelType')}
          placeholder="Label type"
        />

        <div style={{ float: "right" }}>
          <CustomizedButton
            text={TEXT_SUBMIT}
            type="primary"
            htmlType="submit"
          />
          {/**<Link to={`${process.env.PUBLIC_URL}/`}>
            <CustomizedButton
              className="button__transparent color_light-blue"
              text={"SKIP"}
            />
          </Link>**/}
        </div>
      </div>
    </Form>
  )
}

export default ContactForm;
