import React from "react";
import { useTranslation } from "react-i18next";

import { Table, List } from "antd";
import generateAddressByKeys from 'Util/generateAddressByKeys';

const ContactInformation = (props) => {
  const { t } = useTranslation('orderDetailsPage');

  const contactData = props.selectedOrder.waypoint.map((item) => ({
    ...item.contact,
    category: item.type,
    // cashCollected: 'N/A',
    note: item.note,
    status: item.status
  }));

  const column = [
    {
      title: t('contactInformation.tableColumn.category'),
      dataIndex: "category",
      key: "category",
      width: 100,
      fixed: "left"
    },
    {
      title: t('contactInformation.tableColumn.name'),
      dataIndex: "name",
      width: 100,
      key: "name",
    },
    {
      title: t('contactInformation.tableColumn.phoneNumber'),
      dataIndex: "phone",
      width: 100,
      key: "phone"
    },
    {
      title: t('contactInformation.tableColumn.email'),
      dataIndex: "email",
      key: "email",
      width: 100,
      render: text => {
        return (
          <span>{text || 'N/A'}</span>
        );
      }
    },
    {
      title: t('contactInformation.tableColumn.address'),
      dataIndex: "address1",
      key: "address",
      width: 250,
      render: (text, record) => {
        const address = generateAddressByKeys(['unitNo', 'address1', 'address2', 'city', 'state'], record);
        return address;
      }
    },
    {
      title: t('contactInformation.tableColumn.postalCode'),
      dataIndex: "postcode",
      width: 100,
      key: "postcode"
    },
    {
      title: t('contactInformation.tableColumn.country'),
      dataIndex: "country",
      width: 100,
      key: "country"
    },
    // {
    //   title: t('contactInformation.tableColumn.totalCashCollected'),
    //   dataIndex: "cashCollected",
    //   key: "cashCollected",
    //   width: 100,
    //   render: text => {
    //     return (
    //       <div
    //         style={{
    //           overflow: "hidden",
    //           whiteSpace: "nowrap",
    //           textOverflow: "ellipsis"
    //         }}
    //       >
    //         <span>{text || 'N/A'}</span>
    //       </div>
    //     );
    //   }
    // },
    {
      title: t('contactInformation.tableColumn.note'),
      dataIndex: 'note',
      width: 200,
      key: 'note',
      render: (note, record) =>
      (
          <div>
          {note ? <span dangerouslySetInnerHTML={{ __html: note }} /> : '-'}
          </div>
      )
    }
    // {
    //   title: t('contactInformation.tableColumn.location'),
    //   dataIndex: "coord",
    //   width: 100,
    //   key: "coord",
    //   render: (text, record) => {
    //       return (
    //         <span>
    //           <a href={ `https://www.google.com/maps/search/?api=1&query=${record.coord.lat},${record.coord.lon}` } target="_blank" >
    //             <i className="fas fa-map" />
    //           </a>
    //         </span>
    //       )
    //   }
    // }
  ];

  return (
    <List
      bordered
      style={{ backgroundColor: "white", marginBottom: "20px" }}
      header={
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <p className="heading3" style={{ margin: 0 }}>
            {t('contactInformation.title')}
          </p>
        </div>
      }
    >
      <Table
        loading={props.isLoading}
        columns={column}
        dataSource={contactData}
        scroll={{ x: 1290 }}
      />
    </List>
  )
}

export default ContactInformation;
