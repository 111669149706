import React, { useContext, useState, useEffect } from "react";
import { Card, Divider, Form, Row, Col, Select, message } from "antd";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import CustomizedButton from "Components/CustomizedButton";
import { TEXT_SEARCH_COUNTRY } from "Util/Text";
import TextInput from "Components/TextInput";
import PhoneNumberInput from "Components/PhoneNumberInput";
import { STATE_LIST } from "Util/State";
import { GlobalContext } from "Store/store";
import { post, get, update } from "Util/API";
import useGenerateAddress from 'Util/useGenerateAddress';
import useGenerateDropdownItem from 'Util/useGenerateDropdownItem';
import CustomizedDropdown from "Components/CustomizedDropdown";

const Option = Select.Option;
const stateOption = [];
const state = STATE_LIST;

for (let i = 0; i < state.length; i++) {
  stateOption.push(
    <Option key={i} value={state[i]}>
      {state[i]}
    </Option>
  );
}

const validationSchema = Yup.object().shape({
  name: Yup.string().nullable().required("Name is required field"),
  email: Yup.string().nullable(),
  mobile: Yup.string().nullable().required("Phone number is required field"),
  unitNo: Yup.string().nullable(),
  address1: Yup.string().nullable().required("Address 1 is required field"),
  address2: Yup.string().nullable(),
  postcode: Yup.string().nullable().required("Postcode is required field"),
  city: Yup.string().nullable().required("City is required field"),
  state: Yup.string().nullable().required("State is required field"),
  country: Yup.string().nullable().required("Country is required field"),
  tags: Yup.string().nullable()
})

const ContactForm = (props) => {
  const { isLoading, setIsLoading, contactId, contactCustomerId } = props;
  const [state] = useContext(GlobalContext);
  const { countryList, customerDetails, companyDetails } = state.global;

  const [contact, setContact] = useState({});
  const {
    name,
    email,
    mobile,
    unitNo,
    address1,
    address2,
    postcode,
    city,
    country,
    tags
   } = contact;

   const defaultCountryCode = country || customerDetails.country || companyDetails.country;
   const selectedCountryData = countryList.filter((item) => (
     item.countryCode === defaultCountryCode
   ));

  useEffect(() => {
    let mount = true;
    const fetch = async () => {
      setIsLoading(true)
      const getContact = await get(`contact/${contactId}?customerId=${contactCustomerId}`)
      if (getContact.status === 200) {
        mount && setContact(getContact.data.data)
      } else message.error(getContact)
      setIsLoading(false)
    }
    if (mount && contactId) {
      fetch()
    } else setIsLoading(false)
    return () => {
      mount = false
    }
  }, [])

  const onSubmit = async (values) => {
    setIsLoading(true);
    const accountData = { ...values };

    accountData.tags = values.tags;
    

    const updateUserDetails = contactId ?
      await update(`contact/${contactId}`, accountData) :
      await post(`contact`, accountData);
    if (updateUserDetails.status === 200) {
      message.success('Contact' + contactId ? 'updated.' : 'added.')
    } else message.error(updateUserDetails);

    setIsLoading(false);
  }

  return (
    <Formik
      enableReinitialize
      initialValues={{
        source: 'personal',
        name,
        email,
        mobile,
        unitNo,
        address1,
        address2,
        postcode,
        city,
        customerId: contactCustomerId || customerDetails.id,
        state: contact.state,
        country: selectedCountryData[0]?.countryCode,
        tags: contact.tags
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {form => (
        <AccountInformation
          form={form}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      )}
    </Formik>
  )
}

const AccountInformation = (props) => {
  const { t } = useTranslation('addressBookPage');
  const [state] = useContext(GlobalContext);
  const { companyDetails, customerDetails, countryList } = state.global;
  const { form, setIsLoading } = props;

  const tagList = ['Sender','Receiver'];

  const [postcode, setPostcode] = useState(form.values.postcode);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useGenerateAddress({
    setIsLoading,
    countryList,
    country: form.values.country,
    postcode,
    setFieldValue: form.setFieldValue,
    companyCountry: companyDetails.country,
    customerCountry: customerDetails.country
  });

  return (
    <Form layout="vertical" onSubmit={form.handleSubmit}>
      <Card>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p
            className="heading3"
            style={{ margin: 0, marginBottom: 10 }}
          >
            {t('content.form.contactInformation')}
          </p>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                name="name"
                label={t('content.form.fullName')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="email"
                label={t('content.form.email')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <PhoneNumberInput
                {...form}
                style={{ width: '100%' }}
                country={companyDetails.country.toLowerCase()}
                label={t('content.form.mobile')}
                name="mobile"
                enableSearchField={true}
                searchPlaceholder={TEXT_SEARCH_COUNTRY}
                onChange={value => {
                  form.setFieldValue("mobile", value);
                }}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomizedDropdown
                {...form}
                mode="multiple"
                id="tags"
                name="tags"
                optionFilterProp="children"
                children={
                  tagList.map((item, index) => (
                    <Select.Option key={index} value={item}>
                      {item}
                    </Select.Option>
                  ))
                }
                label={t('content.form.tags')}
                onChange={value => {
                  // console.log('value',value);
                  form.setFieldValue("tags", value);
                }}
                onBlur={value => {
                  // console.log('value',value);
                }}
              />
            </Col>
          </Row>
          <Divider />
          <p className="heading3" style={{ margin: 0, marginBottom: 10 }}>
            {t('content.form.address')}
          </p>
          <Row>
            <Row gutter={16}>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="unitNo"
                  placeholder={t('content.form.unitNo')}
                  type="text"
                  style={{ marginBottom: 10 }}
                />
              </Col>
              <Col xs={24} md={24} lg={24}>
                <TextInput
                  {...form}
                  name="address1"
                  placeholder={t('content.form.address1')}
                  type="text"
                  style={{ marginBottom: 10 }}
                />
                <TextInput
                  {...form}
                  name="address2"
                  placeholder={t('content.form.address2')}
                  type="text"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <TextInput
                  {...form}
                  name="postcode"
                  onChange={(val) => {
                    setDisableSubmit(true);
                    form.setFieldValue('postcode', val.target.value);
                  }}
                  onBlur={
                    (val) => {
                      setPostcode(val.target.value);
                      setDisableSubmit(false);
                    }
                  }
                  label={t('content.form.postcode')}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <TextInput
                  {...form}
                  name="city"
                  label={t('content.form.city')}
                  type="text"
                  style={{ width: "100%" }}
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <TextInput
                  {...form}
                  name="state"
                  label={t('content.form.state')}
                  type="text"
                />
              </Col>
              <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                <CustomizedDropdown
                  {...form}
                  name="country"
                  optionFilterProp="children"
                  children={useGenerateDropdownItem(countryList, 'countryCode', 'countryCode', 'name')}
                  label={t('content.form.country')}
                />
              </Col>
            </Row>
          </Row>
        </div>
      </Card>
      <div style={{ float: "right", marginTop: 20, marginBottom: 20 }}>
        <CustomizedButton
          isDisabled={disableSubmit}
          text={t('content.header.save')}
          type="primary"
          htmlType="submit"
        />
      </div>
    </Form>
  )
}

export default ContactForm;
