import React from "react";
import { Row, Col } from "antd";


import ServiceInformation from "./Tables/ServiceInformation";
import Inventories from "./Tables/Inventories";
import Activities from "./Tables/Activities";
import CustomerInformation from "./Tables/CustomerInformation";
import ContactInformation from "./Tables/ContactInformation";
import Addons from './Tables/Addons';
import Note from './Tables/Notes';
import Rating from './Tables/Rating';

const OverviewContent = (props) => {
  return (
    <div>
      <Row gutter={20}>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <CustomerInformation selectedOrder={props.selectedOrder} />
        </Col>
        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
          <ServiceInformation selectedOrder={props.selectedOrder} />
        </Col>
      </Row>
      <ContactInformation
        selectedOrder={props.selectedOrder}
        isLoading={props.isLoading}
      />
      <Inventories
        selectedOrder={props.selectedOrder}
        isLoading={props.isLoading}
      />
      <Addons
        selectedOrder={props.selectedOrder}
        isLoading={props.isLoading}
      />
      <Note selectedOrder={props.selectedOrder} />
      <Rating selectedOrder={props.selectedOrder} />
      <Activities
        activitiesData={props.selectedOrder.activities}
        isLoading={props.isLoading}
      />
    </div>
  )
}

export default OverviewContent;
