import useLocalStorage from 'Util/useLocalStorage';
import { initialState } from 'Store/store';

const globalReducer = (state, action) => {
  switch (action.type) {
    case 'AUTH_INIT':
      return {
        ...state,
        isLoading: true
      };
    case 'AUTH_SUCCESS':
      return {
        ...state,
        isLoading: false,
        companyDetails: action.payload.companyDetails,
        availableServiceCompany: action.payload.availableServiceCompany,
        settings: action.payload.settings,
        multipointServices: action.payload.multipointServices,
        userDetails: action.payload.userDetails,
        countryList: action.payload.countryList,
        customerType: action.payload.customerType,
        industryType: action.payload.industryType,
        services: action.payload.services,
        serviceCompanies: action.payload.serviceCompanies,
        serviceGroups: action.payload.serviceGroups,
        itemTypes: action.payload.itemTypes,
        outlets: action.payload.outlets,
        paymentMethods: action.payload.paymentMethods,
        customerDetails: action.payload.customerDetails,
        customerId: action.payload.customerDetails?.id,
        allCustomerAccounts: action.payload.allCustomerAccounts,
        topupPlan: action.payload.topupPlan,
        authCheck: action.payload.authFinish,
        antdLangFile: action.payload.antdLangFile,
        lang: action.payload.lang,
        getStartedData: action.payload.getStartedData
      };
    case 'AUTH_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: false,
        authCheck: true
      };
    case 'SET_ERROR':
      return {
        ...state,
        isError: action.payload.isError,
        showError: action.payload.showError,
        isLoading: action.payload.isLoading,
        error: action.payload.error
      };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        authCheck: false
      }
    case 'SIGNUP_SUCCESS':
      return {
        ...state,
        authCheck: false,
        message: 'Registration Success. A verification link has been sent to you e-mail. Please check your e-mail (or spam folder).'
      }
    case 'LOGOUT':
      if (!useLocalStorage.getItem("remember")) {
        useLocalStorage.clear()
      } else {
        const username = useLocalStorage.getItem("username");
        const password = useLocalStorage.getItem("password");
        useLocalStorage.clear();
        useLocalStorage.setItem("remember", true);
        useLocalStorage.setItem("username", username);
        useLocalStorage.setItem("password", password);
      }

      return {
        ...initialState.global,
        userDetails: null,
        customerDetails: null,
        allCustomerAccounts: null,
        customerId: null
      }
    case 'ONBOARDING':
      return {
        ...state,
        customerDetails: action.payload.customerDetails
      }
    case 'UPDATE_PROFILE':
      let newState;
      if (action.payload.lang !== state.lang) {
        newState = { ...initialState.global }
      } else {
        newState = { ...state };
      }
      return {
        ...newState,
        lang: useLocalStorage.getItem("lang"),
        userDetails: action.payload.userDetails,
        customerDetails: action.payload.customerDetails,
        // allCustomerAccounts: action.payload.allCustomerAccounts
      }
    case 'CHANGE_ACCOUNT':
      useLocalStorage.setItem("customerId", action.payload.id);
      return {
        ...initialState.global
      }
    case 'GET_NEW_WALLET_BALANCE':
      const { payload } = action;
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          walletBalance: payload.walletBalance
        }
      }
    default:
      return state;
  }
};

export default globalReducer;
