import React, { useState } from "react";
import "App.css";
import { Tag, Table, Spin } from "antd";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  TEXT_ORDER_NO,
  TEXT_STATUS,
  TEXT_SCHEDULE_DATE,
  TEXT_ADDRESS,
  TEXT_RECIPIENT
} from "Util/Text";
import { capitalizeFirstLetter } from "Util/CustomFunctions";
import { orderStatusColor2 } from 'Util/orderStatusColor';
import ShowOn from 'Util/ShowOn';
import useWindowSize from 'Util/useWindowSize';

const columns = [
  {
    title: TEXT_ORDER_NO,
    dataIndex: "consignmentNo",
    key: "consignmentNo",
    width: 200,
    render: (consignmentNo, data) => {
      return (
        <Link
          style={{ width: 100 }}
          to={{
            pathname: `${process.env.PUBLIC_URL}/order/${data.id}`,
            state: { from: `${process.env.PUBLIC_URL}/orders/bulk-orders` }
          }}
        >
          {consignmentNo || "N/A"}
        </Link>
      );
    }
  },
  {
    title: TEXT_STATUS,
    dataIndex: "statusCode",
    key: "status",
    width: 200,
    render: (text, record) => {
      return (
        <Spin spinning={record.status === 'processing' || record.status === 'pending_payment'}>
          <ShowOn on={record.status !== 'processing'}>
            <div>
              <Tag color={orderStatusColor2(text).color}>
              <span>{capitalizeFirstLetter(orderStatusColor2(text).text)}{record.failedReason && ', ' + record.failedReason}</span>
              </Tag>
            </div>
          </ShowOn>
        </Spin>
      );
    }
  },
  {
    title: TEXT_SCHEDULE_DATE,
    width: 200,
    dataIndex: "waypoint",
    key: "scheduledAt",
    render: (data) => {
      const selectedWaypoint = data.filter((item) => item.type === 'PICKUP');
      if (selectedWaypoint.length > 0) {
        const { scheduledAt } = selectedWaypoint[0];
        return (
          <div>
            {scheduledAt ? moment(scheduledAt).format("Do MMM YYYY, h:mm a") : 'N/A'}
          </div>
        )
      } else return null;
    }
  },
  {
    title: TEXT_RECIPIENT,
    dataIndex: "waypoint",
    key: "contactName",
    width: 110,
    render: (data) => {
      const selectedWaypoint = data.filter((item) => item.type === 'DROPOFF');
      if (selectedWaypoint.length > 0) {
        const { contact } = selectedWaypoint[0];
        return (
          <div>{contact.name}</div>
        )
      } else return null;
    }
  },
  {
    title: TEXT_ADDRESS,
    width: 350,
    dataIndex: "waypoint",
    key: "address",
    render: (data) => {
      const selectedWaypoint = data.filter((item) => item.type === 'DROPOFF');
      if (selectedWaypoint.length > 0) {
        const { contact } = selectedWaypoint[0];
        const address = {
          unitNo: contact.unitNo,
          address1: contact.address1,
          address2: contact.address2,
          postcode: contact.postcode,
          city: contact.city,
          country: contact.country
        }
        const addressArr = [];
        Object.values(address).forEach((item, i) => {
          item && typeof item === 'string' && item.length > 0 && item !== '-' && addressArr.push(item);
        });

        return (
          <div>
            {addressArr.join(', ')}
          </div>
        )
      } else return null;
    }
  },
]

const Orders = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const size = useWindowSize();

  return (
    <div>
      <Table
        onChange={pagination => setCurrentPage(pagination.current)}
        dataSource={props.dataSource}
        columns={columns}
        rowKey={record => record.id}
        loading={props.isLoading}
        scroll={{ x: size[0] < 1500 && size[0] }}
        pagination={{
          current: currentPage,
          defaultPageSize: 10,
          showSizeChanger: false,
          showTotal: (total, range) =>
            `${range[1]} of ${total} order(s)`,
          total: props.dataSource.length
        }}
        style={{ maxHeight: "60%", backgroundColor: 'white' }}
      />
    </div>
  )
}

export default Orders;
