import React, { useState } from 'react';
import { message } from 'antd';
import { post, remove } from 'Util/API';
import { Radio, Button, Select, Divider, DatePicker } from 'antd';

import moment from "moment";

const { Option } = Select;

const DialogContent = ({ onOk, onOkData, onCancel }) => {
  const [cancelReason, setCancelReason] = useState('Mistake')
  return (
    <div>
      <div style={{ marginBottom: 10 }}>Cancellation reason:</div>
      <Radio.Group style={{ display: 'flex', flexDirection: 'column' }} onChange={(e) => setCancelReason(e.target.value)} value={cancelReason}>
        <Radio value={'Mistake'}>Mistake</Radio>
        <Radio value={'Duplicate'}>Duplicate</Radio>
        <Radio value={'Late'}>Late</Radio>
        <Radio value={'No collection'}>No collection</Radio>
        <Radio value={'No service'}>No service</Radio>
        <Radio value={'System error'}>System error</Radio>
        <Radio value={'Other'}>Other</Radio>
      </Radio.Group>
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
        <Button style={{ marginRight: 10 }} onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={() => onOk({ ...onOkData, cancelReason })}>Submit</Button>
      </div>
    </div>
  )
}

const RejectDialogContent = ({ rejectReasons, onOk, onOkData, onCancel }) => {

  const [rejectDriverData, setRejectDriverData] = useState({
      reason: ''
  });

  const handleChange = (field) => (value) => {
    return setRejectDriverData({ ...rejectDriverData, [field]: value });
  }

  let rejectReasonOption = [];

  for (var key in rejectReasons) {
      rejectReasonOption.push(
          <Option key={key} value={key}>
              {rejectReasons[key]}
          </Option>
      );
  }

  return (
    <div>
      <div style={{ marginBottom: 10 }}>Rejection reason:</div>

      <Select
          defaultValue={rejectDriverData.reason}
          style={{ display: 'flex', flexDirection: 'column' }}
          onChange={handleChange('reason')}
          value={rejectDriverData.reason}
          >
          <Option value="">(Rejection Reason)</Option>
          {rejectReasonOption}
      </Select>

      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
          <Button style={{ marginRight: 10 }} onClick={onCancel}>Cancel</Button>
          <Button type="primary" onClick={() => onOk({ ...onOkData, rejectDriverData })}>Submit</Button>
      </div>
    </div>
  )
}

const CloneDialogContent = ({ services, onOk, onOkData, onCancel }) => {

  const [cloneOrderData, setCloneOrderData] = useState({
      serviceCode: '',
      scheduledAt: new Date().toISOString(),
      note: '',
  });

  let servicesOption = [];

  for(var i=0; i < services.length; i++) {
      servicesOption.push(
          <Option key={services[i].code} value={services[i].code}>
              {services[i].name} - {services[i].price.currency} {services[i].price.amount}
          </Option>
      );
  }

  const handleChange = (field) => (value) => {
    return setCloneOrderData({ ...cloneOrderData, [field]: value });
  }

  const handleDateTextChange = (field) => (event) => {
    let value = event.target.value;
    return setCloneOrderData({ ...cloneOrderData, [field]: value });
  }

  const handleDateTimeChange = (field) => (value) => {
    const formattedDate = moment(value).format("YYYY-MM-DD HH:mm:ss");
    return setCloneOrderData({ ...cloneOrderData, [field]: formattedDate });
  }

  return (
    <div>
      <div style={{ marginBottom: 10 }}>Service:</div>
      <Select
          defaultValue={cloneOrderData.serviceCode}
          style={{ display: 'flex', flexDirection: 'column' }}
          onChange={handleChange('serviceCode')}
          value={cloneOrderData.serviceCode}
          >
          <Option value="">(Same Service)</Option>
          {servicesOption}
      </Select>
      <Divider />
      <div style={{ marginBottom: 10 }}>Date & Time:</div>
       <DatePicker
          showTime
          style={{ width: "100%" }}
          format={"YYYY-MM-DD HH:mm:ss"}
          defaultValue={moment()}
          onChange={handleDateTimeChange('scheduledAt')}
          onOk={handleDateTimeChange('scheduledAt')}
       />
      {/**<Divider />
      <div style={{ marginBottom: 10 }}>Notes:</div>
      <Input
        type="text" onChange={handleDateTextChange('note')} onOk={handleDateTextChange('note')}
      />**/}
      <Divider />
      <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: 10 }}>
        <Button style={{ marginRight: 10 }} onClick={onCancel}>Cancel</Button>
        <Button type="primary" onClick={() => onOk({ ...onOkData, cloneOrderData })}>Submit</Button>
      </div>
    </div>
  )
}

export const onOpenCancelOrderModal = (props) => {
  const { id, setIsLoading, setModalState, selectedRowsIds, resetState, ordersTableData, modalState, history, transl } = props;

  setModalState({
    ...modalState,
    isModalVisible: true,
    footer: false,
    modal: {
      title: transl('content.dialog.cancelOrder.title'),
      dialogContent: () => {
        return (
          <DialogContent
            onCancel={
              () => setModalState({
                ...modalState,
                isModalVisible: false
              })
            }
            onOk={props.onConfirmCancelOrder || onConfirmCancelOrder}
            onOkData={{
              id,
              setIsLoading,
              setModalState,
              selectedRowsIds,
              resetState,
              ordersTableData,
              modalState,
              history,
              transl
            }}
          />
        )
      }
    }
  });
};

export const onConfirmCancelOrder = async (props) => {
  const { id, setIsLoading, setModalState, selectedRowsIds, resetState, ordersTableData, modalState, history, transl, cancelReason } = props;
  setIsLoading(true);
  setModalState({
    isModalVisible: false,
    modal: {
      title: null,
      onOk: null,
      onCancel: null,
      text: null
    }
  })
  const isOneOrder = id || selectedRowsIds?.length === 1;
  const selectedRowsIdsString = id || selectedRowsIds.join();
  const orderResponse = await post("order/" + selectedRowsIdsString + "/cancel", { reason: cancelReason });
  if (orderResponse.status === 200) {
    ordersTableData && resetState({ status: ordersTableData.status, data: null });
    history.replace({
      ...history.location,
      state: {
        showMsg: {
          type: 'success',
          message: transl(`content.dialog.confirmCancel.message.${isOneOrder ? 'single' : 'multiple'}`)
        }
      }
    })
  } else message.error(orderResponse);
  setIsLoading(false);
  setModalState({
    ...modalState,
    isLoading: false,
    isModalVisible: false
  });
};

export const onOpenRejectDriverModal = (props) => {
  const { id, services, setIsLoading, setModalState, selectedRowsIds, resetState, ordersTableData, modalState, history, transl, rejectReasons } = props;

  setModalState({
    ...modalState,
    isModalVisible: true,
    footer: false,
    modal: {
      title: transl('content.dialog.rejectDriver.title'),
      dialogContent: () => {
        return (
          <RejectDialogContent
            rejectReasons={rejectReasons}
            onCancel={
              () => setModalState({
                ...modalState,
                isModalVisible: false
              })
            }
            onOk={props.onConfirmRejectDriver}
            onOkData={{
              id,
              setIsLoading,
              setModalState,
              selectedRowsIds,
              resetState,
              ordersTableData,
              modalState,
              history,
              transl
            }}
          />
        )
      }
    }
  });
};

export const onOpenFulfilOrderModal = (props) => {
  const { id, services, setIsLoading, setModalState, selectedRowsIds, resetState, ordersTableData, modalState, history, transl } = props;

  setModalState({
    ...modalState,
    isModalVisible: true,
    footer: false,
    modal: {
      title: transl('content.dialog.confirmOrder.title.single'),
      dialogContent: () => {
        return (
          <CloneDialogContent
            services={services}
            onCancel={
              () => setModalState({
                ...modalState,
                isModalVisible: false
              })
            }
            onOk={props.onConfirmFulfilOrder}
            onOkData={{
              id,
              setIsLoading,
              setModalState,
              selectedRowsIds,
              resetState,
              ordersTableData,
              modalState,
              history,
              transl
            }}
          />
        )
      }
    }
  });
};

export const onOpenCloneOrderModal = (props) => {
  const { id, services, setIsLoading, setModalState, selectedRowsIds, resetState, ordersTableData, modalState, history, transl } = props;

  setModalState({
    ...modalState,
    isModalVisible: true,
    footer: false,
    modal: {
      title: transl('content.dialog.cloneOrder.title'),
      dialogContent: () => {
        return (
          <CloneDialogContent
            services={services}
            onCancel={
              () => setModalState({
                ...modalState,
                isModalVisible: false
              })
            }
            onOk={props.onConfirmCloneOrder}
            onOkData={{
              id,
              setIsLoading,
              setModalState,
              selectedRowsIds,
              resetState,
              ordersTableData,
              modalState,
              history,
              transl
            }}
          />
        )
      }
    }
  });
};

export const onOpenOrderSuccessModal = (props) => {
  const { ids, setModalState, modalState, transl } = props;
  setModalState({
    ...modalState,
    isModalVisible: true,
    footer: false,
    modal: {
      ...modalState.modal,
      type: 'success',
      title: transl('content.dialog.orderSuccess.title'),
      text: ids.join(', ')
    }
  });
}

export const onOpenConfirmOrderModal = async (props) => {
  const { id, selectedRowsIds, setModalState, modalState, resetTableState, transl, allSelectedRows } = props;
  const isOneOrder = id || selectedRowsIds?.length === 1;
  const { default: dialogContent } = await import(`Pages/Order/BulkOrder/ConfirmOrderDialog.js`);

  setModalState({
    ...modalState,
    isModalVisible: true,
    footer: false,
    modal: {
      title: transl(`content.dialog.confirmOrder.title.${isOneOrder ? 'single' : 'multiple'}`),
      dialogContent,
      maxWidth: 1200,
      dialogContentProps: {
        match: props.match,
        location: props.location,
        history: props.history,
        orderIds: id ? [id] : selectedRowsIds,
        allSelectedRows,
        onOpenOrderSuccessModal,
        onCloseModal: () => {
          setModalState({ ...modalState, isModalVisible: false });
          resetTableState({ data: null });
        }
      },
    }
  });
}
