import React, { useState, useEffect, useContext } from "react";
import { Table, Popover, message } from "antd";
import { useTranslation } from "react-i18next";

import { GlobalContext } from "Store/store";

import CustomizedButton from "Components/CustomizedButton";
import { post } from 'Util/API';

const columns = ({ setModalState, modalState, onRevokeApi, transl }) => [
  {
    title: transl('content.integration.tableColumn.name'),
    dataIndex: "name",
    width: 500,
    key: "name",
    render: (text, record) => (
      <div>{text}</div>
    )
  },
  {
    title: transl('content.integration.tableColumn.key.name'),
    width: 300,
    key: 'api_key',
    render: (text, record) => (
      <Popover content={transl('content.integration.tableColumn.key.content')}>
        <span><i class="fas fa-key" />{' ****************'}</span>
      </Popover>
    )
  },
  {
    title: transl('content.integration.tableColumn.action'),
    width: 100,
    key: "actions",
    fixed: window.innerWidth < 768 && "right",
    render: (text, record, index) => (
      <div style={{ display: "flex", alignItems: "center" }}>
        <CustomizedButton
          text={transl('content.integration.dialog.revoke.name')}
          onClick={
            () => {
              setModalState({
                ...modalState,
                isModalVisible: true,
                modal: {
                  title: transl('content.integration.dialog.revoke.title'),
                  text: <span>{transl('content.integration.dialog.revoke.text1')}<span style={{ fontWeight: 700 }}>{record.name}</span>{transl('content.integration.dialog.revoke.text2')}</span>,
                  onOk: () => onRevokeApi(index, record.id)
                }
              })
            }
          }
        />
      </div>
    )
  }
]

const AddressBookTableConfig = props => {
  const { setIsLoading } = props;
  const [state] = useContext(GlobalContext);
  const { t } = useTranslation('settingsPage');
  const { modalState, setModalState, resetModalState } = state.global;
  const [dataSource, setDataSource] = useState(null);

  useEffect(() => {
    setDataSource(props.transactionData)
  }, [props.transactionData])

  const onRevokeApi = async (index, id) => {
    setIsLoading(true);
    const fetch = await post(`/auth/personalAccessToken/${id}/revoke`);
    if (fetch.status === 200) {
      resetModalState();
      const newArr = [...dataSource];
      newArr.splice(index, 1)
      setDataSource(newArr);
    } else message.error(fetch);
    setIsLoading(false)
  }

  return (
    <Table
      dataSource={dataSource}
      columns={columns({ setModalState, modalState, onRevokeApi, transl: t })}
      rowKey={record => record.id}
      loading={props.isLoading}
      scroll={{ x: window.innerWidth < 768 && 400 }}
      style={{ maxHeight: "60%" }}
    />
  );
};

export default AddressBookTableConfig;
