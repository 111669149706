import React, { useState } from 'react';

const LimitText = ({ string, ...props }) => {
  const originalText = string;
  const originalCharacterLimit = props.maxChar;
  const enableLimitFeatures = originalText.length > originalCharacterLimit;
  const onLimitCharacter = () => {
    const originalStringArray = originalText.split('');
    const newStringArray = [];
    for (let i = 0; i < originalCharacterLimit; i += 1) {
      newStringArray.push(originalStringArray[i]);
    }
    return newStringArray.join('')
  }
  const [currentText, setCurrentText] = useState(enableLimitFeatures ? onLimitCharacter() : originalText);

  return (
    <div>
      <span dangerouslySetInnerHTML={{ __html: currentText + (enableLimitFeatures && currentText.length < originalText.length ? '...' : '') }} />
      {
        enableLimitFeatures &&
        <span
          style={{ color: 'blue', cursor: 'pointer', fontSize: 13 }}
          onClick={
            () => {
              if (currentText.length > originalCharacterLimit) {
                const limitString = onLimitCharacter();
                setCurrentText(limitString)
              } else {
                setCurrentText(originalText)
              }
            }
          }
        >
          {currentText.length > originalCharacterLimit ? ' (less)' : ' (more)'}
        </span>
      }
    </div>
  )
}

export default LimitText;
