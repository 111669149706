import React, { useContext, useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Layout, Form, Card, Spin, message } from "antd";
import { Link } from "react-router-dom";

import { GlobalContext } from 'Store/store';
import TextInput from "Components/TextInput";
import CustomizedButton from "Components/CustomizedButton";
import { IMAGE_URL_ROOT, post } from 'Util/API';

const VerifyAccount = (props) => {
  const [state] = useContext(GlobalContext);
  const searchParams = window.location.search;
  const urlParams = new URLSearchParams(searchParams);
  const { companyDetails } = state.global;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const username = urlParams.get('username');
    const code = urlParams.get('code');
    const companyId = urlParams.get('companyId');
    if (username && code && companyId) {
      setIsLoading(true);
      onVerifyAcc({ username, code, companyId })
    }
  }, []);

  const onVerifyAcc = async (data) => {
    const fetch = await post("auth/verify", { companyId: companyDetails.id, ...data })
    if (fetch.status === 200) {
      props.history.push({
        pathname: `${process.env.PUBLIC_URL}/login`,
        state: {
          showMsg: {
            type: 'success',
            time: 7,
            message: 'Verification successfull. Please login.'
          }
        }
      })
    } else {
      message.error(fetch);
      setIsLoading(false);
    };
  }

  const onSubmit = async (data) => {
    setIsLoading(true)
    onVerifyAcc(data);
    setIsLoading(false)
  }

  return (
    <Spin spinning={isLoading}>
      <Layout style={{ minHeight: "100vh" }}>
        <div className="center">
          <div className="logo-company">
            <img
              style={{ height: 64, margin: '0 20px' }}
              src={
                companyDetails.logob ?
                  IMAGE_URL_ROOT + companyDetails.logob :
                  'https://cdn.delyva.app/assets/delyvax-logo-b.svg'
                }
              alt="company logo"
            />
          </div>
          <p
            className="heading2"
            style={{ textAlign: "center", fontWeight: 400 }}
          >
            Verify your account
          </p>
          <Card>
            <Formik
              enableReinitialize
              validateOnChange={false}
              validateOnBlur={false}
              initialValues={{
                username: null,
                code: null
              }}
              validationSchema={Yup.object().shape({
                username: Yup.string().nullable().required("Username / Email / Phone number is required"),
                code: Yup.string().nullable().required("Verification code is required")
              })}
              onSubmit={onSubmit}
            >
              {form => (
                <Form onSubmit={form.handleSubmit} className="login-form">
                  <TextInput
                    {...form}
                    name="username"
                    placeholder="Username / Email / Phone number"
                    type="text"
                    className="marginBottom-10"
                  />
                  <TextInput
                    {...form}
                    name="code"
                    placeholder="Code"
                    type="text"
                    className="marginBottom-10"
                  />
                  <CustomizedButton
                    type="primary"
                    htmlType="submit"
                    isBlockType={true}
                    className="login-form-button"
                    text="Submit"
                    isLoading={isLoading}
                  />
                </Form>
              )}
            </Formik>
          </Card>
          <p className="footer-link">
            <Link to={`${process.env.PUBLIC_URL}/login`}>
              <CustomizedButton
                type="link"
                text="Sign in"
              />
            </Link>
          </p>
        </div>
      </Layout>
    </Spin>
  )
}

export default VerifyAccount;
