import React, { useState, useContext, useEffect } from "react";
import { Layout, message } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import PageWrapper from 'Components/PageWrapper';
import { GlobalContext } from 'Store/store';
import { post } from 'Util/API';

import AfterPaymentDialogContent from './AfterPayment/AfterPaymentDialog';
import MultiPointOrderForm from './MultiPointOrderForm';
import submitOrder from './submitOrder';

import { mixPanelTrack } from "Util/CustomFunctions";

const { Content } = Layout;

const formik = {
  validationSchema: Yup.object().shape({
    paymentMethodId: Yup.number().nullable().required('Payment method is required'),
    waypoint: Yup
    .array()
    .of(
      Yup.object().shape({
        name: Yup.string().nullable()
          .when("location", {
            is: (val) => val?.length > 0,
            then: Yup.string().required('Name is required.')
          }),
        phone: Yup.string().nullable()
          .when("location", {
            is: (val) => val?.length > 0,
            then: Yup.string().required('Phone no is required.')
          }),
        location: Yup.array()
      })
    )
  })
};

const getOrderDetailsFromSource = (orderDetails, services) => {
  if (orderDetails) {
    const serviceIndex = services.findIndex((item) => item.companyCode === orderDetails.serviceCode);
    const selectedServiceIndex = serviceIndex > 0 ? serviceIndex : 0;
    return {
      notes: orderDetails.note,
      vehicleType: [
        selectedServiceIndex,
        services[selectedServiceIndex]
      ]
    }
  }
  return null;
}

const MultiPointOrder = (props) => {
  const { match, location, history } = props;
  const [state] = useContext(GlobalContext);
  const { multipointServices, modalState, setModalState, customerDetails } = state.global;
  const [isLoading, setIsLoading] = useState(true);
  const [priceLoading, setPriceLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState('Loading...');
  const start = moment();
  const remainder = 30 - (start.minute() % 30);
  const pickupTime = moment(start).add(remainder + 0, "minutes")

  let filteredMultipointServices = multipointServices;

  if(customerDetails.selectedServices)
  {
      if(customerDetails.selectedServices.length > 0)
      {
          for(let i=0; i < multipointServices.length; i++)
          {
              if( !customerDetails.selectedServices.includes(multipointServices[i].id) )
              {
                  multipointServices.splice(i, 1);
              }
          }

          filteredMultipointServices = [];
          for(let i=0; i < multipointServices.length; i++)
          {
              if(multipointServices[i])
              {
                  filteredMultipointServices[i] = multipointServices[i];
              }
          }
      }
  }

  const sortedMultipointServices = filteredMultipointServices.sort(function(a, b){return a.maxWeight.value - b.maxWeight.value});
  const orderDetailsFromSource = getOrderDetailsFromSource(location.state?.orderDetails, sortedMultipointServices);

  const initialVal = {
    pickupDate: moment(),
    pickupTime,
    pickupNow: true,
    notes: null,
    promoCode: "",
    cod: false,
    codValue: 0.00,
    insurance: false,
    returnTrip: false,
    serviceDetails: null,
    errors: [],
    addon: {},
    waypoint: [{}],
    paymentMethodId: null,
    vehicleType: [
      0,
      sortedMultipointServices[0]
    ],
    ...orderDetailsFromSource
  }
  const [formInitialVal, setFormInitialVal] = useState(initialVal);
  const [currentStep, setCurrentStep] = useState('selectWaypoint');

  const onCloseAfterPaymentDialog = () => {
    setModalState({ ...modalState, isModalVisible: false })
    props.history.replace(`${process.env.PUBLIC_URL}/orders/multipoint`, { orderId: null });
  }

  const onCancelOrder = async ({ id, setModalState, modalState, cancelReason }) => {
    setModalState({
      ...modalState,
      isModalVisible: false
    });
    setIsLoading(true)
    const cancelOrder = await post("order/" + id + "/cancel", { reason: cancelReason });
    if (cancelOrder.status === 200) {
      message.success('Order cancelled.')
    } else {
      message.error(cancelOrder)
    }
    setIsLoading(false);
  }

  const resetState = props.location.state?.resetState;
  useEffect(() => {
    if (resetState) {
      setIsLoading(false);
      setLoadingMsg(null);
      setFormInitialVal(initialVal);
      setCurrentStep('selectWaypoint');
      props.history.replace(`${process.env.PUBLIC_URL}/orders/multipoint`, { resetState: false });
      if (!!props.location.state?.orderId) {
        setModalState({
          ...modalState,
          maskStyle: { backdropFilter: 'blur(4px)' },
          isModalVisible: true,
          footer: false,
          modal: {
            onCancel: onCloseAfterPaymentDialog,
            dialogContent: () => (
              <AfterPaymentDialogContent
                match={match}
                location={location}
                history={history}
                onNextOrder={onCloseAfterPaymentDialog}
                onCancelOrder={onCancelOrder}
              />
            )
          }
        });
      }
    }
  }, [resetState])

  useEffect(() => {
    mixPanelTrack('ViewNewMultipointOrder');
  }, []);

  return (
    <PageWrapper loading={isLoading} loadingMsg={loadingMsg} currentPath={props.location} isCollapseSidebar={true}>
      <Content>
        <Formik
          {...formik}
          enableReinitialize={true}
          validateOnChange={false}
          validateOnBlur={false}
          onSubmit={(data) => {
            setIsLoading(true);
            const dataToSubmit = {
              state,
              publicUrl: process.env.PUBLIC_URL,
              history: props.history,
              closeLoading: () => setIsLoading(false),
              setLoadingMsg,
              ...data
            }
            submitOrder(dataToSubmit)
          }}
          initialValues={formInitialVal}
        >
          {
            form => (
              <MultiPointOrderForm
                form={form}
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
                multipointServices={sortedMultipointServices}
                priceLoading={priceLoading}
                setPriceLoading={setPriceLoading}
                setIsLoading={setIsLoading}
                handleOpenModal={setModalState}
                modalState={modalState}
                handleCloseModal={() => setModalState({
                  ...modalState,
                  isModalVisible: false
                })}
              />
            )
          }
        </Formik>
      </Content>
    </PageWrapper>
  )
}


export default MultiPointOrder;
