import { useEffect, useState, useContext } from 'react';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

// import mixpanel from 'mixpanel-browser';

import { GlobalContext } from 'Store/store';
import capitalizeFirstLetter from 'Util/capitalizeFirstLetter'

import useOnUpdateOrder from './useOnUpdateOrder';

const useAfterPayment = (props) => {
  const { match, location, history, onCancelOrder, onNextOrder } = props;
  const [state] = useContext(GlobalContext);
  const { companyDetails, settings } = state.global;
  const orderId = location.state?.orderId || match.params.orderId;
  const { message } = state.sse;
  const [showContent, setShowContent] = useState(false);
  const [currentOrder, setCurrentOrder] = useState({ id: orderId });
  const [customText, setCustomText] = useState('Processing..');
  const [sentPurchaseEvent, setSentPurchaseEvent] = useState(false);
  const fbPixelId = settings["consumer_portal.dashboard"]?.data?.scripts_fb_pixelid;
  const googleAnalyticsPixelId = settings["consumer_portal.dashboard"]?.data?.scripts_google_analyticid;

  const updatedOrder = useOnUpdateOrder(orderId, message);
  useEffect(() => {
    if (updatedOrder) {
      setCurrentOrder(updatedOrder);
      setShowContent(true);
      if (updatedOrder?.statusCode === 100) {
        setCustomText('Waiting for rider to accept..')
        setSentPurchaseEvent(true)
      } else if (updatedOrder?.statusCode === 200) {
        setCustomText('Rider has accept your order.')
        setSentPurchaseEvent(true)
      } else {
        setCustomText(capitalizeFirstLetter(updatedOrder?.status))
      }
    }
  }, [updatedOrder])

  useEffect(() => {
    if (sentPurchaseEvent) {
      if (fbPixelId) {
        // ReactPixel.track(
        //   'Order',
        //   {
        //     value: updatedOrder.price.amount,
        //     currency: updatedOrder.price.currency
        //   }
        // )
      }
      if(googleAnalyticsPixelId)
      {
          ReactGA.event({
              category: 'Conversion',
              action: 'Order',
              label: 'Instant Order',
              value: Number(updatedOrder.price.amount)
          });
      }
    }
  }, [sentPurchaseEvent])

  return {
    showContent,
    currentOrder,
    history,
    customText,
    onNextOrder,
    onCancelOrder
  }
}

export default useAfterPayment;
