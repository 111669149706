import React, { useEffect } from 'react';

const TrackingMap = (props) => {

  useEffect(() => {
    window.open(window.location.origin + '/track/rmap' + props.location.search, '_self')
    // window.open('https://my.delyva.app/track/rmap' + props.location.search, '_self')
  }, [])

  return <div />
}

export default TrackingMap;
