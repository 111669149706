import React from "react";
import { useTranslation } from "react-i18next";
import { Divider, Form, Input, message } from "antd";
import { Formik } from "formik";

import CustomizedButton from "Components/CustomizedButton";
import { post } from 'Util/API'
import withStore from 'Util/withStore'

const formik = {
  initialValues: {
    promoCode: ""
  }
};

const ConfirmationStep = (props) => {
  const { global: { customerDetails, companyDetails, footerWidth, modalState, setModalState } } = props.state;
  const { t } = useTranslation('topupPage');

  const submitTopupSummary = async values => {
    const params = {
      customerId: customerDetails.id,
      amount: props.selectedTopupAmount,
      paymentMethodId: props.paymentInfo.id,
      promoCode: values.promoCode
    }
    props.setLoading(true);
    const topup = await post("topup/buy", params)
    if (topup.status === 200) {
      if (!topup.data.data.paymentUrl) {
        setModalState({
          ...modalState,
          isModalVisible: true,
          footer: false,
          modal: {
            ...modalState.modal,
            dialogContent: () => <div dangerouslySetInnerHTML={{ __html: topup.data.data.paymentDetails }} />,
            title: 'Payment details'
          }
        })
        props.setLoading(false);
      } else {
        window.location.href = topup.data.data.paymentUrl + window.location.origin + process.env.PUBLIC_URL + "/payment/" + topup.data.data.invoiceId;
        // const win = window.open(topup.data.data.paymentUrl + window.location.origin + `${process.env.PUBLIC_URL}/payment/${topup.data.data.invoiceId}`, '_blank');
        // win.focus();
      }
    } else {
      message.error(topup);
      props.setLoading(false);
    }
  };

  return (
    <div style={{ width: footerWidth, maxWidth: 700, padding: '0 20px' }}>
      <p className="heading3">{t('content.topupConfirmation.topupSummary')}</p>
      <Formik {...formik} onSubmit={submitTopupSummary}>
        {form => (
          <Form onSubmit={form.handleSubmit}>
            <div style={{ backgroundColor: "white", marginBottom: "5%" }}>
              <div style={{ padding: "5% 5% 0%" }}>
                <p style={{ marginBottom: 0 }}>{t('content.topupTabs.paymentMethod')}</p>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p style={{ margin: 0 }}>{props.paymentInfo.name}</p>
                  <CustomizedButton
                    style={{ marginLeft: 15 }}
                    onClick={props.clickPrevious}
                    text={t('header.change')}
                  />
                </div>
              </div>
              <Divider />
              <div style={{ padding: "0% 5%" }}>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>{companyDetails.currency + ' ' + props.selectedTopupAmount} {t('content.topupConfirmation.credit')}</div>
                  <div>
                    <b>{companyDetails.currency + ' ' + props.selectedTopupAmount}</b>
                  </div>
                </div>
              </div>
              <Divider />
              <div
                style={{
                  padding: "0% 5% 2%",
                  display: "flex",
                  justifyContent: "space-between"
                }}
              >
                <div>
                  <b>{t('content.topupTabs.total')}</b>
                </div>
                <div>
                  <b>{companyDetails.currency + ' ' + props.selectedTopupAmount}</b>
                </div>
              </div>
              <Divider />
              <div
                style={{
                  padding: "0% 5% 2%"
                }}
              >
                <p>Have a discount code?</p>
                <Input
                  placeholder="Discount code"
                  onChange={val => form.setFieldValue('promoCode', val.target.value)}
                  style={({ top: 0, marginBottom: 10 })}
                />
              </div>
            </div>
            <Form.Item>
              <CustomizedButton
                htmlType="submit"
                text={t('content.topupConfirmation.confirmPayment')}
                isBlockType
                className="button-success"
              />
            </Form.Item>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default withStore(ConfirmationStep);
