import React, { useContext, useState, useEffect } from "react";
import { Card, Form, Row, Col, DatePicker, Spin, Divider, Select, message } from "antd";
import { Formik } from "formik";
import moment from 'moment';
import languages from 'languages.json';

// import "App.css";
import { update, get } from "Util/API";
import useGenerateAddress from 'Util/useGenerateAddress';
import useGenerateDropdownItem from 'Util/useGenerateDropdownItem';
import { GlobalContext } from "Store/store";
import TextInput from "Components/TextInput";
import CustomizedDropdown from "Components/input/LabelledDropdown";
import CustomFormItem from 'Components/CustomFormItem';
import CustomizedButton from "Components/CustomizedButton";
import useLocalStorage from 'Util/useLocalStorage';
import { useTranslation } from "react-i18next";

import { mixPanelTrack } from "Util/CustomFunctions";

const Option = Select.Option;

const FetchWrapper = (props) => {
  const [fetchItems, setFetchItems] = useState(null);

  async function allFetch() {
    const getIndustryType = get("/parameters/industry");

    return {
      getIndustryType: await getIndustryType
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const fetchAll = await allFetch();
      const { getIndustryType } = fetchAll;
      if (getIndustryType.status === 200) {
        setFetchItems({
          industryType: getIndustryType.data.data
        })
      } else message.error("Request time out. Please try again.")
    }
    fetch()
  }, [])

  return fetchItems
    ? <ContactForm wrapper={{ ...fetchItems }} {...props} />
    : <Spin spinning={!fetchItems}><div style={{ width: '100%', height: 200 }} /></Spin>
}

const ContactForm = (props) => {
  const { isLoading, setIsLoading } = props;
  const [state, dispatch] = useContext(GlobalContext);
  const { t } = useTranslation('settingsPage');
  const { industryType } = props.wrapper;
  const { lang, userDetails, customerDetails, countryList, companyDetails, allCustomerAccounts } = state.global;
  const defaultIndustry = industryType.find((item) => item === customerDetails.industry);
  const defaultCountryCode = customerDetails.country || companyDetails.country;
  const selectedCountryData = countryList.filter((item) => (
    item.countryCode === defaultCountryCode
  ));
  const checkDob = customerDetails.dob?.length > 0 && customerDetails.dob;
  const dob = new Date(checkDob)
  const dateFormat = 'DD-MM-YYYY';
  const formattedDate = moment(dob, dateFormat).format(dateFormat)

  const onSubmit = async (values) => {
    !isLoading && setIsLoading(true);
    const dobToSubmit = moment(values.dob, dateFormat).format('YYYY-MM-DD');
    values.dob = dobToSubmit;
    const { lang, ...rest } = values;
    const customerData = { ...rest, id: customerDetails.id, companyId: companyDetails.id };

    const updateCustomerDetails = await update("customer", customerData)
    if (updateCustomerDetails.status === 200) {
      useLocalStorage.setItem("lang", lang);
      const getCustomerDetails = await get(`/customer/${customerDetails.id}?companyId=${companyDetails.id}&retrieve=branding`);
      if (getCustomerDetails.status === 200) {
        const newCustomersAccount = allCustomerAccounts.slice(0);
        const currentCustomerDetailsIndex = newCustomersAccount.findIndex((item) => item.id === updateCustomerDetails.data.data.id);
        newCustomersAccount[currentCustomerDetailsIndex] = updateCustomerDetails.data.data;
        dispatch({
          type: "UPDATE_PROFILE",
          payload: {
            lang,
            userDetails,
            allCustomerAccounts: newCustomersAccount,
            customerDetails: getCustomerDetails.data.data,
          }
        })
        message.success(t('content.profileInfo.update'))
      } else message.error(getCustomerDetails);
    } else message.error(updateCustomerDetails);

    setIsLoading(false);
  }

  useEffect(() => {
    mixPanelTrack('ViewSettingsProfile');
  }, []);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: customerDetails.name,
        phone: customerDetails.phone,
        email: customerDetails.email,
        unitNo: customerDetails.unitNo ? customerDetails.unitNo : "",
        address1: customerDetails.address1,
        address2: customerDetails.address2,
        postcode: customerDetails.postcode,
        city: customerDetails.city,
        country: selectedCountryData[0]?.countryCode,
        state: customerDetails.state,
        type: customerDetails.type,
        identityNo: customerDetails.identityNo,
        dob: formattedDate,
        industry: defaultIndustry,
        websiteUrl: customerDetails.websiteUrl,
        defaultLabelType: customerDetails.defaultLabelType || 'A4-3',
        priceQuotePref: customerDetails.priceQuotePref || 'default',
        lang
      }}
      onSubmit={onSubmit}
    >
      {form => (
        <ProfileInformation
          form={form}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
          countryList={countryList}
          industryType={industryType}
          dob={dob}
          dateFormat={dateFormat}
        />
      )}
    </Formik>
  )
}

const labelType = ['A4-3', 'A4-Q4', 'A5-2', 'A6-1'];

const priceQuotePrefOption = [];
priceQuotePrefOption.push(<Option key="default" value="default">Default</Option>);
priceQuotePrefOption.push(<Option key="cheapest" value="cheapest">Cheapest</Option>);
priceQuotePrefOption.push(<Option key="fastest" value="fastest">Fastest</Option>);
priceQuotePrefOption.push(<Option key="best" value="best">Reputation</Option>);

const ProfileInformation = (props) => {
  const [state] = useContext(GlobalContext);
  const { customerDetails, customerType } = state.global;
  const { form, dob, dateFormat, industryType } = props;
  const [postcode, setPostcode] = useState(form.values.postcode);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { t } = useTranslation('settingsPage');

  // console.log('props',props);
  // console.log('state.global',state.global);

  useGenerateAddress({
    setIsLoading: props.setIsLoading,
    countryList: props.countryList,
    country: form.values.country,
    postcode,
    setFieldValue: form.setFieldValue,
  });

  return (
    <Form layout="vertical" onSubmit={form.handleSubmit}>
      <Card>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p className="heading3" style={{ margin: 0, marginBottom: 10 }}>
          {t('content.pageTitle')}
          </p>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <CustomizedDropdown
                {...form}
                name="lang"
                optionFilterProp="children"
                children={useGenerateDropdownItem(languages, null, null, 'label')}
                label={t('content.profileInfo.language')}
                placeholder="Choose language"
                onChange={(value) => { useLocalStorage.setItem("lang", value); window.location.reload(false); }}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <CustomizedDropdown
                {...form}
                name="priceQuotePref"
                optionFilterProp="children"
                children={priceQuotePrefOption}
                label={t('content.profileInfo.sortServices')}
                placeholder={t('content.profileInfo.sortServices')}
              />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8}>
              <CustomizedDropdown
                {...form}
                name="defaultLabelType"
                optionFilterProp="children"
                children={useGenerateDropdownItem(labelType)}
                label={t('content.profileInfo.defaultLabelType')}
                placeholder={t('content.profileInfo.defaultLabelType')}
              />
            </Col>
          </Row>
        </div>
      </Card>
      <Card style={{ marginTop: 20 }}>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p className="heading3" style={{ margin: 0, marginBottom: 10 }}>
          {t('content.profileInfo.title')}
          </p>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomizedDropdown
                {...form}
                name="type"
                optionFilterProp="children"
                children={useGenerateDropdownItem(customerType)}
                defaultValue={customerDetails.type}
                label={t('content.profileInfo.type')}
                required
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput {...form} name="name" type="text" label={t('content.profileInfo.businessName')} required/>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput {...form} name="identityNo" type="text" label={t('content.profileInfo.identificationNo')} required/>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput {...form} name="email" type="email" label={t('content.profileInfo.email')} required/>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput {...form} name="phone" type="text" label={t('content.profileInfo.mobile')} required/>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomFormItem {...form} label={t('content.profileInfo.dateOfIncorporation')}>
                <DatePicker
                  style={{ width: '100%' }}
                  onChange={(val) => {
                    const formattedDate = moment(val).format(dateFormat);
                    form.setFieldValue(
                      "dob",
                      formattedDate
                    );
                  }}
                  defaultValue={moment(dob, dateFormat)}
                  format={dateFormat}
                  name="dob"
                  required
                />
              </CustomFormItem>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomizedDropdown
                {...form}
                name="industry"
                optionFilterProp="children"
                children={useGenerateDropdownItem(industryType)}
                label={t('content.profileInfo.industry')}
                placeholder="Industry"
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput {...form} name="websiteUrl" type="text" label={t('content.profileInfo.websiteUrl')} />
            </Col>
          </Row>
          <small>
          Please update your full name, identity number and bank information to ensure a smooth payment process for cash on delivery settlement or refund.
          </small>
        </div>
      </Card>
      <Card style={{ marginTop: 20 }}>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p className="heading3" style={{ margin: 0, marginBottom: 10 }}>
          {t('content.addressInfo.title')}
          </p>
          <Row gutter={16}>
            <Col xs={24} md={24} lg={24}>
              <TextInput
                {...form}
                label= {t('content.addressInfo.address')}
                name="unitNo"
                placeholder="Unit number"
                type="text"
                style={{ marginBottom: 10 }}
                required
              />
              <TextInput
                {...form}
                name="address1"
                placeholder="Address 1"
                type="text"
                style={{ marginBottom: 10 }}
                required
              />
              <TextInput
                {...form}
                name="address2"
                placeholder="Address 2"
                type="text"
                required
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="postcode"
                onChange={(val) => {
                  setDisableSubmit(true);
                  form.setFieldValue('postcode', val.target.value);
                }}
                onBlur={
                  (val) => {
                    setPostcode(val.target.value);
                    setDisableSubmit(false);
                  }
                }
                label={t('content.addressInfo.postcode')}
                required
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="city"
                label={t('content.addressInfo.city')}
                type="text"
                style={{ width: "100%" }}
                required
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="state"
                label={t('content.addressInfo.state')}
                type="text"
                required
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomizedDropdown
                {...form}
                name="country"
                optionFilterProp="children"
                children={useGenerateDropdownItem(props.countryList, 'countryCode', 'countryCode', 'name')}
                label={t('content.addressInfo.country')}
                required
              />
            </Col>
          </Row>
        </div>
      </Card>
      <div style={{ float: "right", marginTop: 20, marginBottom: 20 }}>
        <CustomizedButton
          text={t('content.save')}
          isDisabled={disableSubmit}
          type="primary"
          htmlType="submit"
        />
      </div>
    </Form>
  )
}

export default FetchWrapper;
