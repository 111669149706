import React from "react";
import { Form, Input } from "antd";
import FormItem from './FormItem';

const { TextArea } = Input;

const CustomizedTextarea = ({
  values,
  errors,
  setFieldValue,
  setFieldTouched,
  name,
  placeholder,
  prefix,
  label,
  max,
  onChange,
  onBlur,
  disabled,
  className,
  autoSize,
  required,
  style
}) => (
  <FormItem
    validateStatus={disabled ? null : errors[name] && "error"}
    help={disabled ? null : errors[name]}
    label={label}
    className={className}
    style={style}
    error={errors[name]}
    required={required}
  >
    <TextArea
      disabled={disabled}
      prefix={prefix}
      placeholder={placeholder}
      value={values[name]}
      onChange={
        onChange
          ? onChange
          : event => {
              if (event.target.value.length === max + 1) {
                return;
              }
              setFieldValue(name, event.target.value);
            }
      }
      onBlur={onBlur ? onBlur : () => setFieldTouched(name)}
      autoSize={autoSize}
    />
  </FormItem>
);

export default CustomizedTextarea;
