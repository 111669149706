import moment from "moment";

function checkPriceData(companyId, waypoint, values) {
  const { vehicleType, addon, pickupDate, pickupTime, pickupNow, promoCode } = values;

  const formattedPickupDate = new Date(pickupDate);
  const formattedPickupTime = new Date(pickupTime);
  const pickupYear = formattedPickupDate.getFullYear();
  const pickupMonth = formattedPickupDate.getMonth();
  const pickupDay = formattedPickupDate.getDate();
  const pickupHour = formattedPickupTime.getHours();
  const pickupMinute = formattedPickupTime.getMinutes();
  const pickupSchedule = new Date(
    pickupYear,
    pickupMonth,
    pickupDay,
    pickupHour,
    pickupMinute
  );
  const formattedPickupSchedule = moment(pickupSchedule).format();

  const serviceAddon = [];
  Object.keys(addon).forEach((key) => {
    const addonCopy = { qty: 1, ...addon[key] };
    addonCopy.id = Number(key);
    serviceAddon.push(addonCopy)
  })

  let newWaypoints = [];
  waypoint.forEach((item, index) => {
    if (item.location) {
      newWaypoints.push({
        type: waypoint.length === 1 ? 'DROPOFF' : (index === 0 ? 'PICKUP' : 'DROPOFF'),
        address1: item?.address1?.length > 0 ? item.address1 : item.address2,
        address2: item?.address1?.length > 0 ? (item.address2 || "") : "",
        city: item.city,
        state: item.state,
        postcode: item.postcode,
        country: item.country,
        coord: {
          lat: item.location[1],
          lon: item.location[0]
        },
        scheduledAt: index === 0 ? (pickupNow ? 'now' : formattedPickupSchedule) : (pickupNow ? 'now' : moment(formattedPickupSchedule).add(1, 'hours').format())
      })
    }
  });

  return {
    companyId,
    serviceCompanyCode: vehicleType[1].companyCode,
    waypoint: newWaypoints,
    serviceAddon,
    promoCode,
    weight: {
      unit: vehicleType[1].maxWeight.unit,
      value: vehicleType[1].maxWeight.value
    }
  }
}

export default checkPriceData;
