import React, { Component } from "react";
import { Layout } from "antd";
// import SideBar from "../Home/Sidebar";
// import Navbar from "Components/NavBar";
//import { NProgress } from "@tanem/react-nprogress";
// import Container from "Components/NProgress/Container";
// import Bar from "Components/NProgress/Bar";
import EditAppsContent from "./EditAppsContent";
import PageWrapper from "Components/PageWrapper";
import useLocalStorage from "Util/useLocalStorage";

const { Content } = Layout;

class EditApps extends Component {
  state = {
    collapsedSidebar:
      typeof Storage !== "undefined"
        ? useLocalStorage.getItem("toggleSider") === "true"
          ? true
          : false
        : true,
    isLoading: true
  };

  componentDidMount() {
    this.setState({
      isLoading: false
    });

  }

  toggleSidebar = () => {
    useLocalStorage.getItem("toggleSider") === "true"
      ? this.setState({ collapsedSidebar: false })
      : this.setState({ collapsedSidebar: true });

    useLocalStorage.getItem("toggleSider") === "true"
      ? useLocalStorage.setItem("toggleSider", "false")
      : useLocalStorage.setItem("toggleSider", "true");
  };

  autoMinimizeOnBreakpoint = broken => {
    this.setState({ collapsedSidebar: broken });
    useLocalStorage.setItem("toggleSider", broken);
  };

  render() {

    return (
      <div>
      <PageWrapper loading={this.state.isLoading} isCollapseSidebar={false}>
        <Layout className="ui-container">
          <Content
            className="ui-right-content form"
            style={{
              minHeight: "unset",
              marginBottom: 0,
              flex: "initial"
            }}
          >
            <EditAppsContent
              app={this.props.location.state?.app}
              record={this.props.location.state?.record}
            />
          </Content>
        </Layout>
        </PageWrapper>
      </div>
    );
  }
}

export default EditApps;
