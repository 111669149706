import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { List, Card, Col, Row, Form } from "antd";
import CustomizedButton from "Components/CustomizedButton";
import InputNumber from "Components/InputNumber";
import { Formik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from 'Store/store';

const TopupAmountStep = (props) => {
  const [state] = useContext(GlobalContext);
  const { topupPlan } = props;
  const { companyDetails, footerWidth, settings } = state.global;
  const { t } = useTranslation('topupPage');

  const sortedTopupPlan = topupPlan.prices?.sort(function(a, b){return a.value - b.value}) || [];
  const sortedTopupPlanPrices = sortedTopupPlan.map((item) => item.value);
  const minOrderAmount = sortedTopupPlanPrices[0] || settings["orders.create_an_order"]?.data?.min_order_amount;

  const validationSchema = Yup.object().shape({
    amount: Yup.number().nullable().min(minOrderAmount, `Minimum amount ${companyDetails.currency} ${minOrderAmount} is required`).required(`Minimum amount ${companyDetails.currency} ${minOrderAmount} is required`),
  });

  return (
    <div style={{ padding: "0 30px 30px", width: footerWidth, maxWidth: 900 }}>
      <div>
        <List
          loading={props.isLoading}
          itemLayout="vertical"
          dataSource={sortedTopupPlan}
          footer={
            <Formik
              validationSchema={validationSchema}
              initialValues={{ amount: topupPlan.prices?.length > 0 ? sortedTopupPlanPrices[0] : 50.00 }}
              onSubmit={props.submitAmount}
            >
              {form => (
                <Form onSubmit={form.handleSubmit}>
                  <Card hoverable={true}>
                    <Row gutter={[20, 20]}>
                      <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                        <span className="heading1" style={{ margin: 0, padding: 0, lineHeight: 'normal' }}>
                          {t('content.topupForm.enterAmount')}:
                        </span>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                        <div style={{ maxWidth: '80%', margin: 'auto' }}>
                          <InputNumber
                            {...form}
                            inputStyle={{ width: '100%' }}
                            step={10.00}
                            name="amount"
                            type="number"
                            placeholder={"Min amount " + companyDetails.currency + " " + sortedTopupPlanPrices[0]}
                            style={{ marginBottom: 0, width: "100%" }}
                          />
                        </div>
                      </Col>
                      <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                        <CustomizedButton
                          htmlType="submit"
                          text={t('content.topupForm.topupNow')}
                          className="button-success is-fullwidth-r"
                          style={{ width: '100%' }}
                        />
                      </Col>
                    </Row>
                  </Card>
                </Form>
              )}
            </Formik>
          }
          renderItem={item => {
            return (
              <List.Item
                style={{
                  width: "100%",
                  border: "none"
                }}
              >
                <Card hoverable={true}>
                  <Row gutter={[20, 20]}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                      <span className="heading1" style={{ margin: 0, padding: 0, lineHeight: 'normal' }}>
                        {item.name}
                      </span>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={16}>
                      <div style={{ maxWidth: '80%', margin: 'auto' }}>
                        <span>{item.description}</span>
                      </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={4}>
                      <CustomizedButton
                        onClick={() => props.selectTopupAmount(item.value)}
                        text={t('content.topupForm.select')}
                        className="button-success is-fullwidth-r"
                        style={{ width: '100%' }}
                      />
                    </Col>
                  </Row>
                </Card>
              </List.Item>
            )
          }}
        />
      </div>
    </div>
  )
}

export default TopupAmountStep;
