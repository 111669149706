import React, { useContext, useMemo, useCallback, useState } from "react";

import { Col, Row, Spin, Icon, Select } from "antd";
import {
  TEXT_SEARCH_COUNTRY,
} from "Util/Text";
import {
  LabelledDropdown,
  LabelledTextInput,
  LabelledPhoneNumberInput,
  LabelledInputTextarea,
  LabelledCheckbox
} from "Components/optimized-input";
import { GlobalContext } from "Store/store";
import useGenerateAddress from 'Util/useGenerateAddress';
import { useTranslation } from "react-i18next";

import ShowOn from 'Util/ShowOn';

const ContactDetails = (props) => {
  const [state] = useContext(GlobalContext);
  const { countryList, companyDetails } = state.global;
  const { form } = state.newOrder;
  const { countryDropdownChildren, type } = props;
  const { setFieldValue, handleChange, values, errors } = form;
  const [searchAddress, setSearchAddress] = useState(false)
  const antIcon = <Icon type="loading" style={{ fontSize: 18 }} spin />;
  const { t } = useTranslation('orderPage');

  useGenerateAddress({
    accessor: [type + 'Country', type + 'City', type + 'State'],
    setIsLoading: setSearchAddress,
    countryList,
    country: values[type + 'Country'],
    postcode: values[type + 'Postcode'],
    setFieldValue
  });

  return (
    <Row>
      <div style={{ backgroundColor: "white", width: "100%" }}>
        <Row xs={24} sm={24} md={24} lg={24} xl={24} gutter={20}>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <LabelledTextInput
              id={type + 'Name'}
              error={errors[type + 'Name']}
              label={t('content.newOrder.form.fullName')}
              value={values[type + 'Name']}
              onChange={useCallback(handleChange(type + 'Name'), [])}
              required
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <LabelledPhoneNumberInput
              error={errors[type + 'Mobile']}
              label={t('content.newOrder.form.mobile')}
              name={type + 'Mobile'}
              value={values[type + 'Mobile']}
              onChange={useCallback((val, country, e, formattedValue) => handleChange(type + 'Mobile', val), [])}
              country={useMemo(() => companyDetails.country.toLowerCase(), [])}
              enableSearchField={true}
              searchPlaceholder={TEXT_SEARCH_COUNTRY}
              required
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <LabelledTextInput
              error={errors[type + 'Email']}
              label={t('content.newOrder.form.email')}
              value={values[type + 'Email']}
              onChange={useCallback(handleChange(type + 'Email'), [])}
            />
          </Col>
        </Row>
        <Row xs={24} sm={24} md={24} lg={24} xl={24} gutter={20}>
          <Col xs={24} sm={24} md={24} lg={12} xl={12}>
            <LabelledDropdown
              disabled={type === 'sender' && values.dropAtOutlet}
              label={t('content.newOrder.form.country')}
              error={errors[type + 'Country']}
              value={values[type + 'Country']}
              onChange={useCallback(handleChange(type + 'Country'), [])}
              optionFilterProp="children"
              children={countryDropdownChildren}
              required
              showSearch
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={12}>
            <LabelledTextInput
              error={errors[type + 'Company']}
              label={t('content.newOrder.form.companyName')}
              type="text"
              value={values[type + 'Company']}
              onChange={useCallback(handleChange(type + 'Company'), [])}
            />
          </Col>
        </Row>
        <LabelledTextInput
          disabled={type === 'sender' && values.dropAtOutlet}
          label={t('content.newOrder.form.unitNo')}
          error={errors[type + 'UnitNo']}
          value={values[type + 'UnitNo']}
          onChange={useCallback(handleChange(type + 'UnitNo'), [])}
          placeholder="Unit No / Floor / Block"
          style={useMemo(() => ({ marginBottom: 10 }), [])}
        />
        <LabelledInputTextarea
          disabled={type === 'sender' && values.dropAtOutlet}
          label={t('content.newOrder.form.address1')}
          error={errors[type + 'Address1']}
          value={values[type + 'Address1']}
          onChange={useCallback(handleChange(type + 'Address1'), [])}
          placeholder="Address line 1"
          autoSize={useMemo(() => ({ minRows: 2 }), [])}
          required
        />
        <LabelledTextInput
          disabled={type === 'sender' && values.dropAtOutlet}
          label={t('content.newOrder.form.address2')}
          error={errors[type + 'Address2']}
          value={values[type + 'Address2']}
          onChange={useCallback(handleChange(type + 'Address2'), [])}
          placeholder={t('content.newOrder.form.optional')}
          style={useMemo(() => ({ marginBottom: 10  }), [])}
        />
        <Row xs={24} sm={24} md={24} lg={24} xl={24} gutter={20}>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <LabelledTextInput
              disabled={type === 'sender' && values.dropAtOutlet}
              error={errors[type + 'Postcode']}
              value={values[type + 'Postcode']}
              onChange={useCallback((val) => {
                setFieldValue(type + 'Postcode', val.target.value)
              }, [])}
              required
              label={t('content.newOrder.form.postcode')}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <LabelledTextInput
              disabled={type === 'sender' && values.dropAtOutlet}
              error={errors[type + 'City']}
              value={values[type + 'City']}
              onChange={useCallback(handleChange(type + 'City'), [])}
              label={t('content.newOrder.form.city')}
              required
              suffix={useMemo(() => searchAddress && <Spin indicator={antIcon} />, [searchAddress])}
            />
          </Col>
          <Col xs={24} sm={24} md={24} lg={24} xl={8}>
            <LabelledTextInput
              disabled={type === 'sender' && values.dropAtOutlet}
              error={errors[type + 'State']}
              value={values[type + 'State']}
              onChange={useCallback(handleChange(type + 'State'), [])}
              label={t('content.newOrder.form.state')}
              required
              suffix={useMemo(() => searchAddress && <Spin indicator={antIcon} />, [searchAddress])}
            />
          </Col>
        </Row>
        <ShowOn on={type === 'xsenderx' && values.outlets?.length > 0}>
          <LabelledCheckbox
            text="Drop at the outlet"
            checked={values.dropAtOutlet}
            onChange={e => { form.setFieldValue('dropAtOutlet', e.target.checked) }}
          />
          {
            values.dropAtOutlet &&
            <LabelledDropdown
              allowClear
              onClear={() => { form.setFieldValue('selectedOutletIndex', null) }}
              error={errors["selectedOutletIndex"]}
              value={values["selectedOutletIndex"]}
              onChange={(val) => { form.setFieldValue('selectedOutletIndex', val) }}
              placeholder="Select outlet"
              optionFilterProp="children"
              children={
                values.outlets.map((item, index) => (
                  <Select.Option key={item.id} value={index.toString()}>
                    {item.name}
                  </Select.Option>
                ))
              }
            />
          }
        </ShowOn>
      </div>
    </Row>
  );
}

export default ContactDetails;
