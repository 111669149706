import React, { useContext, useState, useEffect } from "react";
import { Card, Form, Row, Col, Layout, message } from "antd";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";

import TextInput from "Components/TextInput";
import CustomizedButton from "Components/CustomizedButton";
import CustomizedDropdown from "Components/CustomizedDropdown";
import { GlobalContext } from "Store/store";
import { update, get } from "Util/API";
import useGenerateAddress from 'Util/useGenerateAddress';
import useGenerateDropdownItem from 'Util/useGenerateDropdownItem';

import { mixPanelTrack } from "Util/CustomFunctions";

const BankInformation = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { t } = useTranslation('settingsPage');
  const { customerDetails, allCustomerAccounts, userDetails, companyDetails, countryList } = state.global;
  const { billing, customerBankDetails } = customerDetails;
  const defaultCountryCode = billing?.country || customerDetails.country || companyDetails.country;
  const selectedCountryData = countryList.filter((item) => (
    item.countryCode === defaultCountryCode
  ));

  const onSubmit = async (values) => {
    props.setIsLoading(true);
    const { bankName, bankSwiftCode, bankAccountNo, bankAccountName, ...others } = values;

    let accountData = {};
    accountData.customerBankDetails = [{
      bankName,
      bankSwiftCode,
      accountNumber: bankAccountNo,
      accountName: bankAccountName
    }];

    accountData.billing = {
      ...others
    }

    accountData.companyId = companyDetails.id;
    accountData.id = customerDetails.id;

    const updateCustomerDetails = await update("customer", accountData);
    if (updateCustomerDetails.status === 200) {
      // TODO: Remove this after api response correct bankInformation after updateCustomerDetails
      const getCustomerDetails = await get(`/customer/${customerDetails.id}?companyId=${companyDetails.id}`);
      if (getCustomerDetails.status === 200) {
        props.setIsLoading(false);
        dispatch({
          type: "UPDATE_PROFILE",
          payload: {
            userDetails,
            allCustomerAccounts,
            customerDetails: getCustomerDetails.data.data,
          }
        })
        message.success(t('content.billingInfo.update'))
      } else message.error(getCustomerDetails);
    } else message.error(updateCustomerDetails);

    props.setIsLoading(false);
  }

  useEffect(() => {
    mixPanelTrack('ViewSettingsBilling');
  }, []);

  return (
    <Layout>
      <Formik
        enableReinitialize
        initialValues={{
          name: billing?.name,
          email: billing?.email,
          unitNo: billing?.unitNo,
          address1: billing?.address1,
          address2: billing?.address2,
          postcode: billing?.postcode,
          city: billing?.city,
          country: selectedCountryData[0]?.countryCode,
          state: billing?.state,
          bankName: customerBankDetails[0]?.bankName || "",
          bankSwiftCode: customerBankDetails[0]?.bankSwiftCode || "",
          bankAccountNo: customerBankDetails[0]?.accountNumber || "",
          bankAccountName: customerBankDetails[0]?.accountName || ""
        }}
        onSubmit={onSubmit}
      >
        {form => (
          <BankInformationForm
            form={form}
            setIsLoading={props.setIsLoading}
            countryList={countryList}
          />
        )}
      </Formik>
    </Layout>
  )
}

const BankInformationForm = ({ form, setIsLoading, countryList, ...props }) => {
  const [postcode, setPostcode] = useState(form.values.postcode);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { t } = useTranslation('settingsPage');

  useGenerateAddress({
    setIsLoading: setIsLoading,
    countryList,
    country: form.values.country,
    postcode,
    setFieldValue: form.setFieldValue,
  });

  return (
    <Form onSubmit={form.handleSubmit}>
      <Card>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p className="heading3" style={{ margin: 0 }}>
          {t('content.billingInfo.title')}
          </p>
          <Row gutter={16}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextInput
                {...form}
                name="name"
                label={t('content.billingInfo.billingName')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextInput
                {...form}
                name="email"
                label={t('content.billingInfo.billingEmail')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <TextInput
                {...form}
                label={t('content.billingInfo.address')}
                name="unitNo"
                placeholder="Unit number"
                type="text"
                style={{ marginBottom: 10 }}
              />
              <TextInput
                {...form}
                name="address1"
                placeholder="Address 1"
                type="text"
                style={{ marginBottom: 10 }}
              />
              <TextInput
                {...form}
                name="address2"
                placeholder="Address 2"
                type="text"
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="postcode"
                onChange={(val) => {
                  setDisableSubmit(true);
                  form.setFieldValue('postcode', val.target.value);
                }}
                onBlur={
                  (val) => {
                    setPostcode(val.target.value);
                    setDisableSubmit(false);
                  }
                }
                label={t('content.billingInfo.postcode')}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="city"
                label={t('content.billingInfo.city')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="state"
                label={t('content.billingInfo.state')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={24} sm={24} md={12} lg={12} xl={12}>
              <CustomizedDropdown
                {...form}
                name="country"
                optionFilterProp="children"
                children={useGenerateDropdownItem(countryList, 'countryCode', 'countryCode', 'name')}
                label={t('content.billingInfo.country')}
              />
            </Col>
          </Row>
        </div>
      </Card>

      <Card style={{ marginTop: 20 }}>
        <div
          className="whiteBColor"
          style={{ padding: "10px 20px", minHeight: "auto" }}
        >
          <p className="heading3" style={{ margin: 0 }}>
          {t('content.bankInfo.title')}
          </p>
          <Row gutter={16}>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="bankAccountName"
                label={t('content.bankInfo.bankAccountName')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="bankAccountNo"
                label={t('content.bankInfo.bankAccountNo')}
                type="number"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="bankName"
                label={t('content.bankInfo.bankName')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
            <Col xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextInput
                {...form}
                name="bankSwiftCode"
                label={t('content.bankInfo.bankSwiftCode')}
                type="text"
                style={{ width: "100%" }}
              />
            </Col>
          </Row>
          <small>
          Please update your full name, identity number and bank information to ensure a smooth payment process for cash on delivery settlement or refund.
          </small>
        </div>
      </Card>
      <div style={{ float: "right", marginTop: 20, marginBottom: 20 }}>
        <CustomizedButton
          text={t('content.save')}
          isDisabled={disableSubmit}
          type="primary"
          htmlType="submit"
        />
      </div>
    </Form>
  );
}

export default BankInformation;
