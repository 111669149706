import React, { useContext, useEffect, useState, useMemo } from 'react';
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useLocation } from "react-router-dom";
import styled from '@emotion/styled';
import { Select, Row, Col, Spin, Icon, message } from 'antd';

import useValidateField from 'Components/input-v2/useValidateField';
import CustomizedButton from "Components/CustomizedButton";
import TextInput from "Components/input-v2/TextInput";
import CustomizedDropdown from "Components/input-v2/CustomizedDropdown";
import PhoneNumberInput from 'Components/input-v2/PhoneNumberInput';
import InputNumber from 'Components/input-v2/InputNumber';
// import PageWrapper from 'Components/PageWrapper';
import TextareaInput from 'Components/input-v2/CustomizedTextarea';
import useGenerateDropdownItem from 'Util/useGenerateDropdownItem';
import { GlobalContext } from "Store/store";
import PublicHeader from 'Components/PublicHeader';
// import { IMAGE_URL_ROOT } from 'Util/API';
import instabookLogo from 'images/Logo/instabook.png';
import { post } from 'Util/API';
import { getItemTypes } from 'Fetch';
import useGenerateAddress from 'Util/useGenerateAddress';

const Root = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-bottom: 30px;
`;

const FormWrapper = styled.div`
  background-color: white;
  margin: 20px;
  padding: 20px;
  max-width: 700px;
  width: 100%;
`;

const validationSchema = {
  validationSchema: Yup.object().shape({
    productName: Yup.string().nullable().required("Product name is required"),
    amount: Yup.number().min(0.1).nullable().required("Price is required field"),
    typeIndex: Yup.number().nullable().required("Type is required"),
    // weightUnit: Yup.string().nullable().required("Weight unit is required"),
    weightValue: Yup.number().min(0.001).nullable().required("Weight value is required"),
    name: Yup.string().nullable().required("Name is required"),
    phone: Yup.string().nullable().required("Phone number is required field"),
    // name: Yup.string().nullable().required("Full name is required"),
    // mobileNo: Yup.string().nullable().required("Mobile number is required"),
    email: Yup.string().nullable().required("Email is required"),
    unitNo: Yup.string().nullable().required("Unit no is required"),
    address1: Yup.string().nullable().required("Address is required"),
    // address2: Yup.string().nullable(),
    postcode: Yup.string().nullable().required("Postcode is required"),
    city: Yup.string().nullable().required("City is required"),
    state: Yup.string().nullable().required("State is required"),
    // coutry: Yup.string().nullable().required("Country is required"),
    // notes: Yup.string().nullable(),
  })
};

const InstabookForm = ({ form, itemTypeOption }) => {
  const { values, validateField, setFieldValue } = form;
  const [state] = useContext(GlobalContext);
  const { countryList, companyDetails } = state.global;
  const [searchAddress, setSearchAddress] = useState(false);
  const antIcon = <Icon type="loading" style={{ fontSize: 18 }} spin />;

  const countryDropdownChildren = useGenerateDropdownItem(
    countryList,
    'countryCode',
    'countryCode',
    'name'
  );

  useGenerateAddress({
    accessor: ['country', 'city', 'state'],
    setIsLoading: setSearchAddress,
    countryList,
    country: values.country,
    postcode: values.postcode,
    setFieldValue
  });

  const formTouched = Object.keys(form.touched).length > 0;
  useValidateField('productName', values.productName, validateField, formTouched);
  useValidateField('typeIndex', values.typeIndex, validateField, formTouched);
  useValidateField('weightValue', values.weightValue, validateField, formTouched);
  useValidateField('name', values.name, validateField, formTouched);
  useValidateField('phone', values.phone, validateField, formTouched);
  useValidateField('email', values.email, validateField, formTouched);
  useValidateField('unitNo', values.unitNo, validateField, formTouched);
  useValidateField('postcode', values.postcode, validateField, formTouched);
  useValidateField('city', values.city, validateField, formTouched);
  useValidateField('state', values.state, validateField, formTouched);

  return (
      <Row gutter={[20, 15]}>
        <Col>
          <TextInput
            {...form}
            name="productName"
            type="text"
            label="Product name"
            placeholder="e.g. Apparels"
            required
          />
        </Col>
        <Col>
          <InputNumber
            {...form}
            inputStyle={{ width: "100%" }}
            name="amount"
            type="text"
            label={`Price (${companyDetails.currency})`}
            placeholder="Amount"
            min={0}
            required
          />
        </Col>
        <Col>
          <CustomizedDropdown
            {...form}
            name="typeIndex"
            optionFilterProp="children"
            children={itemTypeOption}
            defaultValue={0}
            label="Item type"
            style={{ width: "100%" }}
            required
          />
        </Col>
        <Col>
          <InputNumber
            {...form}
            inputStyle={{ width: '100%' }}
            name="weightValue"
            type="text"
            label="Weight (kg)"
            min={0}
            step={0.001}
            required
          />
        </Col>
        <Col>
          <TextInput
            {...form}
            name="name"
            type="text"
            label="Full name"
            placeholder="Your full name"
            required
          />
        </Col>
        <Col>
          <Row gutter={[20, 15]}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <PhoneNumberInput
                {...form}
                country={companyDetails.country.toLowerCase()}
                label="Mobile number"
                name="phone"
                placeholder="e.g. +60195555555"
                enableSearchField={true}
                searchPlaceholder="Search"
                onChange={value => {
                  form.setFieldValue("phone", value);
                }}
                required
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextInput
                {...form}
                name="email"
                type="text"
                label="Email"
                placeholder="e.g. youremail@email.com"
                required
              />
              <div style={{ fontSize: 10 }}>(For order status notifications)</div>
            </Col>
          </Row>
        </Col>
        <Col>
          <TextInput
            {...form}
            name="unitNo"
            type="text"
            label="Unit no"
            placeholder="e.g. No 99"
            required
          />
        </Col>
        <Col>
          <TextareaInput
            {...form}
            name="address1"
            type="text"
            label="Address"
            placeholder="e.g. Taman Keramat"
            required
          />
        </Col>
        <Col>
          <Row gutter={20}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextInput
                {...form}
                name="postcode"
                type="text"
                label="Postcode"
                placeholder="e.g. 50450"
                required
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextInput
                {...form}
                name="city"
                type="text"
                label="City"
                suffix={useMemo(() => searchAddress && <Spin indicator={antIcon} />, [searchAddress])}
                placeholder="e.g. Ampang"
                required
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <Row gutter={20}>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <TextInput
                {...form}
                name="state"
                type="text"
                label="State"
                suffix={useMemo(() => searchAddress && <Spin indicator={antIcon} />, [searchAddress])}
                placeholder="e.g. Selangor"
                required
              />
            </Col>
            <Col xs={24} sm={24} md={24} lg={12} xl={12}>
              <CustomizedDropdown
                {...form}
                style={{ width: "100%" }}
                name="country"
                optionFilterProp="children"
                children={countryDropdownChildren}
                label="Country"
                required
              />
            </Col>
          </Row>
        </Col>
        <Col>
          <TextareaInput
            {...form}
            name="note"
            type="text"
            label="Note"
            placeholder="e.g. Any instructions..."
           />
        </Col>
        <Col>
          <CustomizedButton style={{ width: '100%', marginTop: 10 }} type="primary" text="Submit" onClick={form.handleSubmit} />
        </Col>
      </Row>
    )
}

const Instabook = (props) => {
  const [state] = useContext(GlobalContext);
  const { companyDetails, customerDetails, countryList } = state.global;
  const [itemTypes, setItemTypes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const itemTypeName = urlParams.get('type');

  useEffect(() => {
    handleGetItemTypes()
  }, [])

  let defaultCountryCode = 'MY';
  if (customerDetails && customerDetails.country) defaultCountryCode = customerDetails.country;
  if (companyDetails && companyDetails.country) defaultCountryCode = companyDetails.country;

  const selectedCountryData = useMemo(() => countryList.filter((item) => (
    item.countryCode === defaultCountryCode
  )), [countryList]);

  const handleGetItemTypes = async () => {
    setIsLoading(true);
    const fetch = await getItemTypes(companyDetails.id);
    if (fetch.status === 200) {
      const itemTypesDataSource = fetch.data.data;
      const parcelIndex = itemTypesDataSource.findIndex((item) => item.name === 'PARCEL');
      if (parcelIndex !== -1) {
        const parcelData = itemTypesDataSource[parcelIndex];
        itemTypesDataSource.splice(parcelIndex, 1);
        itemTypesDataSource.splice(0, 0, parcelData);
      }
      setItemTypes(itemTypesDataSource)
    }
    setIsLoading(false);
  }

  const generateItemTypeDropdownChildren = () => {
    return itemTypes.map((item, index) => (
      <Select.Option
        key={item.id}
        value={index}
      >
        {item.name}
      </Select.Option>
    ))
  }

  const getInitialTypeIndex = () => {
    const index = itemTypes.findIndex((item) => item.name === itemTypeName);
    return index >= 0 ? index : 0;
  }

  const onSubmit = async (val) => {
    setIsLoading(true);
    const data = {
      "item": {
          "name": val.productName,
          "amount": val.amount,
          "type": itemTypes[val.typeIndex].name,
          "weight": {
              "unit": 'kg',
              "value": val.weightValue
          }
      },
      "receiver": {
          "name": val.name,
          "phone": val.phone,
          "email": val.email,
          "unitNo": val.unitNo,
          "address1": val.address1,
          "postcode": val.postcode,
          "city": val.city,
          "state": val.state,
          "country": val.country
      },
      "note": val.note
  }

    const fetch = await post('order/instabook/' + props.match?.params?.id, data);
    if (fetch.status === 200) {
      message.success('Your form have been submitted.')
    } else message.error(fetch)
    setIsLoading(false)
  }

  return (
    <PublicHeader showLogo={false}>
      <Spin spinning={isLoading}>
        <Root>
          <Link to={`${process.env.PUBLIC_URL}/`}>
            <img
              style={{ height: 35, marginBottom: 10 }}
              src={instabookLogo}
              alt="company logo"
            />
          </Link>
          <div>Please fill in your contact and delivery details.</div>
          <FormWrapper>
            <Formik
              {...validationSchema}
              enableReinitialize
              onSubmit={onSubmit}
              initialValues={{
                productName: urlParams.get('name'),
                amount: urlParams.get('value'),
                note: urlParams.get('notes'),
                weightValue: urlParams.get('weight'),
                typeIndex: getInitialTypeIndex(),
                weightUnit: 'kg',
                country: selectedCountryData[0].countryCode,
                // weightValue: 0.000,
                phone: null
              }}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {form => <InstabookForm form={form} itemTypeOption={generateItemTypeDropdownChildren()} />}
            </Formik>
          </FormWrapper>
        </Root>
      </Spin>
    </PublicHeader>
  )
}

export default Instabook;
