import React, { useState } from 'react';
import { Input, Spin, message } from "antd";
import { useTranslation } from "react-i18next";
import { Formik } from "formik";
import * as Yup from "yup";

import { post, update, remove } from 'Util/API';
import './styles.css';

import CustomizedDropdown from "Components/input/LabelledDropdown";
import CustomizedButton from "Components/CustomizedButton";
import useGenerateDropdownItem from 'Util/useGenerateDropdownItem';
import TextInput from "Components/TextInput";

const events = ['order.created', 'order.failed', 'order.updated', 'order_tracking.update', 'order_tracking.change']

const AddNewApi = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { record } = props;

  const onSubmit = async (data) => {
    setIsLoading(true);
    const createToken = record ? await update('webhook/' + record.id, data) : await post('webhook', data);
    if (createToken.status === 200) {
      message.success('Success')
      props.setModalState({
        ...props.modalState,
        isModalVisible: false
      })
      props.setDataSource(null)
    } else message.error(createToken);
    setIsLoading(false);
  }

  return (
    <div>
      <Spin tip="Loading..." spinning={isLoading}>
        <Formik
          enableReinitialize
          initialValues={{
            url: record?.url,
            event: record?.event || 'order.created'
          }}
          validationSchema={Yup.object().shape({
            url: Yup.string()
              .nullable()
              .required('Url is required'),
            event: Yup.string().nullable().required('Event type is required')
          })}
          onSubmit={onSubmit}
        >
          {form => (
            <FormInput form={form} />
          )}
        </Formik>
      </Spin>
    </div>
  )
}

const FormInput = ({ form }) => {
  const { t } = useTranslation('settingsPage');

  return (
    <div>
      <TextInput
        {...form}
        style={{ marginBottom: 20 }}
        name="url"
        placeholder="Url"
        type="text"
        label="Url"
      />
      <CustomizedDropdown
        {...form}
        style={{ width: '100%' }}
        name="event"
        optionFilterProp="children"
        children={useGenerateDropdownItem(events)}
        label="Choose event"
      />
      <div style={{ display: 'flex', margin: -5, justifyContent: 'flex-end' }}>
        <CustomizedButton
          type="primary"
          text="Confirm"
          onClick={() => form.handleSubmit(form.values)}
          style={{ margin: 5 }}
        />
      </div>
    </div>
  )
}

export default AddNewApi;
