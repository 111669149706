import React, { useEffect, useState } from "react";
import {
  DatePicker
} from "antd";
import moment from 'moment';
import { useTranslation } from "react-i18next";

const { RangePicker } = DatePicker;
const dateFormat = "MMM DD,YYYY";

const DateFilter = (props) => {
  const { handleModalRangeDate } = props;
  const [ranges, setRanges] = useState(null);
  const [dateFromTo, setDateFromTo] = useState([null, null])
  const { t } = useTranslation('components');

  const data = [
    { id: -1, label: t('dateFilter.filterValue.allTime'), dateFrom: null, dateTo: null },
    { id: 1, label: t('dateFilter.filterValue.today'), dateFrom: moment(), dateTo: null },
    { id: 2, label: t('dateFilter.filterValue.yesterday'), dateFrom: moment().subtract(1, "days"), dateTo: null },
    { id: 3, label: t('dateFilter.filterValue.tomorrow'), dateFrom: moment().add(1, "days"), dateTo: null },
    { id: 4, label: t('dateFilter.filterValue.lastWeek'), dateFrom: moment().startOf("week").subtract(1,"week"), dateTo: moment().endOf("week").subtract(1,"week") },
    { id: 5, label: t('dateFilter.filterValue.lastMonth'), dateFrom: moment().startOf("month").subtract(1,"month"), dateTo: moment().endOf("month").subtract(1,"month") },
    { id: 6, label: t('dateFilter.filterValue.lastYear'), dateFrom: moment().startOf("year").subtract(1,"year"), dateTo: moment().endOf("year").subtract(1,"year") },
    { id: 7, label: t('dateFilter.filterValue.thisWeek'), dateFrom: moment().startOf("weeks"), dateTo: moment().endOf("weeks") },
    { id: 8, label: t('dateFilter.filterValue.thisMonth'), dateFrom: moment().startOf("month"), dateTo: moment().endOf("month") },
    { id: 9, label: t('dateFilter.filterValue.thisYear'), dateFrom: moment().startOf("year"), dateTo: moment().endOf("year") },
  ];

  useEffect(() => {
    const newData = {};
    data.forEach((item, index) => {
      if (index > 0) {
        newData[item.label] = [item.dateFrom, item.dateTo]
      }
    })
    setRanges(newData);
  }, [])

  return (
    <RangePicker
      format={dateFormat}
      value={dateFromTo}
      ranges={ranges}
      onChange={(date, dateString) => {
        if (date[0]) {
          const dateFrom = date[0];
          const dateTo = date[1] || date[0];
          setDateFromTo([moment(dateFrom), moment(dateTo)])
          const selectedIndex = data.findIndex((item) => item.dateFrom && item.dateFrom.format(dateFormat) === dateString[0])
          handleModalRangeDate(dateFrom, dateTo, data[selectedIndex]?.id || -1)
        } else {
          setDateFromTo([null, null])
          handleModalRangeDate(null, null, -1)
        }
      }}
    />
  )
}

export default DateFilter;
