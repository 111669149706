import { useLocation } from "react-router-dom";

import mixpanel from 'mixpanel-browser';

import useLocalStorage from 'Util/useLocalStorage';

import moment from 'moment'

export const convertTo12HourFormat = timeString => {
  const timeString12 = new Date(
    "1970-01-01T" + timeString + "Z"
  ).toLocaleTimeString(
    {},
    { timeZone: "UTC", hour12: true, hour: "numeric", minute: "numeric" }
  );

  return timeString12;
};

export const convertTo24HourFormat = timeString => {
  const [time, modifier] = timeString.split(" ");
  let [hours, minutes] = time.split(":");

  if (hours < parseInt("10")) {
    hours = "0" + hours;
  }

  if (hours === "12") {
    hours = "00";
  }

  if (modifier === "pm") {
    hours = parseInt(hours) + 12;
  }

  return `${hours}:${minutes}:00`;
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

// Check if object is empty
export const isObjectEmpty = obj => {
  for (var key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
};

// Generate 9 random digits
export const generateNineRanNum = () => {
  return Math.floor(Math.random() * 1000000000);
};

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export const getServiceCompany = (serviceCompanyCode, serviceCompanies) => {
  let serviceCompany = null;

  for(var i=0; i < serviceCompanies.length; i++)
  {
      if(serviceCompanies[i].companyCode == serviceCompanyCode || serviceCompanies[i].id == serviceCompanyCode)
      {
          serviceCompany = serviceCompanies[i];
      }
  }

  return serviceCompany;
}

export const mixPanelTrack = (eventName) => {
  const mpToken = useLocalStorage.getItem("mpToken");
  if(mpToken)
  {
      mixpanel.init(mpToken);
      mixpanel.track(eventName);
  }
}

export const roundCurrencyValue = (currency, amount) => {
  if(amount && (amount > 0) )
  {
      amount = parseFloat(amount);
      if(currency == "MYR")
      {
          const cvalue = Math.floor((amount + 0.025) * 20) / 20;
          return cvalue+"";
      }else {
          const cvalue = Math.floor((amount + 0.025) * 20) / 20;
          return cvalue+"";
      }
  }else {
      return 0+"";
  }
}

export const displayEstimatedDeliveryDate = (trackingDetails) => {

    let edd = null;

    const scheduledAt = trackingDetails.scheduledAt ? trackingDetails.scheduledAt : '';//'2022-09-29 14:22';
    const etaDuration = trackingDetails.etaDuration ? trackingDetails.etaDuration : '';//60*60*2;

    if(scheduledAt && etaDuration)
    {
        if(etaDuration > (24*60*60))
        {
            edd = moment(scheduledAt).add(etaDuration, 'seconds').format("dddd, D MMM YYYY");
        }else {
            edd = moment(scheduledAt).add(etaDuration, 'seconds').format("dddd, D MMM YYYY, h:mm A");
        }
    }

    return edd;
}

export const displayDurationValue = (value) => {
  // 2 decimals
  //if less than 1 minute | 60s, display in seconds = value
  //if less than 1 hour | 60min x 60s , display minutes = value/60
  //if <= 24 hours | 24h x 60min x 60s , display hours = value/(60*60)
  //if > 24 hours | 24h x 60min x 60s , display days = value/(24*60*60)
  //else display No data
  let durationValue = "";

  if(value)
  {
      if(value > 0 && value <= 60)
      {
          durationValue = value.toFixed(0)+" second(s)";
      }else if(value > 60 && value <= (60*60))
      {
          durationValue = (value/(60)).toFixed(0)+" minute(s)";
      }else if(value > (60*60) && value <= (24*60*60))
      {
          durationValue = (value/(60*60)).toFixed(0)+" hour(s)";
      }else if(value > (24*60*60))
      {
          durationValue = (value/(24*60*60)).toFixed(0)+" day(s)";
      }else {
          // return "No data";
      }
  }else {
      // return "No data";
  }

  if(durationValue)
  {
      return durationValue;
  }else{
      return "";
  }
}

export const displayScoreValue = (value) => {
  // 2 decimals
  //if less than 1 minute | 60s, display in seconds = value
  //if less than 1 hour | 60min x 60s , display minutes = value/60
  //if <= 24 hours | 24h x 60min x 60s , display hours = value/(60*60)
  //if > 24 hours | 24h x 60min x 60s , display days = value/(24*60*60)
  //else display No data
  let scoreValue = "";

  if(value)
  {
      if(value > 0)
      {
          scoreValue = value.toFixed(0)+"%";
      }else {
          // return "No data";
      }
  }else {
      // return "No data";
  }

  if(scoreValue)
  {
      return scoreValue;
  }else{
      return "";
  }
}

export const refCodeToSource = refCode => {
  let source = "";

  switch (refCode) {
    case "shoppegram-98":
      source = "shoppegram";
      break;
    case "bizapp-64":
      source = "bizapp";
      break;
    case "ejen2u-81":
      source = "ejen2u";
      break;
    case "justordertoday-18":
      source = "justordertoday";
      break;
    case "easystore-4":
      source = "easystore";
      break;
    case "ordersini-50":
      source = "ordersini";
      break;
    case "orderla-82":
      source = "orderla";
      break;
    case "kiahstore-70":
      source = "kiahstore";
      break;
    case "fastorder-83":
      source = "kiahstore";
      break;
    case "dreamztech-94":
      source = "Dreamztech (M) Berhad";
      break;
    case "dreamztech-20":
      source = "Dreamztech";
      break;
    case "wasepme-95":
      source = "wasep.me";
      break;
    case "ordersini-63":
      source = "ordersini";
      break;
    case "whyq-52":
      source = "WhyQ";
      break;
    case "tatismy-":
      source = "tatismy";
      break;
    case "instaweb-45":
      source = "instaweb";
      break;
    default:
      source = "";
      break;
  }
  return source;
};

export const appPathToConfig = path => {
  let config = "{}";

  switch (path) {
    case 'abx-express':
      config = '{"app_id":"","app_key":"","baseURL":"https://abxsystem.com.my/KerrySmartEDI"}';
      break;
    case 'acl-mobile':
      config = '{"appid":"","userId":"","password":"","subappid":"","contenttype":""}';
      break;
    case 'aramex':
      config = '{"UserName":"","Password":"","Version":"","AccountNumber": "","AccountPin": "","AccountEntity": "","AccountCountryCode": ""}';
      break;
    case 'ninja-van':
      config = '{"sandbox":true,"client_id":"","serviceType":"","country_code":"","client_secret":"","requestPickup":false}';
      break;
    case 'janio':
      config = '{"sandbox":true,"secretKey":"","requestPickup":true}';
      break;
    case 'bungkusit':
      config = '{"app_id":"","token":"","sandbox":true}';
      break;
    case 'courier-by-email':
      config = '{"recipientName":"","recipientEmail":""}';
      break;
    case 'flash-express':
      config = '{"merchantId":"","OpenApiSecret":"","sandbox":true}';
      break;
    case 'goget-my':
      config = '{"token":"","sandbox":true}';
      break;
    case 'grab-express':
      config = '{"client_id":"","client_secret","","sandbox":true}';
      break;
    case 'lalamove':
      config = '{"key":"","secret","","country_code":"","sandbox":true}';
      break;
    case 'lalamove-v3':
      config = '{"apiKey":"","secretKey","","market":"","sandbox":true}';
      break;
    case 'inter-dx':
      config = '{"customerId":1,"companyId":"","userId":""}';
      break;
    case "jd-fop":
      config = '{"app_key":"","app_secret","","customerCode":"","gatewayUser":"","operator":"","sourceSystem":"","authCode":"","sandbox":true}';
      break;
    case "jnt-express":
      config = '{"username":"","api_key":"","cuscode":"","password":"","order_key":"","tracking_key":"","account":"","delivery_service":"","apiKey":"","secretKey":"","market":"","requestPickup":true,"sandbox":true}';
      break;
    case "line-clear":
      config = '{"accountNo":"","username":"","password":"","accessToken":"","requestPickup":true,"sandbox":true}';
      break;
    case "luwjistik":
      config = '{"appSecret":"","appId":"","freight_forwarder":true,"first_mile":false,"customs":true,"customs_partner_code":"","last_mile":true,"last_mile_partner_code":"","incoterm":"DDU","sandbox":true}';
      break;
    case "mr-speedy":
      config = '{"secret_auth_token":"","callback_token":"","sandbox":true}';
      break;
    case "pandago":
      config = '{"keyId":"","scope":"","clientId":"","privateKey":"","sandbox":true}';
      break;
    case "pickupp":
      config = '{"token":"","region":"","sandbox":true}';
      break;
    case "poslaju":
      config = '{"app_id":"","app_key":""}';
      break;
    case "poslaju-usc":
      config = '{"client_id":"","client_secret":"","scope":"","prefix":"","applicationCode":"","secretid":"","username":"","accountNo":"","callerName":"","callerPhone":"","subscriptionCode":"","serviceType":"","requestPickup":true,"sandbox":true}';
      break;
    case "qxpress":
      config = '{"apiKey":"","accountId":"","sandbox":true}';
      break;
    case "sf-express":
      config = '{"appKey":"","appSecret":"","customerCode":"-","aesKey":"","payAccountNo":"","sandbox":true}';
      break;
    case "molpay":
      config = '{"merchantId":"","vkey":"","cancelURL":"","skey":""}';
      break;
    case "billplz":
      config = '{"collectionId":"","username":"","xSignatureKey":"-","sandbox":true}';
      break;
    case "kiple":
      config = '{"merchant_secret":"","ord_mercID":"","sandbox":true}';
      break;
    case "stripe":
      config = '{"secret_key":"","published_key":"","signing_secret":"-"}';
      break;
    case "plivo":
      config = '{"API_ID":"","API_TOKEN":"","SENDER_NO":""}';
      break;
    case "sendgrid":
      config = '{"SENDGRID_API_KEY":""}';
      break;
    case "toyyibpay":
      config = '{"userSecretKey":"","categoryCode":"","sandbox":true}';
      break;
    case "teleport":
      config = '{"email":"","password":""}';
      break;
    case 'thelorry':
      config = '{"apiKey":"","sandbox":true}';
      break;
    case 'dhl-ecommerce-my':
      config = '{"authURL":"","clientId":"","password":"","soldToAccountId":"","pickupAccountId":"","requestPickup":true,"returnMode":"03"}';
      break;
    default:
      config = '{"app_id":"","app_key":""}';
      break;
  }
  return config;
}
