import React, { useContext, useState } from "react";
import { Layout, Tabs, Row, Col, Spin, message } from "antd";
import { useTranslation } from "react-i18next";

import CustomizedButton from 'Components/CustomizedButton';
import { get } from 'Util/API';
import { GlobalContext } from "Store/store";

import TopupTab from "./TopupTabs/TopupTab";

const TabPane = Tabs.TabPane;

const TopupContent = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { topupPlan, paymentMethod } = props;
  const { customerDetails, companyDetails } = state.global;
  const walletBalance = state.global.customerDetails.walletBalance;
  const [isBalanceLoading, setIsBalanceLoading] = useState(false);
  const { t } = useTranslation('topupPage');

  const onRefreshAmount = async () => {
    setIsBalanceLoading(true);
    const getCustomerDetails = await get(`/customer/${customerDetails.id}?companyId=${companyDetails.id}`);
    if (getCustomerDetails.status === 200) {
      const newWalletBalance = getCustomerDetails.data.data.walletBalance;
      if (newWalletBalance !== walletBalance) {
        dispatch({
          type: 'GET_NEW_WALLET_BALANCE',
          payload: {
            walletBalance: newWalletBalance
          }
        })
      }
    } else message.error(getCustomerDetails);
    setIsBalanceLoading(false);
  }

  return (
    <Layout>
      <Row
        gutter={20}
        className="whiteBColor"
        style={{ padding: "30px 40px 20px 40px", margin: 0 }}
      >
        <Col xs={24} sm={24} md={24} lg={12} xl={12} style={{ display: 'flex', alignItems: 'flex-end' }}>
          <div style={{ margin: 10 }}>
            <p style={{ margin: 0 }}>{t('header.currentPlan')}</p>
            {
              customerDetails.planName ?
              <p className="heading1" style={{ margin: 0 }}>
                {customerDetails.planName}
              </p> :
              <p className="heading1" style={{ margin: 0 }}>
                -
              </p>
            }
          </div>
          <div style={{ margin: 10 }}>
            <p style={{ margin: 0 }}>{t('header.pageTitleTopup')}</p>
            {
              isBalanceLoading ?
              <div style={{ display: 'flex', justifyContent: 'center' }}><Spin size="small" /></div> :
              <p className="heading1" style={{ margin: 0 }}>
                {companyDetails.currency + ' ' + walletBalance}
              </p>
            }
          </div>
          <CustomizedButton
            text={t('header.refresh')}
            style={{ margin: 10 }}
            onClick={onRefreshAmount}
          />
        </Col>
      </Row>
      <Row>
        <Col className="tablistBColor">
          <Tabs defaultActiveKey="1" animated={false}>
            <TabPane tab={t('header.makePayment')} key="1">
              <TopupTab
                isLoading={props.isLoading}
                setLoading={props.setLoading}
                topupPrice={topupPlan.prices || []}
                paymentMethod={paymentMethod}
                topupPlan={topupPlan}
                currentStep={props.currentStep}
                invoiceId={props.invoiceId}
                transl={t}
                onRefreshAmount={onRefreshAmount}
              />
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    </Layout>
  )
}

export default TopupContent;
