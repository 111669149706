import React, { useContext, Fragment } from "react";
import { Avatar, Dropdown, Menu } from "antd";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { IMAGE_URL_ROOT, post } from "Util/API";
import { GlobalContext } from "Store/store";
import useLocalStorage from 'Util/useLocalStorage';

import styles from './rightMenu.module.css';

const RightMenu = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const { global: { userDetails, companyDetails, allCustomerAccounts, customerDetails, customerId } } = state;
  const { tertiaryColor } = companyDetails.personalization;
  const { displayName } = props;
  const t = {};
  t.pageWrapper = useTranslation('pageWrapper').t;

  const logout = async () => {
    let data = {
      companyId: userDetails.companyId,
      refreshToken: useLocalStorage.getItem("refreshToken")
    };

    const logoutResponse = await post("auth/logout", data);

    if (logoutResponse.status === 200) {
      dispatch({ type: "LOGOUT" })
    }
  };

  const changeAccount = async (id) => {
    useLocalStorage.setItem("customerId", id)
    dispatch({
      type: "CHANGE_ACCOUNT",
      payload: { id }
    })
  }

  return (
    <Dropdown
      overlay={
        <Menu defaultSelectedKeys={[customerId?.toString()]} className="right-menu-container">
          {
            allCustomerAccounts.map((item) => (
              <Menu.Item key={item.id} onClick={() => customerId !== item.id && changeAccount(item.id)}>
                <div>{item.name}</div>
                <div style={{ fontSize: '0.9em' }}>{item.email}</div>
              </Menu.Item>
            ))
          }
          <Menu.Item>
            <Link to={`${process.env.PUBLIC_URL}/settings`}>
              {t.pageWrapper(`rightMenus.settings`)}
            </Link>
          </Menu.Item>
          <Menu.Item>
            <Link to={`${process.env.PUBLIC_URL}/settings/profile-information`}>
              {t.pageWrapper(`rightMenus.language`)}
            </Link>
          </Menu.Item>
          <Menu.Item onClick={logout}>{t.pageWrapper(`rightMenus.logout`)}</Menu.Item>
        </Menu>
      }
      trigger={["click"]}
    >
      <a className="right-menu-avatar" href="/#">
        <Avatar
          icon={userDetails.profilePic === null && "user"}
          src={
            userDetails.profilePic !== null &&
            IMAGE_URL_ROOT + '/' + userDetails.profilePic
          }
        />
        {
          displayName &&
          <Fragment>
            <span className={styles.avatarName} style={{ color: tertiaryColor && tertiaryColor !== "#FFFFFF" && "#FFFFFF" }}>{customerDetails.name}</span>
            <i className="fas fa-angle-down" style={{ marginTop: 5, color: tertiaryColor && tertiaryColor !== "#FFFFFF" ? "#FFFFFF" : '#434343' }} />
          </Fragment>
        }
      </a>
    </Dropdown>
  )
}

RightMenu.propTypes = {
  displayName: PropTypes.bool
}

RightMenu.defaultProps = {
  displayName: true
}

export default RightMenu;
