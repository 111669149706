import React from "react";

import CustomizedButton from "Components/CustomizedButton";
import { IMAGE_URL_ROOT } from 'Util/API';

const arrowLeft = <i className="fas fa-chevron-left" />;
const arrowRight = <i className="fas fa-chevron-right" />;

const Vehicles = (props) => {
  const { availableVehicleTypes, selectedVehicle, setSelectedVehicle } = props;

  const onSelectVehicle = (index) => {
    props.form.setFieldValue('vehicleType', [index, availableVehicleTypes[index]]);
    setSelectedVehicle(index);
  }

  const vehicleLogo = IMAGE_URL_ROOT + '/' + availableVehicleTypes[selectedVehicle]?.logo;

  return (
    <div>
      <div className="container-selection">
        <CustomizedButton
          className="button-left-previous button__transparent"
          text={arrowLeft}
          onClick={() => selectedVehicle > 0 && onSelectVehicle(selectedVehicle - 1)}
        />
        <div>
          <div>
            <p className="heading3" style={{ textAlign: 'center' }}>{availableVehicleTypes[selectedVehicle].name}</p>
            <p className="icon-center">
              <img
                style={{ height: 36, marginBottom: 10 }}
                src={vehicleLogo}
                alt="company logo"
              />
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: 10, color: 'grey' }}>
                <i class="fas fa-box-open" style={{ fontSize: 12 }} />
                <div style={{ marginLeft: 5 }}>{availableVehicleTypes[selectedVehicle].maxWeight.value}{availableVehicleTypes[selectedVehicle].maxWeight.unit}</div>
              </div>
            </p>
          </div>
        </div>
        <CustomizedButton
          className="button-right-next button__transparent"
          text={arrowRight}
          onClick={() => selectedVehicle < availableVehicleTypes.length - 1 && onSelectVehicle(selectedVehicle + 1)}
        />
      </div>
      <div>
      <div style={{ overflow: 'auto' }}>
        <div className="main-list-slider">
          {
            availableVehicleTypes.map((item, index) => {
              return (
                <span
                  className="list-slider"
                  style={{ marginRight: 10, cursor: 'pointer', maxWidth: 100, display: 'flex', flexDirection: 'column' }}
                  onClick={() => onSelectVehicle(index)}
                >
                  <img
                    style={{ height: 16, marginBottom: 10, opacity: selectedVehicle === index ? 1 : 0.3 }}
                    src={IMAGE_URL_ROOT + '/' + item.logo}
                    alt="company logo"
                  />
                  <div style={{ fontSize: 10,color: 'black', opacity: selectedVehicle === index ? 1 : 0.3, textAlign: 'center' }}>{item.name}</div>
                </span>
              )
            })
          }
        </div>
      </div>
      </div>
    </div>
  )
}

export default Vehicles;
