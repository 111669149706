import React, { useState, useContext } from "react";
import { Tabs } from "antd";


import {
  useShowMessage,
  useGetSelectedRowsIds,
  useOnAllRowsSelectedChange,
  useOnSelectedRowsByPageChange,
  useOnOrderDataChange,
  useOnAllRowsDefaultCheckedChange,
  useOnFilterChange
} from './tableHelper';
import { GlobalContext } from 'Store/store';
import usePrevious from 'Util/usePrevious';
import ShowOn from 'Util/ShowOn';

const TabPane = Tabs.TabPane;

const ServerSidePaginationTable = (props) => {
  const [state] = useContext(GlobalContext);
  const {
    data: ordersTableData,
    setData: setOrdersTableData,
    location,
    isLoading,
    setIsLoading,
    history,
    defaultActiveKey,
    tab,
    generateColumn,
    itemRenderer: ItemRenderer,
    setAllRowsIds,
    allRowsIds,
    getAllRowsIds,
  } = props;
  const { page, rowTotal, data: orderData, dateFrom, dateTo, dateRangeId, status } = ordersTableData;
  const { state: locationState } = location;

  const [selectedRowsIds, setSelectedRowsIds] = useState([]);
  const [selectedRowsByPage, setSelectedRowsByPage] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [totalSelectedRows, setTotalSelectedRows] =  useState(0);
  const [allRows, setAllRows] =  useState({
    checked: false,
    selected: false,
    defaultChecked: false,
    unselectedRowsId: []
  });

  const onSetCurrentPageSelectedRowKeys = () => {
    let newSelectedRowKeys = [];
    selectedRowsByPage[page] && selectedRowsByPage[page].forEach((item) => {
      newSelectedRowKeys.push(item.key)
    })
    setSelectedRowKeys(newSelectedRowKeys);
  }

  const reinitializeTable = () => {
    setIsLoading(false);
    setAllRowsIds([]);
    setSelectedRowsIds([]);
    setSelectedRowsByPage({})
    setTotalSelectedRows(0);
    setSelectedRowKeys([]);
    setAllRows({
      checked: false,
      selected: false,
      defaultChecked: false,
      unselectedRowsId: []
    });
  }

  const resetState = (props) => {
    reinitializeTable();
    setOrdersTableData({
      ...ordersTableData,
      ...props,
      page: props.setToPage || 1
    })
  }

  useShowMessage(isLoading, locationState, history);

  useOnAllRowsSelectedChange({
    allRows,
    selectedRowsByPage,
    page,
    orderData,
    setSelectedRowsByPage,
    setTotalSelectedRows,
    rowTotal
  })

  useOnSelectedRowsByPageChange(
    selectedRowsByPage,
    onSetCurrentPageSelectedRowKeys
  )

  useOnOrderDataChange({
    setSelectedRowsByPage,
    selectedRowsByPage,
    page,
    allRows,
    orderData
  })

  useOnAllRowsDefaultCheckedChange({
    allRows,
    getAllRowsIds,
    setAllRowsIds
  })

  useGetSelectedRowsIds({
    selectedRowsByPage,
    defaultChecked: allRows.defaultChecked,
    unselectedIds: allRows.unselectedRowsId,
    allIds: allRowsIds,
    allRowsIds,
    setSelectedRowsIds: setSelectedRowsIds,
    allRows,
  })

  useOnFilterChange({
    dateFrom,
    dateTo,
    dateRangeId,
    reinitializeTable
  })

  const prevSelectedRows = usePrevious(selectedRowsByPage[page]);
  const onSetSelectedRowsByPage = (selectedRows) => {
    setSelectedRowsByPage({
      ...selectedRowsByPage,
      [page]: selectedRows
    });
    setTotalSelectedRows(totalSelectedRows - prevSelectedRows.length + selectedRows.length);
  };

  const selectAll = () => {
    setAllRows({
      unselectedRowsId: [],
      defaultChecked: !allRows.defaultChecked,
      selected: !allRows.selected,
      checked: !allRows.checked
    });
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: (key, selected) => {
      if (allRows.defaultChecked) {
        const unselectedRowsIdCopy = allRows.unselectedRowsId;
        const newUnselectedRowsIds = selectedRowsByPage[page].map((item) => item.id);
        let newArr;
        if (selected.length === 0) {
          newArr = newUnselectedRowsIds.concat(unselectedRowsIdCopy)
        } else {
          const selectedIds = selected.map((item) => item.id);
          newArr = unselectedRowsIdCopy.filter((i) => !selectedIds.includes(i))
        }
        setAllRows({
          ...allRows,
          unselectedRowsId: newArr
        })
      }
      onSetSelectedRowsByPage(selected);
    },
    onSelect: (record, selected) => {
      if (allRows.defaultChecked) {
        const newArr = allRows.unselectedRowsId.slice(0);
        if (selected) {
          const index = newArr.indexOf(record.id);
          newArr.splice(index, 1);
        } else {
          newArr.push(record.id);
        }
        setAllRows({
          ...allRows,
          unselectedRowsId: newArr
        })
      }
    }
  };

  const columns = generateColumn({
    state,
    history,
    selectedRowsIds,
    setIsLoading,
    resetState,
    ordersTableData
  });

  return (
    <Tabs
      defaultActiveKey={defaultActiveKey}
      animated={false}
      onChange={(val) => resetState({ status: val })}
    >
      {
        tab.map((item) => (
          <TabPane tab={item.name} key={item.key}>
            <ShowOn on={status === item.key}>
              <ItemRenderer
                selectedRowKeys={selectedRowKeys}
                orderIds={selectedRowsIds}
                totalSelectedRows={totalSelectedRows}
                checked={allRows.checked}
                selectAll={selectAll}
                columns={columns}
                rowSelection={rowSelection}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                resetState={resetState}
                ordersTableData={ordersTableData}
                selectedRowsByPage={selectedRowsByPage}
                history={history}
                setOrdersTableData={val => {
                  let validItem = {};
                  Object.keys(val).forEach((item) => {
                    if (val[item] !== undefined) {
                      validItem[item] = val[item]
                    }
                  })
                  const newData = {...ordersTableData, ...validItem};
                  setOrdersTableData(newData)
                }}
              />
            </ShowOn>
          </TabPane>
        ))
      }
    </Tabs>
  )
}

export default ServerSidePaginationTable;
