import { useEffect, useContext, useMemo, useState } from 'react';
import { message } from "antd";

import { GlobalContext } from "Store/store";
import usePrevious from 'Util/usePrevious';

import checkPrice from './checkPrice';

const useUpdateServices = (form, setSectionLoading) => {
  const [state] = useContext(GlobalContext);
  const { customerDetails, companyDetails } = state.global;
  const { values, setFieldValue } = form;
  const {
    pickupDate,
    pickupTime,
    selectedServiceIndex,
    services,
    addon,
    promoCode,
    pickupNow
  } = values;

  const addonKeys = useMemo(() => Object.keys(addon).length, [addon]);
  const prevAddonKeys = usePrevious(addonKeys);
  const [fetch, setFetch] = useState();

  const onCheckPrice = async () => {
    setSectionLoading(true);
    const fetch = await checkPrice(values, companyDetails.id, customerDetails.id);
    if (fetch.status === 200) {
      const { data } = fetch.data;
      const updatedServices = services;
      if (data.services[0]) {
        updatedServices[Number(selectedServiceIndex)] = data.services[0] || [];
        form.setFieldValue('services', updatedServices);
      } else {
        setFieldValue('selectedServiceIndex', null)
        setFieldValue('addon', {})
        setFieldValue('promoCode', "")
        setFieldValue('errors', [])
        setFieldValue('services', null)
      }
      const { errors } = fetch.data;
      form.setFieldValue('errors', errors);
    } else {
      message.error(fetch);
    };
    setSectionLoading(false);
  }

  useEffect(() => {
    let mount = true;
    if (selectedServiceIndex) {
      if (prevAddonKeys === addonKeys) {
        fetch && clearTimeout(fetch);
        mount && setFetch(setTimeout(() => onCheckPrice(), 750));
      } else {
        onCheckPrice()
      }
    }
    return () => {
      mount = false;
    }
  }, [addon]);

  useEffect(() => {
    form.setFieldValue('errors', [])
    if (selectedServiceIndex) {
      onCheckPrice()
    }
  }, [
    pickupDate,
    pickupTime,
    pickupNow,
    promoCode
  ]);
}

export default useUpdateServices;
