import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Button, Row, Col, message } from "antd";

import { API_ROOT } from 'Util/API';
import useLocalStorage from 'Util/useLocalStorage';
import ShowOn from 'Util/ShowOn';

const OrdersHeader = (props) => {
  const { filterAndTableState: ordersTableData, activeTabKey, companyDetails, unbilledAmount } = props;
  const [exportLink, setExportLink] = useState(null);
  const { dateRangeId, dateFrom, dateTo } = ordersTableData;
  const { t } = useTranslation('orderPage');

  const allowToExport = dateRangeId !== 0 || (dateFrom && dateTo);

  useEffect(() => {
    getExportLink()
  }, [ordersTableData])

  const getExportLink = async () => {
    const dataCopy = { ...ordersTableData };
    dataCopy.dateFrom = dataCopy.dateFrom?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.dateTo = dataCopy.dateTo?.format('YYYY-MM-DD').toLocaleString();
    dataCopy.limit = dataCopy.rowTotal;
    dataCopy.jwt = useLocalStorage.getItem("accessToken");
    delete dataCopy.data;
    delete dataCopy.pageTotal;
    delete dataCopy.rowTotal;
    delete dataCopy.dateRangeId;
    delete dataCopy.tabKey;
    const params = [];
    Object.keys(dataCopy).forEach((key, i) => {
      dataCopy[key] && params.push((i > 0 ? '&' : '') + key + '=' + dataCopy[key]);
    });
    setExportLink(`${API_ROOT}wallet/tx/export?` + params.join(""))
  }

  return (
    <Row style={{ marginBottom: 30 }}>
      <Col xs={24} sm={24} md={12} lg={12} xl={12}>
        <p style={{ margin: 0, float: "left" }}>
        <span className="heading1">{activeTabKey === 'invoice' ? 'Invoice' : 'Transaction'}</span>
        {activeTabKey === 'invoice' ? <span>
        Unbilled amount: {companyDetails.currency} {unbilledAmount}
        </span> : ''}
        </p>
      </Col>
      <ShowOn on={activeTabKey !== 'invoice'}>
        <Col
          xs={24}
          sm={24}
          md={12}
          lg={12}
          xl={12}
          className="navigation-table"
        >
          {
            allowToExport ?
            <Button
              className="button-bg-transparent"
              style={{ padding: 0, marginRight: 15 }}
              target="_blank"
              href={exportLink}
            >
              Export <i className="fa fa-upload" style={{ marginLeft: 5 }} />
            </Button> :
            <Button
              className="button-bg-transparent"
              style={{ padding: 0, marginRight: 15 }}
              onClick={() => message.error('Please select date range.')}
            >
              {t('content.header.export')} <i className="fa fa-upload" style={{ marginLeft: 5 }} />
            </Button>
          }
        </Col>
      </ShowOn>
    </Row>
  )
}

export default OrdersHeader;
