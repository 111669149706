import React, { useContext, useState } from "react";
import { useField } from 'formik';
import { useTranslation } from "react-i18next";

import CustomizedButton from "Components/CustomizedButton";
import { GlobalContext } from "Store/store";
import generateAddressByKeys from 'Util/generateAddressByKeys';

import ContactDetails from './ContactDetails';
import CopypasteForm from './CopypasteForm';

const ContactDetailsWrapper = (props) => {
  const [__state] = useContext(GlobalContext);
  const { modalState, setModalState } = __state.global;
  const { header, onClickNext, inputArray, type, countryDropdownChildren } = props;
  const { form } = __state.newOrder;
  const { setFieldValue, values } = form;
  const [copyPaste, activeCopyPaste] = useState(false);
  const [copyPasteInput, setCopyPasteInput] = useState();
  const { t } = useTranslation('orderPage');

  useField(type + 'Name');
  useField(type + 'Mobile')
  useField(type + 'UnitNo')
  useField(type + 'City')
  useField(type + 'State')
  useField(type + 'Postcode')
  useField(type + 'Country')
  useField('selectedOutletIndex')

  const onSetCopyPasteValue = (val) => {
    let isInputEmpty = true;
    const newCopyPasteInput = inputArray.reduce((acc, item, index) => {
      const value = values[item.fullAccessor];
      if (isInputEmpty && value) {
        isInputEmpty = false;
      }
      return acc.concat(value ? value + (`\n`).repeat(item.line) : (`\n`).repeat(item.line));
    }, ``)
    setCopyPasteInput(isInputEmpty ? null : newCopyPasteInput)
    activeCopyPaste(val);
  }

  const onSetNormalFormValue = (val, val2) => {
    const copyPasteInputArray = val2 ? val2.split(/\r?\n/g) : [];
    inputArray.reduce((acc, item) => {
      let value = copyPasteInputArray[acc];
      if (value && item.line > 1) {
        let i;
        for (i = acc + 1; i < acc + item.line; i++) {
          if (copyPasteInputArray[i]?.length > 1) {
            value = value.concat(' ' + copyPasteInputArray[i])
          }
        }
      }
      setFieldValue(item.fullAccessor, value, false);
      return acc + item.line;
    }, 0);
  }

  const onSelectAddress = (val) => {
    console.log('val',val);
    const contactData = {
      ...val
    }
    console.log('contactData',contactData);
    console.log('inputArray',inputArray);
    inputArray.forEach((item) => {
      console.log('item',item);
      console.log('props1',props.type + item.accessor);
      console.log('props2',contactData[item.accessor.toLowerCase()]);
      console.log('props3',item.accessor.toLowerCase());
      console.log('props3',item.accessor);
      if(item.accessor == "UnitNo")
      {
          console.log('props2',contactData["unitNo"]);
          setFieldValue(props.type + item.accessor, contactData["unitNo"]);
      }else {
          setFieldValue(props.type + item.accessor, contactData[item.accessor.toLowerCase()]);
      }
    })
    setModalState({
      ...modalState,
      isModalVisible: false
    });
  }

  const onOpenSearchAddressDialog = async () => {
    const { default: dialogContent } = await import(`Pages/Order/SearchAddressDialogContent.js`);
    setModalState({
      ...modalState,
      isModalVisible: true,
      footer: false,
      modal: {
        title: t('content.dialog.searchAddressBook.title'),
        dialogContent,
        maxWidth: 800,
        dialogContentProps: {
          onSelectAddress
        }
      }
    });
  }

  return (
    <div style={{ width: "100%" }} >
      <div style={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: 'baseline' }}>
        <p className="heading3">{header}</p>
        <CustomizedButton
          text={t('content.newOrder.searchAddressBook')}
          onClick={onOpenSearchAddressDialog}
        />
      </div>
      <div style={{ backgroundColor: "white", padding: "30px", width: "100%" }}>
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomizedButton
            className="button-bg-transparent"
            style={{ color: copyPaste ? 'unset' : '#1890FF' }}
            text={t('content.newOrder.form.form')}
            onClick={() => { copyPaste && activeCopyPaste(false) }}
          />
          <CustomizedButton
            className="button-bg-transparent"
            style={{ color: copyPaste ? '#1890FF' : 'unset' }}
            text={t('content.newOrder.form.copyPaste')}
            onClick={() => !copyPaste && onSetCopyPasteValue(true)}
          />
        </div>
        {
          copyPaste ?
          <CopypasteForm
            inputArray={inputArray}
            form={form}
            inputValue={copyPasteInput}
            setInputValue={setCopyPasteInput}
            countryDropdownChildren={countryDropdownChildren}
            onSetNormalFormValue={onSetNormalFormValue}
            type={type}
          /> :
          <ContactDetails
            type={type}
            countryDropdownChildren={countryDropdownChildren}
            onChangePriceAffectedField={props.onChangePriceAffectedField}
          />
        }
        <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <CustomizedButton
            text={t('content.newOrder.next')}
            onClick={() => {
              onClickNext();
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ContactDetailsWrapper;
