import React from "react";
import { Form, Checkbox } from "antd";

const CheckBox = ({
  errors,
  name,
  text,
  isForgotPassword,
  setFieldValue,
  setFieldTouched,
  touched,
  onChange,
  style,
  className,
  checkboxStyle,
  checked,
  description,
  disabled
}) => (
  <Form.Item
    validateStatus={touched[name] && errors[name] && "error"}
    help={touched[name] ? errors[name] : ""}
    style={style}
    className={className}
  >
      <Checkbox
        onChange={
          onChange
            ? onChange
            : e => {
                setFieldValue(name, e.target.checked);
                setFieldTouched(name, true, false);
              }
        }
        checked={checked}
        style={checkboxStyle}
        disabled={disabled}
      >
        <span style={{ color: '#000000d9' }}>{text}</span>
      </Checkbox>
      {
        description &&
        <div style={{ fontSize: 13 }}>
          {description}
        </div >
      }
  </Form.Item>
);

export default CheckBox;
