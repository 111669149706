import React from "react";
import ReactDOM from "react-dom";
// import * as Sentry from "@sentry/browser";
import i18n from "i18next";
import { I18nextProvider } from "react-i18next";

import "font-awesome/css/font-awesome.min.css";
import "./index.css";

import App from "./App";
import { registerServiceWorker } from './serviceWorker';

// Sentry.init({
//   dsn: "https://088493da0bba4d05b731134621738ffc@sentry.io/1837228",
//   release: "web-customer@79dc7d5b"
// });

i18n.init({
  interpolation: { escapeValue: false },
  lng: 'en_US',
  resources: {}
});

const rootEl = document.getElementById('root')

ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>,
  rootEl
)

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default
    ReactDOM.render(
      <I18nextProvider i18n={i18n}>
        <NextApp />
      </I18nextProvider>,
      rootEl
    )
  })
}

registerServiceWorker()
