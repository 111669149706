import React, { useContext, useEffect } from "react";
import { message, Spin } from "antd";
import i18n from "i18next";
import ReactGA from 'react-ga';
import ReactPixel from 'react-facebook-pixel';
import TagManager from 'react-gtm-module';
// import TiktokPixel from 'tiktok-pixel';


import languages from 'languages.json';
import { GlobalContext } from "Store/store";
import { get, getCompanyInfo } from "Util/API";
import useLocalStorage from 'Util/useLocalStorage';

import getUserInformation from './getUserInformation';

import mixpanel from 'mixpanel-browser';

function removeDuplicate(arr, key) {
  if (typeof arr.length === 'number') {
    let newObj = {};
    newObj = arr.reduce((acc, item) => {
      acc[item[key]] = item;
      return acc;
    }, {})
    return Object.keys(newObj).map((key) => ({
      ...newObj[key]
    }))
  }
}

async function getUserAndCountry(companyId) {
  const getUserDetails = get("user");
  const getCountryList = get("parameters/q/country");
  const getGuestSettings = get(`company/${companyId}/guest-settings/?name=consumer_portal.dashboard`);
  const getMixpanelSettings = get(`company/${companyId}/guest-settings/?name=mixpanel`);

  return {
    getUserDetails: await getUserDetails,
    countryList: await getCountryList,
    guestSettings: await getGuestSettings,
    mixpanelSettings: await getMixpanelSettings
  }
}

async function afterLoginFetch() {
  const getSettings = get("company/settings");
  const getTopupPlan = get("topup/plan/customer");
  const getCustomerType = get("/parameters/customerType");

  return {
    settings: await getSettings,
    topupPlan: await getTopupPlan,
    getCustomerType: await getCustomerType
  }
}

const withAuthentication = Component => {
  const WithAuthentication = props => {
    const [state, dispatch] = useContext(GlobalContext);
    const urlParams = new URLSearchParams(window.location.search);
    const referral = urlParams.get("refer");
    const { authCheck, customerDetails, ...others } = state.global;
    const lang = useLocalStorage.getItem("lang") || state.global.lang || "en_US";

    getUserInformation();

    const loadLang = async () => {
      const { default: componentsFile } = await import(`translations/${lang}/components.json`);
      const { default: pageWrapperLangFile } = await import(`translations/${lang}/pageWrapper.json`);
      const { default: homepageFile } = await import(`translations/${lang}/homepage.json`);
      const { default: addressBookPageFile } = await import(`translations/${lang}/addressbookpage.json`);
      const { default: billingPageFile } = await import(`translations/${lang}/billingpage.json`);
      const { default: orderPageFile } = await import(`translations/${lang}/orderpage.json`);
      const { default: settingsPageFile } = await import(`translations/${lang}/settings.json`);
      const { default: topupPageFile } = await import(`translations/${lang}/topuppage.json`);
      const { default: orderDetailsPageFile } = await import(`translations/${lang}/orderDetailsPage.json`);
      const { default: importBulkOrderFile } = await import(`translations/${lang}/importBulkOrder.json`);
      const { default: multipointOrderFile } = await import(`translations/${lang}/multiPointOrder.json`);
      const { default: integrationPageFile } = await import(`translations/${lang}/integrationpage.json`);
      i18n.addResourceBundle(lang, 'pageWrapper', pageWrapperLangFile, true, true);
      i18n.addResourceBundle(lang, 'components', componentsFile, true, true);
      i18n.addResourceBundle(lang, 'homePage', homepageFile, true, true);
      i18n.addResourceBundle(lang, 'addressBookPage', addressBookPageFile, true, true);
      i18n.addResourceBundle(lang, 'billingPage', billingPageFile, true, true);
      i18n.addResourceBundle(lang, 'orderPage', orderPageFile, true, true);
      i18n.addResourceBundle(lang, 'integrationPage', integrationPageFile, true, true);
      i18n.addResourceBundle(lang, 'settingsPage', settingsPageFile, true, true);
      i18n.addResourceBundle(lang, 'topupPage', topupPageFile, true, true);
      i18n.addResourceBundle(lang, 'orderDetailsPage', orderDetailsPageFile, true, true);
      i18n.addResourceBundle(lang, 'orderDetailsPage', orderDetailsPageFile, true, true);
      i18n.addResourceBundle(lang, 'importBulkOrderPage', importBulkOrderFile, true, true);
      i18n.addResourceBundle(lang, 'multiPointOrderPage', multipointOrderFile, true, true);
      await i18n.changeLanguage(lang);
    }

    useEffect(() => {
      let mount = true;
      const fetchData = async () => {
        dispatch({ type: "AUTH_INIT" });
        const getCompanyRes = await getCompanyInfo();
        // const fetchStartedData = await getCompanyAppUrl();
        if (getCompanyRes?.status === 200) {
          const companyDetails = getCompanyRes.data.data ? getCompanyRes.data.data[0] : getCompanyRes.data;
          await loadLang();
          const antdConfigLabel = languages[lang].antd_config_label;
          const { default: newComponentLang } = await import(`antd/es/locale/${antdConfigLabel || 'en_US'}`);
          const userAndCountry = await getUserAndCountry(getCompanyRes.data.data[0].id);
          const { getUserDetails, countryList, guestSettings, mixpanelSettings } = userAndCountry;
          // const getStartedData = fetchStartedData?.status === 200 ? fetchStartedData.data : [];

          // const getStartedDataObj = getStartedData.reduce((acc, item) => {
          //   acc[item.company_code] = item;
          //   return acc;
          // }, {});

          let guestSettingsObj = {}
          if (guestSettings?.status === 200) {
            guestSettingsObj = guestSettings.data.data.reduce((acc, item) => {
              acc[item.name] = item;
              return acc;
            }, {})
            const fbPixelId = guestSettingsObj["consumer_portal.dashboard"]?.data?.scripts_fb_pixelid;
            const googleAnalyticsPixelId = guestSettingsObj["consumer_portal.dashboard"]?.data?.scripts_google_analyticid;
            const googleAdsPixelId = guestSettingsObj["consumer_portal.dashboard"]?.data?.scripts_google_adwordsid;

            // const tiktokPixelId = (companyDetails.code == 'my')  ? 'CIJ2V2RC77UCKS2E036G': null;

            ////exclude verify && reset-password page          
            if( !window.location.pathname.includes('verify') && !window.location.pathname.includes('reset-password') )
            {
              if (fbPixelId) {
                ReactPixel.init(fbPixelId, {}, { autoConfig: true, debug: false })
                ReactPixel.pageView();
              }
              if(googleAnalyticsPixelId)
              {
                  ReactGA.initialize(googleAnalyticsPixelId, { debug: false });
                  ReactGA.plugin.require('ecommerce', { path: '/log', debug: false });
              }
              if(googleAdsPixelId)
              {
                  const tagManagerArgs = {
                    gtmId: googleAdsPixelId,
                    dataLayer:  {
                      'event': 'webapp_view',
                    }
                  }
                  TagManager.initialize(tagManagerArgs)
              }
              // if(tiktokPixelId)
              // {
              //     // const advancedMatching = {
              //     //   email: 'some@email.com',
              //     //   phone_number: '0123456789',
              //     // }; // optional, more info: https://ads.tiktok.com/help/article?aid=10007891
                  
              //     const options = {
              //       debug: false, // enable logs
              //     };

              //     TiktokPixel.init('yourPixelIdGoesHere');
              // }
            }
          }

          let mixpanelSettingsObj = {}
          if (mixpanelSettings?.status === 200) {
            mixpanelSettingsObj = mixpanelSettings.data.data.reduce((acc, item) => {
              acc[item.name] = item;
              return acc;
            }, {})

            const mpToken = mixpanelSettingsObj["mixpanel"]?.data?.token;

            useLocalStorage.setItem("mpToken",mpToken);

            mixpanel.init(mpToken);
          }

          if (getUserDetails?.status === 200) {
            const afterLoginFetchResult = await afterLoginFetch();
            const {
              // getServices,
              getServiceCompanies,
              getItemTypes,
              getOutlets,
              getPaymentMethods,
              // getServiceGroups,
              getCustomerType,
              getIndustryType,
              topupPlan,
              settings
            } = afterLoginFetchResult;

            let settingsObj = {}
            if (settings?.status === 200) {
              settingsObj = settings.data.data.reduce((acc, item) => {
                acc[item.name] = item;
                return acc;
              }, {})
            }

            mount && dispatch({
              type: "AUTH_SUCCESS",
              payload: {
                 ...others,
                customerDetails,
                companyDetails,
                countryList: countryList?.status === 200 ? removeDuplicate(countryList.data.data, 'countryCode') : [],
                customerType: getCustomerType?.status === 200 ? getCustomerType.data.data : [],
                industryType: getIndustryType?.status === 200 ? getIndustryType.data.data : [],
                itemTypes: getItemTypes?.status === 200 ? getItemTypes.data.data : [],
                outlets: getOutlets?.status === 200 ? getOutlets.data.data : [],
                paymentMethods: getPaymentMethods?.status === 200 ? getPaymentMethods.data.data : [],
                userDetails: getUserDetails?.status === 200 ? getUserDetails.data.data : {},
                // services: getServices?.status === 200 ? getServices.data.data : [],
                serviceCompanies: getServiceCompanies?.status === 200 ? getServiceCompanies.data.data : [],
                // serviceGroups: getServiceGroups?.status === 200 ? getServiceGroups.data.data : [],
                topupPlan: topupPlan?.status === 200 ? topupPlan.data.data : [],
                settings: { ...settingsObj, ...guestSettingsObj },
                antdLangFile: newComponentLang,
                lang,
                // getStartedData: getStartedDataObj[companyDetails.code] || getStartedDataObj.default,
                authFinish: false
              }
            })
          } else {
            mount && dispatch({
              type: "AUTH_SUCCESS",
              payload: {
                companyDetails: getCompanyRes.data.data ? getCompanyRes.data.data[0] : getCompanyRes.data,
                countryList: countryList?.status === 200 ? countryList.data.data : [],
                userDetails: {},
                antdLangFile: newComponentLang,
                lang,
                authFinish: true
              }
            })
          }
        } else {
          message.error(getCompanyRes);
          dispatch({ type: "AUTH_FAILURE" });
        }
      }
      !authCheck && fetchData();
      return () => {
        mount = false;
      };
    }, [authCheck]);

    return (
      authCheck ?
        <Component /> :
        <div>
          <Spin
            className={referral === 'SSL' && 'mb-spin'}
            style={{ width: '100vw', top: '50vh', position: 'absolute', color: 'yellow' }}
          />
        </div>
    );
  };

  return WithAuthentication;
};

export default withAuthentication;
