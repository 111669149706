import React, { useState, useContext, useEffect } from 'react';
import { Spin } from 'antd';
import styled from '@emotion/styled'

import InstantQuote from 'Pages/Home/InstantQuote';
import PublicHeader from 'Components/PublicHeader';
import { GlobalContext } from "Store/store";
import { getItemTypes } from 'Fetch';

const LoadMapSpinner = styled.div`
  position: absolute;
  top: calc(50vh);
  left: calc(50vw);
`;

const FetchWrapper = (props) => {
  const [store, dispatch] = useContext(GlobalContext);
  const [fetchItems, setFetchItems] = useState(null);
  const { companyDetails } = store.global;

  async function allFetch() {
    const getItemTypesRes = await getItemTypes(companyDetails.id);

    return {
      getItemTypesRes: await getItemTypesRes,
    }
  }

  useEffect(() => {
    const fetch = async () => {
      const fetchAll = await allFetch();
      const { getItemTypesRes } = fetchAll;
      const itemTypesDataSource = getItemTypesRes.status === 200 ? getItemTypesRes.data?.data : [];
      const parcelIndex = itemTypesDataSource.findIndex((item) => item.name === 'PARCEL');
      if (parcelIndex !== -1) {
        const parcelData = itemTypesDataSource[parcelIndex];
        itemTypesDataSource.splice(parcelIndex, 1);
        itemTypesDataSource.splice(0, 0, parcelData);
      }
      setFetchItems({
        itemTypes: itemTypesDataSource
      })
    }
    fetch()
  }, [])

  return fetchItems
    ? (
      <GlobalContext.Provider value={[{ ...store, global: { ...store.global, itemTypes: fetchItems.itemTypes } }, dispatch]}>
        <InstantQuotePage wrapper={{ ...fetchItems }} {...props} />
      </GlobalContext.Provider>
    )
    : (
      <LoadMapSpinner>
        <Spin spinning />
      </LoadMapSpinner>
    )
}

const InstantQuotePage = (props) => {
  const [store] = useContext(GlobalContext);
  const [quotation, setQuotation] = useState({ services: [] });
  const [isLoading, setIsLoading] = useState(false);

  const pathname = window.location.pathname;
  const urlParams = new URLSearchParams(pathname);
  const initialValues = {
    origin: {
      title: urlParams.get('o.title') || undefined,
      address1: urlParams.get('o.address1') || "",
      address2: urlParams.get('o.address2') || "",
      city: urlParams.get('o.city') || "",
      state: urlParams.get('o.state') || "",
      zipcode: urlParams.get('o.zipcode') || "",
      country: urlParams.get('o.country') || "",
      countryName: urlParams.get('o.countryName') || "",
      lat: Number(urlParams.get('o.lat')),
      lon: Number(urlParams.get('o.lon')),
      type: urlParams.get('o.type') || 0
    },
    destination: {
      title: urlParams.get('d.title') || undefined,
      address1: urlParams.get('d.address1') || "",
      address2: urlParams.get('d.address2') || "",
      city: urlParams.get('d.city') || "",
      state: urlParams.get('d.state') || "",
      zipcode: urlParams.get('d.zipcode') || "",
      country: urlParams.get('d.country') || "",
      countryName: urlParams.get('d.countryName') || "",
      lat: Number(urlParams.get('d.lat')),
      lon: Number(urlParams.get('d.lon')),
      type: urlParams.get('d.type') || 0
    },
    weight: urlParams.get('weight') || 0.1,
    itemType: urlParams.get('itemType') || store.global.itemTypes[0] || undefined
  }

  const onSubmit = (values) => {
    const { weight } = values;
    const paramsArr = [];
    const addParams = (val, str) => Object.keys(val).forEach((key, index) => {
      val[key] && paramsArr.push(`&${str}.${key}=${val[key]}`)
    });
    addParams(values.origin, 'o');
    addParams(values.destination, 'd');
    paramsArr.push(`&weight=${weight}`);
    const retry = urlParams.get('retry') || 0;
    paramsArr.push(`&retry=${Number(retry) + 1}`);
    props.history.push(`${process.env.PUBLIC_URL}/check-rates/${paramsArr.join("")}`)
  }

  return (
    <PublicHeader style={{ width: '100%' }}>
      <div style={{ width: '100%', maxWidth: 1280 }}>
        <InstantQuote
          setQuotation={setQuotation}
          quotation={quotation}
          history={props.history}
          initialValues={initialValues}
          onSubmit={onSubmit}
          setIsLoading={setIsLoading}
          isLoading={isLoading}
        />
      </div>
    </PublicHeader>
  )
}

export default FetchWrapper;
