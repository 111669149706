import React, { Component } from "react";
import { Form } from "antd";
import { Formik } from "formik";
import * as Yup from "yup";

import {
  MESSAGE_MAX_TEN_NUMBERS,
  MESSAGE_PHONE_NUMBER_REQUIRED
} from "Util/ErrorMessage";
import PhoneNumberInput from "Components/PhoneNumberInput";
import CustomizedButton from "Components/CustomizedButton";
import {
  TEXT_SEARCH_COUNTRY
} from "Util/Text";

const formik = {
  initialValues: {
    newPhoneNumber: ""
  },
  validationSchema: Yup.object().shape({
    newPhoneNumber: Yup.string()
      .min(10, MESSAGE_MAX_TEN_NUMBERS)
      .required(MESSAGE_PHONE_NUMBER_REQUIRED)
  })
};

class ChangePhoneNumberForm extends Component {
  render() {
    return (
      <Formik {...formik} onSubmit={this.props.submitNewPhoneNumber}>
        {form => (
          <Form
            layout="vertical"
            onSubmit={form.handleSubmit}
            className="manage-auth-form"
            style={{ marginTop: 20 }}
          >
            <PhoneNumberInput
              {...form}
              name="phoneNumber"
              enableSearchField={true}
              searchPlaceholder={TEXT_SEARCH_COUNTRY}
              onChange={value => {
                form.values.newPhoneNumber = value;
              }}
              label={this.props.transl('content.changePhoneNo.form.newPhoneNumber')}
            />
            <Form.Item
              className="manage-auth-form-item"
              style={{ marginBottom: 0 }}
            >
              <CustomizedButton
                text={this.props.transl('content.changePhoneNo.form.change')}
                type="primary"
                htmlType="submit"
                isLoading={this.props.isLoading}
              />
            </Form.Item>
          </Form>
        )}
      </Formik>
    );
  }
}

export default ChangePhoneNumberForm;
