import React, { useState, useContext } from "react";

import { Layout, Form, Card, Spin, message } from "antd";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import * as Yup from "yup";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';
import TiktokPixel from 'tiktok-pixel';

import useLocalStorage from 'Util/useLocalStorage';
import {
  MESSAGE_INVALID_EMAIL_ADDRESS,
  MESSAGE_EMAIL_REQUIRED,
  MESSAGE_PASSWORD_REQUIRED,
  MESSAGE_NAME_REQUIRED
} from "Util/ErrorMessage";
import TextInput from "Components/TextInput";
import {
  TEXT_EMAIL_ADDRESS,
  TEXT_PASSWORD,
  TEXT_SIGNUP,
  TEXT_LOADING_PAGE,
  TEXT_FULL_NAME,
  TEXT_SEARCH_COUNTRY
} from "Util/Text";
import PasswordInput from "Components/PasswordInput";
import CustomizedButton from "Components/CustomizedButton";
import CheckBox from 'Components/Checkbox'
import { post } from "Util/API";
import { GlobalContext } from 'Store/store';
import PhoneNumberInput from "Components/PhoneNumberInput";
import { IMAGE_URL_ROOT } from 'Util/API';

const SignUpPage = (props) => {
  const [state, dispatch] = useContext(GlobalContext);
  const pathname = window.location.search;
  const urlParams = new URLSearchParams(pathname);
  let countryCode = urlParams.get('countryCode')?.toLowerCase();
  const referralCode = urlParams.get('referralCode');
  const formik = {
    initialValues: {
      fullname: urlParams.get('fullname') || "",
      email: urlParams.get('email') || "",
      password: "",
      phoneNumber: urlParams.get('phoneNumber') || "",
      terms: false,
      referralCode
    },
    validationSchema: Yup.object().shape({
      fullname: Yup.string().required(MESSAGE_NAME_REQUIRED),
      email: Yup.string()
        .email(MESSAGE_INVALID_EMAIL_ADDRESS)
        .required(MESSAGE_EMAIL_REQUIRED),
      password: Yup.string().required(MESSAGE_PASSWORD_REQUIRED),
      phoneNumber: Yup.string().required("Phone number is required"),
      terms: Yup.bool().oneOf([true], 'Field must be checked'),
      referralCode: Yup.string().nullable()
    })
  };
  if (referralCode) {
    useLocalStorage.setItem("referralCode", referralCode);
  }
  const { companyDetails } = state.global;
  const [isLoading, setIsLoading] = useState(false);

  countryCode = companyDetails.country ? companyDetails.country.toLowerCase() : urlParams.get('countryCode')?.toLowerCase();

  const submitSignup = async values => {
    setIsLoading(true);
    let userdata = {
      companyCode: companyDetails.code,
      name: values.fullname,
      mobile: values.phoneNumber,
      email: values.email,
      password: values.password,
      referralCode: values.referralCode || useLocalStorage.getItem("referralCode")
    };
    const signupResponse = await post("user/register", userdata);
    if (signupResponse.status === 200) {
      const { accessToken, refreshToken, companyId } = signupResponse.data.data;
      useLocalStorage.clear();
      useLocalStorage.setItem("companyId", companyId);
      useLocalStorage.setItem("accessToken", accessToken);
      useLocalStorage.setItem("refreshToken", refreshToken);
      ReactPixel.track('CompleteRegistration', { email: values.email, mobile: values.phoneNumber });
      ReactGA.event({
        category: 'User',
        action: 'Created an Account'
      });

      const tiktokPixelId = (companyDetails.code == 'my')  ? 'CIJ2V2RC77UCKS2E036G': null;

      if(tiktokPixelId)
      {
          const advancedMatching = {
              email: values.email,
              phone_number: values.phoneNumber,
          }; // optional
                
          const options = {
            debug: false, // enable logs
          };

          TiktokPixel.init(tiktokPixelId, advancedMatching, options);

          TiktokPixel.track('CompleteRegistration', {
            // content_type: 'product',
            // quantity: 1,
            // content_name: 'Double Cheese Burger',
            // content_id: '2',
            // currency: 'AUD',
            // value: 10,
          });
      }

      dispatch({ type: 'SIGNUP_SUCCESS' })
    } else {
      setIsLoading(false);
      message.error(signupResponse);
    }
  };

  return (
    <Spin tip={TEXT_LOADING_PAGE} spinning={isLoading}>
      <Layout style={{ height: "100vh", overflow: "hidden" }}>
        <div className="center">
          <div className="logo-company">
            <img
              style={{ height: 64, margin: '0 20px' }}
              src={
                companyDetails.logob ?
                  IMAGE_URL_ROOT + companyDetails.logob :
                  'https://cdn.delyva.app/assets/delyvax-logo-b.svg'
                }
              alt="company logo"
            />
          </div>
          <p
            className="heading2"
            style={{ textAlign: "center", fontWeight: 400 }}
          >
            Sign Up
          </p>
          <Card>
            <Formik {...formik} onSubmit={submitSignup}>
              {form => {
                return (
                  <Form onSubmit={form.handleSubmit} className="login-form">
                    {state.global.companyDetails.code ? null : (
                      <TextInput
                        {...form}
                        name="companyCode"
                        placeholder="Company code"
                        type="text"
                        className="marginBottom-10"
                      />
                    )}
                    <TextInput
                      {...form}
                      name="fullname"
                      placeholder={TEXT_FULL_NAME}
                      type="text"
                      className="marginBottom-10"
                    />
                    <TextInput
                      {...form}
                      name="email"
                      placeholder={TEXT_EMAIL_ADDRESS}
                      type="email"
                      className="marginBottom-10"
                    />
                    <PhoneNumberInput
                      {...form}
                      country={countryCode}
                      name="phoneNumber"
                      enableSearchField={true}
                      searchPlaceholder={TEXT_SEARCH_COUNTRY}
                      onChange={value => {
                        form.values.phoneNumber = value;
                      }}
                      className="marginBottom-10"
                    />
                    <PasswordInput
                      {...form}
                      name="password"
                      placeholder={TEXT_PASSWORD}
                      type="password"
                      className="marginBottom-10"
                    />
                    <TextInput
                      {...form}
                      name="referralCode"
                      placeholder="Referral code (Optional)"
                      type="text"
                      className="marginBottom-10"
                    />
                    <CheckBox
                      style={{ marginTop: 20 }}
                      {...form}
                      name="terms"
                      text="By clicking Sign Up, you agree to our Terms and that you have ready our Data Use Policy, including our Cookie Use."
                      onChange={e => {
                        form.setFieldValue("terms", e.target.checked);
                      }}
                      checked={form.values.terms}
                    />
                    <CustomizedButton
                      type="primary"
                      htmlType="submit"
                      isBlockType={true}
                      className="login-form-button marginBottom-10"
                      text={TEXT_SIGNUP}
                      isLoading={isLoading}
                      style={{ marginTop: 2 }}
                    />
                  </Form>
                )
              }}
            </Formik>
          </Card>
          <p className="footer-link">
            <Link to={`${process.env.PUBLIC_URL}/login`}>
              <CustomizedButton
                className="button__transparent color_light-blue"
                text={"Back to Log in"}
              />
            </Link>
          </p>
        </div>
      </Layout>
    </Spin>
  );
}

export default SignUpPage;
