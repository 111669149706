import React from "react";
import { Checkbox } from "antd";

const LabelledCheckbox = React.memo(({
  text,
  onChange,
  checkboxStyle,
  checked,
  description,
  disabled
}) => {

  return (
    <div style={{ marginBottom: 10 }}>
      <Checkbox
        onChange={onChange}
        checked={checked}
        style={checkboxStyle}
        disabled={disabled}
      >
        <span style={{ color: '#000000d9' }}>{text}</span>
      </Checkbox>
      {
        description &&
        <div style={{ fontSize: 13, marginBottom: 14 }}>
          {description}
        </div >
      }
    </div>
  )
})

export default LabelledCheckbox;
