import React from "react";
import { Form, InputNumber } from "antd";

const LabelledInputNumber = React.memo(({
  value,
  error,
  placeholder,
  type,
  prefix,
  label,
  max,
  onChange,
  addonAfter,
  addonBefore,
  size,
  disabled,
  className,
  style,
  touched,
  step,
  min,
  inputStyle,
  defaultValue,
  id,
  required
}) => {
  // console.log('LabelledInputNumber', value);

  return (
    <Form.Item
      validateStatus={disabled ? null : error && "error"}
      help={disabled ? null : error}
      label={label}
      className={className}
      style={style}
      required={required}
      noStyle
    >
      <InputNumber
        style={{ width: '100%', ...inputStyle }}
        step={step}
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        min={min}
        max={max}
        id={id}
        prefix={prefix}
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        type={type || 'number'}
        size={size}
        defaultValue={defaultValue}
      />
    </Form.Item>
  )
})

export default LabelledInputNumber;
