import React, { useContext } from 'react';
import { Layout, Row, Col } from "antd";
import { Link } from "react-router-dom";
import PropTypes from 'prop-types';

// import logo from "./../images/Logo/delyvax-logo.svg";
import CustomizedButton from "../Components/CustomizedButton";
import { GlobalContext } from "../Store/store";
import { IMAGE_URL_ROOT } from 'Util/API';
import useWindowSize from 'Util/useWindowSize';

const { Footer } = Layout;


const PublicHeader = (props) => {
  const [state] = useContext(GlobalContext);
  const { showLogo, style } = props;
  const { companyDetails, customerDetails } = state.global;
  const windowSize = useWindowSize();

  return (
    <Layout style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', minHeight: '100vh' }}>
      <div style={{ padding: '1em', display: 'flex', flexDirection: windowSize[0] > 380 ? 'row' : 'column', justifyContent: 'space-between', marginBottom: 20 }}>
        <div>
          {
            showLogo &&
            <Link to={`${process.env.PUBLIC_URL}/`}>
              <img
                style={{ height: 64, marginTop: 10, marginBottom: 10 }}
                src={
                  companyDetails.logob ?
                    IMAGE_URL_ROOT + companyDetails.logob :
                    'https://cdn.delyva.app/assets/delyvax-logo-b.svg'
                  }
                alt="company logo"
              />
            </Link>
          }
        </div>
        {
          !customerDetails?.id &&
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link to={`${process.env.PUBLIC_URL}/login`}>
              <div style={{ marginRight: '1em' }}>Sign In</div>
            </Link>
            <Link to={`${process.env.PUBLIC_URL}/signup`}>
              <CustomizedButton text="Sign Up" type="primary" style={{ borderRadius: '2em' }} />
            </Link>
          </div>
        }
      </div>
      <Row style={{ ...props.style, width: '90vw', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {props.children}
      </Row>
      <Footer style={{ textAlign: 'center' }}>Powered by <a href="https://delyvax.com" target="_blank"><b>DelyvaX</b></a></Footer>
    </Layout>
  )
}

PublicHeader.propTypes = {
  showLogo: PropTypes.bool
}

PublicHeader.defaultProps = {
  showLogo: true
}

export default PublicHeader;
